import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import Multiselect from "multiselect-react-dropdown";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { Form, useForm } from "../../utills/useForms";
import {
    FresherJobUpdate,
    FresherPersonalUpdater,
    UpdateResume,
    getBasicInfoLists,
    getCityLists,
    getCountryLists,
    getJobPreferLists,
    getJobTypesLists,
    FresherEducationUpdate,
    getStateLists,
} from "../../state/action/fresherAction";
import { SkillList } from "../../state/action/skillAction";
import AutohideExample from "../../utills/Notification";




const initialValues = {
    jobPraferId: "",
    jobTypeId: "",
    prefferLocations: "",
    country1: "",
    country2: "",
    country3: "",
    state1: "",
    state2: "",
    state3: "",
    city1: "",
    city2: "",
    city3: "",
    fresherSkill: "",
};


function JobPrefrenceEdit(props) {

    const { JobPreference, setJobPreference, refresh, setRefresh } = props;
    const [deleteSkill, setDeleteSkill] = useState([]);
    const [skillData, setSkillData] = useState([]);
    const [selectSkill, setSelectSkill] = useState([]);
    const [jobPreferList, setJobPreferList] = useState([]);
    const [countryList1, setCountryList1] = useState([]);
    const [cityList1, setCityList1] = useState([]);
    const [stateList1, setStateList1] = useState([]);
    const [countryList0, setCountryList0] = useState([]);
    const [cityList0, setCityList0] = useState([]);
    const [stateList0, setStateList0] = useState([]);
    const [countryList2, setCountryList2] = useState([]);
    const [cityList2, setCityList2] = useState([]);
    const [stateList2, setStateList2] = useState([]);
    const [jobLocationList, setJobLocationList] = useState([]);
    const [jobList, setJobList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [show, setShow] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [skillList, setSkillList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [profileList, setProfileList] = useState();



    const validate = (fieldValues = values) => {
    }

    useEffect(() => {
        getContryData0();
        getContryData1();
        getContryData2();
        getJobTypeData();
        getSkillList();
        getJobPreferData();

    }, []);

    const getJobTypeData = async (e) => {
        const res = await getJobTypesLists();
        if (res && res.data) {
            setJobList(res.data);
        }
    };

    const getJobPreferData = async (e) => {
        const res = await getJobPreferLists();
        if (res && res.data) {
            setJobPreferList(res.data);
        }
    };


    const getLocationValidation = () => {
        //if select country 1 then state 1 and city 1 is required
        if (
            values.country1 == "" &&
            values.country2 == "" &&
            values.country3 == ""
        ) {
            setShow({
                isOpen: true,
                message: "Please select atleast one location",
                type: "error",
            });
            return false;
        }

        if (values.country1 != "") {
            if (values.state1 == "") {
                setShow({
                    isOpen: true,
                    message: "Please select state",
                    type: "error",
                });
                return false;
            } else if (values.city1 == "") {
                setShow({
                    isOpen: true,
                    message: "Please select city",
                    type: "error",
                });
                return false;
            }
        }

        if (values.country2 != "") {
            if (values.state2 == "") {
                setShow({
                    isOpen: true,
                    message: "Please select state",
                    type: "error",
                });
                return false;
            }
            if (values.city2 == "") {
                setShow({
                    isOpen: true,
                    message: "Please select city",
                    type: "error",
                });
                return false;
            }
        }

        if (values.country3 != "") {
            if (values.state3 == "") {
                setShow({
                    isOpen: true,
                    message: "Please select state",
                    type: "error",
                });
                return false;
            }
            if (values.city3 == "") {
                setShow({
                    isOpen: true,
                    message: "Please select city",
                    type: "error",
                });
                return false;
            }
        }
        return true;
    };
    const [width, setWidth] = useState(window.innerWidth);

    const Id = useSelector((state) => state?.auth?.user?.id);

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);


    const getSkillList = async () => {
        const res = await SkillList();
        if (res && res.data) {
            const skills = res.data.map((item) => {
                return { skillId: item.id, skillName: item.title };
            });
            setSkillData(skills.filter((item) => { return !selectSkill.some((item2) => { return item.skillId === item2.skillId }) }));

        }
    };
    const multiselectSelectSkill = (data, e) => {
        setSelectSkill([...selectSkill, {
            skillId: e.skillId,
            skillName: e.skillName,
            fresherId: Id,
        }]);
        setSkillData(skillData.filter((item) => { return !data.some((item2) => { return item.skillId === item2.skillId }) }));
    };
    //remove the skill
    const removeSkill = async (e, item) => {
        setSkillData([...skillData, item]);
        const removeSkill = selectSkill.filter(
            (items) => items.skillId != item.skillId
        );
        setSelectSkill(removeSkill);
        if (item && item.id) {
            setDeleteSkill([...deleteSkill, item?.id]);
        }
    };



    const handleCountryLocation0 = (e) => {
        setValues({
            ...values,
            country1: e.target.value,
            state1: "",
            city1: "",
        });
        handleStateList0(e.target.value);
        setCityList0([]);
    };

    const handleStateLocation0 = (e) => {
        setValues({
            ...values,
            state1: e.target.value,
            city1: "",
        });
        handleCityList0(e.target.value);
    };

    const getContryData0 = async () => {
        const res = await getCountryLists();
        if (res && res.data) {
            setCountryList0(res.data);
            setCityList0([]);
            setStateList0([]);
        } else {
            setCountryList0([]);
            setCityList0([]);
            setStateList0([]);
        }
    };
    //city Data

    const handleCityList0 = async (id) => {
        const res = await getCityLists(id);
        if (res.status === true) {
            setCityList0(res.data);
        } else {
            setCityList0([]);
        }
    };

    //state Data
    const handleStateList0 = async (id) => {
        const res = await getStateLists(id);
        if (res.status === true) {
            setStateList0(res.data);
        } else {
            setStateList0([]);
        }
    };

    //country 1Data
    const handleCountryLocation1 = (e) => {
        setValues({
            ...values,
            country2: e.target.value,
            state2: "",
            city2: "",
        });
        handleStateList1(e.target.value);
        setCityList1([]);
    };

    const handleStateLocation1 = (e) => {
        setValues({
            ...values,
            state2: e.target.value,
            city2: "",
        });
        handleCityList1(e.target.value);
    };

    const getContryData1 = async () => {
        const res = await getCountryLists();
        if (res && res.data) {
            setCountryList1(res.data);
            setCityList1([]);
            setStateList1([]);
        } else {
            setCountryList1([]);
            setCityList1([]);
            setStateList1([]);
        }
    };
    //city1 Data

    const handleCityList1 = async (id) => {
        const res = await getCityLists(id);
        if (res.status === true) {
            setCityList1(res.data);
        } else {
            setCityList1([]);
        }
    };

    //state1 Data
    const handleStateList1 = async (id) => {
        const res = await getStateLists(id);
        if (res.status === true) {
            setStateList1(res.data);
        } else {
            setStateList1([]);
        }
    };

    //country 1Data
    const handleCountryLocation2 = (e) => {
        setValues({
            ...values,
            country3: e.target.value,
            state3: "",
            city3: "",
        });
        handleStateList2(e.target.value);
        setCityList2([]);
    };

    const handleStateLocation2 = (e) => {
        setValues({
            ...values,
            state3: e.target.value,
            city3: "",
        });
        handleCityList2(e.target.value);
    };

    const getContryData2 = async () => {
        const res = await getCountryLists();
        if (res && res.data) {
            setCountryList2(res.data);
            setCityList2([]);
            setStateList2([]);
        } else {
            setCountryList2([]);
            setCityList2([]);
            setStateList2([]);
        }
    };
    //city1 Data

    const handleCityList2 = async (id) => {
        const res = await getCityLists(id);
        if (res.status === true) {
            setCityList2(res.data);
        } else {
            setCityList2([]);
        }
    };

    //state1 Data
    const handleStateList2 = async (id) => {
        const res = await getStateLists(id);
        if (res.status === true) {
            setStateList2(res.data);
        } else {
            setStateList2([]);
        }
    };



    const getContryData = async () => {
        const res = await getCountryLists();
        if (res && res.data) {
            setCountryList(res.data);
            // setCityList([]);
            // setStateList([]);
        } else {
            setCountryList([]);
            // setCityList([]);
            // setStateList([]);
        }
    };
    //city Data

    const handleCityList = async (id) => {
        const res = await getCityLists(id);
        if (res.status === true) {
            setCityList(res.data);
        } else {
            setCityList([]);
        }
    };

    //state Data
    const handleStateList = async (id) => {
        const res = await getStateLists(id);
        if (res.status === true) {
            setStateList(res.data);
        } else {
            setStateList([]);
        }
    };

    const getPrefferLocation = (e, i) => {
        setJobLocationList((prevState) => {
            if (!Array.isArray(prevState)) {
                // Handle the case where prevState is not an array
                return [];
            }
            const newState = [...prevState];
            if (i >= newState.length) {
                // Handle the case where i is out of bounds
                newState.push({
                    [e.target.name]: e.target.value,
                });
            } else {
                newState[i] = {
                    ...newState[i],
                    [e.target.name]: e.target.value,
                };
            }
            return newState;
        });
    };
    useEffect(() => {
        if (Id) {
            getProfileList(Id);
        }
    }, [Id]);

    const getProfileList = async () => {

        const res = await getBasicInfoLists(Id);

        if (res && res.status === true) {
            getContryData();
            handleCityList(res?.data?.stateId?.id);
            handleStateList(res?.data?.countryId?.id);
            setProfileList(res?.data);
            setJobLocationList([
                {
                    id: res?.data?.jobLocation[0]?.id,
                    fresherId: Id,
                    country1: res?.data?.jobLocation[0]?.countryId,
                    state1: res?.data?.jobLocation[0]?.stateId,
                    city1: res?.data?.jobLocation[0]?.cityId,
                    cityName: res?.data?.jobLocation[0]?.cityName,
                    stateName: res?.data?.jobLocation[0]?.stateName,
                    countryName: res?.data?.jobLocation[0]?.countryName,
                },
                {
                    id: res?.data?.jobLocation[1]?.id,
                    fresherId: Id,
                    country2: res?.data?.jobLocation[1]?.countryId,
                    state2: res?.data?.jobLocation[1]?.stateId,
                    city2: res?.data?.jobLocation[1]?.cityId,
                    cityName: res?.data?.jobLocation[1]?.cityName,
                    stateName: res?.data?.jobLocation[1]?.stateName,
                    countryName: res?.data?.jobLocation[1]?.countryName,
                },
                {
                    id: res?.data?.jobLocation[2]?.id,
                    fresherId: Id,
                    country3: res?.data?.jobLocation[2]?.countryId,
                    state3: res?.data?.jobLocation[2]?.stateId,
                    city3: res?.data?.jobLocation[2]?.cityId,
                    cityName: res?.data?.jobLocation[2]?.cityName,
                    stateName: res?.data?.jobLocation[2]?.stateName,
                    countryName: res?.data?.jobLocation[2]?.countryName,
                },
            ]);
            setSkillList(res?.data?.fresherSkill);
            const formattedEndDate = new Date(
                res.data?.eduEndDate === "null" ? "null" : res?.data?.eduEndDate
            ).toLocaleDateString("en-CA");

            const formattedStartDate = new Date(
                res?.data?.eduStartDate
            ).toLocaleDateString("en-CA");

            setValues({
                ...values,
                jobPraferId: res?.data?.jobPraferId?.id,
                jobTypeId: res?.data?.jobTypeId?.id,
                country1: res?.data?.jobLocation[0]?.countryId,
                state1: res?.data?.jobLocation[0]?.stateId,
                city1: res?.data?.jobLocation[0]?.cityId,
                country2: res?.data?.jobLocation[1]?.countryId,
                state2: res?.data?.jobLocation[1]?.stateId,
                city2: res?.data?.jobLocation[1]?.cityId,
                country3: res?.data?.jobLocation[2]?.countryId,
                state3: res?.data?.jobLocation[2]?.stateId,
                city3: res?.data?.jobLocation[2]?.cityId,
                fresherSkill: res?.data?.fresherSkill,

            });
            // setStudyRunning(res?.data?.studyRunning);

            handleStateList0(res?.data?.jobLocation[0]?.countryId);
            handleCityList0(res?.data?.jobLocation[0]?.stateId);
            handleStateList1(res?.data?.jobLocation[1]?.countryId);
            handleCityList1(res?.data?.jobLocation[1]?.stateId);
            handleStateList2(res?.data?.jobLocation[2]?.countryId);
            handleCityList2(res?.data?.jobLocation[2]?.stateId);
            setSelectSkill(res?.data?.fresherSkill);
        } else {
        }
    };
    const jobPreferValidation = () => {
        if (values.jobPraferId == "" || values.jobTypeId == "") {
          setShow({
            isOpen: true,
            message: "Please select job prefer",
            type: "error",
          });
          return false;
        }else{
          return true;
        }
      }

    const handleSubmitJob = async (e) => {
        e.preventDefault();

        if (getLocationValidation()&&jobPreferValidation()) {
            const payload = {
                location: jobLocationList,
                jobPraferId: values.jobPraferId,
                jobTypeId: values?.jobTypeId,
                skillId: selectSkill,
                deleteSkill: deleteSkill,
            };
            const res = await FresherJobUpdate(payload);

            if (res && res.status === true) {
                setJobPreference(false);
                getProfileList(Id);
                setRefresh(!refresh)
                setShow({
                    isOpen: true,
                    message: res?.message,
                    type: "success",
                });
                setDeleteSkill([]);
            } else {
                setShow({
                    isOpen: true,
                    message: res?.message,
                    type: "error",
                });
            }
        }
    };


    return (
        <div>
            <Modal
                size="lg"
                show={JobPreference}
                onHide={() => {
                    getProfileList(Id);
                    setJobPreference(false)
                }}
                aria-labelledby=""
                centered
            >
                <Form onSubmit={handleSubmitJob}>
                    <Modal.Header className="border-0" closeButton></Modal.Header>
                    <Modal.Body className="px-lg-5">
                        <div className="row">
                            <div className="col-12 col-lg mb-16">
                                <div className="font-22-28 fw-medium mb-2">Job preference</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12"></div>
                            <div className="col-12">
                                <div className="row">
                                    {width <= 767 ?
                                        <>
                                            <div className="col">
                                                Preferred Location 1
                                            </div>
                                            <hr />
                                        </>
                                        : ''}
                                    <div className="col-12 col-md-4 pe-md-0 mb-2">
                                        <div className="form-label">Country</div>

                                        <select
                                            className="form-select"
                                            name="country1"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 0);
                                                handleCountryLocation0(e);
                                                // handleInputChange(e);
                                            }}
                                            value={values.country1}
                                        >
                                            <option value="">None</option>
                                            {countryList0.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 mb-2">
                                        <div className="form-label">State</div>

                                        <select
                                            className="form-select"
                                            name="state1"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 0);
                                                handleStateLocation0(e);
                                                // handleInputChange(e);
                                            }}
                                            value={values.state1}
                                        >
                                            <option value="">None</option>

                                            {stateList0.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 ps-md-0 mb-2">
                                        <div className="form-label">City</div>

                                        <select
                                            className="form-select"
                                            name="city1"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 0);
                                                handleInputChange(e);
                                            }}
                                            value={values.city1}
                                        >
                                            <option value="">None</option>

                                            {cityList0.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    {width <= 767 ?
                                        <>
                                            <div className="col pt-3">
                                                Preferred Location 2
                                            </div>
                                            <hr />
                                        </>
                                        : ''}
                                    <div className="col-12 col-md-4 pe-md-0 mb-2">
                                        {width <= 767 ?
                                            <div className="form-label">Country</div>
                                            : ''}

                                        <select
                                            className="form-select"
                                            name="country2"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 1);
                                                handleCountryLocation1(e);
                                            }}
                                            value={values.country2}
                                        >
                                            <option value="">None</option>

                                            {countryList1.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 mb-2">
                                        {width <= 767 ?
                                            <div className="form-label">State</div>
                                            : ''}
                                        <select
                                            className="form-select"
                                            name="state2"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 1);
                                                handleStateLocation1(e);
                                            }}
                                            value={values.state2}
                                        >
                                            <option value="">None</option>
                                            {stateList1.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 ps-md-0 mb-2">
                                        {width <= 767 ?
                                            <div className="form-label">City</div>
                                            : ''}
                                        <select
                                            className="form-select"
                                            name="city2"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 1);
                                                handleInputChange(e);
                                            }}
                                            value={values.city2}
                                        >
                                            <option value="">None</option>
                                            {cityList1.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    {width <= 767 ?
                                        <>
                                            <div className="col pt-3">
                                                Preferred Location 3
                                            </div>
                                            <hr />
                                        </>
                                        : ''}
                                    <div className="col-12 col-md-4 pe-md-0 mb-2">
                                        {width <= 767 ?
                                            <div className="form-label">Country</div>
                                            : ''}
                                        <select
                                            className="form-select"
                                            name="country3"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 2);
                                                handleCountryLocation2(e);
                                            }}
                                            value={values.country3}
                                        >
                                            <option value="">None</option>                                             {countryList2.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 mb-2">
                                        {width <= 767 ?
                                            <div className="form-label">State</div>
                                            : ''}
                                        <select
                                            className="form-select"
                                            name="state3"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 2);
                                                handleStateLocation2(e);
                                            }}
                                            value={values.state3}
                                        >
                                            <option value="">state</option>
                                            {stateList2.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 ps-md-0 mb-2">
                                        {width <= 767 ?
                                            <div className="form-label">City</div>
                                            : ''}
                                        <select
                                            className="form-select"
                                            name="city3"
                                            onChange={(e) => {
                                                getPrefferLocation(e, 2);
                                                handleInputChange(e);
                                            }}
                                            value={values.city3}
                                        >
                                            <option value="">None</option>                                             {cityList2.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {width <= 767 ?
                                    <hr />
                                    : ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="form-label">
                                    What type of jobs do you prefer?
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-16">
                                <select
                                    className="form-select"
                                    name="jobTypeId"
                                    onChange={handleInputChange}
                                    value={values?.jobTypeId}
                                >
                                    <option value="">None</option>                                     {jobList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 col-md-6 mb-16">
                                <select
                                    className="form-select"
                                    name="jobPraferId"
                                    onChange={handleInputChange}
                                    value={values?.jobPraferId}
                                >
                                    <option value="">None</option>                                     {jobPreferList?.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 mb-16">
                                <div className="col-12 col-md-12 mb-16">
                                    <div className="form-label">Skills</div>

                                    <Multiselect
                                        options={skillData} // Options to display in the dropdown
                                        selectedValues={selectSkill} // Preselected value to persist in dropdown
                                        onSelect={multiselectSelectSkill} // Function will trigger on select event
                                        onRemove={removeSkill} // Function will trigger on remove event
                                        // displayValue={(option) => (option ? option.skillName : "")} // Property name to display in the dropdown options
                                        displayValue="skillName"
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
                        <Button
                            variant="outline-primary"
                            className="h-44 fw-medium mx-md-4"
                            onClick={() => {
                                setJobPreference(false);
                                setDeleteSkill([]);
                                getProfileList(Id);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            className="h-44 fw-medium m-0"
                            type="submit"
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <AutohideExample show={show} setShow={setShow} />

        </div>
    )
}

export default JobPrefrenceEdit
