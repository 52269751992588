import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        fontSize: '1.2rem',
    },
    submitButton: {
        backgroundColor: '#3498db',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '20px',
    },
    homeButton: {
        backgroundColor: '#143F87',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '10px',
    },

};

const ResultScreen = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (!state && !state?.result) {
        }
        else if (state && state?.result === 1) {
            window.location.reload();
        }
    }, []);

    const handleHome = () => {
        navigate('/assessments', { state: { result: 1 } });
    };
    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);

        const handleBackButton = (event) => {
            event.preventDefault();
            window.history.pushState(null, document.title, window.location.href);
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);


    return (
        <div style={styles.container}>
            <h1> Your Test has been submitted successfully</h1>
            {/* <button style={styles.submitButton} onClick={handleSubmit}>
                Submit
            </button> */}
            <button style={styles.homeButton} onClick={handleHome}>
                Go To Home
            </button>
        </div>
    );
};

export default ResultScreen;
