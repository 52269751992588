import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const PrivateRoute = ({ children, ...props }) => {

    const token = useSelector((state) => state?.auth?.token);

    return token ? children : <Navigate to={props.path} />;
    // return <Navigate to="/login" />;
};

export default PrivateRoute;
