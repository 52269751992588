import React from "react";
import CountryList from "../Location/CountryList";

function country() {
  return (
    <div>
      <CountryList />
    </div>
  );
}

export default country;
