import axiosInstance from "../../utills/AxiosInstance";

//employer list
export const getEmployerLists = async (queryString) => {
    try {
        const response = await axiosInstance.get('/employee/employerList'+queryString)

        return response.data;
    } catch (error) {
        return error;
    }
};

// Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/employee/employerStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete 

export const deleteEmployer = async (id) => {
  try {
    const response = await axiosInstance.delete(`/employee/deleteEmployer/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const getEmployerDetails = async (id) => {
    try {
        const response = await axiosInstance.get(`/employee/getEmployerById/${id}`)

        return response.data;
    } catch (error) {
        return error;
    }
};

