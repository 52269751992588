import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";
import {
  RoleListApi,
  StatusUpdate,
  deleteRole,
} from "../../state/action/addRoleAction";
import Form from "react-bootstrap/Form";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import AddRole from "./addRole";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";
import { IsWrite, IsDelete } from "../../utills/handlePermission";
import '../../components/pagination/style.scss';
import '../../components/pagination/pagination.scss';
import PaginationOne from '../../components/pagination/PaginationOne';
import { useMemo } from 'react';


const initialValues = {
  name: "",
};

const statuslist = [
  { id: "1", title: "Active" },
  { id: "0", title: "Inactive" },
];
const Role = () => {

  const deletePermissions = IsDelete("role");
  const editPermissions = IsWrite("role");


  const [flagName, setflagName] = useState(false);
  const [RoleList, setRoleList] = useState([]);
  const [roleDataList, setroleDataList] = useState([]);
  const [view, setView] = useState(false);

  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  const [openFilterModel, setOpenFilterModel] = useState(false);
  const [totalRecords, setTotalRecords] = useState(3);
  // const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [deleteId, setDeleteId] = useState(null);
  const [id, setId] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const [sortFlag, setSortFlag] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [totalpages, setTotalPages] = useState(10);


  const navigate = useNavigate();
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);

  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);

  const handleFilterModel = () => setOpenFilterModel(true);
  const handleCloseFilter = () => setOpenFilterModel(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  let PageSize = 10;
  const RoleDataPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return RoleList;
  }, [currentPage, RoleList]);


  //role list

  useEffect(() => {
    getRoleList("", "");
  }, [refresh, filterData, search, page]);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const getRoleList = async (page, name = "", flag, limit, perPageSize) => {
    setOpenBreakdrop(true);
    setView(true);

    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let recordLimit = limit ? limit : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&sortby=${name}&sortFlag=${flag}`;



    const res = await RoleListApi(queryString);
    if (res.status === true) {
      setRoleList(res.data);
      setTotalRecords(res.total);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPageLimit(recordLimit)
      setView(false);
    } else {
      setRoleList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      setView(false);
    }
    setFilter(false);
  };



  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPage(page);
    getRoleList(page, "", "", pageLimit); // Call the API with the new page number and page limit
  };


  const handlePageLimitChange = (e) => {
    const limit = parseInt(e.target.value);
    setPageLimit(limit || 10);
    setCurrentPage(1); // Reset the current page to 1 when page limit is changed
    getRoleList(1, "", "", limit); // Call the API with the new page limit and reset the page number to 1
  };




  //status change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getRoleList(fieldName, flagName);
      } else {
        getRoleList(currentPage, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const handleReset = (e) => {
    resetForm();
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
    // resetForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    closeDrawer();
  };

  const handleDelete = async () => {
    const res = await deleteRole(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getRoleList(fieldName, flagName);
      } else {
        getRoleList("");
      }
      handleCloseDelete();
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setView(false);
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
      setView(false);
    }
  };

  return (
    <div>
      <Loader view={view} />
      {/* {!view && ( */}
      <>
        <main className="body-background">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 my-4">
                <div className="rounded-card-head">
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3">
                      <div className="font-PTSerif-b ps-2 font-18-24-05">
                        Role
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="col col-md-auto text-end d-flex justify-content-end flex-wrap">
                        <Controls.Input
                          className="border-0 rounded p-2 mt-2"
                          placeholder="search"
                          name="text"
                          type="text"
                          onChange={(e) => {
                            setTimeout(() => {
                              setSearch(e.target.value);
                            }, 800);
                          }}
                        />

                        <div className="mt-2">
                   
                          {editPermissions && (
                            <Controls.Button
                              style={{ padding: "7px 30px", marginLeft: "16px" }}
                              className="btn btn-primary h-40"
                              text="Add Role"
                              onClick={() => {
                                setIsEdit(false);
                                handleAddSwitchModel();
                              }}
                            />
                          )}
                          <Controls.Button
                            style={{ padding: "7px 30px", marginLeft: "16px" }}
                            className="btn btn-success h-40"
                            text="filter"
                            onClick={() => {
                              toggleDrawer();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showDrawer && (
                  <>
                  <div className="filter-drawer-overlay" onClick={toggleDrawer}>
                    </div>
                  
                    <div className="filter-drawer-content">
                      <div className="d-flex justify-content-between align-items-center filter-header_div ">
                        <label className="filter-header">Filter</label>
                        <button
                          className="filter-close-button"
                          onClick={toggleDrawer}
                        >
                          x
                        </button>
                      </div>
                      <div className="d-flex flex-column h-100">
                        <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">
                          <div>
                            <div>
                              <label className="filter-label">role</label>
                              <Controls.Input
                                className="input_filter"
                                name="name"
                                type="text"
                                value={values?.name}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div>
                              <label className="filter-label">status</label>
                              <select
                                className="form-select h-auto px-3 py-2 "
                                name="isActive"
                                onChange={handleInputChange}
                                value={values.isActive}
                              >
                                <option>status</option>
                                {statuslist?.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="position-relative bottom-0 mb-5 filter-reset-div">
                            <div className="d-flex justify-content-between">
                              <button
                                className="filter-reset-btn"
                                onClick={handleReset}
                              >
                                Reset
                              </button>
                              <button
                                className="filter-btn"
                                type="submit"
                              //onClick={closeDrawer}
                              >
                                Filter
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    </>
                  
                )}
                {/* <select
                      className="w-20 border-0 rounded p-1 mb-3"
                      value={pageLimit}
                      onChange={handlePageLimitChange}
                    >
                      <option value={10}>10 per page</option>
                      <option value={20}>20 per page</option>
                      <option value={30}>30 per page</option>
                 
                    </select> */}

                <div className="theme-table-wrap">
                  <Table responsive className="striped-table mb-0">
                    <thead>
                      <tr>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Date</th>
                        {editPermissions || deletePermissions ? (
                          <th>Action</th>) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {RoleDataPage.map((row) => (
                        <tr
                          key={row.id}
                          className="border rounded"
                          style={{ backgroundColor: "white" }}
                        >
                          <td style={{ width: "25%" }} className="table-cell">
                            {row?.name}
                          </td>
                          <td style={{ width: "25%" }} className="table-cell">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={row.isActive === 1}
                              disabled={row.id == 3 ? true : false}
                              onChange={(e) => {

                                if (!editPermissions) {
                                  setShow({
                                    isOpen: true,
                                    message: "You don't have permission to edit",
                                    type: "error",
                                  });
                                }
                                else {
                                  handleSwitchModel();
                                  setStatus(
                                    e.target.checked ? "Active" : "Inactive"
                                  );
                                  setSwitchId(row.id);
                                  setChecked(e.target.checked);
                                }

                              }}

                            />
                          </td>
                          <td style={{ width: "25%" }} className="table-cell">
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </td>

                          {editPermissions || deletePermissions ? (

                            <td style={{ width: "25%" }} className="table-cell">
                              <ul>
                                <li>
                                  {editPermissions && (<button
                                    className="border-0 rounded px-2 py-1 me-2"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Delete"
                                    onClick={() =>
                                      navigate("/admin/addPermission", {
                                        state: { id: row.id },
                                      })
                                    }
                                    disabled={row.id == 3 ? true : false}
                                  >
                                    Add permission
                                  </button>)}

                                  {deletePermissions && (<button
                                    className="btn btn-sm btn-icon btn-danger btn-inner"
                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                    onClick={() => {
                                      handleDeleteSwitchModel();
                                      setId(row.id);
                                      setDeleteId(row?.id);
                                      handleDeleteModel();
                                    }}
                                    disabled={row.id == 3 ? true : false}
                                  >
                                    <span className="btn-inner">
                                      <DeleteIcon />
                                    </span>
                                  </button>)}

                                </li>
                              </ul>
                            </td>) : null}
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {RoleDataPage && RoleDataPage.length === 0 ? (
                    <div
                      className="noDataFound"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      No Data Found
                    </div>
                  ) : null}
                </div>

                <PaginationOne
                  currentPage={currentPage}
                  totalCount={totalRecords}
                  pageSize={PageSize}
                  onPageChange={page => [setCurrentPage(page), getRoleList(page, "", "", pageLimit)]} />
                <div className="pt-2">
                  {" "}
                  Showing{" "}
                  {currentPage * pageLimit > totalRecords
                    ? totalRecords
                    : currentPage * pageLimit}{" "}
                  of {totalRecords} Results
                </div>



              </div>
            </div>
          </div>

          {/* status change */}
          <Modal
            size="md"
            show={openSwitchModel}
            onHide={() => handleCloseSwitch(false)}
            aria-labelledby=""
            centered
          >
            <Modal.Header
              className="border-0 pb-0"
              closeButton
            ></Modal.Header>
            <Modal.Body>
              <p className="mb-0 font-18-24-05 fw-medium">
                Are you sure you want to status change?
              </p>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
              <Button
                className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                onClick={() => handleCloseSwitch()}
              >
                No
              </Button>
              <Button
                className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                type="submit"
                onClick={() => {
                  handleCloseSwitch();
                  handleChangeStatus(switchId);
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* delete */}
          <Modal
            size="md"
            show={openDeleteSwitchModel}
            onHide={() => handleDeleteCloseSwitch(false)}
            aria-labelledby=""
            centered
          >
            <Modal.Header
              className="border-0 pb-0"
              closeButton
            ></Modal.Header>
            <Modal.Body>
              <p className="mb-0 font-18-24-05 fw-medium">
                Are you sure you want to delete?
              </p>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
              <Button
                className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                onClick={() => handleDeleteCloseSwitch()}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                type="submit"
                onClick={() => {
                  handleDeleteCloseSwitch();
                  handleDelete(deleteId);
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <AddRole
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            handleAddCloseSwitch={handleAddCloseSwitch}
            openAddSwitchModel={openAddSwitchModel}
            roleDataList={roleDataList}
            setOpenFilterModel={setOpenFilterModel}
            handleCloseFilter={handleCloseFilter}
            refreshScreen={refreshScreen}
          />
          <AutohideExample show={show} setShow={setShow} />
        </main>
      </>
      {/* )} */}
    </div>
  );
};

export default Role;
