import React from 'react'
import { useNavigate } from 'react-router-dom'
import error404 from '../../assets/images/error404.png'

const Error = () => {
    const navigate = useNavigate();
    return (
        <div className='container h_100'>
            <div className='row justify-content-between h_100 align-items-center gap-4'>
                <div className='col-md-4'>
                    <div className='row gap-1'>
                        <div className='col-md-12'>
                            <div className='error_heading'>
                                Error 404
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='error_para'>
                                Something went wrong, the page you're looking for is not found.
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <button className='btn btn-primary' onClick={() => { navigate('/') }}>Back to Homepage</button>
                        </div>
                    </div>
                </div>
                <div className='col-md-7'>
                    <img src={error404} className='w-100' />
                </div>
            </div>
        </div>
    )
}

export default Error