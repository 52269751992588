import React, { useState } from "react";
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import SliderImage1 from "../../assets/images/connecting_with_people.svg";
import SliderImage2 from "../../assets/images/man_using_browser.svg";
import SliderImage3 from "../../assets/images/man_climbing_mountain.svg";
import Controls from "../../components/controls/Controls";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useForm } from "../../utills/useForms";
import {
    changepassword,
} from "../../state/action/fresherforgotpasswordAction";
import AutohideExample from "../../utills/Notification";
import { AiOutlineEyeInvisible, AiOutlineEye } from "../../assets/Icons/icons";
import { Loader } from "../../utills/Loader";
import { useLocation } from "react-router-dom";
import ArrowLeft from "../../assets/images/arrow_left.svg";
import { Tooltip } from "react-bootstrap";
const initialValues = { password: "", confirmPassword: "" };
const ChangePassword = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const { state } = useLocation();


    const [showPassword, setShowPassword] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(() => {
        if (state && state?.email) {
            setEmail(state?.email);
        } else {
            navigate("/sendotp");
        }
    }, []);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const [cpasswordVisible, setCPasswordVisible] = useState(false);

    const toggleCPasswordVisibility = () => {
        setCPasswordVisible(!cpasswordVisible);
    };
    const handlePasswordToggle = () => {
        setShowPassword((prevState) => !prevState);
    };

    const [loadingButton, setLoadingButton] = useState(false);
    const [show, setShow] = useState({
        isOpen: false,
        message: "",
        type: "",
    });


    const navigate = useNavigate();
    const [view, setView] = useState(false);



    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("password" in fieldValues) {
            if (fieldValues.password !== "" && fieldValues.password !== undefined) {
                if (fieldValues.password.length < 8) {
                    temp.password = "Password  must be 8 charecter";
                } else {
                    if (!/[0-9]/.test(fieldValues.password)) {
                        temp.password = "Password  must be 1 Number";
                    } else {
                        if (!/[a-z]/.test(fieldValues.password)) {
                            temp.password = "Password  must be 1 Lowercase letter";
                        } else {
                            if (!/[A-Z]/.test(fieldValues.password)) {
                                temp.password = "Password  must be 1 Uppercase letter";
                            } else {
                                // setPass(fieldValues.password);
                                temp.password = "";
                            }
                        }
                    }
                }
            } else {
                temp.password = "This field is required.";
            }
        }

        if ("confirmPassword" in fieldValues) {
            if (fieldValues.confirmPassword !== values.password) {
                temp.confirmPassword = "Password and Confirm Password must be same";
            } else {
                temp.confirmPassword = "";
            }

        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);

        const handleBackButton = (event) => {
            event.preventDefault();
            window.history.pushState(null, document.title, window.location.href);
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {

            let resetpasswordres = await changepassword({
                email: email,
                password: values.password,
            });

            if (resetpasswordres && resetpasswordres.status === true) {
                setShow({
                    isOpen: true,
                    message: resetpasswordres?.message || resetpasswordres?.message,
                    type: "success",
                });

                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            } else {
                setLoadingButton(false);
                setShow({
                    isOpen: true,
                    message:
                        resetpasswordres?.message || "Technical error please try again later.",
                    type: "error",
                });
            }
        }
    };

    return (
        <main>
            <Loader view={view} />

            <div className="container">
                <header className="row header-logo-wrap justify-content-between header-row-wrap">
                    <div className="col-auto">
                        <a href="" className="d-inline-block">
                            {/* <img
                                src={TechfreshrLogo}
                                className="img-fluid header-logo-width"
                            /> */}
                            <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

                        </a>
                    </div>
                    {/* <div className="col-auto align-self-center">
                        <div className='arrowleft'>
                            <a
                                href="javascript:history.go(-1)"
                            >
                                <img src={ArrowLeft} className="img-fluid mx-auto" />
                            </a>
                        </div>
                    </div> */}
                </header>
            </div>
            <div className="container">
                <div className="row login-height-calc align-items-center">
                    <div
                        className="col-lg-6 d-none d-lg-block align-self-center" style={{
                            height: '630px', width: '43%',
                            margin: 'auto'
                        }}
                    >
                        <div className="blur-gradiant">
                            <div className="login-left-slider">
                                <Slider {...settings}>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage1}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Large Network
                                                </div>
                                                <div className="slider-content-text">
                                                    Gain access to a vast network of recruiters <br /> and
                                                    top employers.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage2}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Best-fit Job
                                                </div>
                                                <div className="slider-content-text">
                                                    Find your dream job with our personalized <br />{" "}
                                                    matching system.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage3}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Achieve your Goals
                                                </div>
                                                <div className="slider-content-text">
                                                    Start building your dream career and soar to <br />{" "}
                                                    new heights with our exciting job <br />{" "}
                                                    opportunities.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="login-box-width width_40">
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <h1 className="login-top-head mb-32 font-PTSerif-b">Forgot password</h1>
                                <div className="row">
                                    <div className="col-12 col-md-12 mb-16">
                                        <div className="form-label">New Password</div>
                                        <div className="d-flex flex-column input-relative">
                                            <Controls.Input
                                                className="form-control"
                                                placeholder="Enter your password"
                                                name="password"
                                                lable="enter your password"
                                                value={values.password}
                                                onChange={handleInputChange}
                                                type={passwordVisible ? "text" : "password"}
                                                maxLength="12"
                                            />
                                            <div
                                                className="position-absolute password_eye"
                                                variant="link"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {passwordVisible ? (
                                                    <AiOutlineEyeInvisible
                                                        style={{ width: "80%", height: "30px" }}
                                                    />
                                                ) : (
                                                    <AiOutlineEye
                                                        style={{ width: "80%", height: "30px" }}
                                                    />
                                                )}
                                            </div>
                                            {Boolean(errors.password) ? (
                                                <p className="text-invalid">{errors.password}</p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-12 mb-16">
                                        <div className="form-label">Confirm Password</div>
                                        <div className="d-flex flex-column input-relative">
                                            <Controls.Input
                                                className="form-control"
                                                placeholder="Enter your password"
                                                name="confirmPassword"
                                                lable="enter your password"
                                                value={values.confirmPassword}
                                                onChange={handleInputChange}
                                                type={cpasswordVisible ? "text" : "password"}
                                                maxLength="12"
                                            />
                                            <div
                                                className="position-absolute password_eye"
                                                variant="link"
                                                onClick={toggleCPasswordVisibility}
                                            >
                                                {cpasswordVisible ? (
                                                    <AiOutlineEyeInvisible
                                                        style={{ width: "80%", height: "30px" }}
                                                    />
                                                ) : (
                                                    <AiOutlineEye
                                                        style={{ width: "80%", height: "30px" }}
                                                    />
                                                )}
                                            </div>
                                            {Boolean(errors.confirmPassword) ? (
                                                <p className="text-invalid">{errors.confirmPassword}</p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-10">
                                    <Controls.Button
                                        className="btn btn-primary h-54 w-100"
                                        text="Submit"
                                        type="submit"
                                    />
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <AutohideExample show={show} setShow={setShow} />
        </main>
    );
};

export default ChangePassword;
