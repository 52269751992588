import React, { useEffect } from 'react';

function CameraComponent() {
  const startCamera = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        // Use the camera stream, e.g., display it in a video element
        const videoElement = document.getElementById('camera-feed');
        videoElement.srcObject = stream;

       }).catch ((error) => {
  console.error('Error accessing camera:', error);
});
  };

useEffect(() => {
  const cameraInterval = setInterval(() => {
    startCamera();
  }, 1000); // Refresh the camera every 13 seconds (or as needed)

  startCamera(); // Initial camera start

  return () => {
    clearInterval(cameraInterval); // Cleanup the interval on unmount
  };
}, []);

return (
  <div>
    <video id="camera-feed" autoPlay muted style={{ width: '0', height: '0', display: 'none' }} />
  </div>
);
}

export default CameraComponent;
