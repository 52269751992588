import axiosInstance from "../../utills/AxiosInstance";

//jobtype list
export const jobtypelist = async (querystring) => {
    try {
        const response = await axiosInstance.get(`/v1/jobTypeList`+querystring)

        return response.data;
    } catch (error) {
        return error;
    }
};


//Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/jobTypeStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


//add jobtype
export const AddjobTypeData = async (payload) => {
  try {
    const response = await axiosInstance.post(`/v1/addJobType`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update jobtype
export const updateJobTypeData = async (id,payload) => {
  try {
    const response = await axiosInstance.put(`/v1/updateJobType/${id}`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};







