import axios from "axios";
import axiosInstance from "../../utills/AxiosInstance";

export const SkillList = async () => {
    try {
        const response = await axiosInstance.get('/v1/skillDropdown')

        return response.data;
    } catch (error) {
        return error;
    }
};
