import React from "react";
import StateList from "../Location/StateList";

function state() {
  return (
    <div>
      <StateList />
    </div>
  );
}

export default state;
