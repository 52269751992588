import axios from "axios";
import { ADD_EMPLOYER_DATA, EMPLOYER_FAIL, EMPTY_ALL_DATE } from "../action-types/employerTypes";
import axiosInstance from "../../utills/AxiosInstance";



//jobtype list
export const JobType = async () => {
  try {
    const response = await axiosInstance.get("/v1/jobTypeList");
    return response.data;
  } catch (error) {
    return error;
  }
};

//job prefer list
export const JobPreferList = async () => {
  try {
    const response = await axiosInstance.get("/v1/jobPreferList");
    return response.data;
  } catch (error) {
    return error;
  }
};



//country code dropdown
export const CountryCode = async () => {
  try {
    const response = await axiosInstance.get("/v1/countryCodeList");
    return response.data;
  } catch (error) {
    return error;
  }
};

//employer register
export const EmployerRegister = async (values) => {
  try {
    const response = await axiosInstance.post(
      `/employee/registerEmployerEmail`,
      values
    );
    return response.data;
  } catch (error) {
   
    return error;
  }
};

//otp verify
export const OtpVerify = async (values) => {
  try {
    const response = await axiosInstance.post(
      `/employee/employeeVerification`,
      values
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const employerDashboard = async (id) => {
  try {
    const response = await axiosInstance.get(`/employee/getCandidate/${id}`);

    return response.data;
  } catch (error) {
    return error;
  }
};

//employer profile details

export const employerProfileDetails = async (id) => {
  try {
    const response = await axiosInstance.get(`/employee/getEmployerById/${id}`);

    return response.data;
  } catch (error) {
    return error;
  }
};

//update employer profile

export const employerProfileUpdate = async (id, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axiosInstance.put(
      `/employee/updateEmployer/${id}`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//jobdetails for employee

export const employerJobPostDetails = async (id) => {
  try {
    const response = await axiosInstance.get(`/employee/getJobpostById/${id}`);

    return response.data;
  } catch (error) {
    return error;
  }
};

//delete for employee
export const employerJobDelete = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `/employee/deleteJobpost/${id}`
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

//update the job post in job

export const employerJobDetailsUpdate = async (id, value) => {
  try {
    const response = await axiosInstance.put(
      `employee/updateJobPost/${id}`,
      value
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//reject the candiate
export const getRejectCandidateJob = async (id, ids, values) => {
  try {
    const response = await axiosInstance.put(
      `/employee/rejectCandidate/${id}/${ids}`,
      values
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//interview invite

export const addInterviewJob = async (values) => {
  try {
    const response = await axiosInstance.post(`/employee/addInterview`, values);
    return response.data;
  } catch (error) {
    return error;
  }
};

//interview type dropdown
export const InterviewDropdown = async (id, value) => {
  try {
    const response = await axiosInstance.get(`/v1/interviewTypeDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//candidate details list
export const getCandidateDetailsList = async (id) => {
  try {
    const response = await axiosInstance.get(`/fresher/getFresherById/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getCandidateDetails = async (id, jobId) => {
  try {
    const response = await axiosInstance.get(
      `/employee/getCandidateById/${id}/${jobId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//candidate module list
export const getCandidateLists = async (id, queryString) => {
  try {
    const response = await axiosInstance.get(
      `/employee/getCandidateList/${id}` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//candidate hire

export const getCandidateHire = async (id, ids) => {
  try {
    const response = await axiosInstance.put(
      `/employee/hireCandidate/${id}/${ids}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//numberof employer
export const getNumberEmployer = async () => {
  try {
    const response = await axiosInstance.get(`/employee/jobOpeningDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update employer profile

export const employerCompanyUpdate = async (id, payload) => {
  try {
    const response = await axiosInstance.put(
      `/employee/companyUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//job close
export const employeeJobClose = async (id) => {
  try {
    const response = await axiosInstance.put(`/employee/closeJob/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const resendOtp = async (id) => {
  try {
      const response = await axiosInstance.put(`/fresher/fresherResendOtp/${id}`)
      return response.data;
  } catch (error) {
      return error;
  }
}
