import React from 'react'
import AssesmentMobile from '../../assets/images/assessment-mobile.png'
import { useNavigate } from 'react-router-dom'
import { FaBackward } from 'react-icons/fa';

const AssessmentMobile = () => {
    const navigate = useNavigate();
    return (
        <div className='h_100 bg-white'>
            <div className='container '>
                <div className='row text-center pt-5'>
                    <div className='col-md-12'>
                        <img src={AssesmentMobile} width={200} />
                    </div>
                    <div className='col-md-12 my-3'>
                        <p className='fs-5 mb-1'>You can't access this page from mobile</p>
                        <p className='fs-5'>Please try again from your desktop or laptop to get matched with the jobs.</p>
                    </div>
                    <div className='col-md-12'>
                        <button className="btn btn-primary" onClick={() => navigate(-1)}>
                            <div className="d-flex align-items-center gap-2">
                                <span>
                                    <FaBackward />
                                </span>
                                <span>Back</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssessmentMobile