import axiosInstance from "../../utills/AxiosInstance";

//jobprefer list 
export const jobPrefer = async (queryString) => {
    try {
        const response = await axiosInstance.get('/v1/jobPreferList' + queryString)
        return response.data;
    } catch (error) {
        return error;
    }
};

//job prefer list Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/jobPreferStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


// delete job prefer 

export const deletejobs = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteQuestion/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add job prefernce
export const Addjobprefrencedata = async (payload) => {
  try {
    const response = await axiosInstance.post(`/v1/addJobPrefer`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update job prefernce
export const updatejobprefrence = async (id,payload) => {
  try {
    const response = await axiosInstance.put(`/v1/updateJobPrefer/${id}`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete job prefrence

export const deletejobsprefrence = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteJobPrefer/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }

};

