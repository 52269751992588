import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Async, { useAsync } from "react-select/async";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";
import {
  getinterviewLists,
  StatusUpdate,
} from "../../state/action/interviewaction";
import Form from "react-bootstrap/Form";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/view.svg";
import Addinterview from "./addinterview";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";
import { IsWrite, IsDelete } from "../../utills/handlePermission";

import '../../components/pagination/style.scss';
import '../../components/pagination/pagination.scss';
import PaginationOne from '../../components/pagination/PaginationOne';
import { useMemo } from 'react';

let PageSize = 10;




// import "./filter.css";

const initialValues = {
  title: "",
};
const InterviewType = () => {
  const [flagName, setflagName] = useState(false);
  const [interviewList, setinterviewList] = useState([]);
  const deletePermissions = IsDelete("interviewtype");
  const editPermissions = IsWrite("interviewtype");

  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  const [openFilterModel, setOpenFilterModel] = useState(false);
  const [view, setView] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [isEdit, setIsEdit] = useState(false);
  const [interviewdataList, setinterviewadataList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);

  const [page, setPage] = useState(1);
  const [sortFlag, setSortFlag] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(3);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const navigate = useNavigate();

  const [showDrawer, setShowDrawer] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const statuslist = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];

  const [currentPage, setCurrentPage] = useState(1);


  const interviewListsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return interviewList;
  }, [currentPage, interviewList]);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);

  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);

  const handleCloseFilter = () => setOpenFilterModel(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };


  const updateDataHandler = (data) => {
    // setinterviewadataList(data); 
    getinterviewList(page, "", "");
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  // list
  useEffect(() => {
    getinterviewList(currentPage, "", "");
  }, [refresh, filterData, search, page]);

  const getinterviewList = async (page, name = "", flag, perPageSize) => {
    setOpenBreakdrop(true);
    setView(true);

    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = perPageSize ? perPageSize : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&sortby=${name}&sortFlag=${flag}`;

    const res = await getinterviewLists(queryString);

    if (res.status === true) {
      setinterviewList(res.data);
      setTotalRecords(res.total);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPageLimit(recordLimit);
      setView(false);
    } else {
      setinterviewList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      setView(false);
    }
    setFilter(false);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getinterviewList(page, "", "", pageLimit); // Call the API with the new page number and page limit
    setPage(page);
  };

  const handlePageLimitChange = (e) => {
    const perPageSize = parseInt(e.target.value);
    setPageLimit(perPageSize || 10);
    setCurrentPage(1); // Reset the current page to 1 when page limit is changed
    getinterviewList(1, "", "", perPageSize); // Call the API with the new page limit and reset the page number to 1
  };
  //status change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "name") {
        getinterviewList(fieldName, flagName);
      } else {
        getinterviewList(currentPage, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    setFilterData(!filterData);
  };

  const handleReset = (e) => {
    e.preventDefault();
    resetForm();
    closeDrawer();
    setFilterData(!filterData);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };
  return (
    <div>
      <Loader view={view} />
      {/* {!view && ( */}
      <main className="body-background">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="rounded-card-head">
                <div className="row align-items-center mb-3">
                  <div className="col-md-3">
                    <div className="font-PTSerif-b ps-2 font-18-24-05">
                      Interview Type
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="col col-md-auto text-end d-flex justify-content-end flex-wrap">
                      <Controls.Input
                        className="border-0 rounded p-2 mt-2"
                        placeholder="search"
                        name="text"
                        type="text"
                        onChange={(e) => {
                          setTimeout(() => {
                            setSearch(e.target.value);
                          }, 800);
                        }}
                      />
                      <div className="mt-2">
                        {editPermissions && (
                          <Controls.Button
                            style={{ padding: "7px 30px", marginLeft: "16px" }}
                            className="btn btn-primary h-40"
                            text="Add"
                            onClick={() => {
                              setIsEdit(false);
                              handleAddSwitchModel();
                            }}
                          />
                        )}
                        <Controls.Button
                          style={{ padding: "7px 30px", marginLeft: "16px" }}
                          className="btn btn-success h-40"
                          text="filter"
                          onClick={() => {
                            toggleDrawer();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showDrawer && (
                <>
                  <div className="filter-drawer-overlay" onClick={toggleDrawer}>
                  </div>
                  <div className="filter-drawer-content">
                    <div className="d-flex justify-content-between align-items-center filter-header_div ">
                      <label className="filter-header">Filter</label>
                      <button
                        className="filter-close-button"
                        onClick={toggleDrawer}
                      >
                        x
                      </button>
                    </div>
                    <div className="d-flex flex-column h-100">
                      <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">
                        <div>
                          <div>
                            <label className="filter-label">Title</label>
                            <Controls.Input
                              className="input_filter"
                              name="title"
                              type="text"
                              value={values.title}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <label className="filter-label">status</label>
                            <select
                              className="form-select h-auto px-3 py-2 "
                              name="isActive"
                              onChange={handleInputChange}
                              value={values.isActive}

                            >
                              <option>status</option>
                              {statuslist?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="position-relative bottom-0 mb-5 filter-reset-div">
                          <div className="d-flex justify-content-between">
                            <button
                              className="filter-reset-btn"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                            <button
                              className="filter-btn"
                              type="submit"
                              onClick={(e) => {
                                closeDrawer();
                                handleSubmit(e);
                              }}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}

              <div className="theme-table-wrap">
                <Table responsive className="striped-table mb-0">
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>Title</th>

                      <th>Status</th>
                      <th>Date</th>
                      {editPermissions || deletePermissions ? (<th>Action</th>) : null}

                    </tr>
                  </thead>
                  {interviewListsData.map((row) => {
                    return (
                      <tbody>
                        <tr
                          className="border rounded"
                          style={{ backgroundColor: "white" }}
                        >
                          <td>{row?.id}</td>
                          <td>{row?.title}</td>

                          <td>
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              checked={row.isActive == 1 ? true : false}
                              onChange={(e) => {
                                if (!editPermissions) {
                                  setShow({
                                    isOpen: true,
                                    message: "You don't have permission",
                                    type: "error",
                                  })
                                  return;
                                } else {
                                  handleSwitchModel();
                                  setStatus(
                                    e.target.checked == true
                                      ? "Active"
                                      : "InActive"
                                  );
                                  setSwitchId(row.id);
                                  setChecked(e.target.checked);
                                }
                              }}
                            />
                          </td>
                          <td>
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </td>
                          {editPermissions || deletePermissions ? (
                            <td>
                              <ul className="d-flex action-col-main">
                                <li className="me-3">
                                  {editPermissions && (
                                    <button
                                      className="btn btn-sm btn-icon btn-warning me-4"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                      onClick={() => {
                                        handleAddSwitchModel();
                                        setinterviewadataList(row);
                                        setIsEdit(true);
                                      }}
                                    >
                                      <span className="btn-inner">
                                        <EditIcon />
                                      </span>
                                    </button>
                                  )}

                                  {deletePermissions && (
                                    <button
                                      className="btn btn-sm btn-icon btn-danger btn-inner me-4"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() => {
                                        handleDeleteSwitchModel();
                                        // setId(row.id);
                                        // setDeleteId(row?.id);
                                        handleDeleteModel();
                                      }}
                                    >
                                      <span className="btn-inner">
                                        <DeleteIcon />
                                      </span>
                                    </button>
                                  )}
                                </li>
                              </ul>
                            </td>
                          ) : null}
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
                {interviewListsData && interviewListsData.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </div>
              <PaginationOne
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={PageSize}
                onPageChange={page => [setCurrentPage(page), getinterviewList(page, "", "", pageLimit)]} />
              <div className="pt-2">

                {" "}
                Showing{" "}
                {currentPage * pageLimit > totalRecords
                  ? totalRecords
                  : currentPage * pageLimit}{" "}
                of {totalRecords} Results
              </div>

            </div>
          </div>
        </div>

        {/* status change */}
        <Modal
          size="md"
          show={openSwitchModel}
          onHide={() => handleCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to status change?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleCloseSwitch()}
            >
              No
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleCloseSwitch();

                handleChangeStatus(switchId);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete */}
        <Modal
          size="md"
          show={openDeleteSwitchModel}
          onHide={() => handleDeleteCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to delete?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              onClick={() => handleDeleteCloseSwitch()}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleDeleteCloseSwitch();
                // handleDelete(deleteId);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>

        </Modal>

        <Addinterview
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          handleAddCloseSwitch={handleAddCloseSwitch}
          openAddSwitchModel={openAddSwitchModel}
          interviewdataList={interviewdataList}
          setOpenFilterModel={setOpenFilterModel}
          handleCloseFilter={handleCloseFilter}
          refreshScreen={refreshScreen}
          updateData={updateDataHandler}
        />
        <AutohideExample show={show} setShow={setShow} />
      </main>
      {/* )} */}
    </div>
  );
};

export default InterviewType;