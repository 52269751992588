import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../../components/controls/Controls";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FresherRegisterApi,
  addFresherDataRedux,
} from "../../../state/action/authAction";
import Webcam from "react-webcam";
import { useForm } from "../../../utills/useForms";
import AutohideExample from "../../../utills/Notification";
import BsUpload from "../../../assets/Icons/icons";
import { loadUser } from "../../../state/action/authAction";
import { FiUser } from "react-icons/fi";

const videoConstraints = {
  width: 420,
  height: 420,
  facingMode: "user",
};

const initialValues = {
  engLevel: "",
  lookingJobs: "",
  jobTypeId: "",
  jobPraferId: "",
  country: "",
  city: "",
  state: "",
  fileLocation: "",
};

const FinalDetails = (props) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { activeTab, setActiveTab } = props;

  const [image, setImage] = useState([]);

  const [pdfFile, setPdfFile] = useState();
  const [tmpFile, setTmpFile] = useState({});

  const [img, setImg] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [hasCameraPermission, setHasCameraPermission] = useState(false);
  // const requestCameraPermission = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     setHasCameraPermission(true);
  //     videoRef.current.srcObject = stream;
  //   } catch (error) {
  //     console.error("Error accessing camera:", error);
  //   }
  // };

  // const capture = useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setImg(imageSrc);
  // }, [webcamRef]);

  // useEffect(() => {
  //   requestCameraPermission();
  // }, []);

  const { state } = useLocation();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("firstName" in fieldValues) {
      if (fieldValues.firstName !== "" && fieldValues.firstName !== undefined && fieldValues.firstName != null) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.firstName)) {
          temp.firstName = "Invalid First Name";
        } else {
          temp.firstName = "";
        }
      } else {
        temp.firstName = "This field is required.";
      }
    }
    if ("lastName" in fieldValues) {
      if (fieldValues.lastName !== "" && fieldValues.lastName !== undefined && fieldValues.lastName != null) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.lastName)) {
          temp.lastName = "Invalid Last Name";
        } else {
          temp.lastName = "";
        }
      } else {
        temp.lastName = "This field is required.";
      }
    }
    if ("phoneNumber" in fieldValues) {
      if (
        fieldValues.phoneNumber !== "" &&
        fieldValues.phoneNumber !== undefined && fieldValues.phoneNumber != null
      ) {
        if (!/^[0-9]*$/.test(fieldValues.phoneNumber)) {
          temp.phoneNumber = "Invalid Phone Number";
        } else {
          temp.phoneNumber = "";
        }
      } else {
        temp.phoneNumber = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleImageItem = async (e) => {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["application/pdf"];


    //selected item allow only two mb size
    if (selected && selected.size > 2000000) {
      setShow({
        isOpen: true,
        message: "File size is too large (Max 2MB)",
        type: "error",
      });
      return;
      //set state empty
    }
    else {

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setTmpFile(selected);
      setValues({ ...values, fileLocation: selected });

      setPdfFile(selected);
      setResumeFile(selected);
    } else {
    }
  }
  };

  async function deleteImage1(index) {
    setImage("");
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  }

  const DataId = useSelector((state) => state?.auth?.admin);

  let data = useSelector((state) => state?.auth?.user);

  const HandleSubmit = async (e) => {
    e.preventDefault();

    // data in token remove
    // delete data?.token;

    var formData = new FormData();

    for (const [key, value] of Object.entries(data)) {

      if (value == "" || value == null) {

      }
      else if (key == "profilePhoto" || key == "id" || key == "googleToken" || key == "isEmailVerifiyed" || key == "role" || key == "permission" || key == "createdAt" || key == "updatedAt" || key == "isHired" || key == "isActive" || key == "password" || key == "isProfileComplate" || key == "isSoftSkill" || key == "isPassTechTest" || key == "isPassCodeing" || key == "ternToMatch" || key == "skill") {
      }
      else {
        formData.append([key], value);
      }

    }
    formData.append("profilePhoto", img);
    // formData.append("fileLocation", resumeFile);

    if (values?.fileLocation) {
      formData.append("fileLocation", values?.fileLocation);
    }

    let res = await dispatch(FresherRegisterApi(DataId.id, formData));

    if (res && res.status) {
      setLoadingButton(!loadingButton);
      setShow({
        isOpen: true,
        message: "You are successfully Register",
        type: "success",
      });

      setTimeout(async () => {
        navigate("/");
      }, 2000);

    } else {
      setLoadingButton(false);
      setShow({
        isOpen: true,
        message: res?.message || "Technical error please try again later.",
        type: "error",
      });
    }
  };


  const [resumeFile, setResumeFile] = useState(null);

  const [file, setFile] = useState();

  function handleChange(e) {

    //files allow only jpg and png and jpeg
    if (e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "image/png" && e.target.files[0].type !== "image/jpg") {
      setShow({
        isOpen: true,
        message: "Only JPG, JPEG, PNG files are allowed",
        type: "error",
      });
      return;
    }
   else if (e.target.files[0].size > 2000000) {
      setShow({
        isOpen: true,
        message: "File size is too large (Max 2MB)",
        type: "error",
      });
      return;
    }
    else {
    setFile(URL.createObjectURL(e.target.files[0]));
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setFile(reader.result);
      setImg(reader.result);
    };
  }

  }
  return (
    <main>
      <AutohideExample show={show} setShow={setShow} />

      <div className="row">
        <div className="col-12 col-md-12 mb-16">
          <div className="form-label">Profile picture</div>
          <div className="row mb-4">
            <div className="col align-items-center pt-3 d-flex gap-4">
              {!file ? (
                <>
                  <div className="profile_upload"><FiUser /></div>
                </>
              ) : (
                <>
                  <img src={file} width={100} height={100} style={{ borderRadius: '50%' }} />
                </>
              )}
              <div className="position-relative">
                <input type="file" 
                onChange={handleChange} 
                className="position-absolute"
                 style={{ opacity: '0', width: '100%', height: '100%' }}
                 accept="image/png, image/jpeg"
                  />
                <button className="btn btn-outline-primary btn-lg">Upload profile picture</button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-label">Resume</div>
            </div>
            <div className="col-12">
              <div className="final-detail-file-upload">
                <label className="text-primary py-5">
                  <input
                    id="image-upload"
                    type="file"
                    onChange={handleImageItem}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                    accept="application/pdf"
                    multiple={false}
                  />
                  {!resumeFile && (
                    <svg
                      width="44"
                      height="44"
                      className="mb-3"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                        stroke="#143F87"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 8L12 3L7 8"
                        stroke="#143F87"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 3V15"
                        stroke="#143F87"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                  <br />{" "}
                  {!resumeFile && (
                    <span className="font-18-24-05 fw-medium">
                      Upload your resume
                    </span>
                  )}
                </label>
                {resumeFile && (
                  <div
                    className="d flex"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: 'center'
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      fill="currentColor"
                      class="bi bi-file-pdf"
                      viewBox="0 0 16 16"
                      width="27px"
                    >
                      <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                      <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                    </svg>
                    <p className="mb-0">{resumeFile.name}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-16 mt-16">
          <Controls.Button
            className="btn btn-primary h-54 w-100"
            text="Submit"
            onClick={(e) => {
              HandleSubmit(e);
            }}
          />
        </div>
      </div>
    </main>
  );
};

export default FinalDetails;
