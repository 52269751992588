import React from "react";
import { useForm } from "../../utills/useForms";
import { useState, useEffect } from "react";
import {
  AddAssesmentData,
  updateAssesment,
} from "../../state/action/addAssesment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AutohideExample from "../../utills/Notification";
import AssessmentPage from "./AssessmentPage";
import { Loader } from "../../utills/Loader";

const initialValues = {
  //   id: "",
  skillTitle: "",
  question: "",
  queType: "",
  options: [],
};

const AddRoles = (props) => {
  const {
    setIsEdit,
    isEdit,
    handleAddCloseSwitch,
    openAddSwitchModel,
    assesmentDataList,
    refreshScreen,
  } = props;

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [filterData, setFilterData] = useState(true);
  const [view, setView] = useState(false);

  const [AssesmentList, setAssesmentList] = useState([]);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setIsEdit("");
  }, []);

  const handleSubmit = async (e) => {
    setFilterData(!filterData);
    const body = {
      question: values.question,
      queType: values.queType,
      options: values.options,
    };

    //edit
    if (isEdit) {
      const res = await updateAssesment(assesmentDataList.id, body);
      setView(true);

      if (res && res.status === true) {
        refreshScreen();
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
        setView(false);
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
        setView(false);
      }
    }

    if (!isEdit) {
      const res = await AddAssesmentData(body);
      if (res && res.status === true) {
        refreshScreen();
        AssesmentList("");

        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
        setView(false);
      } else {
        AssesmentList("");

        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
        setView(false);
      }
    }
  };
  //details edit
  useEffect(() => {
    if (
      assesmentDataList.id != "" &&
      assesmentDataList.id != null &&
      assesmentDataList.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        role: assesmentDataList?.role,
      });
    } else {
      resetForm();
    }
  }, [assesmentDataList]);

  return (
    <div>
      <Loader view={view} />
      {!view && (
        <Modal
          size="md"
          show={openAddSwitchModel}
          onHide={handleAddCloseSwitch}
          aria-labelledby=""
          centered
        >
          {/* <Form onClick={handleSubmit} autoComplete="off"> */}
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <h3>Add Assessment</h3>
            </div>
            <AssessmentPage />
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              onClick={handleAddCloseSwitch}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
                handleAddCloseSwitch();
              }}
              variant="primary"
              className="h-44 fw-medium m-0"
            >
              Save
            </Button>
          </Modal.Footer>
          {/* </Form> */}
        </Modal>
      )}

      <AutohideExample show={show} setShow={setShow} />
    </div>
  );
};

export default AddRoles;
