import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import EmployerDashboard from "../../pages/employerdashboard";
import EmployerJobs from "../../pages/employerjobs";
import EmployerDashboardLocked from "../../pages/employerdashboardlocked";
import MCQAssessment from "../../pages/MCQAssessment";
import Candidates from "../../pages/candidates";
import EmployerPersonalInformation from "../../pages/employerRegister/main/main";
import Header from "./header";
import EmployerProfile from "../../pages/employer/EmployerProfile.jsx";
import EmployerSetting from "../../pages/employerSetting/EmployerSetting";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import NotFoundPage from "../../pages/NotFound/NotFoundPage";
import { useSelector } from "react-redux";

const Main = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);

  const slug = location.pathname.split("/")[1];

  useEffect(() => {
    if (slug == "" && user?.completed == 1) {
      navigate("/employer");
    }
  }, [slug == ""]);

  const pages = [
    { path: "employer", element: <EmployerDashboard />, name: "employer" },
    { path: "jobs", element: <EmployerJobs />, name: "jobs" },
    {
      path: "employerprofile",
      element: <EmployerProfile />,
      name: "employerprofile",
    },
    { path: "candidates", element: <Candidates />, name: "candidates" },
    { path: "employersetting", element: <EmployerSetting />, name: "employersetting" },
    {
      path: "employerdashboardlocked",
      element: <EmployerDashboardLocked />,
      name: "employerdashboardlocked",
    },
    {
      path: "mcqassessment",
      element: <MCQAssessment />,
      name: "mcqassessment",
    },
    {
      path: "employerpersonalinformation",
      element: <EmployerPersonalInformation />,
      name: "employerpersonalinformation",
    },
  ];

  return (
    <>
      {location?.pathname !== "/employerpersonalinformation" && pages.find((page) => page.name == slug) ? (
        <Header {...props} />
      ) : null}

      <Routes>
        {pages.map((list, index) => {
          const { name, path, element } = list;
          return <Route key={path} path={path} element={element} />;
        })}
      </Routes>
      {pages.find((page) => page.name == slug) && slug != "" ? null : <NotFoundPage />}
    </>
  );
};

export default Main;
