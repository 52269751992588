import React, { useEffect, useState, useRef } from 'react';
import Editor from "@monaco-editor/react";



const CodeEditorWindow = ({ onChange, language, code, setCode, theme }) => {

    const handleEditorChange = (value) => {
        setCode(value);
        onChange("code", value);
    };
    return (
        <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl" >
            <Editor
                height="65vh"
                width={`100%`}
                language={language || "javascript"}
                value={code}
                theme={theme}
                defaultValue=""
                onChange={handleEditorChange}

            />
        </div>
    )
};

export default CodeEditorWindow;
