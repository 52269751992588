import axiosInstance from "../../utills/AxiosInstance";

//employer list
export const  getTalentpool = async (querystring) => {
  try {
    const response = await axiosInstance.get('/fresher/talentPool' + querystring)

    return response.data;
  } catch (error) {
    return error;
  }
};

// Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/fresher/fresherStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete 

export const deleteFresher = async (id) => {
  try {
    const response = await axiosInstance.put(`/fresher/deleteFresher/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//freher details
export const getFresherDetails = async (id) => {
  try {
    const response = await axiosInstance.get(`/fresher/getFresherById/${id}`)

    return response.data;
  } catch (error) {
    return error;
  }
};

