import React, { useState, useEffect } from "react";

const Input = (props) => {
  const {
    name,
    value,
    onChange,
    maxRows = 1,
    error = null,
    label,
    style,
    ...other
  } = props;

  const [ClassName, setClassName] = useState("");

  useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (<>
    <p className="mb-0">{label}</p>
    <input
      name={name}
      value={value}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
      className={ClassName}
    />
  </>

  );
};

export default Input;