import React from "react";
import { useForm } from "../../utills/useForms";
import { useState, useEffect } from "react";
import { AddFAQData, UpdateFAQ } from "../../state/action/FAQAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Controls from "../../components/controls/Controls";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";

const initialValues = {
  question: "",
  answer: "",
  type: "",
};

const AddFAQ = (props) => {
  const {
    setIsEdit,
    isEdit,
    handleAddCloseSwitch,
    openAddSwitchModel,
    FAQDataList,
    refreshScreen,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("type" in fieldValues) {
      temp.type = fieldValues.type ? "" : "This field is required.";
    }
    if ("question" in fieldValues) {
      temp.question = fieldValues.question ? "" : "This field is required.";
    }
    if ("answer" in fieldValues) {
      temp.answer = fieldValues.answer ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const closeAddSkillPopup = () =>{
    handleAddCloseSwitch();
    resetForm();
  }
 
const truncateAnswer = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, 50) + '...';
  }
  return text;
};


  const [filterData, setFilterData] = useState(true);

  const [setOpenAddSwitchModel] = useState(false);
  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  //  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);
  const { enqueueSnackbar } = useSnackbar();
  const [FAQList, setFAQList] = useState([]);
  const [view, setView] = useState(false);


  const faqList = [
    { id: "1", name: "Fresher" },
    { id: "2", name: "employer" },
  ];
  
  
  useEffect(() => {
    setIsEdit("");
   
  }, []);

  const handleSubmit = async (e) => {
    // e.preventDefault();
    //setFilterData(!filterData);
    if (validate()) {
      resetForm();
      const body = {
        question: values.question,
        answer: values.answer,
        type: values.type,
      };

      //edit
      if (isEdit) {
        const res = await UpdateFAQ(FAQDataList.id, body);
        setView(true);
        if (res && res.status === true) {
          refreshScreen();
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }

      if (!isEdit) {
        const res = await AddFAQData(body);
        if (res && res.status === true) {
          refreshScreen();
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }
      handleAddCloseSwitch();
      resetForm();
    } else {
      setShow({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    }
  };

  //details edit
  useEffect(() => {
    if (
      FAQDataList?.id != "" &&
      FAQDataList?.id != null &&
      FAQDataList?.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        question: FAQDataList?.question,
        answer: FAQDataList?.answer,
        type: FAQDataList?.type,
      });
    } else {
      resetForm();
    }
  }, [FAQDataList]);

  return (
    <div>
      <Loader view={view} />
      {!view && (
        <Modal
          size="md"
          show={openAddSwitchModel}
          onHide={closeAddSkillPopup}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton onClick={closeAddSkillPopup}></Modal.Header>
          {/* <form onClick={handleSubmit} autoComplete="off"> */}
          <Modal.Body>
            <h3>Add FAQ</h3>
             <div className="mb-3">
             <select
                className="form-select font-16-24-05 h-auto py-[10px] px-3"
                name="type"
                placeholder="enter admin role"
                onChange={handleInputChange}
                value={values.type}
                
              >
                <option> add FAQ</option>
                {faqList?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            {Boolean(errors.type) ? (
              <p className="text-invalid">{errors.type}</p>
            ) : (
              ""
            )}
            </div>

           <div className="mb-3">
            <Controls.Input
              className="form-control font-16-24-05 h-auto py-[10px] px-3"
              placeholder="Question"
              name="question"
              type="text"
              lable="question"
              value={values.question}
              onChange={handleInputChange}
            />
            {Boolean(errors.question) ? (
              <p className="text-invalid">{errors.question}</p>
            ) : (
              ""
            )}
            </div>
            <div className="mb-3">
            <Controls.Input
              className="form-control font-16-24-05 h-auto py-[10px] px-3"
              placeholder="Answer"
              name="answer"
              type="text"
              lable="answer"
              value={values.answer}
              onChange={handleInputChange}
            />  
            {Boolean(errors.answer) ? (
              <p className="text-invalid">{errors.answer}</p>
            ) : (
              ""
            )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">


            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
             
              onClick={() => closeAddSkillPopup()}
            >
            
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
                //handleAddCloseSwitch();
              }}
              variant="primary"
              className="h-auto py-2 px-3 fw-medium m-0"
            >
              Save
            </Button>
          </Modal.Footer>
          {/* </form> */}
        </Modal>  
      )}
      <AutohideExample show={show} setShow={setShow} />
    </div>
  );
};

export default AddFAQ;



