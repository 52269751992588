import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "../../pages/dashboard";
import Profile from "../../pages/profile";
import { useSelector } from "react-redux";
import FresherHeader from "./fresherHeader";
import MCQAssessment from "../../pages/MCQAssessment";
import Editor from "../../pages/codindEditor/CodeEdiotr";
import Assessments from "../../pages/assessments";
import EducationalInformation from "../../pages/educationalinformation/educationalinformation";
import PreferencesExpectations from "../../pages/preferencesexpectations/preferencesexpectations";
import FinalDetails from "../../pages/FresherRegister/FinalDetails/finalDetails";
import Setting from "../../pages/fresherSetting/Setting";
import NotFoundPage from "../../pages/NotFound/NotFoundPage";
import ResultScreen from "../../pages/MCQAssessment/ResultScreen";
import AssessmentMobile from "../../pages/assessments/AssessmentMobile";
import ResultScreenCoding from "../../pages/MCQAssessment/ResultScreenCoding";



const Main = (props) => {
  const { user } = useSelector((state) => state?.auth);
  const location = useLocation();
  const slug = location.pathname.split("/")[1];
  console.log("slug-working");

  const pages = [
    { path: "/profile", element: <Profile />, name: "profile" },
    { path: "/", element: <Dashboard />, name: "fresherDashboard" },
    {
      path: "/preferencesinformation",
      element: <PreferencesExpectations />,
      name: "preferencesinformation",
    },

    {
      path: "/finaldetails",
      element: <FinalDetails />,
      name: "finaldetails",
    },

    {
      path: "/educationinformation",
      element: <EducationalInformation />,
      name: "educationinformation",
    },

    { path: "assessments", element: <Assessments />, name: "assessments" },
    { path: "assessments-mobile", element: <AssessmentMobile />, name: "assessments-mobile" },
    { path: "setting", element: <Setting />, name: "setting" },
    {
      path: "mcqassessment",
      element: <MCQAssessment />,
      name: "mcqassessment",
    },
    {
      name: "codingassessment",
      path: "/codingassessment",
      element: <Editor />,
    },
    {
      name: "result",
      path: "/result",
      element: <ResultScreen />,
    },
    {
      name: "result",
      path: "/coddingresult",
      element: <ResultScreenCoding />,
    },
  ];

  return (
    <>
      {location?.pathname !== "/personalinformation" && location?.pathname != "/result" &&
        location?.pathname != "/mcqassessment" && location?.pathname !== "/codingassessment" && (pages.find((page) => page.name == slug) || slug == "") && <FresherHeader {...props} />}

      <Routes>
        {pages.map((list, index) => {
          const { name, path, element } = list;
          return <Route key={path} path={path} element={element} />;
        })}
      </Routes>
      {pages.find((page) => page.name == slug) || slug == "" ? null : <NotFoundPage />}

    </>
  );
};

export default Main;
