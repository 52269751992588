import React, { useState } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import Async, { useAsync } from 'react-select/async';

import InterViewerProfile2 from '../../assets/images/interviewer_profile_2.png';
import DashboardAboutUsImage from '../../assets/images/on_the_office.svg';

import JoinImageComputer from '../../assets/images/computer.svg';
import JoinImageCandidateReview from '../../assets/images/candidate_review.svg';
import JoinImagelist from '../../assets/images/list.svg';
import JoinImageBrowser from '../../assets/images/browser.svg';


const EmployerDashboardLocked = () => {
    const [JobDetails, setJobDetails] = useState(false);

    return (
        <main className="body-background">
            <div className="bg-cyan">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="dashboard-welcome-text py-5">
                                <h3 className="mb-2 font-PTSerif-b">Welcome, John!</h3>
                                <p className="mb-0 font-16-24-05">For now, you can't hire candidates till your account got approved. We <br />  will contact you soon to review your account.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="rounded-card-head pt-3 pt-lg-5 mb-16">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="font-PTSerif-b ps-2 font-18-24-05">Your latest posted jobs</div>
                        </div>
                    </div>
                </div>
                <div className="rounded-card mb-3 mb-md-4">
                    <div className="row align-items-center justify-content-between mb-3">
                        <div className="col-auto">
                            <span className="font-PTSerif-b font-18-24-05 mb-0 pb-0">Job Details</span>
                        </div>
                        <div className="col-auto">
                            <Controls.Button className="btn btn-link text-decoration-none fw-semibold" text="Job Full Details" onClick={() => setJobDetails(true)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 col-xl-2 mb-3 mb-xl-0">
                            <div className="fw-medium font-18-24-05">Job Title</div>
                            <div className="font-16-24-05 mb-2">AI Engineer</div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2 mb-3 mb-xl-0">
                            <div className="fw-medium font-18-24-05">Skills Needed</div>
                            <div className="round-ship-style-1">
                                <ul className="d-flex flex-wrap">
                                    <li>TensorFlow</li>
                                    <li>Keras</li>
                                    <li>Spark</li>
                                    <li>AWS</li>
                                    <li>Python</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                            <div className="fw-medium font-18-24-05">Salary</div>
                            <div className="font-16-24-05 mb-2">5,000 - 10,000 INR</div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                            <div className="fw-medium font-18-24-05">Location</div>
                            <div className="font-16-24-05 mb-2">India, India</div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                            <div className="fw-medium font-18-24-05">Type</div>
                            <div className="font-16-24-05 mb-2">Full-time, On-site</div>
                        </div>
                        <div className="col-lg-2">
                            <select className="select-with-blue-btn h-33 w-100">
                                <option>Open</option>
                                <option>Close</option>
                            </select>
                        </div>
                    </div>
                    <div className="row justify-content-between mb-2 mt-4">
                        <div className="col-auto">
                            <span className="font-18-24-05 fw-semibold">Recommended candidates for this job</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 my-2">
                            <div className="gray-card-inner">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-12 col-lg-6 text-center py-lg-5">
                                        <div className="">
                                            <svg width="52" height="52" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="#74777E" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="#74777E" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className="pt-2 font-PTSerif-b text-gray font-16-24-05">
                                            Thank you for your interest in Job Portal. We would love to help you find top talent for your organization. Our team will contact you shortly
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rounded-card-head pt-3 mb-16">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">About us</div>
                        </div>
                    </div>
                </div>
                <div className="rounded-card mb-5">
                    <div className="row align-items-center flex-lg-row-reverse">
                        <div className="col-lg-6">
                            <img src={DashboardAboutUsImage} className="w-100" />
                        </div>
                        <div className="col-lg-6">
                            <p className="font-18-24-05 lh-24 mb-0 pb-2">At Job Portal, we believe in providing fresh tech graduates with a fair chance to find job opportunities that match their skills and preferences.</p>
                            <p className="font-18-24-05 lh-24 mb-0 pb-2">Our platform streamlines the job search process and connects job seekers with skilled and motivated candidates. We're committed to fostering a community that values diversity, inclusivity, and equal opportunity.</p>
                            <p className="font-18-24-05 lh-24 mb-0 pb-2">Our mission is to bridge the gap between job seekers and employers by providing an efficient and accessible platform.</p>
                            <p className="font-18-24-05 lh-24 mb-0 pb-2">We understand the challenges fresh tech graduates face when entering the job market and are dedicated to providing the resources they need to succeed. With Job Portal, job seekers can find their dream job, and employers can find the skilled candidates they need to grow their businesses.</p>
                        </div>
                    </div>
                </div>
                <div className="rounded-card-head pt-3">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="font-PTSerif-b ps-2 font-18-24-05">Why join Job Portal?</div>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6 col-md-6 col-lg-3 my-3">
                        <div className="rounded-card h-100 text-center text-md-start">
                            <div className="w-100 h-100">
                                <img src={JoinImageComputer} className="img-fluid " />
                            </div>
                            <div className="font-18-24-05 mt-3">Access to pre-screened candidates</div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-3 my-3">
                        <div className="rounded-card h-100 text-center text-md-start">
                            <div className="w-100 h-100">
                                <img src={JoinImageCandidateReview} className="img-fluid " />
                            </div>
                            <div className="font-18-24-05 mt-3">Efficient hiring process</div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-3 my-3">
                        <div className="rounded-card h-100 text-center text-md-start">
                            <div className="w-100 h-100">
                                <img src={JoinImagelist} className="img-fluid " />
                            </div>
                            <div className="font-18-24-05 mt-3">Detailed candidate profiles</div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-3 my-3">
                        <div className="rounded-card h-100 text-center text-md-start">
                            <div className="w-100 h-100">
                                <img src={JoinImageBrowser} className="img-fluid " />
                            </div>
                            <div className="font-18-24-05 mt-3">Comprehensive skill assessments</div>
                        </div>
                    </div>
                </div>


                <div className="rounded-card-head pt-3 mb-16">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="font-PTSerif-b ps-2 pb-0 font-18-24-05">FAQs</div>
                        </div>
                        <div className="col col-md-auto text-end">
                            <a href="" className="pe-2 btn btn-link text-decoration-none text-primary card-head-action">
                                More FAQs
                            </a>
                        </div>
                    </div>
                </div>
                <div className="rounded-card p-0 mb-5">
                    <Accordion defaultActiveKey="" className="accordion-transparent">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How do I know if I'm qualified for a job on Job Portal?</Accordion.Header>
                            <Accordion.Body className="font-16-24-05 pt-0 px-5 mx-1">
                                Lorem ipsum dolor sit amet consectetur. Eu odio sodales cras consectetur sit tempus in. Sed egestas purus sed morbi egestas libero enim nunc. Commodo dolor tristique hac massa dui orci tristique. Leo quam mi lacus arcu non elit a dignissim. Id lacus quis nulla viverra commodo gravida. Curabitur proin mauris tristique nunc tellus. Nisi platea eu rutrum neque viverra habitant. In turpis dictum nisl non quis interdum sit neque elementum.
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>What types of jobs are available on Job Portal?</Accordion.Header>
                            <Accordion.Body className="font-16-24-05 pt-0 px-5 mx-1">
                                Lorem ipsum dolor sit amet consectetur. Eu odio sodales cras consectetur sit tempus in. Sed egestas purus sed morbi egestas libero enim nunc. Commodo dolor tristique hac massa dui orci tristique. Leo quam mi lacus arcu non elit a dignissim. Id lacus quis nulla viverra commodo gravida. Curabitur proin mauris tristique nunc tellus. Nisi platea eu rutrum neque viverra habitant. In turpis dictum nisl non quis interdum sit neque elementum.
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Who are Job Portal's Clients?</Accordion.Header>
                            <Accordion.Body className="font-16-24-05 pt-0 px-5 mx-1">
                                Lorem ipsum dolor sit amet consectetur. Eu odio sodales cras consectetur sit tempus in. Sed egestas purus sed morbi egestas libero enim nunc. Commodo dolor tristique hac massa dui orci tristique. Leo quam mi lacus arcu non elit a dignissim. Id lacus quis nulla viverra commodo gravida. Curabitur proin mauris tristique nunc tellus. Nisi platea eu rutrum neque viverra habitant. In turpis dictum nisl non quis interdum sit neque elementum.
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>How long does Job Portal take to provide a match?</Accordion.Header>
                            <Accordion.Body className="font-16-24-05 pt-0 px-5 mx-1">
                                Lorem ipsum dolor sit amet consectetur. Eu odio sodales cras consectetur sit tempus in. Sed egestas purus sed morbi egestas libero enim nunc. Commodo dolor tristique hac massa dui orci tristique. Leo quam mi lacus arcu non elit a dignissim. Id lacus quis nulla viverra commodo gravida. Curabitur proin mauris tristique nunc tellus. Nisi platea eu rutrum neque viverra habitant. In turpis dictum nisl non quis interdum sit neque elementum.
                                </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>

                {/* <footer className="footer-copyright font-18-24-05 text-center py-3">
                    TechFresher 2023 copyright received
                </footer> */}
            </div>

            <Modal size="xl" show={JobDetails} onHide={() => setJobDetails(false)} aria-labelledby="" centered>
                <Modal.Header className="border-0" closeButton></Modal.Header>
                <Modal.Body className="px-lg-4">
                    <div className="row">
                        <div className="col-12 col-lg mb-16">
                            <div className="font-PTSerif-b font-18-24-05 mb-1">Job Details</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-md-4 col-xl mb-3">
                            <div className="fw-medium font-18-24-05">Job Title</div>
                            <div className="font-16-24-05 mb-2">Software engineer</div>
                        </div>
                        <div className="col-6 col-md-4 col-xl mb-3">
                            <div className="fw-medium font-18-24-05">Number of openings</div>
                            <div className="font-16-24-05 mb-2">3</div>
                        </div>
                        <div className="col-6 col-md-4 col-xl mb-3">
                            <div className="fw-medium font-18-24-05">Salary</div>
                            <div className="font-16-24-05 mb-2">5,000 - 10,000 INR</div>
                        </div>
                        <div className="col-6 col-md-4 col-xl mb-3">
                            <div className="fw-medium font-18-24-05">Location</div>
                            <div className="font-16-24-05 mb-2">India, India</div>
                        </div>
                        <div className="col-6 col-md-4 col-xl mb-3">
                            <div className="fw-medium font-18-24-05">Type</div>
                            <div className="font-16-24-05 mb-2">Full-time, On-site</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="font-18-24-05 fw-semibold mb-1">Required Skills</div>
                        </div>
                        <div className="col-12 col-lg-12 mb-16">
                            <div className="round-ship-style-1">
                                <ul className="d-flex flex-wrap">
                                    <li>TensorFlow</li>
                                    <li>Keras</li>
                                    <li>Spark</li>
                                    <li>AWS</li>
                                    <li>Python</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="font-18-24-05 fw-semibold mb-3">Job Description</div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="font-16-24-05">
                                Facebook is looking for an experienced and creative Front-End Developer to join our growing development team. In this role, you will be responsible for creating and maintaining user-friendly websites and web applications that are both visually appealing and easy to use.
                        </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
                    <Button variant="outline-danger" className="h-44 fw-medium mx-4">Delete</Button>
                    <Button variant="primary" className="h-44 fw-medium m-0">Edit</Button>
                </Modal.Footer>
            </Modal>


        </main >
    )
}

export default EmployerDashboardLocked