import React, { useState, useEffect } from 'react';
import { FaBackward } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Table from "react-bootstrap/esm/Table";
import { AiOutlineCheck } from "../../assets/Icons/icons";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../../utills/Loader";
import { issueResolved } from "../../state/action/reportAction";
import { getAssesmentDetailsWithReport } from "../../state/action/addAssesment";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import AutohideExample from "../../utills/Notification";
import Controls from "../../components/controls/Controls";


const QuestionDetail = () => {
    const Navigate = useNavigate();
    const { state } = useLocation();
    const [reportResolved, setReportResolved] = useState(false);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [questionData, setQuestionData] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [questionId, setQuestionId] = useState('');
    const [reportId, setReportId] = useState('');
    const [showDrawer, setShowDrawer] = useState(false);
    const [statuslist, setStatusList] = useState([{ id: 0, title: "Pending" }, { id: 1, title: "Resolved" }]);
    const [statusValue, setStatusValue] = useState('');
    const [filterData, setFilterData] = useState(true);

    const [show, setShow] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    useEffect(() => {
        if (state && state.id) {
            setQuestionId(state?.id);
            getQuestionDetails(state?.id);
        }
    }, [filterData]);
    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFilterData(!filterData);
        closeDrawer();
    };
    const closeDrawer = () => {
        setShowDrawer(false);
    };
    const handleReset = () => {
        setStatusValue('');
    };

    const getQuestionDetails = async (id) => {
        let selectQuery = `?status=${statusValue}`;
        const res = await getAssesmentDetailsWithReport(id, selectQuery);
        if (res && res.status === true) {
            setReportData(res?.data?.report);
            setQuestionData(res?.data);
        } else {
            setReportData([]);
        }
    }


    const statusManage = (status) => {

        // status = 1
        const statusColors = {
            1: {
                type: "success",
                text: "Resolved",
            },

            0: {
                type: "warning",
                text: "Pending",
            },
        };
        return (
            <Badge bg={statusColors[status].type} text={status == 4 ? "dark" : ""}>
                {statusColors[status].text}
            </Badge>
        );
    };
    const question = {
        title: 'What is the capital of France?',
        options: [
            { text: 'Berlin', isCorrect: false },
            { text: 'Madrid', isCorrect: false },
            { text: 'Paris', isCorrect: true },
            { text: 'Rome', isCorrect: false },
        ],
    };

    const styles = {
        container: {
            maxWidth: '1100px',
            padding: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        option: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
            fontSize: '15px',
        },
        optionText: {
            marginLeft: '10px',
        },
        correctOption: {
            color: 'green',
            fontWeight: 'bold',
        },
        incorrectOption: {
            color: 'red',
            fontWeight: 'bold',
        },

    };
    const reportIssueResolved = async () => {
        setOpenBreakdrop(true);

        const res = await issueResolved(reportId);
        if (res && res.status === true) {
            setOpenBreakdrop(false);
            setShow({
                isOpen: true,
                message: res?.message,
                type: "success",
            });
            getQuestionDetails(questionId);
            setReportResolved(false);
        } else {
            setOpenBreakdrop(false);
        }
    }
    return (
        <>
            <Loader view={openBackdrop} />
            {!openBackdrop && (
                <div className="container-fluid">

                    <div className="d-flex justify-content-between mb-3">
                        <button className="btn btn-primary" onClick={() => Navigate(-1)}>
                            <div className="d-flex align-items-center gap-2">
                                <span>
                                    <FaBackward />
                                </span>
                                <span>Back</span>
                            </div>
                        </button>
                    </div>
                    <div className="row ProfileCard">
                        <div
                            className={
                                "ProfileCard" ? "col-md-12" : "col-md-4 d-flex flex-column "
                            }
                        >
                            <div className="card flex-fill">
                                <div className="card-body d-flex flex-column align-items-start">
                                    <div style={styles.container}>

                                        <h1 style={styles.title}>{questionData.question}</h1>
                                        {questionData && questionData?.code && (
                                            <div className="font-18-24-05 border p-2
                                             " dangerouslySetInnerHTML={{ __html: questionData?.code }}>
                                            </div>
                                        )}

                                        <div>
                                            {questionData && questionData.options && questionData.options.map((option, index) => (
                                                <div
                                                    key={index}
                                                    style={
                                                        option && option.isTrue == 1
                                                            ? { ...styles.option, ...styles.correctOption }
                                                            : { ...styles.option, ...styles.incorrectOption }
                                                    }
                                                >
                                                    <span>{String.fromCharCode(65 + index)}.</span>
                                                    <span style={styles.optionText}>{option.answer}</span>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-card-head" >
                        <div className="row align-items-center mb-3 mt-4" >
                            <div className="col-md-3">
                                <div className="font-PTSerif-b ps-2 font-18-24-05">
                                    Report A Problem
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="col col-md-auto text-end d-flex justify-content-end">
                                    <Controls.Button
                                        style={{ padding: "7px 30px", marginLeft: "16px" }}
                                        className="btn btn-success h-40 mt-2"
                                        text="filter"
                                        onClick={() => {
                                            toggleDrawer();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {showDrawer && (
                        <div className="filter-drawer-overlay">
                            <div className="filter-drawer-content">
                                <div className="d-flex justify-content-between align-items-center filter-header_div ">
                                    <label className="filter-header">Filter</label>
                                    <button
                                        className="filter-close-button"
                                        onClick={toggleDrawer}
                                    >
                                        x
                                    </button>
                                </div>
                                <div className="d-flex flex-column h-100">
                                    <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">
                                        <div>
                                            <div>
                                                <label className="filter-label">status</label>
                                                <select
                                                    className="form-select h-auto px-3 py-2 "
                                                    name="isActive"
                                                    onChange={(e) => {
                                                        setStatusValue(e.target.value)
                                                    }}
                                                    value={statusValue}
                                                >
                                                    <option value="">None</option>
                                                    {statuslist?.map((item, index) => (
                                                        <option key={index} value={item.id}>
                                                            {item.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="position-relative bottom-0 mb-5 filter-reset-div">
                                            <div className="d-flex justify-content-between">
                                                <button
                                                    className="filter-reset-btn"
                                                    onClick={handleReset}
                                                >
                                                    Reset
                                                </button>
                                                <button className="filter-btn" type="submit">
                                                    Filter
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div >
                    )}
                    <div className="theme-table-wrap" style={{ marginTop: "10px" }}>
                        <Table responsive className="striped-table mb-0">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Reported By</th>
                                    <th>Issue</th>
                                    <th>Raised On</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {reportData && reportData.map((row, index) => {
                                return (
                                    <tbody>
                                        <tr
                                            key={row?.id}
                                            className="border rounded"
                                            style={{ backgroundColor: "white" }}
                                        >
                                            <td style={{ width: "14.5%" }}>{index + 1}</td>

                                            <td style={{ width: "14.5%" }}>{row?.reportedBy || "-"}</td>
                                            <td style={{
                                                width: "14.5%",
                                                whiteSpace: "wrap",
                                            }}>{row?.report || "-"}</td>


                                            <td style={{ width: "14.5%" }}>{moment(row?.createdAt).format("DD-MM-YYYY hh:mm A")}</td>

                                            <td style={{ width: "14.5%" }}>{statusManage(row?.status)}</td>

                                            <td style={{ width: "11%" }}>
                                                <ul className="d-flex action-col-main">
                                                    <li className="me-3">
                                                        <button
                                                            className="btn btn-sm btn-icon btn-success btn-inner"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            data-original-title="View"
                                                            onClick={() => {
                                                                setReportResolved(true);
                                                                setReportId(row?.id);
                                                            }}
                                                            style={{ padding: "2px 4px 2px 4px" }}
                                                            disabled={row?.status == 1}
                                                        >
                                                            <span className="btn-inner">
                                                                <AiOutlineCheck
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                    }}
                                                                />
                                                            </span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                );
                            })
                            }
                        </Table>
                        {reportData && reportData.length == 0 && (

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100px",
                                }}
                            >
                                <h3>No Data Found</h3>
                            </div>
                        )}
                    </div >
                </div >
            )}


            <Modal
                size="md"
                show={reportResolved}
                onHide={() => setReportResolved(false)}
                aria-labelledby=""
                centered
            >
                <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
                <Modal.Body>
                    <p className="mb-0 font-18-24-05 fw-medium">
                        Are you sure want to resolve this issue?
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0 pt-0">
                    <Button
                        className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                        onClick={() => setReportResolved(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                        type="submit"
                        onClick={() => reportIssueResolved()}
                    >
                        Resolve
                    </Button>
                </Modal.Footer>
            </Modal>
            <AutohideExample show={show} setShow={setShow} />

        </>

    );
};

export default QuestionDetail;
