import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Async, { useAsync } from "react-select/async";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";

import { IsWrite, IsDelete } from "../../utills/handlePermission";
import {
  getAdminLists,
  StatusUpdate,
  deleteAdmin,
  ResetAdmin,
  AllowForJobApprove
} from "../../state/action/addAdminAction";
import Form from "react-bootstrap/Form";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import AutohideExample from "../../utills/Notification";
import AddAdmin from "./addAdmin";
import { Loader } from "../../utills/Loader";
import { roledropdown } from "../../state/action/addAdminAction";
import ActionIcon1 from "../../assets/images/edit.svg";
import { async } from "videojs-record";


import '../../components/pagination/style.scss';
import '../../components/pagination/pagination.scss';
import PaginationOne from '../../components/pagination/PaginationOne';
import { useMemo } from 'react';


const initialValues = {
  name: "",
  email: "",
  //status: "",
  isActive: "",
};
const Admin = () => {
  const [flagName, setflagName] = useState(false);
  const [AdminList, setAdminList] = useState([]);
  const [AdminRole, setAdminRole] = useState([]);
  const [AdminDataList, setAdminDataList] = useState([]);
  const [view, setView] = useState(false);

  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [switchId, setSwitchId] = useState(null);
  const [jobPostAllowId, setJobPostAllowId] = useState(null);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openJobAllowModel, setOpenJobAllowModel] = useState(false);
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  const handleCloseFilter = () => setOpenFilterModel(false);

  const deletePermissions = IsDelete("Admin");
  const editPermissions = IsWrite("Admin");



  const [sortFlag, setSortFlag] = useState(false);

  const [openFilterModel, setOpenFilterModel] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const statuslist = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
    { id: "2", title: "delete" }
  ];

  const getroleDropDown = async () => {
    const res = await roledropdown();
    if (res) {
      setAdminRole(res?.data);
    } else {
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const [addskill, setaddskill] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [ResetId, setResetId] = useState(null);

  const [id, setId] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [showDrawer, setShowDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const navigate = useNavigate();

  const [totalRecords, setTotalRecords] = useState(3);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleJobPostModel = () => setOpenJobAllowModel(true);
  const handleCloseJobPost = () => setOpenJobAllowModel(false);

  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);

  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const [currentPage, setCurrentPage] = useState(1);

  let PageSize = 10;
  const AdminDataPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return AdminList;
  }, [currentPage, AdminList]);


  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);
  // list
  useEffect(() => {
    getAdminList(currentPage, "", "");

  }, [filterData, search, page, refresh]);


  useEffect(() => {
    setIsEdit("");
    getroleDropDown();
  }, []);

  const getAdminList = async (page, name = "", flag, perPageSize, limit) => {
    setOpenBreakdrop(true);

    setView(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = perPageSize ? perPageSize : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&sortby=${name}&sortFlag=${flag}`;



    const res = await getAdminLists(queryString);

    if (res.status === true) {
      setAdminList(res.data);
      setTotalRecords(res.total);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPageLimit(recordLimit);
      setView(false);
      //setPage(page);
    } else {
      setAdminList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      setView(false);
    }
    setFilter(false);
  };


  const [pageLimit, setPageLimit] = useState(10);

  const [totalpages, setTotalPages] = useState(10);



  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPage(page);
    getAdminList(page, "", "", pageLimit);
    setPage(page);
  };

  const handlePageLimitChange = (e) => {
    const limit = parseInt(e.target.value);
    setPageLimit(limit || 10);
    setCurrentPage(1);
    getAdminList(1, "", "", limit);
  };

  //status change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "name") {
        getAdminList(fieldName, flagName);
      } else {
        getAdminList(page, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };


  //Allow for job post change
  const handleChangeAllowJobPost = async () => {
    setOpenBreakdrop(true);
    if (jobPostAllowId) {
      const res = await AllowForJobApprove(jobPostAllowId);
      if (res && res.status === true) {
        setJobPostAllowId(null)
        if (fieldName == "name") {
          getAdminList(fieldName, flagName);
        } else {
          getAdminList(page, "", "");
        }
        handleCloseJobPost();
        setOpenBreakdrop(false);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setOpenBreakdrop(!openBackdrop);

        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: "Something goes wrong",
        type: "error",
      });
    }

  };

  const handleReset = () => {
    resetForm();
  };



  const closeDrawer = () => {
    setShowDrawer(false);
  };
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
    // resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    closeDrawer();
  };

  const handleDelete = async () => {
    const res = await deleteAdmin(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getAdminList(fieldName, flagName);
      } else {
        getAdminList(page, "", "");
      }
      handleCloseDelete();
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setView(false);
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
      setView(false);
    }
  };

  const ResetPassword = async (ids) => {
    setOpenBreakdrop(true);

    const res = await ResetAdmin(ids);

    if (res && res.status === true) {
      getAdminList(page, fieldName, flagName);
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  return (
    <div>
      <Loader view={view} />
      <main className="body-background">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="rounded-card-head">
                <div className="row align-items-center mb-3">
                  <div className="col-md-3">
                    <div className="font-PTSerif-b ps-2 font-18-24-05">
                      Admins
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="col col-md-auto text-end d-flex flex-wrap justify-content-end">
                      <Controls.Input
                        className="border-0 rounded p-2 mt-2"
                        placeholder="search"
                        name="text"
                        type="text"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                      <div className="mt-2">
                        {editPermissions && (
                          <Controls.Button
                            style={{ padding: "7px 30px", marginLeft: "16px" }}
                            className="btn btn-primary h-40"
                            text="Add"
                            onClick={() => {
                              handleAddSwitchModel();
                            }}
                          />
                        )}
                        <Controls.Button
                          style={{ padding: "7px 30px", marginLeft: "16px" }}
                          className="btn btn-success h-40"
                          text="filter"
                          onClick={() => {
                            toggleDrawer();
                          }}
                        />


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showDrawer && (
                <>
                  <div className="filter-drawer-overlay" onClick={toggleDrawer}>   </div>

                  <div className="filter-drawer-content">
                    <div className="d-flex justify-content-between align-items-center filter-header_div ">
                      <label className="filter-header">Filter</label>
                      <button
                        className="filter-close-button"
                        onClick={toggleDrawer}
                      >
                        x
                      </button>
                    </div>
                    <div className="d-flex flex-column h-100">
                      <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">
                        <div>
                          <div>
                            <label className="filter-label">name</label>
                            <Controls.Input
                              className="input_filter"
                              name="name"
                              type="text"
                              value={values?.name}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <label className="filter-label">email</label>
                            <Controls.Input
                              className="input_filter"
                              name="email"
                              type="text"
                              value={values?.email}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div>
                            <label className="filter-label">status</label>
                            <select
                              className="form-select font-16-24-05 h-auto py-[10px] px-3"
                              name="isActive"
                              onChange={handleInputChange}
                              value={values.isActive}
                            >
                              <option value="">None</option>                               {statuslist?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <label className="filter-label">role</label>
                            <select
                              className="form-select font-16-24-05 h-auto py-[10px] px-3"
                              name="roleId"
                              placeholder="enter admin role"
                              onChange={handleInputChange}
                              value={values.roleId}
                            >
                              <option value="">None</option>                               
                              {AdminRole?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="position-relative bottom-0 mb-5 filter-reset-div">
                          <div className="d-flex justify-content-between">
                            <button
                              className="filter-reset-btn"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                            <button
                              className="filter-btn"
                              type="submit"
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                </>
              )}

              <div className="theme-table-wrap">
                <Table responsive className="striped-table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Role</th>
                      <th>Reset Password</th>
                      <th>Allow Job Post</th>
                      <th>Status</th>
                      <th>Date</th>
                      {deletePermissions || editPermissions ? (
                        <th>Action</th>
                      ) : null}
                    </tr>
                  </thead>
                  {AdminDataPage.map((row) => {

                    return (
                      <tbody>
                        <tr
                          className="border rounded"
                          style={{ backgroundColor: "white" }}
                        >
                          <td>{row?.name}</td>
                          <td>{row?.email}</td>
                          <td>{row?.phone}</td>
                          <td>{row?.rolename}</td>

                          <td>
                            {editPermissions && (

                              <button
                                className="btn btn-sm btn-icon btn-primary"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title="Reset Password"
                                disabled={row?.id == 3 ? true : false}
                                onClick={() => {
                                  ResetPassword(row.id);
                                  setResetId(row.id);
                                }}
                              >
                                <span className="btn-inner">Reset</span>
                              </button>
                            )}
                          </td>

                          <td>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={row.isJobPost == 1 ? true : false}
                              disabled={row?.id == 3 ? true : false}
                              onChange={(e) => {
                                if (!editPermissions) {
                                  setShow({
                                    isOpen: true,
                                    message: "You don't have permission to edit",
                                    type: "error",
                                  });
                                  return;
                                } else {
                                  handleJobPostModel();
                                  setJobPostAllowId(row.id);
                                }
                              }}
                              name="checkedB"
                              color="primary"
                              sx={{ marginLeft: "40px", px: 0 }}
                            />
                          </td>

                          <td>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={row.isActive == 1 ? true : false}
                              disabled={row?.id == 3 ? true : false}
                              onChange={(e) => {
                                if (!editPermissions) {
                                  setShow({
                                    isOpen: true,
                                    message: "You don't have permission to edit",
                                    type: "error",
                                  });
                                  return;
                                } else {
                                  handleSwitchModel();
                                  setStatus(
                                    e.target.checked == true
                                      ? "Active"
                                      : "InActive"
                                  );
                                  setSwitchId(row.id);
                                  setChecked(e.target.checked);
                                }
                              }}
                              name="checkedB"
                              color="primary"
                              sx={{ marginLeft: "40px", px: 0 }}
                            />
                          </td>
                          <td>
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </td>
                          {editPermissions || deletePermissions ? (
                            <td style={{ width: "200px" }}>
                              <ul className="d-flex action-col-main">
                                <li className="me-3">
                                  {editPermissions && (
                                    <button
                                      className="btn btn-sm btn-icon btn-warning me-4"
                                      data-toggle="tooltip" data-placement="top" title="Edit"
                                      disabled={row?.id == 3 ? true : false}
                                      onClick={() => {
                                        handleAddSwitchModel();
                                        setAdminDataList(row);
                                        setIsEdit(true);
                                      }}
                                    >
                                      <span className="btn-inner">
                                        <EditIcon />
                                      </span>
                                    </button>
                                  )}
                                  {deletePermissions && (<button

                                    className="btn btn-sm btn-icon btn-danger btn-inner"
                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                    disabled={row?.id == 3 ? true : false}
                                    onClick={() => {
                                      handleDeleteSwitchModel();
                                      setId(row.id);
                                      setDeleteId(row?.id);
                                      handleDeleteModel();
                                    }}
                                  >
                                    <span className="btn-inner">
                                      <DeleteIcon />
                                    </span>
                                  </button>
                                  )}
                                </li>
                              </ul>
                            </td>
                          ) : null}
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
                {AdminDataPage && AdminDataPage.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </div>

              <PaginationOne
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={PageSize}
                onPageChange={page => [setCurrentPage(page), getAdminList(page, "", "", pageLimit)]} />
              <div className="pt-2">
                {" "}
                Showing{" "}
                {currentPage * pageLimit > totalRecords
                  ? totalRecords
                  : currentPage * pageLimit}{" "}
                of {totalRecords} Results
              </div>








            </div>
          </div>
        </div>

        <Modal
          size="md"
          show={openSwitchModel}
          onHide={() => handleCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to change the status?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary px-3 py-2 fw-medium m-2"
              onClick={() => handleCloseSwitch()}
            >
              No
            </Button>
            <Button
              className="btn btn-primary px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleCloseSwitch();
                handleChangeStatus(switchId);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={openJobAllowModel}
          onHide={() => handleCloseJobPost()}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to allow admin to job post?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleCloseJobPost()}
            >
              No
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => handleChangeAllowJobPost(jobPostAllowId)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={openDeleteSwitchModel}
          onHide={() => handleDeleteCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to delete?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary fw-medium m-2 px-3 py-2"
              onClick={() => handleDeleteCloseSwitch()}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary fw-medium m-2 px-3 py-2"
              type="submit"
              onClick={() => {
                handleDeleteCloseSwitch();
                handleDelete(deleteId);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AddAdmin
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          handleAddCloseSwitch={handleAddCloseSwitch}
          openAddSwitchModel={openAddSwitchModel}
          AdminDataList={AdminDataList}
          setOpenFilterModel={setOpenFilterModel}
          handleCloseFilter={handleCloseFilter}
          getAdminList={getAdminList}
          page={page}

        />
        <AutohideExample show={show} setShow={setShow} />
      </main>
    </div>
  );
};

export default Admin;
