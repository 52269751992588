import React from "react";
import { useUserNotificationSubscribeHook } from "../../notification";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Footer = () => {
  const { registerAndSubscribe } = useUserNotificationSubscribeHook();
  const data = useSelector((state) => state?.auth);

  useEffect(() => {
    if (data && data?.user?.id) {
      const payload = {
        userId: data?.user?.id,
        type: 1,
      }
      registerAndSubscribe(payload);
    }
  }, []);

  return (
    <div>
      {/* <footer className="footer-copyright font-18-24-05 text-center py-3">
        TechFresher 2023 copyright received
      </footer> */}
    </div>
  );
};

export default Footer;
