import React, { useState, useEffect } from "react";
import {
  FaBackward,
  RxCross2,
} from "../../assets/Icons/icons";
import Table from "react-bootstrap/esm/Table";
import moment from "moment";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";
import { useSelector } from "react-redux";
import { useForm } from "../../utills/useForms";
import { ReactComponent as ViewIcon } from "../../assets/images/view.svg";

import {
  getJobPostDetails,
  assignFresherbyAdmin,
  rejectCandidateByAdmin,
  assignjob,
  adminassignjob
} from "../../state/action/jobpostAction";

const initialValues = {
  employerReason: "",
  title: "",
};

const JobpostDetails = () => {
  const navigate = useNavigate();

  const { values, setValues, errors, setErrors, resetForm } =
    useForm(initialValues, true);

  const [fresherId, setFresherId] = useState(null);
  const [jobId, setJobId] = useState("");
  const [flagName, setflagName] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [JobpostList, setJobpostList] = useState([]);
  const [InterviewReject, setInterviewReject] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [candidateId, setCandiateId] = useState("");
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const [JobidData, setJobidData] = useState("");
  const [AdminDataList, setAdminDataList] = useState([]);
  const [search, setSearch] = useState("");
  const [jobApproveRejectModel, setJobApproveRejectModel] = useState(false);
  const [view, setView] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [id, setId] = useState([]);
  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [jobApproveRejectId, setJobApproveRejectId] = useState(null);


  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const AssignFreshers = async (dataId) => {
    const res = await assignFresherbyAdmin(dataId);
    if (res && res.status === true) {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      getJobPostList(dataId);

    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  }


  const AdminJobAssign = async () => {
    const res = await assignjob();
    if (res && res.status == true) {
      setAdminDataList(res.data);
    }
  };
  const Adminid = useSelector((state) => state?.auth);
  const handleSubmit = async (e) => {
    if (JobidData) {
      const payload = {
        jobId: jobId,
        adminId: JobidData
      }
      const res = await adminassignjob(Adminid?.admin?.id, payload);
      if (res && res.status === true) {
        AdminJobAssign()
        setAdminDataList(res.data);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
        setView(false);
        handleAddCloseSwitch();
        resetForm();
        assignjob(1, "", "");

      } else {
        setAdminDataList(res.data);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
        setView(false);
      }
    } else {
      setShow({
        isOpen: true,
        message: "Please select admin",
        type: "error",
      });
    }

  }

  const statusManage = (status) => {
    const statusColors = {
      1: {
        type: "success",
        text: "Schedule",
      },

      0: {
        type: "warning",
        text: "Review",
      },
      2: {
        type: "info",
        text: "On Going",
      },
      3: {
        type: "primary",
        text: "Completed",
      },
      4: {
        type: "secondary",
        text: "reject by fresher",
      },

      5: {
        text: "cancel by employer",
        type: "dark",
      },
      6: {
        text: "admin Reject Candidate",
        type: "danger"
      }
    };
    return (
      <Badge text={status == 4 ? "dark" : ""}>
        {statusColors[status]?.text}
      </Badge>
    );
  };
  const confirmRejectJob = async () => {
    // if (jobApproveRejectId) {
    // const res = await rejectJobbyAdmin(jobApproveRejectId, jobId, fresherId);
    const response = await rejectCandidateByAdmin(jobId, fresherId);
    setOpenBreakdrop(true);
    if (response && response.status === true) {
      refreshScreen();
      setJobApproveRejectModel(false);
      setJobApproveRejectId(null);
      if (fieldName == "name") {
        getJobPostList(fieldName, flagName);
      } else {
        getJobPostList("");
      }
      setShow({
        isOpen: true,
        message: response?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      setJobApproveRejectModel(false);
      setShow({
        isOpen: true,
        message: response?.message,
        type: "error",
      });
    }
    setShowRejectModal(false);
  };

  useEffect(() => {
    getJobPostList("", "");
    AdminJobAssign();
  }, [refresh, filterData, search, page]);

  const { state } = useLocation();
  useEffect(() => {
    if (state && state?.id) {
      setJobId(state?.id)
    }
  }, [])

  const getJobPostList = async (page, name = "", flag, limit) => {
    setOpenBreakdrop(true);
    setView(true);
    const res = await getJobPostDetails(state?.id);
    if (res.status === true) {
      setJobpostList(res.data);
      setJobidData(res?.data?.adminId)
    } else {
      setJobpostList([]);
    }
    setView(false);
  };

  return (
    <div>
      <Loader view={view} />
      {!view && (
        <div className="container-fluid">
          <div className="  d-flex justify-content-between mb-3">
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <div className="d-flex align-items-center gap-2">
                <span>
                  <FaBackward />
                </span>
                <span>Back</span>
              </div>
            </button>
            <div className="d-flex align-items-center gap-2">
              {Adminid?.admin?.roleId == 3 ? (
                <button className="btn btn-success"
                  onClick={() => handleAddSwitchModel()}>
                  <div className="d-flex align-items-center gap-2 ">
                    <span></span>
                    <span>Assign job</span>
                  </div>
                </button>
              ) : (
                ""
              )}
              <button className="btn btn-danger" onClick={() => AssignFreshers(state?.id)}>
                {" "}
                <div className="d-flex align-items-center gap-2 ">
                  <span></span>
                  <span>Assign fresher</span>
                </div>
              </button>
            </div>
          </div>
          <div className="row ProfileCard">
            <div
              className="ProfileCard col-md-12"
            >
              <div className="theme-table-wrap" style={{ marginTop: "10px" }}>
                <Table responsive className="striped-table mb-0">
                  <thead>
                    <tr>
                      <th>Job Title</th>
                      <th>No.of Openings</th>
                      <th>Job Location</th>
                      <th>Job Type</th>
                      <th>Job preferences</th>
                      <th>Job description</th>
                      <th>Skills</th>
                      <th>Salary</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      className="border rounded"
                      style={{ backgroundColor: "white" }}
                    >
                      <td style={{ width: "14.5%" }}>{JobpostList.jobTitle}</td>
                      <td style={{ width: "14.5%" }}>{JobpostList.openings}</td>
                      <td style={{ width: "14.5%" }}>
                        {JobpostList.cityName},
                        {JobpostList.stateName},
                        {JobpostList.countryName}
                      </td>
                      <td style={{ width: "14.5%" }}>
                        {JobpostList?.jobTypeId?.title}
                      </td>
                      <td
                        style={{
                          width: "14.5%",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {JobpostList?.jobPreferId?.title || "-"}
                      </td>
                      <td
                        style={{
                          width: "14.5%",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {/* {JobpostList?.jobDescription} */}
                        <div dangerouslySetInnerHTML={{ __html: JobpostList?.jobDescription}}></div>

                      </td>
                      <td>
                        {JobpostList?.jobSkill?.map((row) => {
                          return <div> {row.skillName}</div>;
                        })}
                      </td>


                      <td>
                        {JobpostList?.minSalary}-{JobpostList?.maxSalary}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="ProfileCard col-md-12 ">
              <div className="ProfileCard gap-0 mt-3 ">
                <div className="card flex-fill mb-3">
                  <div className="card-body">
                    <h5
                      className="card-title"
                      style={{
                        borderBottom: "1px solid #E1E8F4",
                        paddingBottom: "12px",
                      }}
                    >
                      Employer Details
                    </h5>

                    <div className="row">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">Name:</h6>
                            </div>
                            <div className="col-md-6">
                              <p>
                                {" "}
                                {JobpostList?.employerId?.firstName}{" "}
                                {JobpostList?.employerId?.lastName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">Email:</h6>
                            </div>
                            <div className="col-md-6">
                              <p> {JobpostList?.employerId?.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">Website:</h6>
                            </div>
                            <div className="col-md-6">
                              <p>{JobpostList?.employerId?.website}</p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">job type:</h6>
                            </div>
                            <div className="col-md-6">
                              <p>{JobpostList?.jobTypeId?.title}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">job Location:</h6>
                            </div>
                            <div className="col-md-6">
                              <p>
                                {" "}
                                {JobpostList.cityName},{JobpostList.stateName},
                                {JobpostList.countryName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">job description:</h6>
                            </div>
                            <div className="col-md-6">
                            <div dangerouslySetInnerHTML={{ __html: JobpostList?.jobDescription}}></div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">company Name</h6>
                            </div>
                            <div className="col-md-6">
                              <p>{JobpostList?.employerId?.companyName}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">No of Employee </h6>
                            </div>
                            <div className="col-md-6">
                              {JobpostList?.employerId?.noofEmployee}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <h6 className="fw-bold">Phone</h6>
                            </div>
                            <div className="col-md-6">
                              +{JobpostList?.employerId?.countryCode}{" "}
                              {JobpostList?.employerId?.mobile}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <h5>Fresher Details</h5>

                <div className="theme-table-wrap" style={{ marginTop: "10px" }}>
                  <Table responsive className="striped-table mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Location</th>

                        <th>college</th>

                        <th>start Date</th>
                        <th>End Date</th>
                        <th>status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {JobpostList?.fresher?.map((row) => {
                      return (
                        <tbody>
                          <tr
                            // key={row.id}
                            className="border rounded"
                            style={{ backgroundColor: "white" }}
                          >
                            <td style={{ width: "14.5%" }}>
                              {row?.fresherId?.firstName}
                            </td>
                            <td style={{ width: "14.5%" }}>
                              {row?.fresherId?.email}
                            </td>
                            <td style={{ width: "14.5%" }}>
                              {row?.fresherId?.cityName},
                              {row?.fresherId?.countryName}
                            </td>
                            <td
                              style={{
                                width: "14.5%",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {row?.fresherId?.college}
                            </td>
                            <td>
                              {moment(row?.fresherId?.eduStartDate).format(
                                "MM-DD-YYYY LT"
                              )}
                            </td>{" "}
                            <td>
                              {" "}

                              {
                                // row?.fresherId?.eduEndDate
                                row?.fresherId?.eduEndDate ? moment(row?.fresherId?.eduEndDate).format(
                                  "MM-DD-YYYY LT"
                                ) : 'still enrolled'
                              }

                              {/* {moment(row?.fresherId?.eduEndDate).format(
                                "MM-DD-YYYY LT"
                              )} */}
                            </td>
                            <td>
                              {statusManage(row?.status)}
                            </td>
                            <td>
                              <ul className="d-flex action-col-main">
                                <li className="me-3">
                                  <button
                                    className="btn btn-sm btn-icon btn-inner btn-dark me-4"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Reject Job"

                                    disabled={row?.status === 6 || row?.status === 3}



                                    onClick={() => {
                                      setShowRejectModal(true);
                                      setCandiateId(row?.id);
                                      setFresherId(row?.fresherId?.id);
                                    }}
                                  >
                                    <span className="btn-inner">
                                      <RxCross2
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-icon btn-secondary btn-inner"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="view"
                                    onClick={() => {
                                      navigate(`/admin/fresherDetail`, {
                                        state: {
                                          id: row?.fresherId.id,
                                        },
                                      });
                                    }}
                                  >
                                    <span className="btn-inner">
                                      <ViewIcon
                                        style={{
                                          width: "19px",
                                          height: "19px",
                                        }}
                                      />
                                    </span>
                                  </button>

                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </Table>
                  {JobpostList?.fresher?.length == 0 && (<div
                    className="noDataFound"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "150px",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>


          <Modal
            size="md"

            show={showRejectModal}
            onHide={() => {
              setShowRejectModal(false);
              resetForm();
            }}

            aria-labelledby=""
            centered
          >
            <Modal.Header className="border-0" closeButton></Modal.Header>
            <Modal.Body className="px-lg-4">
              <div className="row">
                <div className="col-12 col-lg mb-16">
                  <div className="font-18-24-05 mb-1 fw-semibold mb-2">
                    Do you want to reject this candidate?
                  </div>
                  <div className="font-16-24-05">
                    If yes, Please provide the feedback
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 mb-16">
                  <textarea
                    className="form-control h-auto"
                    placeholder="Eg. I didn't like this candidate because..."
                    rows="4"
                    name="employerReason"
                    type="text"
                    value={values?.employerReason}
                    onChange={handleInputChange}
                    maxLength={100}
                  ></textarea>
                  {Boolean(errors.employerReason) ? (
                    <p className="text-invalid">{errors.employerReason}</p>
                  ) : (
                    ""
                  )}

                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
              <Button
                variant="outline-primary"
                className="h-auto px-3 py-2 fw-medium mx-4"

                onClick={() => {
                  // handleRejectCandidate()
                  confirmRejectJob();


                }}


              >
                Yes, Reject
              </Button>
              <Button
                variant="primary"
                className="h-auto px-3 py-2 fw-medium m-0"
                onClick={() => {
                  setInterviewReject(false);
                  setShowRejectModal(false);
                  resetForm();
                }}
              >
                No, Keep candidate
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="md"
            show={openAddSwitchModel}
            onHide={handleAddCloseSwitch}
            aria-labelledby=""
            centered
          >
            <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
            <Modal.Body>

              <div className="mb-3">
                <select
                  className="form-select font-16-24-05 h-auto py-[10px] px-3"
                  name="roleId"
                  placeholder="enter admin role"
                  onChange={(e) => [handleInputChange, setJobidData(e.target.value)]}
                  value={JobidData || ""}
                >
                  <option> Admin list</option>
                  {AdminDataList?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              {Boolean(errors.roleId) ? (
                <p className="text-invalid">{errors.roleId}</p>
              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
              <Button
                className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                onClick={handleAddCloseSwitch}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="h-auto px-3 py-2 fw-medium m-0"
                onClick={(e) => {
                  handleSubmit(e);
                  //handleAddCloseSwitch();
                  //resetForm();
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          <AutohideExample show={show} setShow={setShow} />
        </div>
      )}
    </div>
  );
};

export default JobpostDetails;