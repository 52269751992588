import React, { useState } from "react";
import Controls from "../../components/controls/Controls";
import "./personalinformation.scss";

import { Redirect, useNavigate, Link, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useForm, Form } from "../../utills/useForms";
import {
  getCityLists,
  getCountryLists,
  getStateLists,
  countryCodeDropdown
} from "../../state/action/fresherAction";
import { addFresherDataRedux } from "../../state/action/authAction";
import ArrowLeft from "../../assets/images/arrow_left.svg";
import { store } from "../../state/store";

const initialValues = {
  firstName: "",
  lastName: "",
  mobile: "",
  countryId: "",
  stateId: "",
  cityId: "",
  email: "",
  countryCode: 91,
};

const PersonalInformation = (props) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dispatch = useDispatch();
  const { state } = useLocation();

  const EmailData = useSelector((state) => state?.auth?.admin);

  const {
    activeTab,
    setActiveTab,
    setStoreData,
    storedata,
    handleSubmitBack1,
  } = props;

  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const datas = useSelector((state) => state?.auth?.user);

  //country Data
  const handleCountryLocation = (e) => {
    setValues({
      ...values,
      countryId: e.target.value,
      stateId: "",
      cityId: "",
    });

    handleStateList(e.target.value);
  };

  const handleStateLocation = (e) => {
    setValues({
      ...values,
      cityId: "",
    });
    handleCityList(e.target.value);
  };

  const getContryData = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList(res.data);
      setStateList([]);
    } else {
      setCountryList([]);
      setStateList([]);
    }
  };
  //city Data

  const handleCityList = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList(res.data);
    } else {
      setCityList([]);
    }
  };

  //state Data
  const handleStateList = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };

  const getCountryCodeData = async () => {
    const res = await countryCodeDropdown();
    if (res && res.data) {
      setCountryCodeList(res.data);

    } else {
      setCountryCodeList([]);
    }
  };

  useEffect(() => {
    getContryData();
    getCountryCodeData();

  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("firstName" in fieldValues) {
      if (fieldValues.firstName !== "" && fieldValues.firstName !== undefined && fieldValues.firstName != null) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.firstName)) {
          temp.firstName = "Invalid First Name";
        } else {
          temp.firstName = "";
        }
      } else {
        temp.firstName = "This field is required.";
      }
    }

    if ("countryId" in fieldValues) {
      temp.countryId = fieldValues.countryId && fieldValues.countryId != null
        ? "" : "This field is required.";
    }

    if ("stateId" in fieldValues) {
      temp.stateId = fieldValues.stateId && fieldValues.stateId != null
        ? "" : "This field is required.";
    }

    if ("cityId" in fieldValues) {
      temp.cityId = fieldValues.cityId && fieldValues.cityId != null
        ? "" : "This field is required.";
    }
    if ("lastName" in fieldValues) {
      if (fieldValues.lastName !== "" && fieldValues.lastName !== undefined && fieldValues.lastName != null) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.lastName)) {
          temp.lastName = "Invalid Last Name";
        } else {
          temp.lastName = "";
        }
      } else {
        temp.lastName = "This field is required.";
      }
    }
    if ("mobile" in fieldValues) {
      if (fieldValues.mobile !== "" && fieldValues.mobile !== undefined && fieldValues.mobile != null) {
        const regex = /^\d{10}$/;
        if (!regex.test(fieldValues.mobile)) {
          temp.mobile = "Invalid Phone Number";
        } else {
          temp.mobile = "";
        }
      } else {
        temp.mobile = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    if (datas) {
      setValues({
        ...datas,
        countryCode: datas?.countryCode || 91,
      });
      handleStateList(datas.countryId);
    }
  }, [datas]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = {
        ...datas,
        firstName: values.firstName,
        lastName: values.lastName,
        mobile: values.mobile,
        countryId: values.countryId,
        stateId: values.stateId,
        cityId: values.cityId,
        email: EmailData.email,
        countryCode: values.countryCode,
      };

      dispatch(addFresherDataRedux(body));
      setActiveTab(2);

    }
  };

  useEffect(() => {
    values.countryId = values.countryId || 101; // Set default value to 102 if countryId is not already set
    handleStateList(values.countryId);
  }, [values.countryId]);

  useEffect(() => {
    if (values.stateId) {
      handleCityList(values.stateId);
    }
  }, [values.stateId]);

  return (
    <main>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="col-lg-12 align-self-center">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-16">
                  <div className="form-label">First name</div>
                  <Controls.Input
                    className="form-control"
                    placeholder="First name"
                    type="text"
                    lable="first name"
                    name="firstName"
                    onChange={handleInputChange}
                    error={errors.firstName}
                    helperText={errors.firstName}
                    value={values.firstName}
                  />
                  {Boolean(errors.firstName) ? (
                    <p className="text-invalid">{errors.firstName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-16">
                  <div className="form-label">Last name</div>
                  <Controls.Input
                    className="form-control"
                    placeholder="Last name"
                    name="lastName"
                    type="text"
                    lable="last name"
                    onChange={handleInputChange}
                    error={errors.lastName}
                    helperText={errors.lastName}
                    value={values.lastName}
                  />
                  {Boolean(errors.lastName) ? (
                    <p className="text-invalid">{errors.lastName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Phone number</div>
                <div className="position-relative phone-number-field">
                  <div className="phone-number-select">
                    <select className="form-select"
                      name="countryCode"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      id="countryCode"
                      value={values.countryCode}

                    >
                      {/* <option selected>+91</option>
                      <option>+91</option>
                      <option>+91</option>
                      <option>+91</option> */}
                      {
                        countryCodeList && countryCodeList.map((item, index) => (
                          <option key={index} value={item.phonecode}>
                            {`+${item.phonecode}`}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                  <Controls.Input
                    className="form-control"
                    placeholder="Enter your phone number"
                    name="mobile"
                    type="text"
                    lable="Phone number"
                    onChange={handleInputChange}
                    error={errors.mobile}
                    helperText={errors.mobile}
                    maxLength="10"
                    value={values.mobile}
                  />
                  {errors.mobile ? (
                    <p className="text-invalid">{errors.mobile}</p>
                  ) : (
                    <p
                      style={{
                        color: "red",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.mobile}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Country of residence</div>

                <select
                  className="form-select"
                  name="countryId"
                  onChange={(e) => {
                    handleCountryLocation(e);
                    // handleInputChange(e);
                  }}
                  id="countryId"
                  value={values.countryId || 102}
                >
                  <option value="">Select the Country</option>
                  {countryList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {Boolean(errors.countryId) ? (
                  <p className="text-invalid">{errors.countryId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">State of residence</div>
                <select
                  className="form-select"
                  name="stateId"
                  onChange={(e) => {
                    handleStateLocation(e);
                    handleInputChange(e);
                  }}
                  id="stateId"
                  value={values.stateId}
                >
                  <option value="">Select the State</option>
                  {stateList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {Boolean(errors.stateId) ? (
                  <p className="text-invalid">{errors.stateId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">City of residence</div>
                <select
                  className="form-select"
                  name="cityId"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  id="cityId"
                  value={values?.cityId}
                >
                  <option value="">Select the city</option>
                  {cityList?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item.name}
                    </option>
                  ))}
                </select>

                {Boolean(errors.cityId) ? (
                  <p className="text-invalid">{errors.cityId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mb-16 mt-16">
              <Controls.Button
                className="btn btn-primary h-54 w-100"
                text="Next"
                onClick={(e) => handleSubmit(e)}
              />
            </div>

            <div className="row"></div>
          </div>
        </form>
      </div >
    </main >
  );
};

export default PersonalInformation;
