import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Form, useForm } from "../../utills/useForms";
import {
  JobPreferLists,
  addNewJobs,
  getCityLists,
  getCountryLists,
  getStateLists,
} from "../../state/action/addjobAction";

import {
  JobTypes,
  JobPreferList,
  getJobLists,
} from "../../state/action/addjobAction";
import { SkillList } from "../../state/action/skillAction";
import { useSelector } from "react-redux";
import AutohideExample from "../../utills/Notification";
import {
  employeeJobClose,
  getCandidateDetails,
  getCandidateHire,
  getNumberEmployer,
} from "../../state/action/employerAction";
import {
  InterviewDropdown,
  addInterviewJob,
  employerJobDelete,
  employerJobDetailsUpdate,
  employerJobPostDetails,
  getCandidateDetailsList,
  getRejectCandidateJob,
} from "../../state/action/employerAction";
import moment from "moment";
import { Tooltip } from "react-bootstrap";
import { async } from "videojs-record";
import EmployerDashboardLocked from "../employerdashboardlocked";
import Multiselect from "multiselect-react-dropdown";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import { Helmet } from 'react-helmet'
const initialValues = {
  jobTypeId: "",
  jobPreferId: "",
  jobTitle: "",
  jobDescription: "",
  maxSalary: "",
  minSalary: "",
  openings: "",
  salCurrency: "INR",
  countryId: "",
  stateId: "",
  cityId: "",
  skillId: "",
  date: "",
  time: "12:00",
  location: "",
  interviewTypeId: "",
  employerReason: "",
  jobPostId: ""
};

const EmployerJobs = () => {
  const [PostNewJob, setPostNewJob] = useState(false);
  const [JobDetails, setJobDetails] = useState(false);
  const [IgnoreJob, setIgnoreJob] = useState(false);
  const [CloseJob, setCloseJob] = useState(false);

  const [FillInterviewDetails, setFillInterviewDetails] = useState(false);
  const [InterviewReject, setInterviewReject] = useState(false);
  const [InterviewHire, setInterviewHire] = useState(false);

  const [CandidateInformation, setCandidateInformation] = useState(false);
  const [jobPostList, setJobPostList] = useState([]);
  const [candidateData, setCandiadteData] = useState([]);

  const [jonPostSkillList, setJobPostSkillList] = useState([]);

  const [jobType, setJobType] = useState([]);
  const [jobPrefer, setJobPrefer] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [selectSkill, setSelectSkill] = useState([]);

  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [interviewIdEmployer, setInterviewIdEmployer] = useState(null);
  const [statusValue, setStatusValue] = useState("");

  const [candidateList, setCandiadteList] = useState([]);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("Jobtab1");
  const [interviewType, setInterviewType] = useState([]);
  const [length, setLength] = useState([]);

  const [acctiveTab, setAcctiveTab] = useState(1);

  const [deleteId, setDeleteId] = useState();
  const [jobId, setJobId] = useState(null);
  const [jobPostId, setJobPostId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteSkill, setDeleteSkill] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [Jobstatus, setjobstatus] = useState(0);

  const handleSortingChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getCandidateLists = async (id, jobId) => {
    const res = await getCandidateDetails(id, jobId);
    if (res) {
      setCandiadteData(res?.data);
    } else {
      setCandiadteData([]);
    }
  };
  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);



  const handleEditorChange = (state) => {
    if (state && state != null) {
      setEditorState(state);
      convertContentToHTML();
    }
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }

  const [isEdit, setIsEdit] = useState(false);

  const setTab = (tab) => {
    setAcctiveTab(tab);
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    if (tabKey == "Jobtab2") {
      setSelectedOption(4)
    }
    else {
      setSelectedOption("")

    }
  };

  const dataId = useSelector((state) => state?.auth?.user);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("jobTitle" in fieldValues) {
      temp.jobTitle = fieldValues.jobTitle ? "" : "This field is required.";
    }

    if ("openings" in fieldValues) {
      //only number regex
      var re = /^[0-9\b]+$/;
      // if (fieldValues.openings) {
      //   if (re.test(fieldValues.openings)) {
      //     temp.openings = "";
      //   } else {
      //     temp.openings = "Only numbers are allowed.";
      //   }
      // }

      if (fieldValues.openings) {
        if (re.test(fieldValues.openings)) {
          temp.openings = "";
        } else {
          temp.openings = "Only numbers are allowed.";
        }
      }
      else {
        temp.openings = "This field is required.";
      }



    }

    if ("jobTypeId" in fieldValues) {
      temp.jobTypeId = fieldValues.jobTypeId ? "" : "This field is required.";
    }
    if ("jobPreferId" in fieldValues) {
      temp.jobPreferId = fieldValues.jobPreferId
        ? ""
        : "This field is required.";
    }
    // if ("jobDescription" in fieldValues) {
    //   temp.jobDescription = fieldValues.jobDescription
    //     ? ""
    //     : "This field is required.";
    // }

    if ("minSalary" in fieldValues) {
      if (fieldValues.minSalary) {
        const minSalary = parseFloat(fieldValues.minSalary);
        const maxValue = parseFloat(values.maxSalary);
        if (minSalary < 0) {
          temp.minSalary = "Minimum salary cannot be negative.";
        }
        else if (minSalary > maxValue) {

          temp.minSalary = `Minimum salary should be less than or equal to ${maxValue}.`;
        } else if (
          fieldValues.maxSalary &&
          minSalary > parseFloat(fieldValues.maxSalary)
        ) {
          temp.minSalary =
            "Minimum salary should be less than or equal to the maximum salary.";
        } else if (minSalary < maxValue) {
          temp.maxSalary = "";
          temp.minSalary = "";
        }
        else {
          temp.minSalary = "";
        }
      } else {
        temp.minSalary = "This field is required.";
      }
    }
    if ("maxSalary" in fieldValues) {
      if (fieldValues.maxSalary) {
        const minSalary = parseFloat(values.minSalary);
        const maxSalary = parseFloat(fieldValues.maxSalary);
        if (maxSalary < 0) {
          temp.maxSalary = "Maximum salary cannot be negative.";
        }
        else if (maxSalary < parseFloat(values.minSalary)) {
          temp.maxSalary =
            "Maximum salary should be greater than or equal to the minimum salary.";
        }
        else if (minSalary > maxValue) {

          temp.maxSalary = "";
          temp.minSalary = "";

        } else {
          temp.maxSalary = "";
        }
      } else {
        temp.maxSalary = "This field is required.";
      }
    }

    if ("countryId" in fieldValues) {
      temp.countryId = fieldValues.countryId ? "" : "This field is required.";
    }

    if ("stateId" in fieldValues) {
      temp.stateId = fieldValues.stateId ? "" : "This field is required.";
    }
    if ("salCurrency" in fieldValues) {
      temp.salCurrency = fieldValues.salCurrency ? "" : "This field is required.";
    }

    if ("cityId" in fieldValues) {
      temp.cityId = fieldValues.cityId ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });


    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const validatess = (fieldValues = values) => {
    let temp = { ...errors };

    if ("interviewTypeId" in fieldValues) {
      temp.interviewTypeId = fieldValues.interviewTypeId
        ? ""
        : "This field is required.";
    }

    if ("date" in fieldValues) {
      temp.date = fieldValues.date ? "" : "This field is required.";
    }

    if ("time" in fieldValues) {
      temp.time = fieldValues.time ? "" : "This field is required.";
    }

    if ("location" in fieldValues) {
      if ("jobType" in fieldValues) {
        temp.jobType = fieldValues.jobType ? "" : "This field is required.";
      }

      if ("jobPrefer" in fieldValues) {
        temp.jobPrefer = fieldValues.jobPrefer ? "" : "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  const employerReasonValidate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("employerReason" in fieldValues) {
      temp.employerReason = fieldValues.employerReason
        ? ""
        : "This field is required.";
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };


  const handleClose = () => {
    setPostNewJob(false);
    setIsEdit(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate, validatess, employerReasonValidate);

  const minValue = values.minSalary;

  const maxValue = values.maxSalary;

  const getHandleData = async (e) => {
    const res = await JobTypes();
    if (res && res.data) {
      setJobType(res.data);
    }
  };

  const getHandlePreferData = async (e) => {
    const res = await JobPreferLists();
    if (res && res.data) {
      setJobPrefer(res.data);
    }
  };

  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      // setSkillData(res.data);

      const skills = res.data.map((item) => {
        return { skillId: item.id, skillName: item.title };
      });

      setSkillData(skills);
    }
  };

  useEffect(() => {
    getHandleData();
    getHandlePreferData();
  }, [skillData]);

  useEffect(() => {
    getSkillList();
  }, []);

  //remove skill


  //remove the skill
  const removeSkill = async (e, item) => {
    setSkillData([...skillData, item]);
    const removeSkill = selectSkill.filter(
      (items) => items.skillId != item.skillId
    );
    setSelectSkill(removeSkill);
    if (item && item.id) {
      setDeleteSkill([...deleteSkill, item?.id]);
    }
  };

  const multiselectSelectSkill = (data, e) => {
    setSelectSkill(data);
    setSkillData(skillData.filter((item) => item.skillId != e.skillId));
  };



  //country Data
  const handleCountryLocation = (e) => {
    setValues({
      ...values,
      countryId: e.target.value,
      stateId: "",
      cityId: "",
    });
    handleStateList(e.target.value);
    // setCityList([]);
  };

  const handleStateLocation = (e) => {
    setValues({
      ...values,
      cityId: "",
    });
    handleCityList(e.target.value);
  };

  const getContryData = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList(res.data);
      // setCityList([]);
    } else {
      setCountryList([]);
      // setCityList([]);
      setStateList([]);
    }
  };
  //city Data

  const handleCityList = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList(res.data);
    } else {
      setCityList([]);
    }
  };

  //state Data
  const handleStateList = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };
  let propertiess = [];

  let skill = selectSkill?.map((car) => {
    return car.skillId;
  });

  let properties = {};

  let datass1 = selectSkill?.map((item) => {
    if (item && item.id && item.id !== "") {
      properties = {
        skillId: item.skillId,
        skillName: item.skillName,
      };
    } else {
      properties = {
        skillId: item.skillId,
        jobPostId: jobPostList?.id,
        skillName: item.skillName,
      };
    }

    return properties;
  });

  const jobDescriptionValidate = () => {
    if (convertedContent == "" || convertedContent == "<p></p>" || convertedContent == null) {
      setShow({
        isOpen: true,
        message: "Job Description is required",
        type: "error",
      });
      return false;
    }
    else {
      return true;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (validate() && jobDescriptionValidate()) {
      if (isEdit) {
        const payload = {
          employerId: dataId?.id,
          jobTitle: values.jobTitle,
          openings: values.openings,
          countryId: values.countryId,
          stateId: values.stateId,
          cityId: values.cityId,
          jobTypeId: values.jobTypeId,
          jobPreferId: values.jobPreferId,
          jobDescription: convertedContent,
          salCurrency: values.salCurrency,
          minSalary: values.minSalary,
          maxSalary: values.maxSalary,
          skillId: datass1,
          deleteSkill: deleteSkill,
        };
        const res = await employerJobDetailsUpdate(jobPostList?.id, payload);
        if (res && res.status === true) {
          setConvertedContent(null);
          setEditorState(() => EditorState.createEmpty());
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          handleClose();
          setJobDetails(false);
          getJobPostData(dataId?.id);
          setDeleteSkill([]);
          setSelectSkill([]);
          getSkillList();
          
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          getSkillList();
        }
      }

      if (!isEdit) {
        if (selectSkill.length === 0) {
          setErrorMessage("Please select at least one skill.");
        } else {
          const body = {
            employerId: dataId?.id,
            jobTitle: values.jobTitle,
            openings: values.openings,
            countryId: values.countryId,
            stateId: values.stateId,
            cityId: values.cityId,
            jobTypeId: values.jobTypeId,
            jobPreferId: values.jobPreferId,
            jobDescription: convertedContent,
            salCurrency: values.salCurrency,
            minSalary: values.minSalary,
            maxSalary: values.maxSalary,
            skillId: skill,
          };
          const res = await addNewJobs(body);
          if (res && res.status === true) {
            setShow({
              isOpen: true,
              message: res?.message,
              type: "success",
            });
            setConvertedContent(null);
            setEditorState(() => EditorState.createEmpty());
            handleClose();
            setSelectSkill([]);
            getJobPostData(dataId?.id);
            getSkillList();
          } else {
            setShow({
              isOpen: true,
              message: res?.message,
              type: "error",
            });
            getSkillList();
          }
        }
      }
    } else {
      setShow({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    }
  };

  //job post Listing Data
  const getJobPostData = async (e) => {
    let status;
    if (activeTab === "Jobtab2") {
      status = 2;
      setLength(length);
    } else if (activeTab === "Jobtab3") {
      status = [3];
      setLength(length);
    } else {
      status = [0, 1];
      setLength(length);
    }

    let queryString = `?status=${status}&search=${search}&sortBy=${selectedOption}`;
    const res = await getJobLists(dataId?.id, queryString);
    if (res && res.data) {
      setJobList(res.data);
      setLength(res.jobpostDatas);
    }
  };

  useEffect(() => {
    getJobPostData(dataId?.id);
  }, [dataId?.id, search, activeTab, selectedOption]);

  //getjob details list

  const getJobList = async (ids) => {
    const res = await employerJobPostDetails(ids);
    if (res) {
      setJobPostList(res?.data);
      setJobPostSkillList(res?.data?.jobSkill);
      setEditorState(() => EditorState.createWithContent(ContentState.createFromBlockArray(
        convertFromHTML(res?.data?.jobDescription)
      )))
      setConvertedContent(res?.data?.jobDescription);
      setValues({
        ...values,
        jobTitle: res?.data?.jobTitle,
        jobTypeId: res?.data?.jobTypeId?.id,
        jobPreferId: res?.data?.jobPreferId?.id,
        jobDescription: res?.data?.jobDescription,
        maxSalary: res?.data?.maxSalary,
        minSalary: res?.data?.minSalary,
        openings: res?.data?.openings,
        salCurrency: res?.data?.salCurrency,
        countryId: res?.data?.countryId,
        stateId: res?.data?.stateId,
        cityId: res?.data?.cityId,
        skillId: res?.data?.jobTitle,
      });
      setjobstatus(res?.data?.status)
      handleCityList(res?.data?.stateId);
      handleStateList(res?.data?.countryId);
      getContryData(res?.data?.countryId);
      let dbId = res?.data?.jobSkill.map((car) => {
        return {
          id: car.id,
          skillId: car.skillId,
          skillName: car.skillName,
          jobPostId: car.jobPostId,
        };
      });
      setSelectSkill(dbId);
    } else {
      setJobPostList([]);
      setSelectSkill([]);
    }
  };

  //delete the job

  const handleDeleteJob = async () => {
    const res = await employerJobDelete(deleteId);
    if (res.status === true) {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setIgnoreJob(false);
      setJobDetails(false);
      getJobPostData(dataId?.id);
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  //handleClick in candidate option

  const handleOptionClick = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === "Reject") {
      setInterviewReject(true);
    }

    if (selectedOption === "Hire") {
      setInterviewHire(true);
    }
    if (selectedOption === "Interview Invite") {
      setFillInterviewDetails(true);
    }
    setStatusValue("");
    resetForm();
  };

  //reject employer candidate

  const handleRejectcandidate = async (e) => {
    e.preventDefault();
    if (employerReasonValidate()) {
      const body = {
        employerReason: values.employerReason,
      };
      const res = await getRejectCandidateJob(jobId, interviewIdEmployer, body);
      if (res && res.status === true) {
        setInterviewReject(false);
        getJobPostData(dataId?.id);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  //hire employer candidate

  const handleHirecandidate = async (e) => {
    e.preventDefault();

    const res = await getCandidateHire(jobId, interviewIdEmployer);

    if (res && res.status === true) {
      setInterviewHire(false);
      getJobPostData(dataId?.id);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  //interview add
  const timeValidation = () => {
    if (values.time == "00:00") {
      setShow({
        isOpen: true,
        message: "Time is required",

        type: "error",

      });
      return false;
    }
    else {
      return true;
    }
  }

  const handleInterviewSubmit = async (e) => {
    e.preventDefault();
    if (validatess()&& timeValidation()) {
      const payload = {
        fresherId: interviewIdEmployer,
        employerId: dataId?.id,
        jobId: jobId,
        interviewTypeId: values.interviewTypeId,
        date: values.date,
        time: values.time,
        location: values.location,
        fresherReason: "",
        employerReason: "",
        interviewWillBe: acctiveTab,
      };

      const res = await addInterviewJob(payload);

      if (res && res.status === true) {
        getJobPostData(dataId?.id);
        setFillInterviewDetails(false);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  const handleJobPost = () => {
    getContryData();
    setPostNewJob(true);
    setIsEdit(false);
    resetForm();
    setSelectSkill([]);
  };

  //interview type dropdown

  const getInterviewTypeList = async () => {
    const res = await InterviewDropdown();
    if (res) {
      setInterviewType(res?.data);
    } else {
    }
  };

  useEffect(() => {
    getInterviewTypeList();
  }, []);

  const handleInputLength = (event) => {
    const maxLength = 5;
    const input = event.target;
    const value = input.value;

    if (value.length > maxLength) {
      input.value = value.slice(0, maxLength);
    }
  };

  const handleInputLengths = (event) => {
    const maxLength = 10;
    const input = event.target;
    const value = input.value;

    if (value.length > maxLength) {
      input.value = value.slice(0, maxLength);
    }
  };

  const interviewstatus = (interviewstatus) => {
    const statusColors = {
      0: {
        key: "Review",
        backgroundColor: "white",
        color: "balck",
      },
      1: {
        key: "Schedule",
        backgroundColor: "white",
        color: "balck",
      },
      2: {
        key: "On going",
        backgroundColor: "white",
        color: "balck",
      },
      3: {
        key: "Completed",
        backgroundColor: "white",
        color: "balck",
      },
      4: {
        key: "Cancel",
        backgroundColor: "white",
        color: "balck",
      },
      5: {
        key: "cancel by employer",
        backgroundColor: "white",
        color: "balck",
      },
    };

    const status = statusColors[interviewstatus];

    return (
      <div
        style={{
          height: "30px",
          fontSize: "15px",
          // backgroundColor: status ? status.backgroundColor : "",
          color: status ? status.color : "",
        }}
      >
        {status ? status.key : ""}
      </div>
    );
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = candidateData?.resume?.fileLocation;
    link.download = "filename.pdf";
    link.click();
  };

  const handleCloseSubmit = async (e) => {
    e.preventDefault();

    const res = await employeeJobClose(jobPostId);

    if (res && res.status === true) {
      getJobPostData(dataId?.id);
      setCloseJob(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const handleCloseOptionClick = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === "Close") {
      setCloseJob(true);
    }
  };

  const handleInterviewClose = () => {
    setFillInterviewDetails(false);
  };

  useEffect(() => {
    values.countryId = values.countryId || 101; // Set default value to 102 if countryId is not already set
    handleStateList(values.countryId);
  }, [values.countryId]);

  useEffect(() => {
    if (values.stateId) {
      handleCityList(values.stateId);
    }
  }, [values.stateId]);

  return (
    <main className="body-background">
      <Helmet>
              <meta charSet="utf-8" />
        <title>Job Portal | Find Skilled Tech Graduates for Your Job Openings</title>
        <meta name="description" content="Discover talented tech graduates for your job openings on Job Portal. Connect with motivated candidates skilled in various technologies to grow your team and drive success." />
      </Helmet>
      <AutohideExample show={show} setShow={setShow} />

      <div className="bg-cyan">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard-welcome-text py-5">
                <h3 className="mb-2 font-PTSerif-b">Jobs</h3>
                <p className="mb-0 font-16-24-05">
                  Post new jobs and keep track of the posted jobs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="rounded-card-head pt-3 pt-lg-5 mb-16">
          <div className="row align-items-center mb-16">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">
                Your latest posted jobs
              </div>
            </div>
            <div className="col col-md-auto text-end">
              <Controls.Button
                className="btn btn-primary h-44"
                text="Post New Job"
                onClick={() => handleJobPost()}
              />
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-12">
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabChange}
                defaultActiveKey="Jobtab1"
                className="mb-3 row-cols-1 row-cols-md-3 blue-border-tabs"
              >
                {length?.map((row) => {
                  return (
                    <Tab
                      eventKey="Jobtab1"
                      title={`Latest posted jobs (${row?.status1Count})`}
                    >
                      <div className="row align-items-center">
                        <div className="col col-md">
                          <div className="row align-items-center mb-16">
                            <div className="col col-md-auto">
                              <Controls.Input
                                className="form-control font-16-24-05 h-35 py-1"
                                placeholder="Search job title"
                                name="text"
                                type="text"
                                lable="Search job title"
                                onChange={(e) => {
                                  setTimeout(() => {
                                    setSearch(e.target.value);
                                  }, 800);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-16">
                            <div className="col col-md-auto">
                              <div class="form-label">Sort By:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100 select_sort"
                                onChange={(e) => {
                                  handleSortingChange(e);
                                  handleInputChange(e);
                                }}
                                value={values.firstName}
                              >
                                <option>select sorting</option>
                                <option value="3">Sort Date ascending</option>
                                <option value="4">Sort Date descending</option>
                                <option value="1">Sort Name ascending </option>
                                <option value="2">Sort Name descending</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {jobList?.map((row) => {
                        return (
                          <>
                            <div className="rounded-card mb-3 mb-md-4">
                              <div className="d-flex justify-content-between items-center mb-3 flexCol">
                                <div className="d-flex align-items-center ">
                                  <span className="font-PTSerif-b font-18-24-05 mb-0 pb-0">
                                    Job Details
                                  </span>
                                </div>
                                <div className="d-flex justifyEnd">
                                  <div className="col-auto">
                                    <Controls.Button
                                      className="btn btn-link text-decoration-none fw-semibold"
                                      text="Job Full Details"
                                      onClick={() => {
                                        setJobDetails(true);
                                        getJobList(row?.jobPostId);
                                        setJobPostId(row?.jobPostId);
                                      }}
                                    />
                                  </div>

                                  <div className="col-auto">
                                    <a
                                      href="javascript:void(0)"
                                      className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                                      onClick={() => {
                                        setPostNewJob(true);
                                        setIsEdit(true);
                                        getJobList(row?.jobPostId);
                                      }}
                                    >
                                      <div className="card-head-action-icon pe-2">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </div>{" "}
                                      Edit
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1 mb-1">
                                    Job Title
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobTitle}
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1 mb-1">
                                    Salary
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobPostMinExp} - {row?.jobPostMaxExp}  {row?.salCurrency}{" "}

                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1 mb-1">
                                    Location
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.cityId}, {row?.stateId},{" "}
                                    {row?.countryId}
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1 mb-1">
                                    Type
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobtypeTitle}, {row?.jobpreferTitle}
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1 mb-1">
                                    Skills Needed
                                  </div>
                                  <div className="round-ship-style-1">
                                    <ul className="d-flex flex-wrap">
                                      {row?.jobPostSkills?.map((item) => {
                                        return (
                                          <>
                                            <li>{item?.skillName}</li>
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <select
                                    className="select-with-blue-btn h-33 w-100"
                                    disabled={row?.jobPostStatus == 6 ? true : false}
                                    value={row?.jobPostStatus == 6 ? "Close" : "Open"}
                                    onChange={(e) => {
                                      handleCloseOptionClick(e);
                                      setJobPostId(row?.jobPostId);
                                    }}
                                  >
                                    <option value="Open">Open</option>
                                    <option value="Close">Close</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row justify-content-between mb-2 mt-4">
                                <div className="col-auto">
                                  <span className="font-18-24-05 mb-1 fw-semibold">
                                    Recommended candidates for this job
                                  </span>
                                </div>
                              </div>
                              {row?.jobFresherId === null ? (
                                <div className="row">
                                  <div className="col-12 my-2">
                                    <div className="gray-card-inner">
                                      <div className="row justify-content-center align-items-center">
                                        <div className="col-12 col-lg-6 text-center py-lg-5">
                                          <div className="">
                                            <svg
                                              width="52"
                                              height="52"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                                stroke="#74777E"
                                                stroke-width="1"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                                stroke="#74777E"
                                                stroke-width="1"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </div>
                                          <div className="pt-2 font-PTSerif-b text-gray font-16-24-05">
                                            Thank you for your interest in
                                            Job Portal. We would love to help
                                            you find top talent for your
                                            organization. Our team will contact
                                            you shortly
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="row">
                                  {row?.fresher?.map((rows) => {
                                    return (
                                      <>
                                        <div className="col-12 my-2">
                                          <div className="gray-card-inner">
                                            <div className="row justify-content-between align-items-center">
                                              <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                                <div className="row align-items-center">
                                                  <div className="col-auto">
                                                    <div className="interviewer-profile_1">
                                                      <img
                                                        src={rows?.profilePic}
                                                        style={{
                                                          maxWidth: "100%",
                                                          height: "100px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col ps-0">
                                                    <div className="font-18-24-05 mb-1 fw-medium">
                                                      Name
                                                    </div>
                                                    <div className="font-16-24-05">
                                                      {rows?.fresherName} {rows?.lastName}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                                <div className="font-18-24-05 mb-1 fw-medium">
                                                  Matched Skills
                                                </div>

                                                <div className="mt-2">
                                                  <ul>
                                                    {rows?.skill?.map(
                                                      (item) => {
                                                        return (
                                                          <>
                                                            {rows &&
                                                              rows.matchSkilll &&
                                                              rows.matchSkilll.filter(
                                                                (items) =>
                                                                  items ==
                                                                  item.skillId
                                                              ).length > 0 && (
                                                                <li className="d-inline-block">
                                                                  <div
                                                                    className={
                                                                      rows &&
                                                                        rows.matchSkilll &&
                                                                        rows.matchSkilll.filter(
                                                                          (
                                                                            items
                                                                          ) =>
                                                                            items ==
                                                                            item.skillId
                                                                        ).length >
                                                                        0
                                                                        ? "checkbox-round checkbox-check checkbox-round-md"
                                                                        : "checkbox-round checkbox-round-md"
                                                                    }
                                                                  >
                                                                    <label className="custom-checkbox">
                                                                      {
                                                                        item?.title
                                                                      }
                                                                      <Controls.Input
                                                                        type="checkbox"
                                                                        checked={
                                                                          rows &&
                                                                            rows.matchSkilll &&
                                                                            rows.matchSkilll.filter(
                                                                              (
                                                                                items
                                                                              ) =>
                                                                                items ==
                                                                                item.skillId
                                                                            )
                                                                              .length >
                                                                            0
                                                                            ? true
                                                                            : false
                                                                        }
                                                                      />
                                                                      <span className="checkmark"></span>
                                                                    </label>
                                                                  </div>
                                                                </li>
                                                              )}
                                                          </>
                                                        );
                                                      }
                                                    )}

                                                    {/* <li className="d-inline-block ">
                                                <div className="checkbox-round checkbox-round-md">
                                                  <label className="custom-checkbox">
                                                    {" "}
                                                    APIs
                                                    <Controls.Input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                </div>
                                              </li> */}
                                                  </ul>
                                                </div>
                                              </div>
                                              <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                                <div class="fw-medium font-18-24-05 mb-1">
                                                  Status
                                                </div>
                                                <div class="font-16-24-05 mb-2">
                                                  {interviewstatus(
                                                    rows?.status
                                                  )}
                                                </div>
                                              </div>
                                              <div className="col-12 col-lg-4">
                                                <div className="row text-nowrap">
                                                  <div className="col-md-6 mb-2 pe-md-0">
                                                    <select
                                                      className="select-with-blue-btn h-33 w-100"
                                                      value={statusValue}
                                                      // onClick={(e) => {
                                                      //   setInterviewIdEmployer(
                                                      //     rows?.fresherId
                                                      //   );
                                                      //   setJobId(row?.jobPostId);
                                                      // }}
                                                      onChange={(e) => {
                                                        // setStatusValue(
                                                        //   e.target.value
                                                        // );
                                                        handleOptionClick(e);
                                                        setInterviewIdEmployer(
                                                          rows?.fresherId
                                                        );
                                                        setJobId(
                                                          row?.jobPostId
                                                        );
                                                      }}
                                                    >
                                                      <option value="">
                                                        Select Option
                                                      </option>
                                                      {/* <option value="Interview Invite">
                                                        Interview Invite
                                                      </option>
                                                      <option value="Reject">
                                                        Reject
                                                      </option>
                                                      {rows?.status !==
                                                        2 ? null : (
                                                        <option value="Reject">
                                                          Hire
                                                        </option>
                                                      )} */}
                                                      {rows?.status != 3 && (

                                                        // {rows?.status === 0 && (
                                                        <option value="Interview Invite">
                                                          Interview Invite
                                                        </option>
                                                      )}
                                                      {rows?.status != 3 && (

                                                        <option value="Reject">
                                                          Reject
                                                        </option>
                                                      )}
                                                      {rows?.status ===
                                                        2 && (
                                                          <option value="Hire">
                                                            Hire
                                                          </option>
                                                        )}
                                                    </select>
                                                  </div>
                                                  <div className="col-md-6 mb-2">
                                                    <Controls.Button
                                                      className="btn btn-link text-decoration-none w-100 h-32 fw-medium"
                                                      text="Candidate Details"
                                                      onClick={() => {
                                                        getCandidateLists(
                                                          rows?.fresherId, row?.jobPostId
                                                        );
                                                        setCandidateInformation(
                                                          true
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </Tab>
                  );
                })}

                {length?.map((row) => {
                  return (
                    <Tab
                      eventKey="Jobtab2"
                      title={`Ongoing jobs(${row?.status2Count})`}
                    >
                      <div className="row align-items-center">
                        <div className="col col-md">
                          <div className="row align-items-center mb-16">
                            <div className="col col-md-auto">
                              <Controls.Input
                                className="form-control font-16-24-05 h-35 py-1"
                                placeholder="Search job title"
                                name="text"
                                type="text"
                                lable="Search job title"
                                onChange={(e) => {
                                  setTimeout(() => {
                                    setSearch(e.target.value);
                                  }, 800);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-16">
                            <div className="col col-md-auto">
                              <div class="form-label">Sort By:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100 select_sort"
                                onChange={handleSortingChange}
                              >
                                <option value="">None</option>
                                <option value="3">Sort Date ascending</option>
                                <option value="4">Sort Date descending</option>
                                <option value="1">Sort Name ascending </option>
                                <option value="2">Sort Name descending</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {jobList?.map((row) => {
                        return (
                          <>
                            <div className="rounded-card mb-3 mb-md-4">
                              <div className="d-flex justify-content-between items-center mb-3 flexCol">
                                <div className="d-flex align-items-center ">
                                  <span className="font-PTSerif-b font-18-24-05 mb-0 pb-0">
                                    Job Details
                                  </span>
                                </div>
                                <div className="d-flex justifyEnd">
                                  <div className="col-auto">
                                    <Controls.Button
                                      className="btn btn-link text-decoration-none fw-semibold"
                                      text="Job Full Details"
                                      onClick={() => {
                                        setJobDetails(true);
                                        getJobList(row?.jobPostId);
                                        setJobPostId(row?.jobPostId);
                                      }}
                                    />
                                  </div>

                                  <div className="col-auto">
                                    <a
                                      href="javascript:void(0)"
                                      className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                                      onClick={() => {
                                        setPostNewJob(true);
                                        setIsEdit(true);
                                        getJobList(row?.jobPostId);
                                      }}
                                    >
                                      <div className="card-head-action-icon pe-2">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </div>{" "}
                                      Edit
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Job Title
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobTitle}
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Skills Needed
                                  </div>
                                  <div className="round-ship-style-1">
                                    <ul className="d-flex flex-wrap">
                                      {row?.jobPostSkills?.map((item) => {
                                        return (
                                          <>
                                            <li>{item?.skillName}</li>
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Salary
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobPostMinExp} - {row?.jobPostMaxExp}  {row?.salCurrency}{" "}
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Location
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.countryId}, {row?.stateId},{" "}
                                    {row?.cityId}
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Type
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobtypeTitle}, {row?.jobpreferTitle}
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <select
                                    className="select-with-blue-btn h-33 w-100"
                                    disabled={row?.jobPostStatus == 6 ? true : false}
                                    value={row?.jobPostStatus == 6 ? "Close" : "Open"}
                                    onChange={(e) => {
                                      handleCloseOptionClick(e);
                                      setJobPostId(row?.jobPostId);
                                    }}
                                  >
                                    <option value="Open">Open</option>
                                    <option value="Close">Close</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row justify-content-between mb-2 mt-4">
                                <div className="col-auto">
                                  <span className="font-18-24-05 mb-1 fw-semibold">
                                    Recommended candidates for this job
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                {row?.fresher?.map((rows) => {
                                  return (
                                    <>
                                      <div className="col-12 my-2">
                                        <div className="gray-card-inner">
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                              <div className="row align-items-center">
                                                <div className="col-auto">
                                                  <div className="interviewer-profile_1">
                                                    <img
                                                      src={rows?.profilePic}
                                                      style={{
                                                        maxWidth: "100%",
                                                        height: "100px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col ps-0">
                                                  <div className="font-18-24-05 mb-1 fw-medium">
                                                    Name
                                                  </div>
                                                  <div className="font-16-24-05">
                                                    {rows?.fresherName}{" "}
                                                    {rows?.lastName}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                              <div className="font-18-24-05 mb-1 fw-medium">
                                                Matched Skills
                                              </div>

                                              <div className="mt-2">
                                                <ul>
                                                  {rows?.skill?.map((item) => {
                                                    return (
                                                      <>
                                                        {rows &&
                                                          rows.matchSkilll &&
                                                          rows.matchSkilll.filter(
                                                            (items) =>
                                                              items ==
                                                              item.skillId
                                                          ).length > 0 && (
                                                            <li className="d-inline-block">
                                                              <div
                                                                className={
                                                                  rows &&
                                                                    rows.matchSkilll &&
                                                                    rows.matchSkilll.filter(
                                                                      (items) =>
                                                                        items ==
                                                                        item.skillId
                                                                    ).length > 0
                                                                    ? "checkbox-round checkbox-check checkbox-round-md"
                                                                    : "checkbox-round checkbox-round-md"
                                                                }
                                                              >
                                                                <label className="custom-checkbox">
                                                                  {item?.title}
                                                                  <Controls.Input
                                                                    type="checkbox"
                                                                    checked={
                                                                      rows &&
                                                                        rows.matchSkilll &&
                                                                        rows.matchSkilll.filter(
                                                                          (
                                                                            items
                                                                          ) =>
                                                                            items ==
                                                                            item.skillId
                                                                        ).length >
                                                                        0
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  />
                                                                  <span className="checkmark"></span>
                                                                </label>
                                                              </div>
                                                            </li>
                                                          )}
                                                      </>
                                                    );
                                                  })}

                                                  {/* <li className="d-inline-block ">
                                                <div className="checkbox-round checkbox-round-md">
                                                  <label className="custom-checkbox">
                                                    {" "}
                                                    APIs
                                                    <Controls.Input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                </div>
                                              </li> */}
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                              <div class="fw-medium font-18-24-05 mb-1">
                                                Status
                                              </div>
                                              <div class="font-16-24-05 mb-2">
                                                {interviewstatus(rows?.status)}
                                              </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                              <div className="row text-nowrap">
                                                <div className="col-md-6 mb-2 pe-md-0">
                                                  <select
                                                    className="select-with-blue-btn h-33 w-100"
                                                    value={statusValue}
                                                    // onClick={(e) => {
                                                    //   handleOptionClick(e);
                                                    //   setInterviewIdEmployer(
                                                    //     rows?.fresherId
                                                    //   );
                                                    //   setJobId(row?.jobPostId);
                                                    // }}
                                                    onChange={(e) => {
                                                      handleOptionClick(e);
                                                      setInterviewIdEmployer(
                                                        rows?.fresherId
                                                      );
                                                      setJobId(row?.jobPostId);
                                                    }}
                                                  >
                                                    <option value="">
                                                      Select Option
                                                    </option>
                                                    {rows?.status != 3 && (

                                                      // {rows?.status === 0 && (
                                                      <option value="Interview Invite">
                                                        Interview Invite
                                                      </option>
                                                    )}
                                                    {rows?.status != 3 && (

                                                      <option value="Reject">
                                                        Reject
                                                      </option>
                                                    )}
                                                    {rows?.status ===
                                                      2 && (
                                                        <option value="Hire">
                                                          Hire
                                                        </option>
                                                      )}
                                                  </select>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                  <Controls.Button
                                                    className="btn btn-link text-decoration-none w-100 h-32 fw-medium"
                                                    text="Candidate Details"
                                                    onClick={() => {
                                                      getCandidateLists(
                                                        rows?.fresherId, row?.jobPostId
                                                      );
                                                      setCandidateInformation(
                                                        true
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Tab>
                  );
                })}

                {length?.map((row) => {
                  return (
                    <Tab
                      eventKey="Jobtab3"
                      title={`Completed(${row?.status3Count})`}
                    >
                      <div className="row align-items-center">
                        <div className="col col-md">
                          <div className="row align-items-center mb-16">
                            <div className="col col-md-auto">
                              <Controls.Input
                                className="form-control font-16-24-05 h-35 py-1"
                                placeholder="Search job title"
                                name="text"
                                type="text"
                                lable="Search job title"
                                onChange={(e) => {
                                  setTimeout(() => {
                                    setSearch(e.target.value);
                                  }, 800);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-16">
                            <div className="col col-md-auto">
                              <div class="form-label">Sort By:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100 select_sort"
                                onChange={handleSortingChange}
                              >
                                <option value="">None</option>

                                <option value="3">Sort Date ascending</option>
                                <option value="4">Sort Date descending</option>
                                <option value="1">Sort Name ascending </option>
                                <option value="2">Sort Name descending</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {jobList?.map((row) => {
                        return (
                          <>
                            <div className="rounded-card mb-3 mb-md-4">
                              <div className="d-flex justify-content-between items-center mb-3 flexCol">
                                <div className="d-flex align-items-center ">
                                  <span className="font-PTSerif-b font-18-24-05 mb-0 pb-0">
                                    Job Details
                                  </span>
                                </div>
                                <div className="d-flex justifyEnd">
                                  <div className="col-auto">
                                    <Controls.Button
                                      className="btn btn-link text-decoration-none fw-semibold"
                                      text="Job Full Details"
                                      onClick={() => {
                                        setJobDetails(true);
                                        getJobList(row?.jobPostId);
                                        setJobPostId(row?.jobPostId);
                                      }}
                                    />
                                  </div>

                                  <div className="col-auto">
                                    <a
                                      href="javascript:void(0)"
                                      className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                                      onClick={() => {
                                        setPostNewJob(true);
                                        setIsEdit(true);
                                        getJobList(row?.jobPostId);
                                      }}
                                    >
                                      <div className="card-head-action-icon pe-2">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </div>{" "}
                                      Edit
                                    </a>
                                  </div>
                                </div>


                              </div>
                              <div className="row">
                                <div className="col-12 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Job Title
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobTitle}
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Skills Needed
                                  </div>
                                  <div className="round-ship-style-1">
                                    <ul className="d-flex flex-wrap">
                                      {row?.jobPostSkills?.map((item) => {
                                        return (
                                          <>
                                            <li>{item?.skillName}</li>
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Salary
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobPostMinExp} - {row?.jobPostMaxExp}  {row?.salCurrency}{" "}
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Location
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.countryId}, {row?.stateId},{" "}
                                    {row?.cityId}
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                                  <div className="fw-medium font-18-24-05 mb-1">
                                    Type
                                  </div>
                                  <div className="font-16-24-05 mb-2">
                                    {row?.jobtypeTitle}, {row?.jobpreferTitle}
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <select
                                    className="select-with-blue-btn h-33 w-100"
                                    disabled={row?.jobPostStatus == 6 ? true : false}
                                    value={row?.jobPostStatus == 6 ? "Close" : "Open"}
                                    onChange={(e) => {
                                      handleCloseOptionClick(e);
                                      setJobPostId(row?.jobPostId);
                                    }}
                                  >
                                    <option value="Open">Open</option>
                                    <option value="Close">Close</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row justify-content-between mb-2 mt-4">
                                <div className="col-auto">
                                  <span className="font-18-24-05 mb-1 fw-semibold">
                                    Recommended candidates for this job
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                {row?.fresher?.map((rows) => {
                                  return (
                                    <>
                                      <div className="col-12 my-2">
                                        <div className="gray-card-inner">
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                              <div className="row align-items-center">
                                                <div className="col-auto">
                                                  <div className="interviewer-profile_1">
                                                    <img
                                                      src={rows?.profilePic}
                                                      style={{
                                                        maxWidth: "100%",
                                                        height: "100px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col ps-0">
                                                  <div className="font-18-24-05 mb-1 fw-medium">
                                                    Name
                                                  </div>
                                                  <div className="font-16-24-05">
                                                    {rows?.fresherName} {rows?.lastName}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                              <div className="font-18-24-05 mb-1 fw-medium">
                                                Matched Skills
                                              </div>

                                              <div className="mt-2">
                                                <ul>
                                                  {rows?.skill?.map((item) => {
                                                    return (
                                                      <>
                                                        {rows &&
                                                          rows.matchSkilll &&
                                                          rows.matchSkilll.filter(
                                                            (items) =>
                                                              items ==
                                                              item.skillId
                                                          ).length > 0 && (
                                                            <li className="d-inline-block">
                                                              <div
                                                                className={
                                                                  rows &&
                                                                    rows.matchSkilll &&
                                                                    rows.matchSkilll.filter(
                                                                      (items) =>
                                                                        items ==
                                                                        item.skillId
                                                                    ).length > 0
                                                                    ? "checkbox-round checkbox-check checkbox-round-md"
                                                                    : "checkbox-round checkbox-round-md"
                                                                }
                                                              >
                                                                <label className="custom-checkbox">
                                                                  {item?.title}
                                                                  <Controls.Input
                                                                    type="checkbox"
                                                                    checked={
                                                                      rows &&
                                                                        rows.matchSkilll &&
                                                                        rows.matchSkilll.filter(
                                                                          (
                                                                            items
                                                                          ) =>
                                                                            items ==
                                                                            item.skillId
                                                                        ).length >
                                                                        0
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  />
                                                                  <span className="checkmark"></span>
                                                                </label>
                                                              </div>
                                                            </li>
                                                          )}
                                                      </>
                                                    );
                                                  })}

                                                  {/* <li className="d-inline-block ">
                                                <div className="checkbox-round checkbox-round-md">
                                                  <label className="custom-checkbox">
                                                    {" "}
                                                    APIs
                                                    <Controls.Input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                </div>
                                              </li> */}
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                              <div class="fw-medium font-18-24-05 mb-1">
                                                Status
                                              </div>
                                              <div class="font-16-24-05 mb-2">
                                                {interviewstatus(rows?.status)}
                                              </div>
                                            </div>
                                            {rows?.status === 3 ? null : (
                                              <div className="col-12 col-lg-4">
                                                <div className="row text-nowrap">
                                                  <div className="col-md-6 mb-2 pe-md-0">
                                                    <select
                                                      className="select-with-blue-btn h-33 w-100"
                                                      value={statusValue}
                                                      // onClick={(e) => {
                                                      //   handleOptionClick(e);
                                                      //   setInterviewIdEmployer(
                                                      //     rows?.fresherId
                                                      //   );
                                                      //   setJobId(
                                                      //     row?.jobPostId
                                                      //   );
                                                      // }}
                                                      onChange={(e) => {
                                                        handleOptionClick(e);
                                                        setInterviewIdEmployer(
                                                          rows?.fresherId
                                                        );
                                                        setJobId(
                                                          row?.jobPostId
                                                        );
                                                      }}
                                                    >
                                                      <option value="">
                                                        Select Option
                                                      </option>
                                                      {/* {rows?.status != 3 && (<option value="Interview Invite">
                                                        Interview Invite
                                                      </option>)}

                                                      <option value="Reject">
                                                        Reject
                                                      </option>
                                                      {rows?.status !== 2 && (<option value="Hire">
                                                        Hire
                                                      </option>)} */}
                                                      {rows?.status != 3 && (

                                                        // {rows?.status === 0 && (
                                                        <option value="Interview Invite">
                                                          Interview Invite
                                                        </option>
                                                      )}
                                                      {rows?.status != 3 && (

                                                        <option value="Reject">
                                                          Reject
                                                        </option>
                                                      )}
                                                      {rows?.status ===
                                                        2 && (
                                                          <option value="Hire">
                                                            Hire
                                                          </option>
                                                        )}

                                                    </select>
                                                  </div>
                                                  <div className="col-md-6 mb-2">
                                                    <Controls.Button
                                                      className="btn btn-link text-decoration-none w-100 h-32 fw-medium"
                                                      text="Candidate Details"
                                                      onClick={() => {
                                                        getCandidateLists(
                                                          rows?.fresherId, row?.jobPostId
                                                        );
                                                        setCandidateInformation(
                                                          true
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Tab>
                  );
                })}
              </Tabs>
            </div>
          </div>
        </div>

        
      </div>

      <Modal
        size="lg"
        show={PostNewJob}
        onHide={() => {
          setPostNewJob(false);
          getSkillList();
          setConvertedContent(null);
          setEditorState(() => EditorState.createEmpty());
        }
        }
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">
                  {" "}
                  {isEdit ? "Update job details" : "Post New Job"}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Job titles</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter job title"
                  name="jobTitle"
                  type="text"
                  lable="Enter job title"
                  onChange={handleInputChange}
                  value={values.jobTitle}
                  maxLength={200}
                />
                {Boolean(errors.jobTitle) ? (
                  <p className="text-invalid">{errors.jobTitle}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Job description</div>
                {/* <textarea
                  className="form-control h-auto"
                  placeholder="Enter the description of the job"
                  rows="4"
                  name="jobDescription"
                  onChange={handleInputChange}
                  value={values.jobDescription}
                  maxLength={1000}
                ></textarea> */}

                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                // onblur={() => handleSubmit()}
                />

                {Boolean(errors.jobDescription) ? (
                  <p className="text-invalid">{errors.jobDescription}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Number of openings</div>

                <Controls.Input
                  className="form-control"
                  placeholder="Number of openings"
                  name="openings"
                  type="text"
                  lable="Number of openings"
                  onChange={handleInputChange}
                  onInput={handleInputLength}
                  value={values.openings}
                />

                {Boolean(errors.openings) ? (
                  <p className="text-invalid">{errors.openings}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-label">Job location</div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-4 pe-md-0 mb-2">
                    <select
                      className="form-select form-control"
                      name="countryId"
                      onChange={handleCountryLocation}
                      value={values.countryId || 101}
                      id="countryId"
                    >
                      <option value="">None</option>
                      {countryList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {Boolean(errors.countryId) ? (
                      <p className="text-invalid">{errors.countryId}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-2">
                    <select
                      className="form-select form-control"
                      name="stateId"
                      onChange={(e) => {
                        handleStateLocation(e);
                        handleInputChange(e);
                      }}
                      value={values.stateId}
                      id="stateId"
                    >
                      <option value=""> None</option>
                      {stateList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {Boolean(errors.stateId) ? (
                      <p className="text-invalid">{errors.stateId}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-12 col-md-4 ps-md-0 mb-2">
                    <select
                      className="form-select form-control"
                      name="cityId"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      value={values.cityId}
                      id="cityId"
                    >
                      {/* <option value=""> city</option> */}
                      <option value=""> None</option>
                      {cityList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {Boolean(errors.cityId) ? (
                      <p className="text-invalid">{errors.cityId}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-label">Job type</div>
              </div>
              <div className="col-12 col-md-6 mb-16">
                <select
                  className="form-select form-control"
                  name="jobTypeId"
                  onChange={handleInputChange}
                  value={values.jobTypeId}
                  id="jobTypeId"
                >
                  {/* <option value="">none</option> */}
                  <option value=""> None</option>
                  {jobType?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.jobTypeId) ? (
                  <p className="text-invalid">{errors.jobTypeId}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <select
                  className="form-select form-control"
                  name="jobPreferId"
                  onChange={handleInputChange}
                  value={values.jobPreferId}
                  id="jobPreferId"
                >
                  {/* <option value="">none</option> */}
                  <option value=""> None</option>
                  {jobPrefer.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.jobPreferId) ? (
                  <p className="text-invalid">{errors.jobPreferId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="col-12 col-md-12 mb-16">
                  <div className="form-label">Skills</div>
                  <Multiselect
                    options={skillData} // Options to display in the dropdown
                    selectedValues={selectSkill} // Preselected value to persist in dropdown
                    onSelect={multiselectSelectSkill} // Function will trigger on select event
                    onRemove={removeSkill}  // Function will trigger on remove event
                    // displayValue={(option) => (option ? option.skillName : "")} // Property name to display in the dropdown options
                    displayValue="skillName"
                    disable={Jobstatus == 0 ? false : true}
                  />
                  {errorMessage && (
                    <div className="text-invalid">{errorMessage}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-label">Salary range</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3 mb-16">
                <select
                  className="form-select form-control"
                  name="salCurrency"
                  onChange={handleInputChange}
                  value={values?.salCurrency}
                >
                  {/* <option value="">Select</option> */}
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                </select>
                {Boolean(errors.salCurrency) ? (
                  <p className="text-invalid">{errors.salCurrency}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md mb-16">
                <Controls.Input
                  className="form-control"
                  placeholder="Minimum"
                  name="minSalary"
                  type="number"
                  lable="SkiMinimumlls"
                  onChange={handleInputChange}
                  value={values?.minSalary}
                  onInput={handleInputLengths}
                />
                {Boolean(errors.minSalary) ? (
                  <p className="text-invalid">{errors.minSalary}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md mb-16">
                <Controls.Input
                  className="form-control"
                  placeholder="Maximum"
                  name="maxSalary"
                  type="number"
                  lable="Maximum"
                  onChange={handleInputChange}
                  value={values?.maxSalary}
                  onInput={handleInputLengths}
                />
                {Boolean(errors.maxSalary) ? (
                  <p className="text-invalid">{errors.maxSalary}</p>
                ) : (
                  ""
                )}
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            {!isEdit ? (
              <Button
                variant="outline-primary"
                className="h-44 fw-medium mx-md-4"
                onClick={() => {
                  setPostNewJob(false);
                  setDeleteSkill([]);
                  resetForm();
                  getSkillList();
                  setConvertedContent(null);
                  setEditorState(() => EditorState.createEmpty());
                }}
              >
                Cancel
              </Button>
            ) : null}
            {!isEdit ? (
              <Button
                type="submit"
                variant="primary"
                className="h-44 fw-medium m-0"
              >
                Post
              </Button>
            ) : (
              <Button
                type="submit"
                variant="primary"
                className="h-44 fw-medium m-0"
              >
                Update
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="xl"
        show={JobDetails}
        onHide={() => {
          setJobDetails(false)
        }}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton ></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 mb-1">Job Details</div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Job Title</div>
              <div className="font-16-24-05 mb-2">
                {jobPostList?.jobTitle}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Number of openings</div>
              <div className="font-16-24-05 mb-2">
                {jobPostList?.openings}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Salary</div>
              <div className="font-16-24-05 mb-2">
                {" "}
                {jobPostList?.minSalary} - {jobPostList?.maxSalary}{" "}
                {jobPostList?.salCurrency}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Location</div>
              <div className="font-16-24-05 mb-2">
                {jobPostList?.cityName}, {jobPostList?.stateName},{" "}
                {jobPostList?.countryName}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Type</div>
              <div className="font-16-24-05 mb-2">
                {" "}
                {jobPostList?.jobTypeId?.title},{" "}
                {jobPostList?.jobPreferId?.title}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="font-18-24-05 mb-1 fw-semibold mb-1">
                Required Skills
              </div>
            </div>
            <div className="col-12 col-lg-12 mb-16">
              <div className="round-ship-style-1">
                <ul className="d-flex flex-wrap">
                  {jonPostSkillList?.map((row) => {
                    return (
                      <>
                        <li>{row?.skillName}</li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="font-18-24-05 mb-1 fw-semibold mb-3">
                Job Description
              </div>
            </div>
            <div className="col-12 col-lg-12">
              <div className="font-16-24-05">
                <div dangerouslySetInnerHTML={{ __html: jobPostList?.jobDescription }}></div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
          <Button variant="outline-danger" className="h-44 fw-medium mx-4" onClick={() => {
            setIgnoreJob(true);
            setDeleteId(jobPostList?.id);
          }} >
            Delete
          </Button>
          <Button
            variant="primary"
            className="h-44 fw-medium m-0"
            onClick={() => {
              setPostNewJob(true);
              setIsEdit(true);
              // getJobList(jobPostList?.jobPostId);
            }}
          >
            Edit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={FillInterviewDetails}
        onHide={() => {
          handleInterviewClose();
          resetForm();
        }}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleInterviewSubmit}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-4">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-18-24-05 mb-1 fw-semibold">
                  Fill Interview Details
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-6 mb-3">
                <div className="form-label">
                  The interview will be
                </div>
                <div className="blue-tabs-wrap">
                  <ul className="d-flex font-18-24-05 pb-0 mb-1 text-center">
                    <li
                      onClick={(e) => {
                        setTab(2);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 2 ? "active" : ""}`}
                    >
                      In-Person
                    </li>
                    <li
                      onClick={(e) => {
                        setTab(1);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 1 ? "active" : ""}`}
                    >
                      Online
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl mb-3">
                <div className="form-label">Interview Type</div>
                <select
                  className="form-select form-control"
                  name="interviewTypeId"
                  onChange={handleInputChange}
                  value={values.interviewTypeId}
                >
                  <option value="">None</option>
                  {interviewType?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.interviewTypeId) ? (
                  <p className="text-invalid">{errors.interviewTypeId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Time</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter interview time"
                  name="time"
                  type="time"
                  lable="Enter interview time"
                  onChange={handleInputChange}
                  value={values.time}

                />
                {Boolean(errors.time) ? (
                  <p className="text-invalid">{errors.time}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Date</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter interview date"
                  name="date"
                  type="date"
                  lable="Enter interview date"
                  onChange={handleInputChange}
                  value={values.date}
                  //past date disble today and feature date allow 
                  min={new Date().toISOString().split("T")[0]}
                />
                {Boolean(errors.date) ? (
                  <p className="text-invalid">{errors.date}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                {acctiveTab === 1 ? (
                  <div className="form-label">Interview Link</div>
                ) : (
                  <div className="form-label">Interview Location</div>
                )}
                <Controls.Input
                  className="form-control"
                  placeholder={
                    acctiveTab === 1
                      ? "Enter interview link"
                      : "Enter interview location"
                  }
                  name="location"
                  type="text"
                  lable="Enter interview location"
                  onChange={handleInputChange}
                  value={values.location}
                />
                {Boolean(errors.location) ? (
                  <p className="text-invalid">{errors.location}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Send
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="md"
        show={InterviewReject}
        onHide={() => {
          setInterviewReject(false);
          resetForm();
        }}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-18-24-05 mb-1 fw-semibold mb-2">
                Do you want to reject this candidate?
              </div>
              <div className="font-16-24-05">
                If yes, Please provide the feedback
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <textarea
                className="form-control h-auto"
                placeholder="Eg. I didn't like this candidate because..."
                rows="4"
                name="employerReason"
                type="text"
                value={values?.employerReason}
                onChange={handleInputChange}
              ></textarea>
              {Boolean(errors.employerReason) ? (
                <p className="text-invalid">{errors.employerReason}</p>
              ) : (
                ""
              )}
              {/* <div className="opacity-75">Minimum 100 characters</div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
          <Button
            variant="outline-primary"
            className="h-44 fw-medium mx-4"
            onClick={handleRejectcandidate}
          >
            Yes, Reject
          </Button>
          <Button
            variant="primary"
            className="h-44 fw-medium m-0"
            onClick={() => setInterviewReject(false)}
          >
            No, Keep candidate
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={CandidateInformation}
        onHide={() => setCandidateInformation(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 mb-1 pb-0">
                Candidate Informations
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5">
              <div className="interviewer-profile_1 mx-auto">
                <img src={candidateData?.fresherId?.profilePhoto} height='100px' />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Name</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.firstName} {candidateData?.fresherId?.lastName}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Email</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.email}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Phone Number</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.countryCode}
                {candidateData?.fresherId?.mobile}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Resume</div>
              <div className="font-16-24-05 mb-2 d-inline-block">
                <a
                  href="#"
                  className="d-flex btn btn-link"
                  onClick={handleDownload}
                >
                  <div className="card-head-action-icon pe-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_871_17631)">
                        <path
                          d="M8 17L12 21L16 17"
                          stroke="#74777E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 12V21"
                          stroke="#74777E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.8799 18.0901C21.7493 17.4787 22.4013 16.6062 22.7412 15.5992C23.0811 14.5922 23.0913 13.5031 22.7703 12.4899C22.4493 11.4767 21.8138 10.5921 20.956 9.96462C20.0982 9.33709 19.0628 8.99922 17.9999 9.00006H16.7399C16.4392 7.82793 15.8764 6.7393 15.0941 5.81614C14.3117 4.89297 13.3301 4.15932 12.2231 3.67041C11.1162 3.1815 9.91278 2.95008 8.70346 2.99357C7.49414 3.03706 6.31045 3.35433 5.24149 3.92148C4.17253 4.48864 3.24616 5.2909 2.53212 6.26788C1.81808 7.24487 1.33498 8.3711 1.11919 9.5618C0.903403 10.7525 0.960543 11.9767 1.28631 13.1421C1.61208 14.3075 2.19799 15.3838 2.99993 16.2901"
                          stroke="#74777E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_871_17631">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>{" "}
                  <Tooltip
                    title={candidateData?.resume?.fileLocation}
                  ></Tooltip>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">Education</div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">College</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.college}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Level of education</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.educationLevel}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Field of study</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.studyField}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Start Date</div>
              <div className="font-16-24-05 mb-2">
                {moment(candidateData?.fresherId?.eduStartDate).format(
                  "DD-MM-YYYY"
                )}
              </div>
            </div>

            <div className="col-6 col-md-4 col-xl mb-3">
              <div class="fw-medium font-18-24-05 mb-1">End Date</div>

              {candidateData?.fresherId?.studyRunning == 0 ? (
                <>

                  <div class="font-16-24-05 mb-2">
                    {moment(candidateData?.fresherId?.eduEndDate).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </>
              ) : (
                <div className="font-16-24-05 mb-2">
                  <div class=" font-16-24-05 mb-1">Still enrolled</div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">
                Candidate's Skills
              </div>
            </div>
            <div className="mt-0 mb-3">
              <ul>
                {candidateData?.fresherSkill?.map((items) => {
                  return (
                    <>
                      <li className="d-inline-block">
                        <div
                          className={
                            candidateData &&
                              candidateData.matchSkill &&
                              candidateData?.matchSkill.filter(
                                (item) => item == items.skillId
                              ).length > 0
                              ? "checkbox-round checkbox-check checkbox-round-md"
                              : "checkbox-round checkbox-round-md"
                          }
                        >
                          <label className="custom-checkbox">
                            {items?.fskilltitle}
                            <Controls.Input
                              type="checkbox"
                              checked={
                                candidateData &&
                                  candidateData.matchSkill &&
                                  candidateData?.matchSkill.filter(
                                    (item) => item == items.skillId
                                  ).length > 0
                                  ? true
                                  : false
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">Candidate Score</div>
            </div>
            <div className="col-12 px-lg-5">
              <div className="row">
                {candidateData && candidateData?.userexamdata && candidateData?.userexamdata?.map((items) => {
                  return (
                    items.isPass == 1 && (
                      <div className="col-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-3">
                        <div className="level-card rounded-3 text-center p-3">
                          <div className="font-18-24-05 mb-1 fw-medium mb-1">
                            {items?.examType == 1 ? 'Soft Skill Assessment' : items?.examType == 2 ? 'Technical Assessment' : 'Coding Assessment'}
                          </div>
                          <div className="font-18-24-05 mb-1">{items?.skilltitle != "" ? items?.skilltitle : items?.examType == 3 ? 'Coding Challenge' : ''}</div>
                          <div className="d-flex justify-content-center align-items-center py-3">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="#FEC348"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                                stroke="#FEC348"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="ps-1 pt-1 font-16-24-05">{
                              items?.percentage > 90 ? '5/5' : items?.percentage > 74 ? '4.5/5' : items?.percentage > 49 ? '4/5' : items?.percentage > 34 ? '3.5/5' : items?.percentage > 24 ? '3/5' : '0/5'
                            } </span>
                          </div>
                          <div className="font-18-24-05 mb-1 opacity-75 mb-1">
                            {items?.totalTime} minutes
                          </div>
                        </div>
                      </div>
                    )

                  )
                })}


              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={IgnoreJob}
        onHide={() => setIgnoreJob(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 mb-1 fw-medium">
            Do you want to Delete this job?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => {
              handleDeleteJob(deleteId);
            }}
          >
            Yes
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => setIgnoreJob(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={InterviewHire}
        onHide={() => setInterviewHire(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-18-24-05 mb-1 fw-semibold mb-2">
                Do you want to Hire this candidate?
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
          <Button
            variant="outline-primary"
            className="h-44 fw-medium mx-4"
            onClick={handleHirecandidate}
          >
            Yes
          </Button>
          <Button
            variant="primary"
            className="h-44 fw-medium m-0"
            onClick={() => setInterviewHire(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={CloseJob}
        onHide={() => setCloseJob(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 mb-1 fw-medium">
            Do you want to Close this job?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={handleCloseSubmit}
          >
            Yes
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => setCloseJob(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default EmployerJobs;
