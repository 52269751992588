
import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";
import { AiOutlineCheck } from "../../assets/Icons/icons";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import '../../components/pagination/style.scss';
import '../../components/pagination/pagination.scss';
import PaginationOne from '../../components/pagination/PaginationOne';
import { getIssue, markAsResolved } from "../../state/action/codingAssessmentAction";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../../utills/Loader";
import AutohideExample from "../../utills/Notification";
import Button from "react-bootstrap/Button";

const initialValues = {
  name: "",
  isActive: "",
};
const CodingAssessment = () => {
  const [reportData, setReportData] = useState([]);
  const [reportId, setReportId] = useState('');
  const [search, setSearch] = useState("");
  const [reportResolved, setReportResolved] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState(true);

  const statusManage = (status) => {

    // status = 1
    const statusColors = {
      1: {
        type: "success",
        text: "Resolved",
      },

      0: {
        type: "warning",
        text: "Pending",
      },
    };
    return (
      <Badge bg={statusColors[status].type} text={status == 4 ? "dark" : ""}>
        {statusColors[status].text}
      </Badge>
    );
  };

  const statuslist = [
    { id: "1", title: "Resolved" },
    { id: "0", title: "Pending" },
  ];

  const [showDrawer, setShowDrawer] = useState(false);

  let PageSize = 10;

  const getIssueDetails = async () => {
    let queryString = `?page=${currentPage}&limit=${PageSize}&status=${values.isActive}`;
    const res = await getIssue(queryString);
    if (res && res.status === true) {
      setReportData(res?.data);
      setTotalRecords(res?.totalCount);
    } else {
      setReportData([]);
    }
  }

  useEffect(() => {
    getIssueDetails();
  }, [reportResolved, filterData, currentPage]);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    closeDrawer();
  };

  const handleReset = () => {
    resetForm();
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const reportIssueResolved = async () => {
    setOpenBreakdrop(true);

    const res = await markAsResolved(reportId);
    if (res && res.status === true) {
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setReportResolved(false);
    } else {
      setOpenBreakdrop(false);
    }
  }

  return (
    <main className="body-background">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="rounded-card-head">
              <div className="row align-items-center mb-3">
                <div className="col-md-3">
                  <div className="font-PTSerif-b ps-2 font-18-24-05">
                    Coding Report A Problem
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="col col-md-auto text-end d-flex justify-content-end">
                    <Controls.Button
                      style={{ padding: "7px 30px", marginLeft: "16px" }}
                      className="btn btn-success h-40 mt-2"
                      text="filter"
                      onClick={() => {
                        toggleDrawer();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {showDrawer && (
              <div className="filter-drawer-overlay">
                <div className="filter-drawer-content">
                  <div className="d-flex justify-content-between align-items-center filter-header_div ">
                    <label className="filter-header">Filter</label>
                    <button
                      className="filter-close-button"
                      onClick={toggleDrawer}
                    >
                      x
                    </button>
                  </div>
                  <div className="d-flex flex-column h-100">
                    <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">
                      <div>
                        <div>
                          <label className="filter-label">status</label>
                          <select
                            className="form-select h-auto px-3 py-2 "
                            name="isActive"
                            onChange={handleInputChange}
                            value={values.isActive}
                          >
                   <option value="">None</option> 
                      {statuslist?.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="position-relative bottom-0 mb-5 filter-reset-div">
                        <div className="d-flex justify-content-between">
                          <button
                            className="filter-reset-btn"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                          <button className="filter-btn" type="submit">
                            Filter
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            <div className="theme-table-wrap" style={{ marginTop: "10px" }}>
              <Table responsive className="striped-table mb-0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Reported By</th>
                    <th>Issue</th>
                    <th>Raised On</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {reportData && reportData.map((row, index) => {
                  return (
                    <tbody>
                      <tr
                        key={row?.id}
                        className="border rounded"
                        style={{ backgroundColor: "white" }}
                      >
                        <td style={{ width: "14.5%" }}>{index + 1}</td>

                        <td style={{ width: "14.5%" }}>{row?.reportedBy || "-"}</td>
                        <td style={{
                          width: "14.5%",
                          whiteSpace: "wrap",
                        }}>{row?.report || "-"}</td>
                        <td style={{ width: "14.5%" }}>{row?.createdAt && row?.createdAt != null ?
                          moment(row?.createdAt).format("DD-MM-YYYY hh:mm A") : "-"}</td>

                        <td style={{ width: "14.5%" }}>{statusManage(row?.status)}</td>

                        <td style={{ width: "11%" }}>
                          <ul className="d-flex action-col-main">
                            <li className="me-3">
                              <button
                                className="btn btn-sm btn-icon btn-success btn-inner"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Resolve issue"
                                onClick={() => {
                                  setReportResolved(true);
                                  setReportId(row?.id);
                                }}
                                style={{ padding: "2px 4px 2px 4px" }}
                                disabled={row?.status == 1}
                              >
                                <span className="btn-inner">
                                  <AiOutlineCheck
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </span>
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
                }
              </Table>
              {reportData && reportData.length == 0 && (

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                  }}
                >
                  <h3>No Data Found</h3>
                </div>
              )}
            </div >
            <PaginationOne
              currentPage={currentPage}
              totalCount={totalRecords}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)} />

            <div className="pt-2">
              Showing {reportData?.length} {""}
              of {totalRecords} Results
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="md"
        show={reportResolved}
        onHide={() => setReportResolved(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 fw-medium">
            Are you sure want to resolve this issue?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
            onClick={() => setReportResolved(false)}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
            type="submit"
            onClick={() => reportIssueResolved()}
          >
            Resolve
          </Button>
        </Modal.Footer>
      </Modal>
      <AutohideExample show={show} setShow={setShow} />
    </main>
  );
};

export default CodingAssessment;
