import * as React from "react";


export default function SelectBox(props) {
  const {
    name,
    value,
    onChange,
    maxRows = 0,
    options,
    disabled,
    ClassName,
    error = null,
    label,
    style,
    ...other
  } = props;

  return (
    <div style={{ minWidth: 160, marginTop: "7px" }}>

      <select
        name={name}
        id="demo-select-small"
        value={value}
        label={label}
        disabled={disabled}
        onChange={onChange}
        {...other}
        {...(error && { error: true, helperText: error })}
      >
        {/* <option value="" sx={{ color: "#000!important" }}>
          None
        </option> */}
        {options.map((item) => {
          return (
            <option
              key={item.id}
              value={item.id}
              sx={{ color: "#000!important" }}
            >
              {item.name || item.title}
            </option>
          );
        })}
      </select>
    </div>
  );
}
