import React, { useState, useEffect } from "react";
import TechfreshrLogo from "../../../assets/images/techfresher-logo.svg";
import SliderImage1 from "../../../assets/images/hired.svg";
import SliderImage2 from "../../../assets/images/office_management.svg";
import SliderImage3 from "../../../assets/images/devices.svg";
import Slider from "react-slick";
import PersonalInformation from "../employerpersonalinformation/employerpersonalinformation";
import EmployerJobDetails from "../employerjobdetails/employerjobdetails";
import { useNavigate } from "react-router-dom";

import ArrowLeft from "../../../assets/images/arrow_left.svg";
import { useSelector } from "react-redux";

const EmployerRegister = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [activeTab, setActiveTab] = useState(1);
  const [storeData, setStoreData] = useState([]);

  const navigate = useNavigate();

  const data1 = useSelector((state) => state?.auth?.user);

  const handleSubmitBack = async (e) => {
    e.preventDefault();
    setStoreData(data1);
    setActiveTab(1);
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <main>
      <div className="container">
        <header className="row header-logo-wrap justify-content-between header-row-wrap">
          <div className="col-auto">
            <a href="" className="d-inline-block">
              {/* <img
                src={TechfreshrLogo}
                className="img-fluid header-logo-width"
              /> */}
              <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

            </a>
          </div>
          <div className="col-auto align-self-center">
            {activeTab == 2 ? (
              <div className="arrowleft">
                <a
                  href="javascript:void(0)"
                  className=""
                  onClick={(e) => handleSubmitBack(e)}
                >
                  <img src={ArrowLeft} className="img-fluid mx-auto" />
                </a>
              </div>
            ) : null}
          </div>
        </header>
      </div>

      <div className="container">
        <div className="row login-height-calc">
          <div className={`${activeTab == 2 ? 'height_1000' : ''} d-none d-lg-block col-lg-6`} style={{
            height: '630px', width: '43%',
            margin: '0 auto'
          }}>
            <div className="cyan-gradiant">
              <div className="login-left-slider">
                <Slider {...settings}>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage1}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Qualified Engineers
                        </div>
                        <div className="slider-content-text">
                          Find qualified engineers quickly and efficiently{" "}
                          <br /> with Job Portal
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage2}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Management System
                        </div>
                        <div className="slider-content-text">
                          We offers tracking and managing hiring <br /> process
                          from start to finish.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage3}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Reliable platform
                        </div>
                        <div className="slider-content-text">
                          Securely connect with top talents and simplify your{" "}
                          <br /> recruitment process with Job Portal reliable
                          platform.
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="login-box-width width_40">
              <div className="signup-top-step-wrap">
                <div className="row">
                  <div className="col">
                    {activeTab == 1 ? (
                      <h1 className="login-top-head mb-2 font-PTSerif-b">
                        Personal & Company Information
                      </h1>
                    ) : null}

                    {activeTab == 2 ? (
                      <h1 className="login-top-head mb-2 font-PTSerif-b">
                        Job Details
                      </h1>
                    ) : null}
                  </div>

                  <div className="col-auto align-items-center">
                    <div className="step-count-number login-top-head font-PTSerif-b">
                      1
                    </div>
                  </div>
                </div>
                <ul className="d-flex mb-32 step-count-dash">
                  <li
                    className="col"
                    style={{
                      backgroundColor: activeTab == 1 ? "#476FB2" : "#E0E0E0",
                    }}
                  ></li>
                  <li
                    className="col"
                    style={{
                      backgroundColor: activeTab == 2 ? "#476FB2" : "#E0E0E0",
                    }}
                  ></li>
                </ul>
              </div>

              {activeTab === 1 && (
                <PersonalInformation setActiveTab={setActiveTab} />
              )}
              {activeTab === 2 && (
                <EmployerJobDetails setActiveTab={setActiveTab} />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmployerRegister;
