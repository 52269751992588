import React, { useState } from 'react'
import Controls from "../../components/controls/Controls";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { deleteEmployerApi, changeEmployerEmail, employerEmailVerified, changeEmployerPassword } from "../../state/action/employerSettingAction";
import { removeData } from "../../state/action/settingAction"
import { useSelector, useDispatch } from 'react-redux';
import AutohideExample from "../../utills/Notification";
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "../../utills/useForms";
import { useEffect } from "react";
import { loadUser } from "../../state/action/authAction";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { resendOtp } from "../../state/action/employerForgotPasswordAction";
import { useForm1 } from '../../utills/useForms1';
import { useForm2 } from '../../utills/useForms2';

const initialValues = { newEmail: "" };
const initialValues1 = { otp: "" };
const initialValues2 = { oldPassword: "", newPassword: "", confirmPassword: "" };

const EmployerSetting = () => {

	const allowedDomains = [
		"gmail.com",
		"yahoo.com",
		"outlook.com",
		"aol.com",
		"hotmail.com",
		"icloud.com",
		'protonmail.com',
		"mail.com",
		"zoho.com",
		"yandex.com",
		"gmx.com",
		"tutanota.com",
		"163.com",
		"qq.com",
		"sina.com",
		"live.com",
		"aim.com",
		"rocketmail.com",
		"inbox.com",
		"me.com",
		"msn.com",
		"fastmail.com",
		"hushmail.com",
		"lavabit.com",
		"opmbx.org",
		"rediffmail.com",
		"mail.ru",
		"Bigpond.com",
		"mailnaitor.com",

	];
	const [show, setShow] = useState(false);

	const Navigate = useNavigate();
	const [otpShow, setOtpShow] = useState(false);
	const [shows, setShows] = useState({
		isOpen: false,
		message: "",
		type: "",
	});
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state?.auth);
	const [counter, setCounter] = useState(10)

	const decrementCounter = () => {
		setCounter((prevCounter) => prevCounter > 0 ? prevCounter - 1 : prevCounter);
	};

	// const Id = useSelector((store) => store);


	const handleResendOTP = async () => {
		const payload = {
			newEmail: values.newEmail,
		};
		if (counter === 0) {

			setCounter(10);

			try {
				const response = await resendOtp(payload, user?.id);

				if (response.status === true) {
					setShows({
						isOpen: true,
						message: response.message,
						type: "success",
					});
				} else {
					setShows({
						isOpen: true,
						message: response.message || "Failed to resend OTP.",
						type: "error",
					});
				}
			} catch (error) {
				setShows({
					isOpen: true,
					message: "Technical error. Please try again later.",
					type: "error",
				});
			}
		}
	};






	const deleteAccount = async () => {
		const res = await dispatch(deleteEmployerApi(user?.id));
		if (res && res.status == true) {
			setShows({
				isOpen: true,
				message: res?.message,
				type: "success",
			})
			// setShow(false);
			setTimeout(() => {
				Navigate("/employerlogin");
			}, 2000);
		}
		else {
			setShows({
				isOpen: true,
				message: res?.message,
				type: "error",
			})
		}

	}

	const validate = (fieldValues = values) => {
		let temp = { ...errors };
		if ("newEmail" in fieldValues) {
			if (fieldValues.newEmail !== "" && fieldValues.newEmail !== undefined) {
				// const emailRegex =
				// 	/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in)$/;
				const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

				const isEmailValid = emailRegex.test(fieldValues.newEmail);
				if (!isEmailValid) {
					temp.newEmail = "Invalid email address. Please enter a valid email.";
				} else {
					const domain = fieldValues.newEmail.split("@")[1];
					if (allowedDomains.includes(domain.toLowerCase())) {
						temp.newEmail = "Please enter a valid work email";
					} else {
						temp.newEmail = ""; // Clear the error message when conditions are met
					}
				}
			} else {
				temp.newEmail = "This field is required.";
			}
		}

		setErrors({
			...temp,
		});

		if (fieldValues === values)
			return Object.values(temp).every((x) => x === "");
	};
	const validates = (fieldValues = values1) => {
		let temp = { ...errors };

		if ("otp" in fieldValues) {
			if (fieldValues.otp !== "" && fieldValues.otp !== undefined) {
				if (!/^[0-9]*$/.test(fieldValues.otp)) {
					temp.otp = "Only Number Are Allowed";
				} else {
					temp.otp = "";
				}
			} else {
				temp.otp = "This field is required.";
			}
		}


		setErrors({
			...temp,
		});

		if (fieldValues === values1)
			return Object.values(temp).every((x) => x === "");
	};

	const passwordValidate = (fieldValues = values2) => {
		let temp = { ...errors2};

		if ("oldPassword" in fieldValues) {
			if (fieldValues.oldPassword !== "" && fieldValues.oldPassword !== undefined) {
				if (fieldValues.oldPassword.length < 8) {
					temp.oldPassword = "Password  must be 8 charecter";
				} else {
					if (!/[0-9]/.test(fieldValues.oldPassword)) {
						temp.oldPassword = "Password  must be 1 Number";
					} else {
						if (!/[a-z]/.test(fieldValues.oldPassword)) {
							temp.oldPassword = "Password  must be 1 Lowercase letter";
						} else {
							if (!/[A-Z]/.test(fieldValues.oldPassword)) {
								temp.oldPassword = "Password  must be 1 Uppercase letter";
							} else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(fieldValues.oldPassword)) {
								temp.oldPassword = "Password must contain at least 1 special character";
							} else {
								// setPass(fieldValues.password);
								temp.oldPassword = "";
							}
						}
					}
				}
			} else {
				temp.oldPassword = "This field is required.";
			}
		}

		if ("newPassword" in fieldValues) {
			if (fieldValues.newPassword !== "" && fieldValues.newPassword !== undefined) {
				if (fieldValues.newPassword.length < 8) {
					temp.newPassword = "Password  must be 8 charecter";
				} else {
					if (!/[0-9]/.test(fieldValues.newPassword)) {
						temp.newPassword = "Password  must be 1 Number";
					} else {
						if (!/[a-z]/.test(fieldValues.newPassword)) {
							temp.newPassword = "Password  must be 1 Lowercase letter";
						} else {
							if (!/[A-Z]/.test(fieldValues.newPassword)) {
								temp.newPassword = "Password  must be 1 Uppercase letter";
							} else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(fieldValues.newPassword)) {
								temp.newPassword = "Password must contain at least 1 special character";
							} else {
								// setPass(fieldValues.password);
								temp.newPassword = "";
							}
						}
					}
				}
			} else {
				temp.newPassword = "This field is required.";
			}
		}


		if ("confirmPassword" in fieldValues) {
			if (fieldValues.confirmPassword !== "" && fieldValues.confirmPassword !== undefined) {
				if (fieldValues.confirmPassword !== values2.newPassword) {
					temp.confirmPassword = "Confirm password not same as new password";
				} else {
					temp.confirmPassword = "";
				}
			} else {
				temp.confirmPassword = "This field is required.";
			}
		}
		setErrors({
			...temp,
		});


		if (fieldValues === values2)
			return Object.values(temp).every((x) => x === "");
	};

	useEffect(() => {

		if (counter > 0) {
			const intervalId = setInterval(decrementCounter, 1000);
			return () => clearInterval(intervalId);
		}
	}, [counter]);

	const { values, errors, handleInputChange, setErrors } =
		useForm(initialValues, true, validate);
	const { values1, errors1, handleInputChange1, setErrors1 } =
		useForm1(initialValues1, true, validates);

	const { values2, errors2, handleInputChange2, setErrors2 } =
		useForm2(initialValues2, true, passwordValidate);

	const sendOtp = async (e) => {
		e.preventDefault();


		if (validate()) {
			const payload = {
				newEmail: values.newEmail,
			};
			let res = await changeEmployerEmail(payload, user?.id);

			if (res && res.status === true) {
				setShows({
					isOpen: true,
					message: res?.message,
					type: "success",
				});
				setOtpShow(true);
				if (counter > 0) {
					const intervalId = setInterval(decrementCounter, 1000);
					return () => clearInterval(intervalId);
				}

			} else {
				setShows({
					isOpen: true,
					message: res?.message,
					type: "error",
				});
			}
		}
	};

	const handelSubmit = async (e) => {
		e.preventDefault();
		const payload = {
			email: user.email,
			newEmail: values?.newEmail,
			otp: values1.otp,
		};
		if (validates()) {
			let res = await employerEmailVerified(payload, user?.id);

			if (res && res.status === true) {
				setShows({
					isOpen: true,
					message: res?.message,
					type: "success",
				});
				setOtpShow(false);
				await dispatch(loadUser());


			} else {
				setShows({
					isOpen: true,
					message: res?.message,
					type: "error",
				});
			}
		}
	};
	const changePassword = async (e) => {
		e.preventDefault();
		const payload = {
			oldPassword: values2.oldPassword,
			newPassword: values2.newPassword,
		};
		console.log(passwordValidate());
		if (passwordValidate()) {
			let res = await changeEmployerPassword(payload, user?.id);

			if (res && res.status === true) {
				setShows({
					isOpen: true,
					message: res?.message,
					type: "success",
				});
				// setOtpShow(true);
				Navigate("/employerlogin");

				setTimeout(() => {
					dispatch(removeData());

				}, 2000);

			} else {
				setShows({
					isOpen: true,
					message: res.response.data?.message,
					type: "error",
				});
			}
		}
	};

	const [passwordVisible, setPasswordVisible] = useState(false);
	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};
	const togglenewPasswordVisibility = () => {
		setNewPasswordVisible(!newPasswordVisible);
	};
	const toggleConfirmPasswordVisibility = () => {
		setConfirmPasswordVisible(!confirmPasswordVisible);
	};

	return (
		<div>
			<div className="container mb-5 mt-3">
				<h3 className='login-top-head mb-32 font-PTSerif-b pt-3 margin-3'>Account Settings</h3>
				<div className="row align-items-center rounded-card padding_card margin-3">
					<div className="col-md-6 ">
						<div className="">
							{/* <form autoComplete="off"> */}
							<div className="row">
								<div className='col-md-12 mb-3'>
									<div className="form-label">Email Address</div>
									<Controls.Input
										className="form-control"
										name="newEmail"
										type="email"
										id="newEmail"
										maxLength="50"
										onChange={handleInputChange}
										placeholder='Enter New Email Address'
									/>
									{Boolean(errors.newEmail) ? (
										<p className="text-invalid">{errors.newEmail}</p>
									) : (
										""
									)}
								</div>
								{/* <div className='col-md-12 mb-3'>
									<div className="form-label">Password</div>
									<Controls.Input
										className="form-control"
										name="password"
										type="password"
										maxLength="50"
									/>
								</div> */}
								<div className='col-md-12 mb-3'>
									<div className="text-end">
										<Controls.Button
											className="btn btn-primary h-44 "
											text="Change Email"
											onClick={(e) => sendOtp(e)}
										/>
									</div>
								</div>
							</div>
							<hr />
							<div>
								<h4 className="login-top-head mb-32 font-PTSerif-b mt-4">Your Password</h4>
							</div>
							<div className='row'>
								<div className='col-md-12 mb-3'>
									<div className="form-label">Current Password</div>
									<div className="d-flex flex-column input-relative">
										<Controls.Input
											className="form-control"
											name="oldPassword"
											maxLength="12"
											onChange={handleInputChange2}
											type={passwordVisible ? "text" : "password"}
											placeholder='Enter Current Password'
										/>
										<div
											className="position-absolute password_eye"
											variant="link"
											onClick={togglePasswordVisibility}
										>
											{passwordVisible ? (
												<AiOutlineEyeInvisible
													style={{ width: "80%", height: "30px" }}
												/>
											) : (
												<AiOutlineEye
													style={{ width: "80%", height: "30px" }}
												/>
											)}
										</div>
										{Boolean(errors2.oldPassword) ? (
											<p className="text-invalid">{errors2.oldPassword}</p>
										) : (
											""
										)}
									</div>
								</div>
								<div className='col-md-12 mb-3'>
									<div className="form-label">New Password</div>
									<div className="d-flex flex-column input-relative">
										<Controls.Input
											className="form-control"
											name="newPassword"
											type={newPasswordVisible ? "text" : "password"}
											maxLength="12"
											onChange={handleInputChange2}
											placeholder='Enter New Password'
										/>
										<div
											className="position-absolute password_eye"
											variant="link"
											onClick={togglenewPasswordVisibility}
										>
											{newPasswordVisible ? (
												<AiOutlineEyeInvisible
													style={{ width: "80%", height: "30px" }}
												/>
											) : (
												<AiOutlineEye
													style={{ width: "80%", height: "30px" }}
												/>
											)}
										</div>
										{Boolean(errors2.newPassword) ? (
											<p className="text-invalid">{errors2.newPassword}</p>
										) : (
											""
										)}
									</div>
								</div>
								<div className='col-md-12 mb-3'>
									<div className="form-label">Confirm Password</div>
									<div className="d-flex flex-column input-relative">
										<Controls.Input
											className="form-control"
											name="confirmPassword"
											type={confirmPasswordVisible ? "text" : "password"}
											onChange={handleInputChange2}
											maxLength="12"
											placeholder='Confirm New Password'

										/>
										<div
											className="position-absolute password_eye"
											variant="link"
											onClick={toggleConfirmPasswordVisibility}
										>
											{confirmPasswordVisible ? (
												<AiOutlineEyeInvisible
													style={{ width: "80%", height: "30px" }}
												/>
											) : (
												<AiOutlineEye
													style={{ width: "80%", height: "30px" }}
												/>
											)}
										</div>
										{Boolean(errors2.confirmPassword) ? (
											<p className="text-invalid">{errors2.confirmPassword}</p>
										) : (
											""
										)}
									</div>
								</div>
								<div className='col-md-12 mb-3'>
									<div className="text-end">
										<Controls.Button
											className="btn btn-primary h-44 "
											text="Change Password"
											onClick={(e) => changePassword(e)}
										/>
									</div>
								</div>
							</div>

							{/* </form> */}
						</div>
					</div>
					<div className='col-md-6'>
						<div className='row delete_account'>
							<div className='col-md-12 mb-3'>
								<h4 className="login-top-head font-PTSerif-b mb-0">Delete Account</h4>
							</div>
							<div className='col-md-12 mb-3'>
								<h5 className="login-top-head mb-2">Once you delete your account, there is no going back, Please be certain</h5>
							</div>
							<div className='col-md-12 text-end'>
								<Controls.Button
									className="btn btn-danger h-44 w-100"
									text="Delete Your Account"
									onClick={() => setShow(true)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				size="md"
				show={show} onHide={() => setShow(false)} centered
				aria-labelledby=""
			>
				<Modal.Header className="border-0 pb-0" closeButton>
					<Modal.Title><b>Delete Account</b></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Modal.Title>
						<div className='mb-0 fw-normal font-16-24-05'>
							By proceeding with the account deletion, all your data will be permanently
							removed and your credentials will be blocked from further use on our platform.
						</div>
					</Modal.Title>
				</Modal.Body>
				<Modal.Footer
					style={{
						flexDirection: "row",
						columnGap: "10px",
						width: "100%",
						margin: "0 0 10px 0 !important",
						border: "none",
					}}
				>
					<Button
						className="btn btn-primary h-44 fw-medium m-2"
						onClick={() => setShow(false)}
						style={{
							width: "100px",
							padding: "0 13px",
						}}
					>
						Close
					</Button>
					<Button
						className="btn h-44 fw-medium m-2" variant="danger"
						onClick={() => {
							deleteAccount();
						}
						}
						style={{
							width: "100px",
							padding: "0 13px",
							flexDirection: "row",
						}}
					>
						yes
					</Button>
				</Modal.Footer>
			</Modal>


			<Modal
				size="md"
				show={otpShow} onHide={() => setOtpShow(false)} centered
				aria-labelledby=""
			>
				<Modal.Header className="border-0 pb-0" closeButton>
					<Modal.Title>OTP Validation</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='row'>
						<div className='col-md-12'>
							<div className="form-label">OTP</div>
							<Controls.Input
								className="form-control"
								name="otp"
								type="otp"
								maxLength="50"
								onChange={handleInputChange1}
							/>
							{Boolean(errors1.otp) ? (
								<p className="text-invalid">{errors1.otp}</p>
							) : (
								""
							)}
						</div>
						<div className="text-end">
							<div className="resend_otp">
								<Controls.Button
									style={{ color: '#143f87' }}
									className="bg-transparent border-0 h-45 w-35 mb-2"
									text={`Resend OTP  ${counter === 0 ? '' : counter}`}
									type="submit"
									onClick={handleResendOTP}




								/>
							</div>
						</div>
					</div>


				</Modal.Body>
				<Modal.Footer
					style={{
						flexDirection: "row",
						columnGap: "10px",
						width: "100%",
						margin: "0 0 10px 0 !important",
						border: "none",
					}}
				>
					<Button
						className="btn btn-primary h-44 fw-medium m-2"
						onClick={() => [setOtpShow(false), setCounter(10)]}
						style={{
							width: "100px",
							padding: "0 13px",
						}}
					>
						Close
					</Button>
					<Button
						className="btn btn-primary h-44 fw-medium m-2"
						onClick={(e) =>
							handelSubmit(e)}
						style={{
							width: "100px",
							padding: "0 13px",
							flexDirection: "row",
						}}
					>
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
			<AutohideExample show={shows} setShow={setShows} />

		</div>
	)
}

export default EmployerSetting