import axios from "axios";
import axiosInstance from "../../utills/AxiosInstance";




export const sendotp = async (payload) => {
    try {
        const response = await axiosInstance.post('/fresher/forgotPasswordMailSend', payload)
        return response.data;
    } catch (error) {
        return error;
    }
}


export const verifyotp = async (payload) => {
    try {
        const response = await axiosInstance.put('/fresher/emailVerification', payload)
        return response.data;
    } catch (error) {
        return error;
    }
}


export const changepassword = async (payload) => {
    try {
        const response = await axiosInstance.put('/fresher/fresherForgotPassword', payload)

        return response.data;
    } catch (error) {
        return error;
    }
}


export const resendOtp = async (payload, id) => {
    try {
        const response = await axiosInstance.put(`/fresher/fresherResendOtp/${id}`, payload)
        return response.data;
    } catch (error) {
        return error;
    }
}



export const resendOtp1 = async (email) => {
    try {
        const response = await axiosInstance.put(`/fresher/fresherResendForgotOtp/${email}`)
        return response.data;
    } catch (error) {
        return error;
    }
}

export const fresherRegisterResendOtp = async (email) => {
    try {
        const response = await axiosInstance.put(`/fresher/fresherRegisterResendOtp/${email}`)
        return response.data;
    } catch (error) {
        return error;
    }
}
