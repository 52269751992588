import React from 'react'
import { useState } from 'react'

export function Loader(props) {

    const { view } = props;


    return (
        <div>

            {view && (<div class="d-flex justify-content-center">
                <div class="spinner-border" role="status" style={{
                    position: "absolute",
                    top: "50%",
                    height: "50px",
                    width: "50px",
                }}>
                    <span class="sr-only"></span>
                </div>
            </div>)}

        </div>
    )
}


