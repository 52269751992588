import React, { useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Async, { useAsync } from "react-select/async";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import InterViewerProfile from "../../assets/images/interviewer_profile.png";
import InterViewerProfile2 from "../../assets/images/interviewer_profile_2.png";
import InterViewerProfile3 from "../../assets/images/interviewer_profile_3.png";
import InterViewerProfile4 from "../../assets/images/interviewer_profile_4.png";
import CloseIcon from "../../assets/images/close_circle.svg";
import { getJobDropDown } from "../../state/action/candidateAction";
import { getCountryLists } from "../../state/action/addjobAction";
import { Helmet } from 'react-helmet'
import {
  InterviewDropdown,
  addInterviewJob,
  getCandidateHire,
  getCandidateLists,
  getRejectCandidateJob,
} from "../../state/action/employerAction";
import { useSelector } from "react-redux";
import AutohideExample from "../../utills/Notification";
import { Form, useForm } from "../../utills/useForms";
import { getCandidateDetails } from "../../state/action/employerAction";
import { Tooltip } from "react-bootstrap";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';


const initialValues = {
  jobTypeId: "",
  jobPreferId: "",
  jobtitle: "",
  jobDescription: "",
  maxSalary: "",
  minSalary: "",
  openings: "",
  salCurrency: "",
  countryId: "",
  stateId: "",
  cityId: "",
  skillId: "",
  date: "",
  time: "12:00",
  location: "",
  interviewTypeId: "",
  employerReason: "",
  sortby: "",
};

const Candidates = () => {
  const { user } = useSelector((state) => state?.auth);

  const [PostNewJob, setPostNewJob] = useState(false);
  const [JobDetails, setJobDetails] = useState(false);
  const [FillInterviewDetails, setFillInterviewDetails] = useState(false);
  const [InterviewReject, setInterviewReject] = useState(false);
  const [InterviewHire, setInterviewHire] = useState(false);

  const [CandidateInformation, setCandidateInformation] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  const [statusValue, setStatusValue] = useState("");

  const [jobTitle, setJobTitle] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [activeTab, setActiveTab] = useState("Jobtab1");
  const [interviewIdEmployer, setInterviewIdEmployer] = useState(null);
  const [interviewType, setInterviewType] = useState([]);
  const [acctiveTab, setAcctiveTab] = useState(1);
  const [fresherId, setFresherId] = useState(null);
  const [candidateData, setCandiadteData] = useState([]);
  const [countData, setCountData] = useState([]);
  const [convertedContent, setConvertedContent] = useState(null);

  const [jobId, setJobId] = useState(null);
  const [CloseJob, setCloseJob] = useState(false);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = candidateData?.resume?.fileLocation;
    link.download = "filename.pdf";
    link.click();
  };

  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorState, setEditorState] = useState();



  const handleEditorChange = (state) => {
    if (state && state != null) {
      setEditorState(state);
      convertContentToHTML();
    }
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const setTab = (tab) => {
    setAcctiveTab(tab);
  };

  const dataId = useSelector((state) => state?.auth?.user);

  const getCandidateList = async (id, jobId) => {
    const res = await getCandidateDetails(id, jobId);
    if (res) {
      setCandiadteData(res?.data);
    } else {
      setCandiadteData([]);
    }
  };

  //candidate Listing Data
  const getCandidateData = async (e) => {
    let status;
    if (activeTab === "Jobtab2") {
      status = [1, 2];
    } else if (activeTab === "Jobtab3") {
      status = 3;
    } else if (activeTab === "Jobtab4") {
      status = [4, 5];
    } else {
      status = 0;
    }

    let queryString = `?status=${status}&title=${values.jobtitle}&country=${values.countryId}&sortBy=${values.sortby}`;
    const res = await getCandidateLists(dataId?.id, queryString);
    if (res && res.data) {
      setCandidateList(res.data);
      setCountData(res.count);
    }
  };

  //interview status

  const interviewstatus = (interviewstatus) => {
    const statusColors = {
      0: {
        key: "Review",
        backgroundColor: "white",
        color: "balck",
      },
      1: {
        key: "Schedule",
        backgroundColor: "white",
        color: "balck",
      },
      2: {
        key: "On going",
        backgroundColor: "white",
        color: "balck",
      },
      3: {
        key: "Completed",
        backgroundColor: "white",
        color: "balck",
      },
      4: {
        key: "rejected by fresher",
        backgroundColor: "white",
        color: "balck",
      },
      5: {
        key: "rejected by employer",
        backgroundColor: "white",
        color: "balck",
      },
    };

    const status = statusColors[interviewstatus];

    return (
      <div
        style={{
          height: "30px",
          fontSize: "15px",
          // backgroundColor: status ? status.backgroundColor : "",
          color: status ? status.color : "",
        }}
      >
        {status ? status.key : ""}
      </div>
    );
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("interviewTypeId" in fieldValues) {
      temp.interviewTypeId = fieldValues.interviewTypeId
        ? ""
        : "This field is required.";
    }

    if ("date" in fieldValues) {
      temp.date = fieldValues.date ? "" : "This field is required.";
    }

    if ("time" in fieldValues) {
      temp.time = fieldValues.time ? "" : "This field is required.";
    }

    if ("location" in fieldValues) {
      temp.location = fieldValues.location ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const employerReasonValidate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("employerReason" in fieldValues) {
      temp.employerReason = fieldValues.employerReason
        ? ""
        : "This field is required.";
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate, employerReasonValidate);

  //reject employer candidate

  useEffect(() => {
    getCandidateData(dataId?.id);
  }, [dataId?.id, activeTab, values.jobtitle, values.countryId, values.sortby]);

  const handleRejectcandidate = async (e) => {
    e.preventDefault();
    if (employerReasonValidate()) {
      const body = {
        employerReason: values.employerReason,
      };
      const res = await getRejectCandidateJob(jobId, fresherId, body);

      if (res && res.status === true) {
        setInterviewReject(false);
        getCandidateData(dataId?.id);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  //handleClick in candidate option

  const handleOptionClick = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === "Reject") {
      setInterviewReject(true);
    }

    if (selectedOption === "Hire") {
      setInterviewHire(true);
    }
    if (selectedOption === "Interview Invite") {
      setFillInterviewDetails(true);
    }
    setStatusValue("");
  };

  //interview add
  const timeValidation = () => {
    if (values.time == "00:00") {
      setShow({
        isOpen: true,
        message: "Time is required",

        type: "error",

      });
      return false;
    }
    else {
      return true;
    }
  }

  const handleInterviewSubmit = async (e) => {
    e.preventDefault();
    if (validate()  && timeValidation()) {
      const payload = {
        fresherId: fresherId,
        employerId: dataId?.id,
        jobId: jobId,
        interviewTypeId: values.interviewTypeId,
        date: values.date,
        time: values.time,
        location: values.location,
        fresherReason: "",
        employerReason: "",
      };

      const res = await addInterviewJob(payload);

      if (res && res.status === true) {
        getCandidateData(dataId?.id);
        setFillInterviewDetails(false);
        resetForm();
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  //hire employer candidate

  const handleHirecandidate = async (e) => {
    e.preventDefault();

    const res = await getCandidateHire(jobId, fresherId);

    if (res && res.status === true) {
      setInterviewHire(false);
      getCandidateData(dataId?.id);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  //interview type dropdown

  const getInterviewTypeList = async () => {
    const res = await InterviewDropdown();
    if (res) {
      setInterviewType(res?.data);
    } else {
    }
  };

  useEffect(() => {
    getInterviewTypeList();
    getCountryDropdown();
    if (user && user?.id) getJobTitleDropDown(user.id);
  }, []);

  const getJobTitleDropDown = async (id) => {
    const res = await getJobDropDown(id);
    if (res) {
      setJobTitle(res?.data);
    } else {
    }
  };

  const getCountryDropdown = async () => {
    const res = await getCountryLists();
    if (res && res?.status) {
      setCountryList(res?.data);
    } else {
      setCountryList([]);
    }
  };

  return (
    <main className="body-background">
       <Helmet>
              <meta charSet="utf-8" />
        <title>Find Skilled Candidates | TechFresher Employer Dashboard</title>
        <meta name="description" content="Search and find skilled candidates on TechFresher's employer dashboard. Connect with fresh tech graduates who possess the skills your company needs." />
      </Helmet>
      <AutohideExample show={show} setShow={setShow} />

      <div className="bg-cyan">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard-welcome-text py-5">
                <h3 className="mb-2 font-PTSerif-b">Candidates</h3>
                <p className="mb-0 font-16-24-05">
                  Manage and keep track of candidates
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="rounded-card-head pt-3 pt-lg-5 mb-16">
          <div className="row align-items-center mb-16">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">
                Candidates
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-12">
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabChange}
                defaultActiveKey="Jobtab1"
                className="mb-3 row-cols-1 row-cols-md-4 blue-border-tabs"
              >
                {countData?.map((row) => {
                  return (
                    <Tab
                      eventKey="Jobtab1"
                      title={`Review(${row?.status0Count})`}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Posted jobs:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100"
                                value={values.jobtitle}
                                onChange={handleInputChange}
                                name="jobtitle"
                              >
                                <option value="">All</option>
                                {jobTitle?.map((item) => {
                                  return (
                                    <option value={item?.jobPostId}>
                                      {item?.jobTitle}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Location:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100"
                                value={values.countryId}
                                onChange={handleInputChange}
                                name="countryId"
                              >
                                <option value="">All</option>
                                {countryList?.map((item) => {
                                  return (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Sort By:</div>
                            </div>
                            <div className="col col-md">
                              <select className="select-with-border-btn h-36 w-100"
                                onChange={handleInputChange}
                                name="sortby"
                                value={values.sortby}

                              >
                                <option value="1">Date By Ascending</option>
                                <option value="2">Date By Descending</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {candidateList?.map((row) => {
                        return (
                          <>
                            <div className="rounded-card mb-3 mb-md-4">
                              <div className="row">
                                <div className="col-12 my-2">
                                  <div className="row justify-content-between align-items-center">
                                    <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                      <div className="row align-items-center">
                                        <div className="col-auto">
                                          <div className="interviewer-profile_1">
                                            <img
                                              src={row?.fresherImage}
                                              style={{
                                                maxWidth: "100%",
                                                height: "100px",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col ps-0">
                                          <div className="font-18-24-05 mb-1 fw-medium">
                                            Name
                                          </div>
                                          <div className="font-16-24-05">
                                            {row?.fresherName}{" "}
                                            {row?.fresherLastName}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                      <div className="font-18-24-05 mb-1 fw-medium">
                                        Matched Skills
                                      </div>
                                      <div className="mt-2">
                                        {row?.fresherSkill?.map((items) => {
                                          return (
                                            <>
                                              {row &&
                                                row.matchSkill &&
                                                row?.matchSkill.filter(
                                                  (item) =>
                                                    item == items?.skillId
                                                ).length > 0 && (
                                                  <li className="d-inline-block">
                                                    <div
                                                      className={
                                                        row &&
                                                          row.matchSkill &&
                                                          row?.matchSkill.filter(
                                                            (item) =>
                                                              item ==
                                                              items?.skillId
                                                          ).length > 0
                                                          ? "checkbox-round checkbox-check checkbox-round-md"
                                                          : "checkbox-round checkbox-round-md"
                                                      }
                                                    >
                                                      <label className="custom-checkbox">
                                                        {items?.title}
                                                        <Controls.Input
                                                          type="checkbox"
                                                          checked={
                                                            row &&
                                                              row.matchSkill &&
                                                              row?.matchSkill.filter(
                                                                (item) =>
                                                                  item ==
                                                                  items.skillId
                                                              ).length > 0
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                    </div>
                                                  </li>
                                                )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                      <div class="fw-medium font-18-24-05 mb-1">
                                        Status
                                      </div>
                                      <div class="font-16-24-05 mb-2">
                                        {interviewstatus(row?.status)}
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                      <div className="row text-nowrap">
                                        <div className="col-md-6 mb-2 pe-md-0">
                                          <select
                                            className="select-with-blue-btn h-33 w-100"
                                            value={statusValue}

                                            onChange={(e) => {
                                              handleOptionClick(e);
                                              setFresherId(row?.fresherId);
                                              setJobId(row?.jobPostId);

                                            }}
                                          >
                                            <option value="">
                                              Select Option
                                            </option>
                                            <option value="Interview Invite">
                                              Interview Invite
                                            </option>
                                            <option value="Reject">
                                              Reject
                                            </option>
                                            {row?.status !== 2 ? null : (
                                              <option value="Hire">Hire</option>
                                            )}
                                          </select>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                          <Controls.Button
                                            className="btn btn-link text-decoration-none w-100 h-32 fw-medium"
                                            text="Candidate Details"
                                            onClick={() => {
                                              setCandidateInformation(true);
                                              getCandidateList(row?.fresherId, row?.jobPostId);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Tab>
                  );
                })}

                {countData?.map((row) => {
                  return (
                    <Tab
                      eventKey="Jobtab2"
                      title={`Awaiting interview(${row?.status12Count})`}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Posted jobs:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100"
                                value={values.jobtitle}
                                onChange={handleInputChange}
                                name="jobtitle"
                              >
                                <option value="">All</option>
                                {jobTitle?.map((item) => {
                                  return (
                                    <option value={item?.jobPostId}>
                                      {item?.jobTitle}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Location:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100"
                                value={values.countryId}
                                onChange={handleInputChange}
                                name="countryId"
                              >
                                <option value="">All</option>
                                {countryList?.map((item) => {
                                  return (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Sort By:</div>
                            </div>
                            <div className="col col-md">
                              <select className="select-with-border-btn h-36 w-100"
                                onChange={handleInputChange}
                                name="sortby"
                                value={values.sortby}

                              >
                                <option value="1">Date By Ascending</option>
                                <option value="2">Date By Descending</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {candidateList?.map((row) => {
                        return (
                          <>
                            <div className="rounded-card mb-3 mb-md-4">
                              <div className="row">
                                <div className="col-12 my-2">
                                  <div className="row justify-content-between align-items-center">
                                    <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                      <div className="row align-items-center">
                                        <div className="col-auto">
                                          <div className="interviewer-profile_1">
                                            <img
                                              src={row?.fresherImage}
                                              style={{
                                                maxWidth: "100%",
                                                height: "100px",
                                              }} />
                                          </div>
                                        </div>
                                        <div className="col ps-0">
                                          <div className="font-18-24-05 mb-1 fw-medium">
                                            Name
                                          </div>
                                          <div className="font-16-24-05">
                                            {row?.fresherName}{" "}
                                            {row?.fresherLastName}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                      <div className="font-18-24-05 mb-1 fw-medium">
                                        Matched Skills
                                      </div>
                                      <div className="mt-2">
                                        {row?.fresherSkill?.map((items) => {
                                          return (
                                            <>
                                              {row &&
                                                row.matchSkill &&
                                                row?.matchSkill.filter(
                                                  (item) =>
                                                    item == items?.skillId
                                                ).length > 0 && (
                                                  <li className="d-inline-block">
                                                    <div
                                                      className={
                                                        row &&
                                                          row.matchSkill &&
                                                          row?.matchSkill.filter(
                                                            (item) =>
                                                              item ==
                                                              items?.skillId
                                                          ).length > 0
                                                          ? "checkbox-round checkbox-check checkbox-round-md"
                                                          : "checkbox-round checkbox-round-md"
                                                      }
                                                    >
                                                      <label className="custom-checkbox">
                                                        {items?.title}
                                                        <Controls.Input
                                                          type="checkbox"
                                                          checked={
                                                            row &&
                                                              row.matchSkill &&
                                                              row?.matchSkill.filter(
                                                                (item) =>
                                                                  item ==
                                                                  items.skillId
                                                              ).length > 0
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                    </div>
                                                  </li>
                                                )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                      <div class="fw-medium font-18-24-05 mb-1">
                                        Status
                                      </div>
                                      <div class="font-16-24-05 mb-2">
                                        {interviewstatus(row?.status)}
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                      <div className="row text-nowrap">
                                        <div className="col-md-6 mb-2 pe-md-0">
                                          <select
                                            className="select-with-blue-btn h-33 w-100"
                                            value={statusValue}
                                            onChange={(e) => {
                                              handleOptionClick(e);
                                              setFresherId(row?.fresherId);
                                              setJobId(row?.jobPostId);
                                              // setInterviewIdEmployer(
                                              //   item?.fresherId
                                              // );
                                            }}
                                          >
                                            <option value="">
                                              Select Option
                                            </option>

                                            <option value="Reject">
                                              Reject
                                            </option>
                                            {row?.status !== 2 ? null : (
                                              <option value="Hire">Hire</option>
                                            )}
                                          </select>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                          <Controls.Button
                                            className="btn btn-link text-decoration-none w-100 h-32 fw-medium"
                                            text="Candidate Details"
                                            onClick={() => {
                                              getCandidateList(row?.fresherId, row?.jobPostId);
                                              setCandidateInformation(true);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Tab>
                  );
                })}

                {countData?.map((row) => {
                  return (
                    <Tab
                      eventKey="Jobtab3"
                      title={`Hired(${row?.status3Count})`}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Posted jobs:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100"
                                value={values.jobtitle}
                                onChange={handleInputChange}
                                name="jobtitle"
                              >
                                <option value="">All</option>
                                {jobTitle?.map((item) => {
                                  return (
                                    <option value={item?.jobPostId}>
                                      {item?.jobTitle}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Location:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100"
                                value={values.countryId}
                                onChange={handleInputChange}
                                name="countryId"
                              >
                                <option value="">All</option>
                                {countryList?.map((item) => {
                                  return (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Sort By:</div>
                            </div>
                            <div className="col col-md">
                              <select className="select-with-border-btn h-36 w-100"
                                onChange={handleInputChange}
                                name="sortby"
                                value={values.sortby}

                              >
                                <option value="1">Date By Ascending</option>
                                <option value="2">Date By Descending</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {candidateList?.map((row) => {
                        return (
                          <>
                            <div className="rounded-card mb-3 mb-md-4">
                              <div className="row">
                                <div className="col-12 my-2">
                                  <div className="row justify-content-between align-items-center">
                                    <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                      <div className="row align-items-center">
                                        <div className="col-auto">
                                          <div className="interviewer-profile_1">
                                            <img
                                              src={row?.fresherImage}
                                              style={{
                                                maxWidth: "100%",
                                                height: "100px",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col ps-0">
                                          <div className="font-18-24-05 mb-1 fw-medium">
                                            Name
                                          </div>
                                          <div className="font-16-24-05">
                                            {row?.fresherName}{" "}
                                            {row?.fresherLastName}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                      <div className="font-18-24-05 mb-1 fw-medium">
                                        Matched Skills
                                      </div>
                                      <div className="mt-2">
                                        {row?.fresherSkill?.map((items) => {
                                          return (
                                            <>
                                              {row &&
                                                row.matchSkill &&
                                                row?.matchSkill.filter(
                                                  (item) =>
                                                    item == items?.skillId
                                                ).length > 0 && (
                                                  <li className="d-inline-block">
                                                    <div
                                                      className={
                                                        row &&
                                                          row.matchSkill &&
                                                          row?.matchSkill.filter(
                                                            (item) =>
                                                              item ==
                                                              items?.skillId
                                                          ).length > 0
                                                          ? "checkbox-round checkbox-check checkbox-round-md"
                                                          : "checkbox-round checkbox-round-md"
                                                      }
                                                    >
                                                      <label className="custom-checkbox">
                                                        {items?.title}
                                                        <Controls.Input
                                                          type="checkbox"
                                                          checked={
                                                            row &&
                                                              row.matchSkill &&
                                                              row?.matchSkill.filter(
                                                                (item) =>
                                                                  item ==
                                                                  items.skillId
                                                              ).length > 0
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                    </div>
                                                  </li>
                                                )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                      <div class="fw-medium font-18-24-05 mb-1">
                                        Status
                                      </div>
                                      <div class="font-16-24-05 mb-2">
                                        {interviewstatus(row?.status)}
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                      <div className="row text-nowrap">
                                        <div className="col-md-6 mb-2 pe-md-0">
                                          {/* <select
                                            className="select-with-blue-btn h-33 w-100"
                                            value={statusValue}
                                            onChange={(e) => {
                                              handleOptionClick(e);
                                              setFresherId(row?.fresherId);
                                              setJobId(row?.jobPostId);
                                              // setInterviewIdEmployer(
                                              //   item?.fresherId
                                              // );
                                            }}
                                          >
                                            <option value="">
                                              Select Option
                                            </option>
                                            {row?.status !== 3 ? null : (
                                              <option value="Reject">
                                                Reject
                                              </option>
                                            )}
                                          </select> */}
                                        </div>
                                        <div className="col-md-6 mb-2">
                                          <Controls.Button
                                            className="btn btn-link text-decoration-none w-100 h-32 fw-medium"
                                            text="Candidate Details"
                                            onClick={() => {
                                              getCandidateList(row?.fresherId, row?.jobPostId);
                                              setCandidateInformation(true);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Tab>
                  );
                })}

                {countData?.map((row) => {
                  return (
                    <Tab
                      eventKey="Jobtab4"
                      title={`Rejected(${row?.status45Count})`}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Posted jobs:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100"
                                value={values.jobtitle}
                                onChange={handleInputChange}
                                name="jobtitle"
                              >
                                <option value="">All</option>
                                {jobTitle?.map((item) => {
                                  return (
                                    <option value={item?.jobPostId}>
                                      {item?.jobTitle}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Location:</div>
                            </div>
                            <div className="col col-md">
                              <select
                                className="select-with-border-btn h-36 w-100"
                                value={values.countryId}
                                onChange={handleInputChange}
                                name="countryId"
                              >
                                <option value="">All</option>
                                {countryList?.map((item) => {
                                  return (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="row align-items-center mb-3">
                            <div className="col col-md-auto">
                              <div class="form-label">Sort By:</div>
                            </div>
                            <div className="col col-md">
                              <select className="select-with-border-btn h-36 w-100"
                                onChange={handleInputChange}
                                name="sortby"
                                value={values.sortby}

                              >
                                <option value="1">Date By Ascending</option>
                                <option value="2">Date By Descending</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {candidateList?.map((row) => {
                        return (
                          <>
                            <div className="rounded-card mb-3 mb-md-4">
                              <div className="row">
                                <div className="col-12 my-2">
                                  <div className="row justify-content-between align-items-center">
                                    <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                      <div className="row align-items-center">
                                        <div className="col-auto">
                                          <div className="interviewer-profile_1">
                                            <img
                                              src={row?.fresherImage}
                                              style={{
                                                maxWidth: "100%",
                                                height: "100px",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col ps-0">
                                          <div className="font-18-24-05 mb-1 fw-medium">
                                            Name
                                          </div>
                                          <div className="font-16-24-05">
                                            {row?.fresherName}{" "}
                                            {row?.fresherLastName}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                      <div className="font-18-24-05 mb-1 fw-medium">
                                        Matched Skills
                                      </div>
                                      <div className="mt-2">
                                        {row?.fresherSkill?.map((items) => {
                                          return (
                                            <>
                                              {row &&
                                                row.matchSkill &&
                                                row?.matchSkill.filter(
                                                  (item) =>
                                                    item == items?.skillId
                                                ).length > 0 && (
                                                  <li className="d-inline-block">
                                                    <div
                                                      className={
                                                        row &&
                                                          row.matchSkill &&
                                                          row?.matchSkill.filter(
                                                            (item) =>
                                                              item ==
                                                              items?.skillId
                                                          ).length > 0
                                                          ? "checkbox-round checkbox-check checkbox-round-md"
                                                          : "checkbox-round checkbox-round-md"
                                                      }
                                                    >
                                                      <label className="custom-checkbox">
                                                        {items?.title}
                                                        <Controls.Input
                                                          type="checkbox"
                                                          checked={
                                                            row &&
                                                              row.matchSkill &&
                                                              row?.matchSkill.filter(
                                                                (item) =>
                                                                  item ==
                                                                  items.skillId
                                                              ).length > 0
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                    </div>
                                                  </li>
                                                )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                      <div class="fw-medium font-18-24-05 mb-1">
                                        Status
                                      </div>
                                      <div class="font-16-24-05">
                                        {interviewstatus(row?.status)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Tab>
                  );
                })}
              </Tabs>
            </div>
          </div>
        </div>

        {/* <footer className="footer-copyright font-18-24-05 mb-1 text-center py-3">
          TechFresher 2023 copyright received
        </footer> */}
      </div>

      <Modal
        size="lg"
        show={PostNewJob}
        onHide={() => setPostNewJob(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-5">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-22-28 fw-medium mb-2">Post new job</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div className="form-label">Job title</div>
              <Controls.Input
                className="form-control"
                placeholder="Enter job title"
                name="text"
                type="text"
                lable="Enter job title"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div className="form-label">Job descriptionsss</div>
              <textarea
                className="form-control h-auto"
                placeholder="Enter the description of the job"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-16">
              <div className="form-label">Number of openings</div>
              <Controls.Input
                className="form-control"
                placeholder="Number of openings"
                name="text"
                type="text"
                lable="Number of openings"
              />
            </div>
            <div className="col-12 col-md-6 mb-16">
              <div className="form-label">Job location</div>
              <Controls.Input
                className="form-control"
                placeholder="Job location"
                name="text"
                type="text"
                lable="Job location"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div className="form-label">Required skills</div>
              <Controls.Input
                className="form-control"
                placeholder="What skills you are searching for in the candidate?"
                name="text"
                type="text"
                lable="What skills you are searching for in the candidate?"
              />
              <div className="round-suggestion-wrap round-suggestion-selected">
                <ul className="d-flex flex-wrap">
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    C#
                  </li>
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    Java
                  </li>
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    HTML
                  </li>
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    CSS
                  </li>
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    JavaScript
                  </li>
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    Python
                  </li>
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    C++
                  </li>
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    Assembly
                  </li>
                  <li>
                    <span className="selected-suggestion-close">
                      <img src={CloseIcon} className="img-fluid" />
                    </span>
                    C
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-label">Job type</div>
            </div>
            <div className="col-12 col-md-6 mb-16">
              <select className="form-select form-control">
                <option selected>Full-time</option>
                <option>Full-time 1</option>
                <option>Full-time 2</option>
                <option>Full-time 3</option>
              </select>
            </div>
            <div className="col-12 col-md-6 mb-16">
              <select className="form-select form-control">
                <option selected>On-Site</option>
                <option>On-Site 1</option>
                <option>On-Site 2</option>
                <option>On-Site 3</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-label">Salary range</div>
            </div>
            <div className="col-12 col-md-2 mb-16">
              <select className="form-select form-control">
                <option>INR</option>
                <option>USD</option>
              </select>
            </div>
            <div className="col-12 col-md mb-16">
              <Controls.Input
                className="form-control"
                placeholder="Minimum"
                name="text"
                type="text"
                lable="SkiMinimumlls"
              />
            </div>
            <div className="col-12 col-md mb-16">
              <Controls.Input
                className="form-control"
                placeholder="Maximum"
                name="text"
                type="text"
                lable="Maximum"
              />
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
          <Button variant="outline-primary" className="h-44 fw-medium mx-md-4">
            Cancel
          </Button>
          <Button variant="primary" className="h-44 fw-medium m-0">
            Post
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={JobDetails}
        onHide={() => setJobDetails(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 mb-1">Job Details</div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Job Title</div>
              <div className="font-16-24-05 mb-2">Software engineer</div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Number of openings</div>
              <div className="font-16-24-05 mb-2">3</div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Salary</div>
              <div className="font-16-24-05 mb-2">5,000 - 10,000 INR</div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Location</div>
              <div className="font-16-24-05 mb-2">India, India</div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Type</div>
              <div className="font-16-24-05 mb-2">Full-time, On-site</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="font-18-24-05 mb-1 fw-semibold mb-1">
                Required Skills
              </div>
            </div>
            <div className="col-12 col-lg-12 mb-16">
              <div className="round-ship-style-1">
                <ul className="d-flex flex-wrap">
                  <li>TensorFlow</li>
                  <li>Keras</li>
                  <li>Spark</li>
                  <li>AWS</li>
                  <li>Python</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="font-18-24-05 mb-1 fw-semibold mb-3">
                Job Description
              </div>
            </div>
            <div className="col-12 col-lg-12">
              <div className="font-16-24-05">
                Facebook is looking for an experienced and creative Front-End
                Developer to join our growing development team. In this role,
                you will be responsible for creating and maintaining
                user-friendly websites and web applications that are both
                visually appealing and easy to use.
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
          <Button variant="outline-danger" className="h-44 fw-medium mx-4">
            Delete
          </Button>
          <Button variant="primary" className="h-44 fw-medium m-0">
            Edit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={FillInterviewDetails}
        onHide={() => {
          setFillInterviewDetails(false);
          resetForm();
        }}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleInterviewSubmit}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-4">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-18-24-05 mb-1 fw-semibold">
                  Fill Interview Details
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-6 mb-3">
                <div className="form-label">
                  The interview will be
                </div>
                <div className="blue-tabs-wrap">
                  <ul className="d-flex font-18-24-05 pb-0 mb-1 text-center">
                    <li
                      onClick={(e) => {
                        setTab(1);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 1 ? "active" : ""}`}
                    >
                      In-Person
                    </li>
                    <li
                      onClick={(e) => {
                        setTab(2);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 2 ? "active" : ""}`}
                    >
                      Online
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Interview Type</div>
                <select
                  className="form-select form-control"
                  name="interviewTypeId"
                  onChange={handleInputChange}
                  value={values.interviewTypeId}
                >
                  <option>None</option>
                  {interviewType?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.interviewTypeId) ? (
                  <p className="text-invalid">{errors.interviewTypeId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Time</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter interview time"
                  name="time"
                  type="time"
                  lable="Enter interview time"
                  onChange={handleInputChange}
                  value={values.time}

                />
                {Boolean(errors.time) ? (
                  <p className="text-invalid">{errors.time}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Date</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter interview date"
                  name="date"
                  type="date"
                  lable="Enter interview date"
                  onChange={handleInputChange}
                  value={values.date}
                  min={new Date().toISOString().split("T")[0]}

                />
                {Boolean(errors.date) ? (
                  <p className="text-invalid">{errors.date}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                {acctiveTab === 2 ? (
                  <div className="form-label">Interview Link</div>
                ) : (
                  <div className="form-label">Interview Location</div>
                )}
                <Controls.Input
                  className="form-control"
                  placeholder={
                    acctiveTab === 2
                      ? "Enter interview link"
                      : "Enter interview location"
                  }
                  name="location"
                  type="text"
                  lable="Enter interview location"
                  onChange={handleInputChange}
                  value={values.location}
                />
                {Boolean(errors.location) ? (
                  <p className="text-invalid">{errors.location}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Send
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="md"
        show={InterviewReject}
        onHide={() => {
          setInterviewReject(false);
          resetForm();
        }}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-18-24-05 mb-1 fw-semibold mb-2">
                Do you want to reject this candidate?
              </div>
              <div className="font-16-24-05">
                If yes, Please provide the feedback
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <textarea
                className="form-control h-auto"
                placeholder="Eg. I didn't like this candidate because..."
                rows="4"
                name="employerReason"
                type="text"
                value={values?.employerReason}
                onChange={handleInputChange}
              ></textarea>
              {Boolean(errors.employerReason) ? (
                <p className="text-invalid">{errors.employerReason}</p>
              ) : (
                ""
              )}
              {/* <div className="opacity-75">Minimum 100 characters</div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
          <Button
            variant="outline-primary"
            className="h-44 fw-medium mx-4"
            onClick={handleRejectcandidate}
          >
            Yes, Reject
          </Button>
          <Button
            variant="primary"
            className="h-44 fw-medium m-0"
            onClick={() => setInterviewReject(false)}
          >
            No, Keep candidate
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={CandidateInformation}
        onHide={() => setCandidateInformation(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 mb-1 pb-0">
                Candidate Informations
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5">
              <div className="interviewer-profile_1 mx-auto">
                <img src={candidateData?.fresherId?.profilePhoto} height='100px' />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Name</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.firstName} {candidateData?.fresherId?.lastName}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Email</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.email}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Phone Number</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.countryCode}
                {candidateData?.fresherId?.mobile}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Resume</div>
              <div className="font-16-24-05 mb-2 d-inline-block">
                <a
                  href="#"
                  className="d-flex btn btn-link"
                  onClick={handleDownload}
                >
                  <div className="card-head-action-icon pe-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_871_17631)">
                        <path
                          d="M8 17L12 21L16 17"
                          stroke="#74777E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 12V21"
                          stroke="#74777E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.8799 18.0901C21.7493 17.4787 22.4013 16.6062 22.7412 15.5992C23.0811 14.5922 23.0913 13.5031 22.7703 12.4899C22.4493 11.4767 21.8138 10.5921 20.956 9.96462C20.0982 9.33709 19.0628 8.99922 17.9999 9.00006H16.7399C16.4392 7.82793 15.8764 6.7393 15.0941 5.81614C14.3117 4.89297 13.3301 4.15932 12.2231 3.67041C11.1162 3.1815 9.91278 2.95008 8.70346 2.99357C7.49414 3.03706 6.31045 3.35433 5.24149 3.92148C4.17253 4.48864 3.24616 5.2909 2.53212 6.26788C1.81808 7.24487 1.33498 8.3711 1.11919 9.5618C0.903403 10.7525 0.960543 11.9767 1.28631 13.1421C1.61208 14.3075 2.19799 15.3838 2.99993 16.2901"
                          stroke="#74777E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_871_17631">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>{" "}
                  <Tooltip
                    title={candidateData?.resume?.fileLocation}
                  ></Tooltip>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">Education</div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">College</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.college}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Level of education</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.educationLevel}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Field of study</div>
              <div className="font-16-24-05 mb-2">
                {candidateData?.fresherId?.studyField}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Start Date</div>
              <div className="font-16-24-05 mb-2">
                {moment(candidateData?.fresherId?.eduStartDate).format(
                  "DD-MM-YYYY"
                )}
              </div>
            </div>

            <div className="col-6 col-md-4 col-xl mb-3">
              <div class="fw-medium font-18-24-05 mb-1">End Date</div>

              {candidateData?.fresherId?.studyRunning == 0 ? (
                <>

                  <div class="font-16-24-05 mb-2">
                    {moment(candidateData?.fresherId?.eduEndDate).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </>
              ) : (
                <div className="font-16-24-05 mb-2">
                  <div class=" font-16-24-05 mb-1">Still enrolled</div>
                </div>
              )}
            </div>

          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">
                Candidate's Skills
              </div>
            </div>
            <div className="mt-0 mb-3">
              <ul>
                {candidateData?.fresherSkill?.map((items) => {
                  return (
                    <>
                      {(
                        <li className="d-inline-block">
                          <div
                            className={
                              candidateData &&
                                candidateData.matchSkill &&
                                candidateData?.matchSkill.filter(
                                  (item) => item == items.skillId
                                ).length > 0
                                ? "checkbox-round checkbox-check checkbox-round-md"
                                : "checkbox-round checkbox-round-md"
                            }
                          >
                            <label className="custom-checkbox">
                              {items?.fskilltitle}
                              <Controls.Input
                                type="checkbox"
                                checked={
                                  candidateData &&
                                    candidateData.matchSkill &&
                                    candidateData?.matchSkill.filter(
                                      (item) => item == items.skillId
                                    ).length > 0
                                    ? true
                                    : false
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">Candidate Score</div>
            </div>
            <div className="col-12 px-lg-5">
              <div className="row">
                {candidateData && candidateData?.userexamdata && candidateData?.userexamdata?.map((items) => {
                  return (
                    items.isPass == 1 && (
                      <div className="col-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-3">
                        <div className="level-card rounded-3 text-center p-3">
                          <div className="font-18-24-05 mb-1 fw-medium mb-1">
                            {items?.examType == 1 ? 'Soft Skill Assessment' : items?.examType == 2 ? 'Technical Assessment' : 'Coding Assessment'}
                          </div>
                          <div className="font-18-24-05 mb-1">{items?.skilltitle != "" ? items?.skilltitle : items?.examType == 3 ? 'Coding Challenge' : ''}</div>
                          <div className="d-flex justify-content-center align-items-center py-3">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="#FEC348"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                                stroke="#FEC348"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="ps-1 pt-1 font-16-24-05">{
                              items?.percentage > 90 ? '5/5' : items?.percentage > 74 ? '4.5/5' : items?.percentage > 49 ? '4/5' : items?.percentage > 34 ? '3.5/5' : items?.percentage > 24 ? '3/5' : '0/5'
                            } </span>
                          </div>
                          <div className="font-18-24-05 mb-1 opacity-75 mb-1">
                            {items?.totalTime} minutes
                          </div>
                        </div>
                      </div>
                    )

                  )
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={InterviewHire}
        onHide={() => setInterviewHire(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-18-24-05 mb-1 fw-semibold mb-2">
                Do you want to Hire this candidate?
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
          <Button
            variant="outline-primary"
            className="h-44 fw-medium mx-4"
            onClick={handleHirecandidate}
          >
            Yes
          </Button>
          <Button
            variant="primary"
            className="h-44 fw-medium m-0"
            onClick={() => setInterviewHire(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};
export default Candidates;
