import React, { useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import "./preferencesexpectations.scss";
import AutohideExample from "../../utills/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../utills/useForms";
import {
  getCityLists,
  getCountryLists,
  getJobPreferLists,
  getJobTypesLists,
  getStateLists,
} from "../../state/action/fresherAction";
import { addFresherDataRedux } from "../../state/action/authAction";

const initialValues = {
  engLevel: 2,
  lookingJobs: "",
  jobTypeId: "",
  jobPraferId: "",
  country1: 101,
  city1: "",
  state1: "",
  country2: 101,
  city2: "",
  state2: "",
  country3: 101,
  city3: "",
  state3: "",
  basic: 2,
  lookingFor: 2,
};

const PreferencesExpectations = (props) => {

  const dispatch = useDispatch();
  const { activeTab, setActiveTab } = props;
  const [acctiveTab, setAcctiveTab] = useState(2);
  const [acctiveJobTab, setAcctiveJobTab] = useState(2);
  const [jobList, setJobList] = useState([]);
  const [jobPreferList, setJobPreferList] = useState([]);
  const [countryList0, setCountryList0] = useState([]);
  const [cityList0, setCityList0] = useState([]);
  const [stateList0, setStateList0] = useState([]);
  const [prefferLocations, setPrefferLocations] = useState([]);
  const [countryList1, setCountryList1] = useState([]);
  const [cityList1, setCityList1] = useState([]);
  const [stateList1, setStateList1] = useState([]);
  const [countryList2, setCountryList2] = useState([]);
  const [cityList2, setCityList2] = useState([]);
  const [stateList2, setStateList2] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const setTab = (tab) => {
    setAcctiveTab(tab);
    setErrorMessage("");
  };

  const getPrefferLocation = (e, i) => {
    setPrefferLocations((prevState) => {
      if (!Array.isArray(prevState)) {
        // Handle the case where prevState is not an array
        return [];
      }
      const newState = [...prevState];
      if (i >= newState.length) {
        // Handle the case where i is out of bounds
        newState.push({
          [e.target.name]: e.target.value,
        });
      } else {
        newState[i] = {
          ...newState[i],
          [e.target.name]: e.target.value,
        };
      }
      return newState;
    });
  };

  const setActiveJobTab = (tab) => {
    setAcctiveJobTab(tab);
    setErrorMessages("");
  };

  const getLocationValidation = () => {
    if (
      values.country1 == "" &&
      values.country2 == "" &&
      values.country3 == ""
    ) {
      setShow({
        isOpen: true,
        message: "Please select atleast one location",
        type: "error",
      });
      return false;
    }

    if (values.country1 != "") {
      if (values.state1 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      } else if (values.city1 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }

    if (values.country2 != "") {
      if (values.state2 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      }
      if (values.city2 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }

    if (values.country3 != "") {
      if (values.state3 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      }
      if (values.city3 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }
    return true;
  };

  //country Data
  const handleCountryLocation0 = (value) => {
    setValues({
      ...values,
      country1: value,
      state1: "",
      city1: "",
    });
    handleStateList0(value);
    setCityList0([]);
  };

  const handleStateLocation0 = (e) => {
    setValues({
      ...values,
      state1: e,
      city1: "",
    });
    handleCityList0(e);
  };

  const getContryData0 = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList0(res.data);
    } else {
      setCountryList0([]);
    }
  };
  //city Data

  const handleCityList0 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList0(res.data);
    } else {
      setCityList0([]);
    }
  };

  //state Data
  const handleStateList0 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList0(res.data);
    } else {
      setStateList0([]);
    }
  };

  //country 1Data
  const handleCountryLocation1 = (e) => {
    setValues({
      ...values,
      country2: e,
      state2: "",
      city2: "",
    });

    handleStateList1(e);
    setCityList1([]);
  };

  const handleStateLocation1 = (e) => {
    setValues({
      ...values,
      state2: e,
      city2: "",
    });
    handleCityList1(e);
    setCityList1([]);
  };

  const getContryData1 = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList1(res.data);
    } else {
      setCountryList1([]);
    }
  };

  //city1 Data
  const handleCityList1 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList1(res.data);
    } else {
      setCityList1([]);
    }
  };

  //state1 Data
  const handleStateList1 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList1(res.data);
    } else {
      setStateList1([]);
    }
  };

  useEffect(() => {
    getContryData0();
    getContryData1();
    getContryData2();
    if (!datas?.prefferedLocation) {
      handleStateList0(101);
      handleStateList1(101);
      handleStateList2(101);

      getPrefferLocation({
        target: {
          name: "country1",
          value: 101,
        }
      }
        , 0);
      getPrefferLocation({
        target: {
          name: "country2",
          value: 101,
        }
      }, 1);
      getPrefferLocation({
        target: {
          name: "country3",
          value: 101,
        }
      }, 2);
    }
  }, []);

  //country 1Data
  const handleCountryLocation2 = (e) => {
    setValues({
      ...values,
      country3: e,
      state3: "",
      city3: "",
    });
    handleStateList2(e);
    setCityList2([]);
  };

  const handleStateLocation2 = (e) => {
    setValues({
      ...values,
      state3: e,
      city3: "",
    });
    handleCityList2(e);
  };

  const getContryData2 = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList2(res.data);
    } else {
      setCountryList2([]);
    }
  };

  //city1 Data
  const handleCityList2 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList2(res.data);
    } else {
      setCityList2([]);
    }
  };

  //state1 Data
  const handleStateList2 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList2(res.data);
    } else {
      setStateList2([]);
    }
  };


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("jobTypeId" in fieldValues)
      temp.jobTypeId = fieldValues.jobTypeId && fieldValues.jobTypeId != null
        ? "" : "This field is required.";

    if ("jobPraferId" in fieldValues)
      temp.jobPraferId = fieldValues.jobPraferId && fieldValues.jobPraferId != null
        ? ""
        : "This field is required.";

    if ("prefferedLocation.length" in fieldValues)
      temp.prefferedLocation.length = fieldValues.prefferedLocation.length
        ? ""
        : "This field is required.";

    if ("basic" in fieldValues) {
      temp.basic = fieldValues.basic && fieldValues.basic != null
        ? "" : "This field is required.";
    }
    if ("lookingFor" in fieldValues) {
      temp.lookingFor = fieldValues.lookingFor && fieldValues.lookingFor != null
        ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const datas = useSelector((state) => state?.auth?.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && getLocationValidation()) {
      if (activeTab === 3) {
        setErrorMessage("Please select at least one skill.");
      }
      const body = {
        ...datas,
        engLevel: acctiveTab,
        lookingJobs: acctiveJobTab,
        jobTypeId: values.jobTypeId,
        jobPraferId: values.jobPraferId,
        prefferedLocation: JSON.stringify(prefferLocations),
      };
      const data = await dispatch(addFresherDataRedux(body));
      setActiveTab(4);
      setErrorMessage("Please fill in all the required fields.");
      setErrorMessages("Please fill in all the required fields."); // Display the error message if validation fails
    } else {
      setErrorMessage("");
      setErrorMessages(""); // Display the error message if validation fails
    }
  };

  useEffect(() => {
    if (datas) {
      if (datas?.prefferedLocation) {
        const location = JSON.parse(datas?.prefferedLocation);

        handleStateList0(location[0]?.country1);
        handleStateList1(location[1]?.country2);
        handleStateList2(location[2]?.country3);

        getPrefferLocation({
          target: {
            name: "country1",
            value: location[0]?.country1,
          }
        }
          , 0);
        getPrefferLocation({
          target: {
            name: "country2",
            value: location[1]?.country2,
          }
        }, 1);
        getPrefferLocation({
          target: {
            name: "country3",
            value: location[2]?.country3,
          }
        }, 2);
        setValues({
          ...datas,
          basic: datas?.engLevel ? datas?.engLevel : "",
          lookingFor: datas?.lookingJobs ? datas?.lookingJobs : "",
          jobTypeId: datas?.jobTypeId ? datas?.jobTypeId : "",
          jobPraferId: datas?.jobPraferId ? datas?.jobPraferId : "",
          country1: location[0]?.country1 ? location[0]?.country1 : "",
          state1: location[0]?.state1 ? location[0]?.state1 : "",
          city1: location[0]?.city1 ? location[0]?.city1 : "",
          country2: location[1]?.country2 ? location[1]?.country2 : "",
          state2: location[1]?.state2 ? location[1]?.state2 : "",
          city2: location[1]?.city2 ? location[1]?.city2 : "",
          country3: location[2]?.country3 ? location[2]?.country3 : "",
          state3: location[2]?.state3 ? location[2]?.state3 : "",
          city3: location[2]?.city3 ? location[2]?.city3 : "",
        });
        setAcctiveJobTab(datas?.lookingJobs)
        setAcctiveTab(datas?.engLevel)
        if (datas?.prefferedLocation) {
          setPrefferLocations(JSON.parse(datas?.prefferedLocation));
        }
        if (location[0]?.country1) {
          handleCityList0(location[0]?.state1);
        }
        if (location[1]?.country2) {
          handleCityList1(location[1]?.state2);
        }
        if (location[2]?.country3) {
          handleCityList2(location[2]?.state3);
        }
      }
    }
  }, [datas]);

  const updateData = (newData) => {
  };

  const addData = (newData) => {
    updateData(newData);
  };

  //JobType Data
  const getJobTypeData = async (e) => {
    const res = await getJobTypesLists();
    if (res && res.data) {
      setJobList(res.data);
    }
  };

  useEffect(() => {
    getJobTypeData();
  }, []);

  //JobType Prefer Data
  const getJobPreferData = async (e) => {
    const res = await getJobPreferLists();
    if (res && res.data) {
      setJobPreferList(res.data);
    }
  };

  useEffect(() => {
    getJobPreferData();
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  return (
    <main>
      <div className="row">
        <div className="col-lg-12 align-self-center">
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div className="row">
                <div className="form-label">English proficiency</div>
                <div className="blue-tabs-wrap">
                  <ul className="d-flex font-18-24-05 text-center pb_0">
                    <li
                      onClick={(e) => {
                        e.target = {
                          value: "basic",
                          name: "basic",
                        };
                        setTab(1);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 1 ? "active" : ""}`}
                      value={values.engLevel}
                    >
                      Basic
                    </li>
                    <li
                      onClick={(e) => {
                        setTab(2);
                        e.target = {
                          value: "Average",
                          name: "basic",
                        };

                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 2 ? "active" : ""}`}
                      value={values.engLevel}
                    >
                      Average
                    </li>
                    <li
                      onClick={(e) => {
                        e.target = {
                          value: "Great",
                          name: "basic",
                        };
                        handleInputChange(e);
                        setTab(3);
                      }}
                      className={`w-100 ${acctiveTab === 3 ? "active" : ""}`}
                      value={values.engLevel}
                    >
                      Great
                    </li>
                  </ul>
                
                  {errors?.basic ? (
                    <p className="text-invalid">{errors.basic}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div className="form-label">
                Are you actively looking for a job?
              </div>
              <div className="blue-tabs-wrap">
                <ul className="d-flex font-18-24-05 text-center">
                  <li
                    onClick={(e) => {
                      e.target = {
                        value: "lookingFor",
                        name: "lookingFor",
                      };

                      setActiveJobTab(1);
                      handleInputChange(e);
                    }}
                    className={`w-100 ${acctiveJobTab === 1 ? "active" : ""}`}
                    value={values.lookingFor}
                  >
                    Yes
                  </li>
                  <li
                    onClick={(e) => {
                      e.target = {
                        value: "lookingFor",
                        name: "lookingFor",
                      };

                      setActiveJobTab(2);
                      handleInputChange(e);
                    }}
                    className={`w-100 ${acctiveJobTab === 2 ? "active" : ""}`}
                    value={values.lookingFor}
                  >
                    Exploring
                  </li>
                  <li
                    onClick={(e) => {
                      e.target = {
                        value: "lookingFor",
                        name: "lookingFor",
                      };
                      setActiveJobTab(3);
                      handleInputChange(e);
                    }}
                    className={`w-100 ${acctiveJobTab === 3 ? "active" : ""}`}
                    value={values.lookingFor}
                  >
                    No
                  </li>
                </ul>
                {errors.lookingFor ? (
                  <p className="text-invalid">{errors.lookingFor}</p>
                ) : ""}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="form-label">What type of jobs do you prefer?</div>
            </div>
            <div className="col-12 col-md-6 mb-16">
              <select
                className="form-select"
                name="jobTypeId"
                onChange={handleInputChange}
                value={values.jobTypeId}
              >
                <option value="">None</option>
                {jobList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              {errors.jobTypeId ? (
                <p className="text-invalid">{errors.jobTypeId}</p>
              ) : (
                <p
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.jobTypeId}
                </p>
              )}
            </div>
            <div className="col-12 col-md-6 mb-16">
              <select
                className="form-select"
                name="jobPraferId"
                onChange={handleInputChange}
                value={values.jobPraferId}
              >
                <option value="">none</option>
                {jobPreferList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              {errors.jobPraferId ? (
                <p className="text-invalid">{errors.jobPraferId}</p>
              ) : (
                <p
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.jobPraferId}
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="form-label">Preferred location</div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-4 pe-md-0 mb-2">
                  {width <= 767 ?
                    <div className="form-label">Country</div>
                    : ''}
                  <select
                    className="form-select"
                    name="country1"
                    onChange={(e) => {
                      getPrefferLocation(e, 0);
                      handleCountryLocation0(e.target.value);
                    }}
                    value={values.country1}
                  >
                    <option value="">country</option>
                    {countryList0.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.country1 ? (
                    <p className="text-invalid">{errors.country1}</p>
                  ) : (
                    <p
                      style={{
                        color: "red",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.country1}
                    </p>
                  )}
                </div>
                <div className="col-12 col-md-4 mb-2">
                  {width <= 767 ?
                    <div className="form-label">State</div>
                    : ''}
                  <select
                    className="form-select"
                    name="state1"
                    onChange={(e) => {
                      getPrefferLocation(e, 0);
                      handleStateLocation0(e.target.value);
                      handleInputChange(e);
                    }}
                    value={values.state1}
                  >
                    <option value="">state</option>
                    {stateList0.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.state1 ? (
                    <p className="text-invalid">{errors.state1}</p>
                  ) : (
                    <p
                      style={{
                        color: "red",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.state1}
                    </p>
                  )}
                </div>
                <div className="col-12 col-md-4 ps-md-0 mb-2">
                  {width <= 767 ?
                    <div className="form-label">City</div>
                    : ''}
                  <select
                    className="form-select"
                    name="city1"
                    onChange={(e) => {
                      getPrefferLocation(e, 0);
                      handleInputChange(e);
                    }}
                    value={values.city1}
                  >
                    <option value="">city</option>
                    {cityList0.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.city1 ? (
                    <p className="text-invalid">{errors.city1}</p>
                  ) : (
                    <p
                      style={{
                        color: "red",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.city1}
                    </p>
                  )}
                </div>
              </div>
              {width <= 767 ?
                <hr />
                : ''}
              <div className="row">
                <div className="col-12 col-md-4 pe-md-0 mb-2">
                  {width <= 767 ?
                    <div className="form-label">Country</div>
                    : ''}
                  <select
                    className="form-select"
                    name="country2"
                    onChange={(e) => {
                      getPrefferLocation(e, 1);
                      handleCountryLocation1(e.target.value);
                    }}
                    value={values.country2}
                  >
                    <option value="">country</option>
                    {countryList1.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  {width <= 767 ?
                    <div className="form-label">State</div>
                    : ''}
                  <select
                    className="form-select"
                    name="state2"
                    onChange={(e) => {
                      getPrefferLocation(e, 1);
                      handleStateLocation1(e.target.value);
                    }}
                    value={values.state2}
                  >
                    <option value="">state</option>
                    {stateList1.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-4 ps-md-0 mb-2">
                  {width <= 767 ?
                    <div className="form-label">City</div>
                    : ''}
                  <select
                    className="form-select"
                    name="city2"
                    onChange={(e) => {
                      getPrefferLocation(e, 1);
                      handleInputChange(e);
                    }}
                    value={values.city2}
                  >
                    <option value="">city</option>
                    {cityList1.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {width <= 767 ?
                <hr />
                : ''}
              <div className="row">
                <div className="col-12 col-md-4 pe-md-0 mb-2">
                  {width <= 767 ?
                    <div className="form-label">Country</div>
                    : ''}
                  <select
                    className="form-select"
                    name="country3"
                    onChange={(e) => {
                      getPrefferLocation(e, 2);
                      handleCountryLocation2(e.target.value);
                    }}
                    value={values.country3}
                  >
                    <option value="">Country</option>
                    {countryList2.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  {width <= 767 ?
                    <div className="form-label">State</div>
                    : ''}
                  <select
                    className="form-select"
                    name="state3"
                    onChange={(e) => {
                      getPrefferLocation(e, 2);
                      handleStateLocation2(e.target.value);
                    }}
                    value={values.state3}
                  >
                    <option value="">state</option>
                    {stateList2.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-4 ps-md-0 mb-2">
                  {width <= 767 ?
                    <div className="form-label">City</div>
                    : ''}
                  <select
                    className="form-select"
                    name="city3"
                    onChange={(e) => {
                      getPrefferLocation(e, 2);
                      handleInputChange(e);
                    }}
                    value={values.city3}
                  >
                    <option value="">city</option>
                    {cityList2 &&
                      cityList2.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-16 mt-16">
              <Controls.Button
                className="btn btn-primary h-54 w-100"
                text="Next"
                onClick={(e) => handleSubmit(e)}
              />
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16 text-center">
                <p
                  href=""
                  className="w-100 h-54 font-16 btn btn-link text-decoration-none"
                  onClick={() => {
                    setActiveTab(4);
                    const newData = "New data";
                    addData(newData);
                  }}
                >
                  Skip this Step
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AutohideExample show={show} setShow={setShow} />
    </main>
  );
};

export default PreferencesExpectations;