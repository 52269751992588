
import { Row } from "react-bootstrap";
import axiosInstance from "../../utills/AxiosInstance";




//assessment list
export const getAssesmentList = async (queryString) => {
  try {
    const response = await axiosInstance.get('/v1/questionList' + queryString)

    return response.data;
  } catch (error) {
    return error;
  }
};

//details
export const getAssesmentDetails = async (id) => {
  try {
    const response = await axiosInstance.get(`/v1/questionById/${id}`)


    return response.data;
  } catch (error) {
    return error;
  }
};


//Assessment Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.post(`/v1/questionStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
//add Assesment
export const AddAssesmentData = async (payload) => {
  try {
    const response = await axiosInstance.post(`/v1/addQuestion`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update Assesment
export const updateAssesment = async (id, payload) => {
  try {
    const response = await axiosInstance.put(`/v1/updateQuestion/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};


// delete Assessment 

export const deleteAssesment = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteQuestion/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAssesmentDetailsWithReport = async (id, selectQuery) => {
  try {
    const response = await axiosInstance.get(`/v1/questionByIdWithReport/${id}${selectQuery} `)
    return response.data;
  } catch (error) {
    return error;
  }
};