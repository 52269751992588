import React, { useState } from "react";
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import GoogleButtonLogo from "../../assets/images/google-logo.svg";
import SliderImage1 from "../../assets/images/hired.svg";
import SliderImage2 from "../../assets/images/office_management.svg";
import SliderImage3 from "../../assets/images/devices.svg";
import ArrowLeft from "../../assets/images/arrow_left.svg";
import Controls from "../../components/controls/Controls";
import Slider from "react-slick";
import Cookies from "universal-cookie";

import { registerResendOtp } from "../../state/action/employerForgotPasswordAction";


import {
  Redirect,
  useNavigate,
  Link,
  useLocation,
  Navigate,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useForm, Form } from "../../utills/useForms";
import AutohideExample from "../../utills/Notification";
import { EmployeeEmailverifyApi } from "../../state/action/authAction";

const initialValues = { otp: "" };
const EmployerVerifyEmail = (props) => {
  const { state } = useLocation();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();

  const cookies = new Cookies();
  const isAuthenticated = useSelector((store) => store?.auth?.isAuthenticated);
  const [loadingButton, setLoadingButton] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [counter, setCounter] = useState(10)
  const decrementCounter = () => {
    setCounter((prevCounter) => prevCounter - 1);
  };

  const Id = useSelector((store) => store);
  const handleResendOTP = async () => {

    if (counter === 0) {

      setCounter(10);

      try {
        const response = await registerResendOtp(email);

        if (response.status === true) {
          setShow({
            isOpen: true,
            message: response.message,
            type: "success",
          });
        } else {
          setShow({
            isOpen: true,
            message: response.message || "Failed to resend OTP.",
            type: "error",
          });
        }
      } catch (error) {
        setShow({
          isOpen: true,
          message: "Technical error. Please try again later.",
          type: "error",
        });
      }
    }
  };
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [email, setEmail] = useState("");
  const dispatch = useDispatch();


  // if (isAuthenticated) {
  //   navigate("/");
  // }

  useEffect(() => {
    if (state) {
      setEmail(state?.email);
    }

  }, [state]);

  useEffect(() => {
    if (state) {
      setEmail(state?.email);
    }
    if (counter > 0) {
      const intervalId = setInterval(decrementCounter, 1000);
      return () => clearInterval(intervalId);
    }

  }, [state, counter]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("otp" in fieldValues) {
      if (fieldValues.otp !== "" && fieldValues.otp !== undefined) {
        if (!/^[0-9]*$/.test(fieldValues.otp)) {
          temp.otp = "Only Numbers Are Allowed";
        } else if (fieldValues.otp.length !== 6) {
          temp.otp = "OTP must be exactly 6 characters long.";
        } else {
          temp.otp = "";
        }
      } else {
        temp.otp = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handelSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      otp: values.otp,
    };
    if (validate()) {
      let res = await dispatch(EmployeeEmailverifyApi(payload, 1));

      if (res && res.status === true) {
        setLoadingButton(!loadingButton);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });

        setTimeout(() => {
          navigate("/employerpersonalinformation", {
            state: res?.employer,
          });
        }, 2000);
      } else {
        setLoadingButton(false);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };


  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);


  return (
    <main>
      <div className="container">
        <header className="row header-logo-wrap justify-content-between header-row-wrap">
          <div className="col-auto">
            <a href="" className="d-inline-block">
              {/* <img
                src={TechfreshrLogo}
                className="img-fluid header-logo-width"
              /> */}
              <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

            </a>
          </div>
        </header>
      </div>
      <div className="container">
        <div className="row login-height-calc align-items-center">
          <div
            className="d-none d-lg-block col-lg-6 align-self-center" style={{
              height: '630px', width: '43%',
              margin: 'auto'
            }}
          >
            <div className="cyan-gradiant">
              <div className="login-left-slider">
                <Slider {...settings}>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage1}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Large Network
                        </div>
                        <div className="slider-content-text">
                          Gain access to a vast network of recruiters <br /> and
                          top employers.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage2}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Best-fit Job
                        </div>
                        <div className="slider-content-text">
                          Find your dream job with our personalized <br />{" "}
                          matching system.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage3}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Achieve your Goals
                        </div>
                        <div className="slider-content-text">
                          Start building your dream career and soar to <br />{" "}
                          new heights with our exciting job <br />{" "}
                          opportunities.
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="login-box-width width_40">
              <form onSubmit={handelSubmit} autoComplete="off">
                <div className="login-box-width">
                  <h1 className="login-top-head mb-2 font-PTSerif-b">
                    Verify your email
                  </h1>
                  <h2 className="login-top-subtext mb-32">
                    We sent you a verification code to{" "}
                    <span className="text-primary-emphasis">{email}</span>. Kindly
                    enter the verification code to continue.
                  </h2>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-16">
                      <Controls.Input
                        className="form-control"
                        placeholder="Enter verification code"
                        name="otp"
                        type="text"
                        lable="enter verification code"
                        value={values.otp}
                        onChange={handleInputChange}
                        maxLength="6"
                      />
                      {Boolean(errors.otp) ? (
                        <p className="text-invalid">{errors.otp}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-end">
                    <div className="resend_otp">
                      <Controls.Button
                        style={{ color: '#143f87' }}
                        className="bg-transparent border-0 h-45 w-35 mb-2"
                        text={`Resend OTP  ${counter === 0 ? '' : counter}`}
                        type="submit"
                        onClick={handleResendOTP}
                      />
                    </div>
                  </div>


                  <div className="mb-32 mt-16">
                    <Controls.Button
                      className="btn btn-primary h-54 w-100"
                      text="Verify and Continue"
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <AutohideExample show={show} setShow={setShow} />
    </main>
  );
};

export default EmployerVerifyEmail;
