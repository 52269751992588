import React, { useState } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Multiselect from "multiselect-react-dropdown";
import InterViewerProfile from "../../assets/images/tech_fresher_fav_logo.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet'
import Camera1 from "../../components/CameraPermission/Camera1";
import {
  FresherJobUpdate,
  JobIntrested,
  getCityLists,
  getCountryLists,
  getFresherPerferenceLists,
  getIgnoreJob,
  getJobPreferLists,
  getJobTypesLists,
  getRejectJob,
  getSeeInterviewJob,
  getStateLists,
} from "../../state/action/fresherAction";
import moment from "moment";
import AutohideExample from "../../utills/Notification";
import { Form, useForm } from "../../utills/useForms";
import { SkillList } from "../../state/action/skillAction";
import CloseIcon from "../../assets/images/close_circle.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./progress.css";
import { BsCalendarEvent } from "react-icons/bs";
import CameraComponent from "../../components/CameraPermission/CameraPermission";
import { async } from "videojs-record";

const initialValues = {
  fresherReason: "",
  jobPraferId: "",
  jobTypeId: "",
  prefferLocations: "",
  country1: "",
  country2: "",
  country3: "",
  state1: "",
  state2: "",
  state3: "",
  city1: "",
  city2: "",
  city3: "",
  fresherSkill: "",
  fileLocation: "",
};


const Dashboard = () => {
  const [IgnoreJob, setIgnoreJob] = useState(false);
  const [SeeMoreForJob, setSeeMoreForJob] = useState(false);
  const [InterestedForJob, setInterestedForJob] = useState(false);
  const [InterestedForJobEmployer, setInterestedForJobEmployer] =
    useState(false);
  const [itemList, setItemList] = useState([]);
  const [interviewList, setInterviewList] = useState([]);
  const [interviewId, setInterviewId] = useState(null);
  const [jobpostSeeId, setJobPostSeeId] = useState(null);
  const [interviewIdEmployer, setInterviewIdEmployer] = useState(null);
  const [jobPostId, setJobPostId] = useState(null);
  const [userSkillList, setUserSkillList] = useState([]);
  const [seeJobList, setSeeJobList] = useState([]);
  const [whyJoinList, setwhyJoinList] = useState([]);
  const [FaqList, setFaqList] = useState([]);
  const [prefferLocations, setPrefferLocations] = useState([]);
  const [JobPreference, setJobPreference] = useState(false);
  const [countryList1, setCountryList1] = useState([]);
  const [cityList1, setCityList1] = useState([]);
  const [stateList1, setStateList1] = useState([]);
  const [countryList2, setCountryList2] = useState([]);
  const [cityList2, setCityList2] = useState([]);
  const [stateList2, setStateList2] = useState([]);
  const [countryList0, setCountryList0] = useState([]);
  const [cityList0, setCityList0] = useState([]);
  const [stateList0, setStateList0] = useState([]);
  const [interviewAssign, setInterviewAssign] = useState(0);
  const [jobList, setJobList] = useState([]);
  const [jobPreferList, setJobPreferList] = useState([]);
  const [aboutList, setJobAboutList] = useState([]);
  const [selectSkill, setSelectSkill] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [testSkillList, setTestSkillList] = useState([]);
  const [deleteSkill, setDeleteSkill] = useState([]);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [acctiveTab, setAcctiveTab] = useState(1);
  const [SoftSkillAssessment, setSoftSkillAssessment] = useState(false);
  const [Permissions, setPermissions] = useState(false);
  const [skillIds, setSkillIds] = useState(0);
  const [isSoftSkill, setisSoftSkill] = useState(false);
  const [studyRunning, setStudyRunning] = useState(false); // Set initial state to false
  const [hasCameraPermission, setHasCameraPermission] = useState(true);
  const [profileComplete, setProfileComplete] = useState(0);
  const [skillNames, setSkillNames] = useState("");
  const [skillDescription, setSkillDescription] = useState("");
  const [softSkillData, setSoftSKillData] = useState([]);
  const [cameraPermission, setCameraPermission] = useState(false);
  const [cpermissions, setCpermissions] = useState(false);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const customStyles = buildStyles({
    pathColor: '#143f87', // Change the color of the progress bar
  });

  const multiselectSelectSkill = (data, e) => {
    setSelectSkill([...selectSkill, {
      skillId: e.skillId,
      fresherId: fresherId?.user?.id,
      skillName: e.skillName,
    }]);
    setSkillData(skillData?.filter((item) => { return !data.some((item2) => { return item.skillId === item2.skillId }) }));
  };


  const getPermission = async () => {

    try {
      // Request permission to use the camera
      await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraPermission(false);
      navigate("/mcqassessment", {
        state: {
          isSoftSkill: isSoftSkill,
          skillId: skillIds,
          skillName: skillNames,
        }
      }
      );
    } catch (error) {
      setShow({
        isOpen: true,
        message: "Please allow camera permission",
        type: "error",
      });
    }
  };

  // const getPermission = async () => {



  //   if (hasCameraPermission == false) {

  //     setShow({
  //       isOpen: true,
  //       message: "Please allow camera permission",
  //       type: "error",
  //     });
  //     return false;
  //   } else {

  //   }
  // };
  const removeSkill = async (e, item) => {
    setSkillData([...skillData, item]);
    const removeSkill = selectSkill.filter(
      (items) => items.skillId != item.skillId
    );
    setSelectSkill(removeSkill);
    if (item && item.id) {
      setDeleteSkill([...deleteSkill, item?.id]);
    }
  };

  const setTab = (tab) => {
    setAcctiveTab(tab);
  };

  const calculateDays = (date) => {
    const start = new Date();
    const end = new Date(date);
    const timeDiff = Math.abs(end - start);
    const daysCount = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysCount;
  };

  const [activeAccordion, setActiveAccordion] = useState("");


  const handleAccordionToggle = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  const totalItems = 6;
  const completedItems = [
    itemList?.isHired,
    itemList?.isPassCodeing,
    itemList?.isPassTechTest,
    itemList?.isProfileComplate,
    itemList?.isSoftSkill,
    itemList?.ternToMatch, // Note the corrected spelling
  ].reduce((count, item) => count + (item === 1 ? 1 : 0), 0);


  const completionPercentage = (completedItems / totalItems) * 100;


  //skilllist

  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      const skills = res.data.map((item) => {
        return { skillId: item.id, skillName: item.title };
      });
      setSkillData(skills.filter((item) => { return !selectSkill.some((item2) => { return item.skillId === item2.skillId }) }));
    }
  };

  //country Data
  const handleCountryLocation0 = (e) => {
    setValues({
      ...values,
      country1: e.target.value,
      state1: "",
      city1: "",
    });
    handleStateList0(e.target.value);
    setCityList0([]);
  };

  const handleStateLocation0 = (e) => {
    setValues({
      ...values,
      state1: e.target.value,
      city1: "",
    });
    handleCityList0(e.target.value);
  };

  const getContryData0 = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList0(res.data);
      setCityList0([]);
      setStateList0([]);
    } else {
      setCountryList0([]);
      setCityList0([]);
      setStateList0([]);
    }
  };
  //city Data

  const handleCityList0 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList0(res.data);
    } else {
      setCityList0([]);
    }
  };

  //state Data
  const handleStateList0 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList0(res.data);
    } else {
      setStateList0([]);
    }
  };

  //country 1Data
  const handleCountryLocation1 = (e) => {
    setValues({
      ...values,
      country2: e.target.value,
      state2: "",
      city2: "",
    });
    handleStateList1(e.target.value);
    setCityList1([]);
  };

  const handleStateLocation1 = (e) => {
    setValues({
      ...values,
      state2: e.target.value,
      city2: "",
    });
    handleCityList1(e.target.value);
  };

  const getContryData1 = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList1(res.data);
      setCityList1([]);
      setStateList1([]);
    } else {
      setCountryList1([]);
      setCityList1([]);
      setStateList1([]);
    }
  };
  //city1 Data

  const handleCityList1 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList1(res.data);
    } else {
      setCityList1([]);
    }
  };

  //state1 Data
  const handleStateList1 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList1(res.data);
    } else {
      setStateList1([]);
    }
  };

  //country 1Data
  const handleCountryLocation2 = (e) => {
    setValues({
      ...values,
      country3: e.target.value,
      state3: "",
      city3: "",
    });
    handleStateList2(e.target.value);
    setCityList2([]);
  };

  const handleStateLocation2 = (e) => {
    setValues({
      ...values,
      state3: e.target.value,
      city3: "",
    });
    handleCityList2(e.target.value);
  };

  const getContryData2 = async () => {
    const res = await getCountryLists();
    localStorage.setItem("keyPressCount", 0);

    if (res && res.data) {
      setCountryList2(res.data);
      setCityList2([]);
      setStateList2([]);
    } else {
      setCountryList2([]);
      setCityList2([]);
      setStateList2([]);
    }
  };
  //city1 Data

  const handleCityList2 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList2(res.data);
    } else {
      setCityList2([]);
    }
  };

  //state1 Data
  const handleStateList2 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList2(res.data);
    } else {
      setStateList2([]);
    }
  };

  const getPrefferLocation = (e, i) => {
    setPrefferLocations((prevState) => {
      if (!Array.isArray(prevState)) {
        // Handle the case where prevState is not an array
        return [];
      }
      const newState = [...prevState];
      if (i >= newState.length) {
        // Handle the case where i is out of bounds
        newState.push({
          [e.target.name]: e.target.value,
        });
      } else {
        newState[i] = {
          ...newState[i],
          [e.target.name]: e.target.value,
        };
      }
      return newState;
    });
  };

  useEffect(() => {
    getContryData0();
    getContryData1();
    getContryData2();

  }, []);

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  //JobType Data
  const getJobTypeData = async (e) => {
    const res = await getJobTypesLists();
    if (res && res.data) {
      setJobList(res.data);
    }
  };
  //JobType Prefer Data
  const getJobPreferData = async (e) => {
    const res = await getJobPreferLists();
    if (res && res.data) {
      setJobPreferList(res.data);
    }
  };

  const fresherId = useSelector((state) => state?.auth);


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const navigate = useNavigate();
  // if (!fresherId.isAuthenticated) {
  //   navigate("/login");
  // } else {
  // }


  const getitemList = async () => {
    const res = await getFresherPerferenceLists(fresherId?.user?.id);

    if (res) {
      setItemList(res?.data);
      setPrefferLocations([
        {
          id: res?.data?.jobLocation[0]?.id,
          fresherId: fresherId?.user?.id,
          country1: res?.data?.jobLocation[0]?.countryId,
          state1: res?.data?.jobLocation[0]?.stateId,
          city1: res?.data?.jobLocation[0]?.cityId,
          cityName: res?.data?.jobLocation[0]?.cityName,
          stateName: res?.data?.jobLocation[0]?.stateName,
          countryName: res?.data?.jobLocation[0]?.countryName,
        },
        {
          id: res?.data?.jobLocation[1]?.id,
          fresherId: fresherId?.user?.id,
          country2: res?.data?.jobLocation[1]?.countryId,
          state2: res?.data?.jobLocation[1]?.stateId,
          city2: res?.data?.jobLocation[1]?.cityId,
          cityName: res?.data?.jobLocation[1]?.cityName,
          stateName: res?.data?.jobLocation[1]?.stateName,
          countryName: res?.data?.jobLocation[1]?.countryName,
        },
        {
          id: res?.data?.jobLocation[2]?.id,
          fresherId: fresherId?.user?.id,
          country3: res?.data?.jobLocation[2]?.countryId,
          state3: res?.data?.jobLocation[2]?.stateId,
          city3: res?.data?.jobLocation[2]?.cityId,
          cityName: res?.data?.jobLocation[2]?.cityName,
          stateName: res?.data?.jobLocation[2]?.stateName,
          countryName: res?.data?.jobLocation[2]?.countryName,
        },
      ]);
      setInterviewList(res?.data?.interview);
      setUserSkillList(res?.data?.userSkills);
      setwhyJoinList(res?.data?.whyjoinus);
      setSoftSKillData(res?.data?.softSkill);
      setFaqList(res?.data?.faqs);
      setJobAboutList(res?.data?.aboutus);
      setTestSkillList(res?.data?.assessmentskills);
      setProfileComplete(res?.data?.profilePercentage);
      setValues({
        ...values,
        jobPraferId: res?.data?.jobPraferId,
        jobTypeId: res?.data?.jobTypeId,
        jobLocation: res?.data?.jobLocation,
        country1: res?.data?.jobLocation[0]?.countryId,
        state1: res?.data?.jobLocation[0]?.stateId,
        city1: res?.data?.jobLocation[0]?.cityId,
        country2: res?.data?.jobLocation[1]?.countryId,
        state2: res?.data?.jobLocation[1]?.stateId,
        city2: res?.data?.jobLocation[1]?.cityId,
        country3: res?.data?.jobLocation[2]?.countryId,
        state3: res?.data?.jobLocation[2]?.stateId,
        city3: res?.data?.jobLocation[2]?.cityId,
        fresherSkill: res?.data?.userSkills,
      });
      handleStateList0(res?.data?.jobLocation[0]?.countryId);
      handleCityList0(res?.data?.jobLocation[0]?.stateId);
      handleStateList1(res?.data?.jobLocation[1]?.countryId);
      handleCityList1(res?.data?.jobLocation[1]?.stateId);
      handleStateList2(res?.data?.jobLocation[2]?.countryId);
      handleCityList2(res?.data?.jobLocation[2]?.stateId);
      setSelectSkill(res?.data?.userSkills);
      setAcctiveTab(res?.data?.lookingJobs ? res?.data?.lookingJobs : 1);
    } else {
    }
  };
  const jobPreferValidation = () => {
    if (values.jobPraferId == "" || values.jobTypeId == "") {
      setShow({
        isOpen: true,
        message: "Please select job prefer",
        type: "error",
      });
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    getitemList(fresherId?.user?.id);
  }, [fresherId?.user?.id]);

  //ignore job
  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      fresherReason: values.fresherReason,
    };
    const res = await getIgnoreJob(interviewId, fresherId?.user?.id, body);

    if (res && res.status === true) {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setInterestedForJob(false);
      getitemList(fresherId?.user?.id);
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  //ignore job
  const handleSubmits = async (e) => {
    e.preventDefault();

    const body = {
      fresherReason: values.fresherReason,
    };
    const res = await getIgnoreJob(jobpostSeeId, fresherId?.user?.id, body);

    if (res && res.status === true) {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setInterestedForJobEmployer(false);
      getitemList(fresherId?.user?.id);
      setSeeMoreForJob(false);
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const getSeeInterviewList = async (id) => {
    const res = await getSeeInterviewJob(id, fresherId?.user?.id);
    if (res) {
      setSeeJobList(res?.data);
      setInterviewAssign(res?.interviewAssignDataIs);
    } else {
      setSeeJobList([]);
    }
  };

  const getLocationValidation = () => {

    if (
      values.country1 == "" &&
      values.country2 == "" &&
      values.country3 == ""
    ) {
      setShow({
        isOpen: true,
        message: "Please select atleast one location",
        type: "error",
      });
      return false;
    }

    if (values.country1 != "") {
      if (values.state1 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      } else if (values.city1 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }

    if (values.country2 != "") {
      if (values.state2 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      }
      if (values.city2 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }

    if (values.country3 != "") {
      if (values.state3 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      }
      if (values.city3 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }
    return true;
  };

  let properties = {};


  const handleSubmitJob = async (e) => {
    e.preventDefault();

    if (getLocationValidation() && jobPreferValidation()) {
      const payload = {
        location: prefferLocations,
        jobPraferId: values.jobPraferId,
        jobTypeId: values?.jobTypeId,
        skillId: selectSkill,
        deleteSkill: deleteSkill,
        lookingJobs: acctiveTab,
      };
      const res = await FresherJobUpdate(payload);

      if (res && res.status === true) {
        setJobPreference(false);
        getitemList(fresherId?.user?.id);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
        setDeleteSkill([]);
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  //Work availability

  const getStatus = (status) => {
    const mapStatus = {
      1: "Actively looking for job",
      2: "Open to offers",
      3: " Not looking for job",
    };
    return mapStatus[status];
  };

  const handleIntereJob = async (e) => {
    e.preventDefault();

    const res = await JobIntrested(jobPostId, fresherId?.user?.id);
    if (res && res.status === true) {
      setOpenSwitchModel(false);
      getitemList(fresherId?.user?.id);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const url = `http://localhost:3000/${process.env.DOMAIN}/faqs`;
  const modifiedUrl = url.replace(/^http:\/\/localhost:3000\//, "");
  const handleLinkClick = () => {
    window.location.href = modifiedUrl;
  };

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);



  return (
    <main className="body-background">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fresher Dashboard | Job Portal | Jobs for Freshers</title>
        <meta name="description" content="Access your Job Portal dashboard for freshers, browse through job listings, and discover remote and work-from-home opportunities tailored for fresh tech graduates." />
      </Helmet>
      <div className="bg-cyan">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard-welcome-text">
                <h3 className="mb-2 font-PTSerif-b">
                  Welcome, {fresherId?.user?.firstName ? fresherId?.user?.firstName : "Job Portal"} {""}
                  {fresherId?.user?.lastName ? fresherId?.user?.lastName : ""}
                </h3>
                <p className="mb-0 font-16-24-05">
                  We are happy you are joining Job Portal. Complete your
                  profile and take our assessments to start getting jobs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="rounded-card mb-4 mb-md-5 dashboard-card-minus">

          <div className="d-block d-sm-none">
            <div className="font-PTSerif-b font-18-24-05 mb-3">
              Want to get hired quickly?
            </div>
            <div className="mb-4 font-16-24-05">
              Complete following steps to unlock job opportunities and <br />{" "}
              maximize your potential
            </div>
          </div>
          <div className="row flex-row justify-contant-between">
            <div className="col-lg-8 order-last order-sm-first">
              <div className="font-PTSerif-b font-18-24-05 mb-3 d-none d-sm-block">

                Want to get hired quickly?
              </div>
              <div className="mb-4 font-16-24-05 d-none d-sm-block">
                Complete following steps to unlock job opportunities and <br />{" "}
                maximize your potential
              </div>
              <div className="row">
                <div className="col-md-6">
                  <ul className="profile-complate-card">
                    <li
                      className={
                        itemList?.isProfileComplate === 0
                          ? "d-flex align-items-baseline height_26"
                          : "d-flex active align-items-baseline"
                      }
                    >
                      <div className="profile-complate-count">1</div>
                      <div className="font-16-24-05">Complete profile</div>
                    </li>
                    <li
                      className={
                        itemList?.isSoftSkill === 0 ? "d-flex align-items-baseline height_26" : "d-flex active align-items-baseline"
                      }
                    >
                      <div className="profile-complate-count">2</div>
                      <div className="font-16-24-05">
                        Pass soft skill assessment
                      </div>
                    </li>
                    <li
                      className={
                        itemList?.isPassTechTest === 0
                          ? "d-flex align-items-baseline height_26"
                          : "d-flex active align-items-baseline"
                      }
                    >
                      <div className="profile-complate-count">3</div>
                      <div className="font-16-24-05">
                        Pass at least one tech stack
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 ">
                  <ul className="profile-complate-card">
                    <li
                      className={
                        itemList?.isPassCodeing === 0
                          ? "d-flex disabled align-items-baseline"
                          : "d-flex active align-items-baseline"
                      }
                    >
                      <div className="profile-complate-count">4</div>
                      <div className="font-16-24-05">Pass coding challenge</div>
                    </li>
                    <li
                      className={
                        itemList?.ternToMatch === 0
                          ? "d-flex disabled align-items-baseline"
                          : "d-flex active align-items-baseline"
                      }
                    >
                      <div className="profile-complate-count">5</div>
                      <div className="font-16-24-05">
                        Stay tuned for a match
                      </div>
                    </li>
                    <li
                      className={
                        itemList?.isHired === 0
                          ? "d-flex disabled align-items-baseline"
                          : "d-flex active align-items-baseline"
                      }
                    >
                      <div className="profile-complate-count">6</div>
                      <div className="font-16-24-05">Get Hired</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-5 mb-md-0">
              <div className="row justify-content-center justify-content-sm-end">
                <div style={{ width: 230, height: 200 }}>
                  <CircularProgressbar
                    styles={customStyles}
                    value={completionPercentage}
                    text={`${Math.round(completionPercentage)}%`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-card-head pt-3 mb-16">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 pb-0 font-18-24-05">
                Preference
              </div>
            </div>
            <div className="col-auto">
              <a
                href="javascript:void(0)"
                onClick={() => {
                  setJobPreference(true);
                  getSkillList();
                  getJobTypeData();
                  getJobPreferData();
                }}
                className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
              >
                <div className="card-head-action-icon pe-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>{" "}
                Edit
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-card mb-4 mb-md-5">
          <div className="row">
            <div className="col-6 col-lg-3 my-2 my-lg-0">
              <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                <div className="">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="fw-medium font-18-24-05 mb-1">Skills</div>
              <div className="mt-2">
                <ul>
                  {itemList && itemList.userSkills
                    ? itemList.userSkills.map((item) => (
                      <li className="d-inline-block">
                        {/* checkbox-check */}
                        <div className={`checkbox-round ${item.isPass == 1 ? 'checkbox-check' : ''}`}>
                          <label className="custom-checkbox">
                            {item.skillName}
                            <Controls.Input type="checkbox" checked />
                            {item.isPass == 1 ?
                              <span className="checkmark"></span>
                              :
                              <span className="checkmark hide_icon"></span>
                            }
                          </label>
                        </div>
                      </li>
                    ))
                    : ""}
                </ul>
              </div>
            </div>
            <div className="col-6 col-lg-3 my-2 my-lg-0">
              <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                <div className="">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="fw-medium font-18-24-05 mb-1">
                Job preference
              </div>
              <div className="font-16-24-05 mb-2">
                {itemList?.jobType}, {itemList?.preferJob}
              </div>
            </div>
            <div className="col-6 col-lg-3 my-2 my-lg-0">
              <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                <div className="">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="fw-medium font-18-24-05 mb-1">
                Preferred location
              </div>
              {prefferLocations?.map((row) => (
                <>
                  <div className="font-16-24-05 mb-2">
                    {row?.countryName}, {row?.stateName}, {row?.cityName}
                  </div>
                </>
              ))}
            </div>
            <div className="col-6 col-lg-3 my-2 my-lg-0">
              <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                <div className="">
                  <svg
                    style={{ transform: " rotate(90deg)" }}
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.9999 21L16.6499 16.65"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="fw-medium font-18-24-05 mb-1">
                Work availability
              </div>
              <div className="font-16-24-05 mb-2">
                {getStatus(itemList?.lookingJobs)}
              </div>
            </div>
          </div>
        </div>

        {interviewList?.map((row) => {
          return (
            <>
              {
                <div className="rounded-card-head pt-3 mb-16">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="font-PTSerif-b ps-2 font-18-24-05">
                        Jobs you're being considered for
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="rounded-card mb-4">
                <div className="row">
                  <div className="col col-lg-9">
                    <div className="font-18-24-05 fw-medium">
                      {row?.jobTitle}
                      {row && row.interviewData && row.interviewData.id ? (
                        <>
                          {row?.interviewData?.isHired !== 1 ? (
                            <span className="bg-secondary select-inrview-tag d-inline-block">
                              Selected for interview
                            </span>
                          ) : (
                            <span className="bg-secondary select-inrview-tag d-inline-block">
                              Hired
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props} id="tooltip-top">

                            <div className="container">
                              <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600", paddingLeft: "10px" }}>
                                Next Step
                              </div>

                              <div className={row && row.interviewData && row?.interviewData?.jobFresherStatus !== 0 ? "step completed" : "step active"}>
                                <div className="v-stepper">
                                  <div className="circle"></div>
                                  <div className="line"></div>
                                </div>

                                <div className="content">
                                  Shortlisted for job
                                </div>
                              </div>

                              <div className={row && row.interviewData && row?.interviewData?.jobFresherStatus !== 0 ? "step completed" : "step active"}>
                                <div className="v-stepper">
                                  <div className="circle"></div>
                                  <div className="line"></div>
                                </div>

                                <div className="content">
                                  Company reviewing your profile
                                </div>
                              </div>

                              <div className={(row?.jobFresherStatus == 1 || row?.jobFresherStatus == 2) ? "step active" : (row?.interviewData?.isHired == 1 ? "step completed" : "step")}>
                                <div className="v-stepper">
                                  <div className="circle"></div>
                                  <div className="line"></div>
                                </div>

                                <div className="content">
                                  Selected for interview
                                </div>
                              </div>


                              <div className={row && row.interviewData && row?.interviewData?.isHired == 1 ? "step completed" : "step"} >
                                <div className="v-stepper">
                                  <div className="circle"></div>
                                  <div className="line"></div>
                                </div>

                                <div className="content">
                                  Your are hired
                                </div>
                              </div>

                            </div>

                          </Tooltip>
                        )}
                        placement="right"
                      >
                        <svg
                          style={{ marginLeft: "5px" }}
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#A6A6A6"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.09009 8.99996C9.32519 8.33163 9.78924 7.76807 10.4 7.40909C11.0108 7.05012 11.729 6.9189 12.4273 7.03867C13.1255 7.15844 13.7589 7.52148 14.2152 8.06349C14.6714 8.60549 14.9211 9.29148 14.9201 9.99996C14.9201 12 11.9201 13 11.9201 13"
                            stroke="#A6A6A6"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 17H12.01"
                            stroke="#A6A6A6"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                      </OverlayTrigger>


                    </div>
                  </div>
                  <div className="col-auto col-lg-3">
                    <div className="fw-medium text-end font-18-24-05">
                      {row?.minSalary} - {row?.maxSalary} {row?.salCurrency}
                    </div>
                    <div className="fw-medium text-end font-18-24-05 text-primary-light">
                      {/* /Monthly */}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mt-md-0">
                  <div className="col-12 col-md col-lg-8 col-xl-9">
                    <ul className="row round-style-blue-wrap">
                      <li className="col-auto mb-3 mt-1 mb-lg-2">
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center justify-content-center round-blue-style-1 w-48">
                            <div className="">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 22 22"
                                fill="none"
                              >
                                <path
                                  d="M2.75008 17.4166V5.22498C2.75003 5.03681 2.80788 4.85318 2.91579 4.69903C3.02369 4.54487 3.17643 4.42766 3.35325 4.36331L12.2183 1.14031C12.2876 1.11511 12.3619 1.10697 12.4349 1.1166C12.508 1.12622 12.5777 1.15332 12.638 1.1956C12.6984 1.23788 12.7477 1.29409 12.7817 1.35946C12.8157 1.42484 12.8334 1.49745 12.8334 1.57114V6.11139L18.6231 8.04098C18.8057 8.1018 18.9645 8.21855 19.0771 8.37469C19.1896 8.53083 19.2501 8.71842 19.2501 8.91089V17.4166H21.0834V19.25H0.916748V17.4166H2.75008ZM4.58342 17.4166H11.0001V3.53373L4.58342 5.86756V17.4166ZM17.4167 17.4166V9.57181L12.8334 8.04373V17.4166H17.4167Z"
                                  fill="#205FC8"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="ms-2 fw-medium font-16-24-05">
                            {row?.companyName}
                          </div>
                        </div>
                      </li>
                      <li className="col-auto mb-3 mt-1 mb-lg-2">
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center justify-content-center round-blue-style-1 w-48">
                            <div className="">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                                  stroke="#205FC8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                                  stroke="#205FC8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="ms-2 fw-medium font-16-24-05">
                            {row?.city}, {row?.state}, {row?.country}
                          </div>
                        </div>
                      </li>
                      <li className="col-auto mb-3 mt-1 mb-lg-2">
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center justify-content-center round-blue-style-1 w-48">
                            <div className="">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
                                  stroke="#205FC8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14 2V8H20"
                                  stroke="#205FC8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M16 13H8"
                                  stroke="#205FC8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M16 17H8"
                                  stroke="#205FC8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10 9H9H8"
                                  stroke="#205FC8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="ms-2 fw-medium font-16-24-05">
                            {row?.jobtypeTitle}, {row?.jobpreferTitle}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="round-style-wrap-1">
                      <ul className="d-flex flex-wrap">
                        {row && row.jobskilldata && row.jobskilldata?.map((item) => {
                          return (
                            <>
                              <li>{item?.title}</li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto col-lg-4 col-xl-3 align-self-end text-center">
                    <div className="row pt-3 justify-content-end">
                      <div className="col-12 col-md-auto mt-2">
                        <Controls.Button
                          className="btn btn-outline-primary h-44 fw-semibold w-100"
                          text="Ignore"
                          onClick={() => {
                            setIgnoreJob(true);
                            setInterviewId(row?.id);
                            resetForm();
                          }}
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <Controls.Button
                          className="btn btn-primary h-44 fw-semibold w-100"
                          text="See More"
                          onClick={() => {
                            setSeeMoreForJob(true);
                            // setInterviewId(row?.id);
                            getSeeInterviewList(row?.id);
                          }}
                        />
                      </div>

                    </div>
                  </div>
                </div>
                {
                  row && row?.interviewData && row.interviewData.id ? (
                    <>
                      <div className="row">
                        <div className="col-12 mt-4">
                          <div className="gray-card-inner">
                            <div className="font-18-24-05 fw-semibold">
                              Congratulations, you have been selected for
                              interview 🎉
                            </div>
                            <div className="row">
                              <div className="col-6 col-lg mt-3">
                                <div className="font-18-24-05 fw-medium">
                                  Title
                                </div>
                                <div className="font-16-24-05">
                                  {row?.jobTitle}
                                </div>
                              </div>
                              <div className="col-6 col-lg mt-3">
                                <div className="font-18-24-05 fw-medium">
                                  Date
                                </div>
                                <div className="font-16-24-05">
                                  {moment(row.interviewData
                                    .date).format("MM-DD-YYYY")}
                                </div>
                              </div>
                              <div className="col-6 col-lg mt-3">
                                <div className="font-18-24-05 fw-medium">
                                  Time
                                </div>
                                <div className="font-16-24-05">
                                  {row?.interviewData?.time}
                                </div>
                              </div>
                              {row?.interviewData?.interviewWillBe == 2 && (
                                <>
                                  <div className="col-6 col-lg mt-3">

                                    <div className="font-18-24-05 fw-medium">
                                      Location
                                    </div>
                                    <div className="font-16-24-05">
                                      {row?.interviewData?.location}
                                    </div>
                                  </div>

                                </>
                              )}
                              {row?.interviewData?.interviewWillBe == 1 && (
                                <>
                                  <div className="col-12 col-lg mt-3">

                                    <div className="font-18-24-05 fw-medium">
                                      Link
                                    </div>
                                    <div className="font-16-24-05">
                                      <a
                                        href={row?.interviewData?.location}
                                        target="_blank"
                                        className="text-black"
                                      >
                                        {row?.interviewData?.location}
                                      </a>
                                    </div>
                                  </div>

                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-4">
                          <div className="gray-card-inner pb-0">
                            <div className="font-18-24-05 fw-semibold">
                              Your point of contact at TechFresher for this
                              interview
                            </div>
                            <div className="row my-4 align-items-center">
                              <div className="col-auto">
                                <div >
                                  {/* <img
                                    width='50px'
                                    src={InterViewerProfile}
                                    className="img-fluid"
                                  /> */}
                                  <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

                                </div>
                              </div>
                              <div className="col px-0">
                                <div className="font-18-24-05 fw-medium">
                                  {row?.adminId[0]?.name}{" "}
                                  {/* {" "}
                                  {row?.interviewData?.lastName} */}
                                </div>
                                <div className="font-16-24-05">
                                  {/* {row?.interviewData?.companyName} */}
                                  Talent Acquisition Specialist
                                </div>
                              </div>
                            </div>
                            <div className="interviewer-contact-details">
                              <div className="row justify-content-between mx-0">
                                <div className="col-12 col-md-6 mb-4 mb-md-0">
                                  <div className="font-18-24-05 fw-semibold">
                                    Email
                                  </div>
                                  <div className="font-16-24-05 fw-medium">
                                    {row?.adminId[0]?.email}
                                  </div>
                                </div>
                                {/* <div className="col-6 col-md-auto mb-4 mb-md-0">
                                <div className="font-18-24-05 fw-semibold">
                                  Skype ID
                                </div>
                                <div className="font-16-24-05 fw-medium">
                                  {itemList?.skypeid}
                                </div>
                                </div>*/}
                                <div className="col-12 col-md-6 mb-0 mb-md-0">
                                  <div className="font-18-24-05 fw-semibold">
                                    Phone
                                  </div>
                                  <div className="font-16-24-05 fw-medium">
                                    {row?.adminId[0]?.phone}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )
                }
              </div>
            </>
          );
        })}
        {
          <div className="rounded-card-head pt-3">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-9">
                <div className="font-PTSerif-b ps-2 mb-2 mb-md-3 font-18-24-05">
                  Assessments that will unlock your potential
                </div>
                <p className="ms-2 mb-0 fw-normal font-16-24-05">
                  Give assessment for skills you are interested in, and we'll
                  match you with the best-fit job opportunities involving this
                  skill. Don't miss out on this chance to kick start your
                  career!
                </p>
              </div>
              <div className="col-12 col-md-auto text-end">
                <a
                  href="/assessments"
                  className="pe-2 btn btn-link text-decoration-none text-primary card-head-action"
                >
                  More Assessments
                </a>
              </div>
            </div>
          </div>
        }

        <div className="row flex-nowrap flex-lg-wrap level-card-scroll mb-4">
          <div className="col-8 col-md-5 col-lg-3 my-3">
            <div className="level-card level-card-primary">
              <div className="font-18-24-05 fw-medium level-card-head">
                {itemList && itemList.softSkill && itemList.softSkill.title ? itemList.softSkill.title : "Soft Skill"}
              </div>
              <div className="font-16-24-05 level-card-body">
                {itemList && itemList.softSkill && itemList.softSkill.description ? itemList.softSkill.description : "Soft skills"}

              </div>
              {profileComplete > 99 ? (

                <div className="level-card-footer">
                  <a href="javascript:void(0)"
                    className="font-18-24-05 fw-medium "
                    onClick={() => {

                      if (softSkillData.isPass == 1) {
                        setShow({
                          isOpen: true,
                          message: "You have already passed this assessment",
                          type: "warning",
                        });

                      } else if (softSkillData.isBlockd == 1) {
                        setShow({
                          isOpen: true,
                          message: "Your Assessment is blocked for " + calculateDays(softSkillData.blockedDate) + "days",
                          type: "error",
                        });
                      } else if (profileComplete > 99) {
                        if (width <= 991) {
                          navigate('/assessments-mobile')
                        } else {
                          setSkillIds(1);
                          setisSoftSkill(true);
                          setSoftSkillAssessment(true);
                          setSkillNames(itemList && itemList.softSkill && itemList.softSkill.title ? itemList.softSkill.title : "Soft Skill");
                          setSkillDescription(itemList && itemList.softSkill && itemList.softSkill.bdescription ? itemList.softSkill.bdescription : "Soft skills");
                        }
                      } else {
                        setShow({
                          isOpen: true,
                          message: "Your profile is not completed yet please complete your profile 100% to unlock this assessment",
                          type: "error",
                        });
                      }
                    }}>
                    <div className="mx-0 row justify-content-between">
                      <div className="col-auto">{softSkillData.isPass == 1 ? "Pass" : softSkillData.isPass == 2 ? "Didn't Pass" : "Start"}</div>
                      {softSkillData.isPass == 0 && softSkillData.isBlockd != 1 && (
                        <div className="col-auto">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 12H19"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5L19 12L12 19"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>)}
                      {
                        softSkillData.isBlockd == 1 && (
                          <>
                            {/* <div className="col-auto"> */}
                            {/* <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div> */}
                            <div className="col-auto d-flex align-items-center" style={{ gap: '6px' }}>
                              <BsCalendarEvent className="col-auto" />
                              <div>
                                {calculateDays(softSkillData.blockedDate)}&nbsp;days
                              </div>
                            </div>
                          </>
                        )

                      }
                    </div>
                  </a>
                </div>) : (<div className="level-card-footer">
                  <a href="JavaScript:void(0)" className="font-18-24-05 fw-medium ">
                    <div className="mx-0 row justify-content-between" onClick={() => {
                      setShow({
                        isOpen: true,
                        message: "Complete your profile to 100% to unlock this assessment",
                        type: "error",
                      });
                    }}>
                      <div className="col-auto">Locked</div>
                      <div className="col-auto">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                            stroke="#E4E4E4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                            stroke="#E4E4E4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>)}
            </div>
          </div>
          {testSkillList?.map((row) => {
            return (
              <>
                <div className="col-8 col-md-5 col-lg-3 my-3">
                  <div className={`level-card ${softSkillData.isPass === 1 ? "level-card-primary" : "level-card-locked"} `}>

                    <div className="font-18-24-05 fw-medium level-card-head">
                      {row?.skillName}
                    </div>
                    <div className="font-16-24-05 level-card-body">
                      {row?.skillDescription}

                    </div>
                    {softSkillData.isPass === 1 ? (

                      <div className="level-card-footer">
                        <a href="javascript:void(0)"
                          className="font-18-24-05 fw-medium "
                          onClick={() => {

                            if (row.isPass == 1) {
                              setShow({
                                isOpen: true,
                                message: "You have already passed this assessment",
                                type: "warning",
                              });

                            }
                            else if (row.isBlockd == 1) {
                              setShow({
                                isOpen: true,
                                message: `Your Assessment is blocked for ${calculateDays(row.blockedDate)} days`,
                                type: "error",
                              });
                            } else if (profileComplete > 99) {
                              if (width <= 991) {
                                navigate('/assessments-mobile')
                              } else {
                                setSkillIds(row?.skillId);
                                setSoftSkillAssessment(true);
                                setisSoftSkill(false);
                                setSkillNames(row && row?.skillName ? row?.skillName : "Tech Skill");
                                setSkillDescription(row && row?.bdescription ? row?.bdescription : "Tech skills");
                              }
                            } else {
                              setShow({
                                isOpen: true,
                                message: "Your profile is not completed yet please complete your profile 100% to unlock this assessment",
                                type: "error",
                              });
                            }
                          }}>
                          <div className="mx-0 row justify-content-between">

                            {
                              softSkillData.isPass == 1 &&
                              <div className="col-auto">{row.isPass == 1 ? "Pass" : row.isPass == 2 ? "Didn't Pass" : "Start"}</div>
                            }
                            {row.isPass == 0 && row.isBlockd != 1 ? (
                              <>
                                {/* <a href="JavaScript:void(0)" className="font-18-24-05 fw-medium ">
                                  <div className="mx-0 row justify-content-between">
                                    <div className="col-auto">Locked</div>
                                    <div className="col-auto">
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                          stroke="#E4E4E4"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                          stroke="#E4E4E4"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  </a> */}
                                <div className="col-auto">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 12H19"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12 5L19 12L12 19"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                              </>
                            ) : null}
                            {
                              row.isBlockd == 1 && (
                                <>
                                  {/* <div className="col-auto">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                        stroke="#E4E4E4"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                        stroke="#E4E4E4"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div> */}
                                  <div className="col-auto d-flex align-items-center" style={{ gap: '6px' }}>
                                    <BsCalendarEvent className="col-auto" />
                                    <div>
                                      {calculateDays(row.blockedDate)}&nbsp;days
                                    </div>
                                  </div>
                                </>
                              )
                            }


                          </div>
                        </a>
                      </div>
                    ) : (
                      <div className="level-card-footer">
                        <a href="JavaScript:void(0)"
                          className="font-18-24-05 fw-medium ">
                          <div className="mx-0 row justify-content-between" onClick={() => {
                            setShow({
                              isOpen: true,
                              message: "Pass the soft skill test to unlock the technical assessments.",
                              type: "error",
                            });
                          }}>
                            <div className="col-auto">Locked</div>
                            <div className="col-auto">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>

              </>
            );
          })}
        </div>


        <div className="rounded-card-head pt-3 mb-16">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">About us</div>
            </div>
          </div>
        </div>
        <div className="rounded-card mb-5">
          <>
            <div className="row align-items-center flex-lg-row-reverse">
              <div className="col-lg-6">
                <img src={aboutList?.image} className="w-100" />
              </div>
              <div className="col-lg-6">
                <p className="font-18-24-05 lh-24 mb-0 pb-2">At TechFresher, we believe in providing fresh tech graduates with a fair chance to find job opportunities that match their skills and preferences.</p>
                <p className="font-18-24-05 lh-24 mb-0 pb-2">Our platform streamlines the job search process and connects job seekers with skilled and motivated candidates. We're committed to fostering a community that values diversity, inclusivity, and equal opportunity.</p>
                <p className="font-18-24-05 lh-24 mb-0 pb-2">Our mission is to bridge the gap between job seekers and employers by providing an efficient and accessible platform.</p>
                <p className="font-18-24-05 lh-24 mb-0 pb-2">We understand the challenges fresh tech graduates face when entering the job market and are dedicated to providing the resources they need to succeed. With TechFresher, job seekers can find their dream job, and employers can find the skilled candidates they need to grow their businesses.</p>
              </div>
            </div>
          </>
        </div>
        <div className="rounded-card-head pt-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05">
                Why join Job Portal?
              </div>
            </div>
          </div>
        </div>

        <div className="row join-tech-fresher-wrap mb-4">
          {whyJoinList?.map((row) => {
            return (
              <>
                <div className="col-6 col-md-6 col-lg-3 my-3">
                  <div className="rounded-card h-100 text-center text-md-start">
                    <div className="join-tech-fresher-img">
                      <img src={row?.image} className="w-100 h-100" />
                    </div>
                    <div className="font-18-24-05 mt-3">
                      {row?.description}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <div className="rounded-card-head pt-3 mb-16">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 pb-0 font-18-24-05">FAQs</div>
            </div>
            <div className="col col-md-auto text-end">
              <a
                href={`${process.env.DOMAIN}/faqs`}
                target="_blank"
                // onClick={handleLinkClick}
                className="pe-2 btn btn-link text-decoration-none text-primary card-head-action"
              >
                More FAQs
              </a>
            </div>
          </div>
        </div>

        <div className="rounded-card p-0 mb-5">
          {FaqList?.map((row, index) => {
            return (
              <>
                <Accordion
                  key={index}
                  activeKey={activeAccordion}
                  onSelect={handleAccordionToggle}
                  className="accordion-transparent"
                >
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{row?.question}</Accordion.Header>
                    <Accordion.Body className="font-16-24-05 pt-0 px-4 mx-1">
                      {row?.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            );
          })}
        </div>

        {/* <footer className="footer-copyright font-18-24-05 text-center py-3">
          TechFresher 2023 copyright received
        </footer> */}
      </div >
      <Modal
        size="md"
        show={IgnoreJob}
        onHide={() => setIgnoreJob(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 fw-medium">
            Do you want to ignore this job?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => {
              setInterestedForJob(true);
              setIgnoreJob(false);
            }}
          >
            Yes, Ignore
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => setIgnoreJob(false)}
          >
            No, Keep it
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={SeeMoreForJob}
        onHide={() => setSeeMoreForJob(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header
          className="border-0 px-lg-5 py-lg-4"
          closeButton
        ></Modal.Header>
        <Modal.Body className="px-lg-5">
          <div className="row">
            <div className="col-12 col-lg mb-4 mb-lg-0">
              <div className="font-18-24-05 fw-medium mb-2 ps-2">
                {seeJobList?.employerId?.jobTitle}
              </div>
              <div className="font-18-24-05 fw-medium ps-2">
                <div className="col-auto">
                  {seeJobList?.jobId?.jobTitle}
                </div>
              </div>
            </div>
            {seeJobList?.status !== 2 && seeJobList?.status !== 3 ? (
              <div className="col-12 col-lg-auto">
                {interviewAssign == 1 && (<Button
                  className="btn btn-primary h-44 fw-medium m-2"
                  onClick={() => {
                    handleSwitchModel(true);
                    setSeeMoreForJob(false);
                    setJobPostId(seeJobList?.jobId?.id);
                  }}
                >
                  <svg
                    className="me-2"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 6L9 17L4 12"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Interested
                </Button>)}

                <Button
                  className="btn btn-secondary h-44 fw-medium m-2"
                  onClick={() => {
                    setInterestedForJobEmployer(true);
                    setInterviewIdEmployer(seeJobList?.id);
                    setJobPostSeeId(seeJobList?.jobId?.id);
                  }}
                >
                  Not Interested
                </Button>
              </div>
            ) : (
              <>
                {seeJobList?.status !== 3 ? (
                  <div className="col-12 col-lg-auto">
                    <Button className="btn btn-primary h-44 fw-medium m-2">
                      <svg
                        className="me-2"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Accepted
                    </Button>
                  </div>
                ) : (
                  <div className="col-12 col-lg-auto">
                    <Button className="btn btn-primary h-44 fw-medium m-2">
                      <svg
                        className="me-2"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Hired
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="gray-card-inner padding-18 my-4">
            <div className="row">
              <div className="col-6 col-lg my-3 my-md-2">
                <div className="font-18-24-05 fw-medium">Job location</div>
                <div className="font-16-24-05">
                  {seeJobList?.jobId?.cityName} {seeJobList?.jobId?.stateName} ,
                  {seeJobList?.jobId?.countryName}
                </div>
              </div>
              <div className="col-6 col-lg my-3 my-md-2">
                <div className="font-18-24-05 fw-medium">Salary</div>
                <div className="font-16-24-05">
                  {seeJobList?.jobId?.minSalary} -{" "}
                  {seeJobList?.jobId?.maxSalary}{" "}
                  {seeJobList?.jobId?.salCurrency}{" "}
                </div>
              </div>
              <div className="col-6 col-lg my-3 my-md-2">
                <div className="font-18-24-05 fw-medium">Job type</div>
                <div className="font-16-24-05">
                  {seeJobList?.jobId?.jobTypeTitle},{" "}
                  {seeJobList?.jobId?.jobPreferTitle}
                </div>
              </div>
            </div>
          </div>
          <div className="ps-2 py-2 py-lg-0">
            <div className="font-18-24-05 fw-semibold">Skills Required</div>
            <div className="mt-2">
              <ul>
                {seeJobList?.fresherskill?.map((item) => {
                  const matchingSkill = seeJobList.Jobskill.find(
                    (row) => row.skillId === item.skillId
                  );
                  const isChecked = matchingSkill ? true : false;

                  return (
                    <li className="d-inline-block" key={item.skillId}>
                      <div
                        className={`checkbox-round ${isChecked ? "checkbox-check" : ""
                          }`}
                      >
                        <label className="custom-checkbox">
                          {item?.fresherskillName}
                          <Controls.Input type="checkbox" checked={isChecked} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {interviewAssign == 1 && (<>
            <div className="gray-card-inner padding-18 my-4">

              <div className="font-18-24-05 fw-semibold my-2">
                Congratulations, you have been selected for interview 🎉
              </div>
              <div className="row">
                <div className="col-6 col-lg my-2">
                  <div className="font-18-24-05 fw-medium">Title</div>
                  <div className="font-16-24-05">
                    {seeJobList?.jobId?.jobTitle}

                  </div>
                </div>
                <div className="col-6 col-lg my-2">
                  <div className="font-18-24-05 fw-medium">Date</div>
                  <div className="font-16-24-05">
                    {moment(seeJobList?.date).format(
                      "MM-DD-YYYY"
                    )}
                  </div>
                </div>
                <div className="col-6 col-lg my-2">
                  <div className="font-18-24-05 fw-medium">Time</div>
                  <div className="font-16-24-05 text-nowrap">
                    {seeJobList?.time}
                  </div>
                </div>
                {seeJobList?.interviewWillBe == 2 && (
                  <div className="col-6 col-lg my-2">
                    <div className="font-18-24-05 fw-medium">Location</div>
                    <div className="font-16-24-05">{seeJobList?.location}</div>
                  </div>
                )}
                {seeJobList?.interviewWillBe == 1 && (
                  <div className="col-12 col-lg my-2">
                    <div className="font-18-24-05 fw-medium">Link</div>
                    <div className="font-16-24-05">
                      <a
                        href={seeJobList?.location}
                        target="_blank"
                        className="text-black"
                      >
                        {seeJobList?.location}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
          )}
          <div className="row mb-3">
            <div className="col-12 mt-4">
              <div className="gray-card-inner pb-0">
                <div className="font-18-24-05 fw-semibold">
                  Your point of contact at TechFresher for this interview
                </div>
                <div className="row my-4 align-items-center">
                  <div className="col-auto">
                    <div>
                      <img
                        width='50px'
                        src={InterViewerProfile}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col px-0">
                    <div className="font-18-24-05 fw-medium">
                      {seeJobList?.jobId?.adminId?.name}
                    </div>
                    <div className="font-16-24-05">
                      {/* {seeJobList?.employerId?.companyName} */}
                      Talent Acquisition Specialist
                    </div>
                  </div>
                </div>
                <div className="interviewer-contact-details">
                  <div className="row justify-content-between mx-0">
                    <div className="col-12 col-md-auto mb-4 mb-md-0">
                      <div className="font-18-24-05 fw-semibold">Email</div>
                      <div className="font-16-24-05 fw-medium">
                        {seeJobList?.jobId?.adminId?.email}

                      </div>
                    </div>

                    <div className="col-12 col-md-auto mb-0 mb-md-0">
                      <div className="font-18-24-05 fw-semibold">Phone</div>
                      <div className="font-16-24-05 fw-medium">
                        {seeJobList?.jobId?.adminId?.phone}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ps-2">
            <div className="col-12">
              <div className="font-18-24-05 fw-semibold py-3">Job Description</div>
              <div className="font-16-24-05">
                <div dangerouslySetInnerHTML={{ __html: seeJobList?.jobId?.jobDescription }} />
              </div>
            </div>

          </div>

        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={InterestedForJob}
        onHide={() => setInterestedForJob(false)}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you not interested in that job?
            </p>
            <p className="mb-0 font-16-24-05">
              If yes, Tell us why you are not interested
            </p>
            <div className="mt-3">
              <Controls.Input
                className="form-control"
                name="fresherReason"
                type="text"
                value={values?.fresherReason}
                onChange={handleInputChange}
                rows="4"
              />
            </div>
            {/* <p className="mb-0">Minimum 100 characters</p> */}
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              type="submit"
            >
              Yes, I'm not Interested
            </Button>
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              onClick={() => {
                setInterestedForJob(false)
              }
              }
            >
              No, I'm Interested
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        size="lg"
        show={JobPreference}
        onHide={() => {
          setJobPreference(false);
          setDeleteSkill([]);
          getitemList();
        }}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmitJob}>
          <Modal.Header className="border-0" closeButton

          ></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">Job preference</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12"></div>
              <div className="col-12">
                <div className="row">

                  {width <= 767 ?
                    <>
                      <div className="col">
                        Preferred Location 1
                      </div>
                      <hr />
                    </>
                    : ''}

                  <div className="col-12 col-md-4 pe-md-0 mb-2">
                    <div className="form-label">Country</div>
                    <select
                      className="form-select"
                      name="country1"
                      onChange={(e) => {
                        getPrefferLocation(e, 0);
                        handleCountryLocation0(e);
                        // handleInputChange(e);
                      }}
                      value={values.country1}
                    >
                      <option value="">None</option>
                      {countryList0.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12 col-md-4 mb-2">
                    <div className="form-label">State</div>

                    <select
                      className="form-select"
                      name="state1"
                      onChange={(e) => {
                        getPrefferLocation(e, 0);
                        handleStateLocation0(e);
                        // handleInputChange(e);
                      }}
                      value={values.state1}
                    >
                      <option value="">None</option>
                      {stateList0.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 ps-md-0 mb-2">
                    <div className="form-label">City</div>

                    <select
                      className="form-select"
                      name="city1"
                      onChange={(e) => {
                        getPrefferLocation(e, 0);
                        handleInputChange(e);
                      }}
                      value={values.city1}
                    >
                      <option value="">None</option>
                      {cityList0.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {width <= 767 ?
                  <>
                    <div className="col">
                      Preferred Location 2
                    </div>
                    <hr style={{ margin: '0 0 10px' }} />
                  </>
                  : ''}
                <div className="row">
                  <div className="col-12 col-md-4 pe-md-0 mb-2">
                    {width <= 767 ?
                      <div className="form-label">Country</div>
                      : ''}
                    <select
                      className="form-select"
                      name="country2"
                      onChange={(e) => {
                        getPrefferLocation(e, 1);
                        handleCountryLocation1(e);
                      }}
                      value={values.country2}
                    >
                      <option value="">None</option>
                      {countryList1.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 mb-2">
                    {width <= 767 ?
                      <div className="form-label">State</div>
                      : ''}
                    <select
                      className="form-select"
                      name="state2"
                      onChange={(e) => {
                        getPrefferLocation(e, 1);
                        handleStateLocation1(e);
                      }}
                      value={values.state2}
                    >
                      <option value="">None</option>
                      {stateList1.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 ps-md-0 mb-2">
                    {width <= 767 ?
                      <div className="form-label">City</div>
                      : ''}
                    <select
                      className="form-select"
                      name="city2"
                      onChange={(e) => {
                        getPrefferLocation(e, 1);
                        handleInputChange(e);
                      }}
                      value={values.city2}
                    >
                      <option value="">None</option>
                      {cityList1.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {width <= 767 ?
                  <>
                    <div className="col">
                      Preferred Location 3
                    </div>
                    <hr style={{ margin: '0 0 10px' }} />
                  </>
                  : ''}
                <div className="row">
                  <div className="col-12 col-md-4 pe-md-0 mb-2">
                    {width <= 767 ?
                      <div className="form-label">Country</div>
                      : ''}
                    <select
                      className="form-select"
                      name="country3"
                      onChange={(e) => {
                        getPrefferLocation(e, 2);
                        handleCountryLocation2(e);
                      }}
                      value={values.country3}
                    >
                      <option value="">None</option>

                      {countryList2.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 mb-2">
                    {width <= 767 ?
                      <div className="form-label">State</div>
                      : ''}
                    <select
                      className="form-select"
                      name="state3"
                      onChange={(e) => {
                        getPrefferLocation(e, 2);
                        handleStateLocation2(e);
                      }}
                      value={values.state3}
                    >
                      <option value="">None</option>
                      {stateList2.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 ps-md-0 mb-2">
                    {width <= 767 ?
                      <div className="form-label">City</div>
                      : ''}
                    <select
                      className="form-select"
                      name="city3"
                      onChange={(e) => {
                        getPrefferLocation(e, 2);
                        handleInputChange(e);
                      }}
                      value={values.city3}
                    >
                      <option value="">None</option>
                      {cityList2.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 mb-16 mt-3">
                <div className="form-label">Work availabilty</div>
                <div className="blue-tabs-wrap">
                  <ul className="d-flex font-18-24-05 text-center pb-0 flex-wrap justify-content-between">
                    <li
                      onClick={(e) => {
                        setTab(1);
                        handleInputChange(e);
                      }}
                      className={`w_100 ${acctiveTab == 1 ? "active" : ""} `}
                      value={values.lookingJobs}
                    >
                      Actively looking for job
                    </li>
                    <li
                      onClick={(e) => {
                        setTab(2);
                        handleInputChange(e);
                      }}
                      className={`w_100 ${acctiveTab == 2 ? "active" : ""}`}
                      value={values.lookingJobs}
                    >
                      Open to offers
                    </li>
                    <li
                      onClick={(e) => {
                        setTab(3);
                        handleInputChange(e);
                      }}
                      className={`w_100 ${acctiveTab == 3 ? "active" : ""}`}
                      value={values.lookingJobs}
                    >
                      Not looking for job
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-label">
                  What type of jobs do you prefer?
                </div>
              </div>
              <div className="col-12 col-md-6 mb-16">
                <select
                  className="form-select"
                  name="jobTypeId"
                  onChange={handleInputChange}
                  value={values?.jobTypeId}
                >
                  <option value="">None</option>
                  {jobList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 mb-16">
                <select
                  className="form-select"
                  name="jobPraferId"
                  onChange={handleInputChange}
                  value={values?.jobPraferId}
                >
                  <option value="">None</option>
                  {jobPreferList?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="col-12 col-md-12 mb-16">
                  <div className="form-label">Skills</div>
                  <Multiselect
                    options={skillData} // Options to display in the dropdown
                    selectedValues={selectSkill} // Preselected value to persist in dropdown
                    onSelect={multiselectSelectSkill} // Function will trigger on select event
                    onRemove={removeSkill}                    // displayValue={(option) => (option ? option.skillName : "")} // Property name to display in the dropdown options
                    displayValue="skillName"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => {
                setJobPreference(false);
                setDeleteSkill([]);
                getitemList();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        size="md"
        show={InterestedForJobEmployer}
        onHide={() => {
          setInterestedForJobEmployer(false);
          setValues({ ...values, fresherReason: "" })
        }}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmits}>
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you not interested in that job?
            </p>
            <p className="mb-0 font-16-24-05">
              If yes, Tell us why you are not interested
            </p>
            <div className="mt-3">
              <Controls.Input
                className="form-control"
                name="fresherReason"
                type="text"
                value={values?.fresherReason}
                onChange={handleInputChange}
                rows="4"
              />
            </div>
            {/* <p className="mb-0">Minimum 100 characters</p> */}
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0 flexCol">
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              type="submit"
              onClick={() => {
                setValues({ ...values, fresherReason: "" })

              }}
            >
              Yes, I'm not Interested
            </Button>
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              // onClick={() => setInterestedForJobEmployer(false)}
              onClick={() => {
                setValues({ ...values, fresherReason: "" })
                setInterestedForJobEmployer(false)

              }}
            >
              No, I'm Interested
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="xl"
        show={SoftSkillAssessment}
        onHide={() => setSoftSkillAssessment(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 fw-semibold">
                {/* Soft Skill Assessment */}
                {isSoftSkill ? "Soft Skill Assessment" : `${skillNames} Assessment`}

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="font-16-24-05 mb-2">
                {skillDescription}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="fw-medium font-18-24-05">Instructions</div>
            </div>
          </div>
          <div className="row flex-nowrap flex-lg-wrap level-card-scroll">
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 7V12H17M21.0036 4.57115L17.9395 2M6.06418 2L3 4.57115M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Time</div>
                <div className="font-16-24-05 mb-lg-2">
                  You will have 20 minutes to complete 30 assessment questions
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8506 11.5442C17.0475 10.6829 16.0641 10.0096 14.9707 9.57227M20.7759 8.81625C19.5712 7.52437 18.0961 6.51439 16.4561 5.8584C14.816 5.20241 13.0514 4.91635 11.2881 5.02111M8.34277 14.5905C8.95571 13.9332 9.73448 13.4532 10.5971 13.2012C11.4598 12.9491 12.3745 12.9335 13.2449 13.1574M6.14941 11.5438C7.09778 10.5268 8.29486 9.77461 9.62259 9.36133M3.22363 8.81604C4.1215 7.85319 5.17169 7.04466 6.33211 6.42285M4.41406 4L18.5562 18.1421M12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">
                  Internet Connection
                </div>
                <div className="font-16-24-05 mb-lg-2">
                  Ensure that you have a stable internet connection before
                  starting
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H20C21.1046 17 22 16.1046 22 15V5C22 3.89543 21.1046 3 20 3Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 21H16"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 17V21"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Screen</div>
                <div className="font-16-24-05 mb-lg-2">
                  Do not switch tabs or browsers during assessment.
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23 7L16 12L23 17V7Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Record</div>
                <div className="font-16-24-05 mb-lg-2">
                  Your video will be recorded during the assessment
                  for evaluation purposes.
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 5L6 9H2V15H6L11 19V5Z"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M23 9L17 15"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 9L23 15"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Quiet Room</div>
                <div className="font-16-24-05 mb-lg-2">
                  Find a distraction-free and quiet room for the assessment.
                  Avoid noisy environments.
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="fw-medium font-18-24-05">Notes</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ul className="font-16-24-05 list-style-dots">
                <li className="mb-2">
                  By clicking the "Continue" button, you agree to our{" "}
                  <span className="fw-semibold">terms and services.</span>
                </li>
                <li className="mb-2">
                  If you do not pass the assessment, you will have the
                  opportunity to retake it after 15 days.
                </li>
                <li className="mb-2">
                  <span className="text-danger">Warning:</span> Cheating during
                  the assessment will result in a ban from our platform.
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
          <Button
            variant="primary"
            className="d-flex justify-content-center h-44 fw-medium m-0"
            onClick={() => {
              setPermissions(true);
              setSoftSkillAssessment(false);
            }}
          >
            Continue
            <div className="card-head-action-icon ps-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12H19"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 5L19 12L12 19"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={Permissions}
        onHide={() => setPermissions(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-32">
              <div className="fw-medium font-18-24-05 mb-2">Permissions</div>
              <div className="font-16-24-05">
                We need to record your video and audio during the assessment for
                evaluation purposes, Do you agree to record your video and
                audio?
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="checkbox-round-md">
                <label className="custom-checkbox">
                  {" "}
                  Yes, I agree
                  <Controls.Input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCameraPermission(true)
                      }
                      else {
                        setCameraPermission(false)
                      }
                      setStudyRunning(!studyRunning);
                    }}
                    checked={studyRunning}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
          <Button
            variant="primary"
            className={
              studyRunning
                ? "d-flex justify-content-center align-items-center h-44 fw-medium m-0"
                : "d-flex justify-content-center align-items-center h-44 fw-medium m-0 disabled"
            }
            onClick={() => {
              getPermission();
            }}
          >
            <div className="card-head-action-icon pe-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 3L19 12L5 21V3Z"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            Start
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        show={openSwitchModel}
        onHide={() => handleCloseSwitch(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 fw-medium">
            Are you interested this job?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => handleCloseSwitch()}
          >
            No
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            type="submit"
            onClick={handleIntereJob}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <AutohideExample show={show} setShow={setShow} />
    </main >
  );
};

export default Dashboard;
