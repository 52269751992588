import axios from "axios";
import axiosInstance from "../../utills/AxiosInstance";


//skill list
export const getSkillLists = async (querystring) => {
    try {
        const response = await axiosInstance.get('/v1/skillList'+querystring)

        return response.data;
    } catch (error) {
        return error;
    }
};

//skill Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/statusChangeSkill/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
//add skill
export const AddSkillData = async (payload) => {
  try {
    const response = await axiosInstance.post(`/v1/addSkills`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};


//update skill
export const UpdateSkill = async (id,payload) => {
  try {
    const response = await axiosInstance.put(`/v1/updateSkills/${id}`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//delete skill

export const deleteSkill = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteSkill/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};