import React, { useEffect, useRef, useState } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router";
import { Helmet } from 'react-helmet'

import { useSelector } from "react-redux";
import {
  InterviewDropdown,
  addInterviewJob,
  employeeJobClose,
  employerDashboard,
  employerJobDelete,
  employerJobDetailsUpdate,
  employerJobPostDetails,
  getCandidateDetails,
  getCandidateHire,
  getRejectCandidateJob,
} from "../../state/action/employerAction";
import { useLocation } from "react-router-dom";
import AutohideExample from "../../utills/Notification";
import { Form, useForm } from "../../utills/useForms";
import {
  JobPreferLists,
  JobTypes,
  getCityLists,
  getCountryLists,
  getStateLists,
} from "../../state/action/addjobAction";
import { SkillList } from "../../state/action/skillAction";
import moment from "moment";
import { Tooltip } from "react-bootstrap";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';

const initialValues = {
  jobTitle: "",
  jobTypeId: "",
  jobPreferId: "",
  jobDescription: "",
  maxSalary: "",
  minSalary: "",
  openings: "",
  salaryCurrency: "",
  countryId: "",
  stateId: "",
  cityId: "",
  skillId: "",
  date: "",
  time: "12:00",
  location: "",
  interviewTypeId: "",
};

const EmployerDashboard = () => {
  const [JobDetails, setJobDetails] = useState(false);
  const [IgnoreJob, setIgnoreJob] = useState(false);
  const [FillInterviewDetails, setFillInterviewDetails] = useState(false);
  const [PersonalInformation, setPersonalInformation] = useState(false);
  const [validationType, setValidationType] = useState("");

  const [InterviewReject, setInterviewReject] = useState(false);
  const [CandidateInformation, setCandidateInformation] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [jonPostSkillList, setJobPostSkillList] = useState([]);

  const [aboutList, setJobAboutList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [faqList, setJobFaqList] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [jobIds, setJobIds] = useState("");
  const [whyjoinusList, setWhyJoinList] = useState([]);

  const [jobPostList, setJobPostList] = useState([]);

  const [deleteId, setDeleteId] = useState();
  const [jobType, setJobType] = useState([]);
  const [jobPrefer, setJobPrefer] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [selectSkill, setSelectSkill] = useState([]);
  const [InterviewHire, setInterviewHire] = useState(false);
  const [deleteSkill, setDeleteSkill] = useState([]);

  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [acctiveTab, setAcctiveTab] = useState(1);
  const [fresherId, setFresherId] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [candidateList, setCandiadteList] = useState([]);
  const [InterviewCalenderList, setInterViewCalenderList] = useState([
  ]);

  const [interviewType, setInterviewType] = useState([]);
  const [interviewIdEmployer, setInterviewIdEmployer] = useState(null);
  const [CloseJob, setCloseJob] = useState(false);
  const [jobPostId, setJobPostId] = useState(null);

  const setTab = (tab) => {
    setAcctiveTab(tab);
  };

  const employerId = useSelector((state) => state?.auth);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const multiselectSelectSkill = (data, i) => {
    setSelectSkill([...selectSkill, {
      skillId: i.id,
      jobPostId: jobIds,
      title: i.title,

    }]);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = candidateList?.resume?.fileLocation;
    link.download = "filename.pdf";
    link.click();
  };

  const navigate = useNavigate();
  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    if (state && state != null) {
      setEditorState(state);
      convertContentToHTML();
    }
  }

  const convertContentToHTML = () => {
    const currentContent = editorState.getCurrentContent();
    if (currentContent && currentContent != null && currentContent != undefined) {

      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(currentContentAsHTML);
    }
  }

  //get dashboard list
  const getitemList = async () => {
    const res = await employerDashboard(employerId?.user?.id);
    if (res) {
      setItemList(res?.data);
      setJobAboutList(res?.aboutus);
      setJobFaqList(res?.faq);
      setWhyJoinList(res?.whyjoinus);
      setInterViewCalenderList(res?.getInterview);
    } else {
      setItemList([]);
    }
  };

  useEffect(() => {
    getitemList(employerId?.user?.id);
  }, []);




  const getJobList = async (ids) => {
    const res = await employerJobPostDetails(ids);
    if (res) {
      setJobPostList(res?.data);
      setJobPostSkillList(res?.data?.jobSkill);
      setValues({
        ...values,
        jobTitle: res?.data?.jobTitle,
        jobTypeId: res?.data?.jobTypeId?.id,
        jobPreferId: res?.data?.jobPreferId?.id,
        jobDescription: res?.data?.jobDescription,
        maxSalary: res?.data?.maxSalary,
        minSalary: res?.data?.minSalary,
        openings: res?.data?.openings,
        salaryCurrency: res?.data?.jobTitle,
        countryId: res?.data?.countryId,
        stateId: res?.data?.stateId,
        cityId: res?.data?.cityId,
        skillId: res?.data?.jobTitle,
      });
      if (res && res?.data && res?.data?.jobDescription) {
        setEditorState(() => EditorState.createWithContent(ContentState.createFromBlockArray(
          convertFromHTML(res?.data?.jobDescription)
        )))
      }
      setConvertedContent(res?.data?.jobDescription);
      let dbId = res?.data?.jobSkill.map((car) => {
        return {
          id: car.id,
          skillId: car.skillId,
          title: car.skillName,
          jobPostId: car.jobPostId,
        };
      });

      setSelectSkill(dbId);
      handleCityList(res?.data?.stateId);
      handleStateList(res?.data?.countryId);
    } else {
      setJobPostList([]);
    }
  };

  //delete the job

  const handleDeleteJob = async () => {
    const res = await employerJobDelete(deleteId);
    if (res.status === true) {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setIgnoreJob(false);
      setJobDetails(false);
      getitemList(employerId?.user?.id);
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };



    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validates = (fieldValues = values) => {
    let temp = { ...errors };
    if (validationType == 2) {
      if ("jobTitle" in fieldValues)
        temp.jobTitle = fieldValues.jobTitle && fieldValues.jobTitle != null
          ? "" : "This field is required.";

      if ("noOfOpenings" in fieldValues) {
        //only number
        const re = /^[0-9\b]+$/;
        if (fieldValues.noOfOpenings != "" && fieldValues.noOfOpenings != null) {
          if (fieldValues.noOfOpenings) {
            if (re.test(fieldValues.noOfOpenings)) {
              temp.noOfOpenings = "";
            } else {
              temp.noOfOpenings = "Only number allowed";
            }
          }
        }
        else {
          temp.noOfOpenings = "This field is required.";
        }


      }


      if ("jobType" in fieldValues)
        temp.jobType = fieldValues.jobType && fieldValues.jobType != null
          ? "" : "This field is required.";
      if ("jobPrefer" in fieldValues)
        temp.jobPrefer = fieldValues.jobPrefer && fieldValues.jobPrefer != null
          ? "" : "This field is required.";
      if ("jobDescription" in fieldValues)
        temp.jobDescription = fieldValues.jobDescription && fieldValues.jobDescription != null
          ? ""
          : "This field is required.";

      if ("minSalary" in fieldValues) {

        if (fieldValues.minSalary) {

          const minSalary = parseFloat(fieldValues.minSalary);
          const maxValue = parseFloat(values.maxSalary);
          if (minSalary < 0) {
            temp.minSalary = "Minimum salary cannot be negative.";
          }
          else if (minSalary > maxValue) {

            temp.minSalary = `Minimum salary should be less than or equal to ${maxValue}.`;
          } else if (

            fieldValues.maxSalary &&
            minSalary > parseFloat(fieldValues.maxSalary)
          ) {
            temp.minSalary =
              "Minimum salary should be less than or equal to the maximum salary.";
          } else if (minSalary < maxValue) {
            temp.maxSalary = "";
            temp.minSalary = "";

          }
          else {
            temp.minSalary = "";
          }
        } else {

          temp.minSalary = "This field is required.";
        }
      }
      if ("maxSalary" in fieldValues) {
        if (fieldValues.maxSalary) {
          const minSalary = parseFloat(values.minSalary);
          const maxSalary = parseFloat(fieldValues.maxSalary);
          if (maxSalary < 0) {
            temp.maxSalary = "Maximum salary cannot be negative.";
          }
          else if (maxSalary < parseFloat(values.minSalary)) {
            temp.maxSalary =
              "Maximum salary should be greater than or equal to the minimum salary.";
          }
          else if (minSalary > maxValue) {

            temp.maxSalary = "";
            temp.minSalary = "";

          } else {
            temp.maxSalary = "";
          }
        } else {
          temp.maxSalary = "This field is required.";
        }
      }

      if ("countryId" in fieldValues)
        temp.countryId = fieldValues.countryId && fieldValues.countryId != null
          ? "" : "This field is required.";

      if ("stateId" in fieldValues)
        temp.stateId = fieldValues.stateId && fieldValues.stateId != null
          ? "" : "This field is required.";
      if ("cityId" in fieldValues)
        temp.cityId = fieldValues.cityId && fieldValues.cityId != null
          ? "" : "This field is required.";

      if ("salaryCurrency" in fieldValues)
        temp.salaryCurrency = fieldValues.salaryCurrency && fieldValues.salaryCurrency != null
          ? ""
          : "This field is required.";
    }

    if (validationType == 1) {
      if ("interviewTypeId" in fieldValues) {
        temp.interviewTypeId = fieldValues.interviewTypeId
          ? ""
          : "This field is required.";
      }

      if ("date" in fieldValues) {
        temp.date = fieldValues.date ? "" : "This field is required.";
      }

      if ("time" in fieldValues) {
        temp.time = fieldValues.time ? "" : "This field is required.";
      }

      if ("location" in fieldValues) {
        temp.location = fieldValues.location ? "" : "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validates);

  const maxValue = values.maxSalary;

  const getHandleData = async (e) => {
    const res = await JobTypes();
    if (res && res.data) {
      setJobType(res.data);
    }
  };

  const getHandlePreferData = async (e) => {
    const res = await JobPreferLists();
    if (res && res.data) {
      setJobPrefer(res.data);
    }
  };
  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      setSkillData(res.data);
    }
  };

  useEffect(() => {
    getSkillList();
  }, []);


  const removeSkill = (e, item) => {
    setSkillData([...skillData, item]);
    const removeSkill = selectSkill.filter(
      (items) => items.skillId != item.skillId
    );
    setSelectSkill(removeSkill);
    if (item && item.id) {
      setDeleteSkill([...deleteSkill, item?.id]);
    }
  };

  //country Data
  const getContryData = async (e) => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList(res.data);
    }
  };

  useEffect(() => {
    getContryData();
  }, []);

  //city Data

  const handleCityList = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList(res.data);
    } else {
      setCityList([]);
    }
  };

  //state Data
  const handleStateList = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };

  useEffect(() => {
    getHandleData();
    getHandlePreferData();
  }, [skillData]);

  //edit the job
  let properties = {};

  // let datass1 = selectSkill?.map((item) => {
  //   if (item && item.id && item.id !== "") {
  //     properties = {
  //       skillId: item.skillId,
  //       title: item.skillName,
  //     };
  //   } else {
  //     properties = {
  //       skillId: item.skillId,
  //       jobPostId: jobPostList?.id,
  //       title: item.skillName,
  //     };
  //   }

  //   return properties;
  // });

  const jobDescriptionValidate = () => {
    if (convertedContent == "" || convertedContent == "<p></p>" || convertedContent == null) {
      setShow({
        isOpen: true,
        message: "Job Description is required",
        type: "error",
      });
      return false;
    }
    else {
      return true;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validates() && jobDescriptionValidate()) {
      if (selectSkill.length === 0) {
        setErrorMessage("Please select at least one skill.");
      } else {

        const body = {
          employerId: employerId?.user?.id,
          jobTitle: values.jobTitle,
          openings: values.openings,
          countryId: values.countryId,
          stateId: values.stateId,
          cityId: values.cityId,
          jobTypeId: values.jobTypeId,
          jobPreferId: values.jobPreferId,
          jobDescription: convertedContent,
          salCurrency: values.salCurrency,
          minSalary: values.minSalary,
          maxSalary: values.maxSalary,
          skillId: selectSkill,
          deleteSkill: deleteSkill,
        };
        const res = await employerJobDetailsUpdate(jobPostList?.id, body);

        if (res && res.status === true) {
          setPersonalInformation(false);
          setConvertedContent(null);
          setEditorState(() => EditorState.createEmpty());
          getitemList(employerId?.user?.id);
          setJobDetails(false);
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
        }
      }

    }
  };

  //handleClick in candidate option

  const handleOptionClick = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === "Reject") {
      setInterviewReject(true);
    }

    if (selectedOption === "Hire") {
      setInterviewHire(true);
    }
    if (selectedOption === "Interview Invite") {
      setFillInterviewDetails(true);
      setValidationType(1);
    }
    setStatusValue("");
  };
  //interview add
  const timeValidation = () => {
    if (values.time == "00:00") {
      setShow({
        isOpen: true,
        message: "Time is required",

        type: "error",

      });
      return false;
    }
    else {
      return true;
    }
  }


  const handleInterviewSubmit = async (e) => {
    e.preventDefault();
    if (validates() && timeValidation()) {
      const payload = {
        fresherId: fresherId,
        employerId: employerId?.user?.id,
        jobId: jobId,
        interviewTypeId: values.interviewTypeId,
        date: values.date,
        time: values.time,
        location: values.location,
        fresherReason: "",
        employerReason: "",
      };

      const res = await addInterviewJob(payload);

      if (res && res.status === true) {
        getitemList(employerId?.user?.id);
        setFillInterviewDetails(false);
        resetForm();
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  //interview type dropdown

  const getInterviewTypeList = async () => {
    const res = await InterviewDropdown();
    if (res) {
      setInterviewType(res?.data);
    } else {
    }
  };

  useEffect(() => {
    getInterviewTypeList();
  }, []);

  //reject employer candidate

  const handleRejectcandidate = async (e) => {
    e.preventDefault();


    if (values.employerReason == "" || values.employerReason == null) {
      setShow({
        isOpen: true,
        message: "Reason is required",
        type: "error",
      });
      return false;
    }
    else {
      const body = {
        employerReason: values.employerReason,
      };
      const res = await getRejectCandidateJob(jobId, interviewIdEmployer, body);

      if (res && res.status === true) {
        setInterviewReject(false);
        getitemList(employerId?.user?.id);

        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }

    }

  };

  //hire employer candidate

  const handleHirecandidate = async (e) => {
    e.preventDefault();

    const res = await getCandidateHire(jobId, interviewIdEmployer);

    if (res && res.status === true) {
      setInterviewHire(false);
      getitemList(employerId?.user?.id);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  //candidate details

  const getCandidateList = async (id, jobId) => {
    const res = await getCandidateDetails(id, jobId);
    if (res) {
      setCandiadteList(res?.data);
    } else {
      setCandiadteList([]);
    }
  };

  const handleCloseSubmit = async (e) => {
    e.preventDefault();

    const res = await employeeJobClose(jobPostId);

    if (res && res.status === true) {
      getitemList(employerId?.user?.id);
      setCloseJob(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const interviewstatus = (interviewstatus) => {
    const statusColors = {
      0: {
        key: "Review",
        backgroundColor: "white",
        color: "balck",
      },
      1: {
        key: "Schedule",
        backgroundColor: "white",
        color: "balck",
      },
      2: {
        key: "On going",
        backgroundColor: "white",
        color: "balck",
      },
      3: {
        key: "Completed",
        backgroundColor: "white",
        color: "balck",
      },
      4: {
        key: "Cancel by fresher",
        backgroundColor: "white",
        color: "balck",
      },
      5: {
        key: "cancel by employer",
        backgroundColor: "white",
        color: "balck",
      },
    };

    const status = statusColors[interviewstatus];

    return (
      <div
        style={{
          height: "30px",
          fontSize: "15px",
          // backgroundColor: status ? status.backgroundColor : "",
          color: status ? status.color : "",
        }}
      >
        {status ? status.key : ""}
      </div>
    );
  };

  const handleCloseOptionClick = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === "Close") {
      setCloseJob(true);
    }
  };

  const [activeAccordion, setActiveAccordion] = useState("");

  const handleAccordionToggle = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  const url = `http://localhost:3000/${process.env.DOMAIN}/faqs`;
  const modifiedUrl = url.replace(/^http:\/\/localhost:3000\//, "");
  const handleLinkClick = () => {
    window.location.href = modifiedUrl;
  };

  return (
    <main className="body-background">
      <Helmet>
              <meta charSet="utf-8" />
        <title>Employer Dashboard | Job Portal | Find Skilled Freshers</title>
        <meta name="description" content="Access your Job Portal employer dashboard to post jobs, search for talented freshers, and connect with motivated tech graduates ready to contribute to your team." />
      </Helmet>
      <AutohideExample show={show} setShow={setShow} />

      <div className="bg-cyan">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard-welcome-text py-5">
                <h3 className="mb-2 font-PTSerif-b">
                  Welcome, {employerId?.user?.firstName}{" "}
                  {employerId?.user?.lastName}!
                </h3>
                <p className="mb-0 font-16-24-05">
                  We are happy you are joining Job Portal. Start interviewing
                  to hire candidates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="rounded-card-head pt-3 pt-lg-5 mb-16">
          {itemList ? '' :
            <div className="row align-items-center">
              <div className="col">
                <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">
                  Your latest posted jobs
                </div>
              </div>
            </div>
          }
        </div>

        {itemList?.map((row) => {
          return (
            <>
              <div className="rounded-card mb-3 mb-md-4">
                <div className="row align-items-center justify-content-between mb-3">
                  <div className="col-auto d-flex align-items-center">
                    <span className="font-PTSerif-b font-18-24-05 mb-0 pb-0">
                      Job Details
                    </span>
                  </div>
                  <div className="col-auto">
                    <Controls.Button
                      className="btn btn-link text-decoration-none fw-semibold"
                      text="Job Full Details"
                      onClick={() => {
                        setJobDetails(true);
                        getJobList(row?.jobPostId);
                        setJobIds(row?.jobPostId)
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                    <div className="fw-medium font-18-24-05 mb-1 mb-1">Job Title</div>
                    <div className="font-16-24-05 mb-2">{row?.jobTitle}</div>
                  </div>

                  <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                    <div className="fw-medium font-18-24-05 mb-1 mb-1">Salary</div>
                    <div className="font-16-24-05 mb-2">
                      {row?.jobPostMinExp} - {row?.jobPostMaxExp} INR
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                    <div className="fw-medium font-18-24-05 mb-1 mb-1">Location</div>
                    <div className="font-16-24-05 mb-2">
                      {row?.cityId}, {row?.stateId} ,{row?.countryId}
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2 mb-3 mb-xl-0">
                    <div className="fw-medium font-18-24-05 mb-1 mb-1">Type</div>
                    <div className="font-16-24-05 mb-2">
                      {row?.jobtypeTitle}, {row?.jobpreferTitle}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl-2 mb-3 mb-xl-0">
                    <div className="fw-medium font-18-24-05 mb-1 mb-1">Skills Needed</div>
                    <div className="round-ship-style-1">
                      <ul className="d-flex flex-wrap">
                        {row?.jobPostSkills?.map((item) => {
                          return (
                            <>
                              <li>{item?.skillName}</li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <select
                      className="select-with-blue-btn h-33 w-100"
                      disabled={row?.jobPostStatus == 6 ? true : false}
                      value={row?.jobPostStatus == 6 ? "Close" : "Open"}
                      onChange={(e) => {
                        handleCloseOptionClick(e);
                        setJobPostId(row?.jobPostId);
                      }}
                    >
                      <option value="Open">Open</option>
                      <option value="Close">Close</option>
                    </select>
                  </div>
                </div>
                <div className="row justify-content-between mb-2 mt-4">
                  <div className="col-auto">
                    <span className="font-18-24-05 mb-1 fw-semibold">
                      Recommended candidates for this job
                    </span>
                  </div>
                </div>
                <div className="row">
                  {row?.jobFresherId === null ?
                    (
                      <div className="col-12 my-2">
                        <div className="gray-card-inner">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-lg-6 text-center py-lg-5">
                              <div className="">
                                <svg
                                  width="52"
                                  height="52"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                    stroke="#74777E"
                                    stroke-width="1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                    stroke="#74777E"
                                    stroke-width="1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="pt-2 font-PTSerif-b text-gray font-16-24-05">
                                Thank you for your interest in
                                Job Portal. We would love to help
                                you find top talent for your
                                organization. Our team will contact
                                you shortly
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (row?.fresher?.map((item) => {
                      return (
                        <>
                          <div className="col-12 my-2">
                            <div className="gray-card-inner">
                              <div className="row justify-content-between align-items-center">
                                <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                  <div className="row align-items-center">
                                    <div className="col-auto">
                                      <div className="interviewer-profile_1">
                                        {item?.profilePic ? (
                                          <img
                                            src={item?.profilePic}
                                            style={{
                                              maxWidth: "100%",
                                              height: "100px",
                                            }}
                                            alt=""
                                          />
                                        ) : ""}
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="font-18-24-05 mb-1 fw-medium">
                                        Name
                                      </div>
                                      <div className="font-16-24-05">
                                        {item?.fresherName} {item?.lastName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-4 mb-3 mb-md-0">
                                  <div className="font-18-24-05 mb-1 fw-medium">
                                    Matched Skills
                                  </div>
                                  <div className="mt-2">
                                    <ul>
                                      {item?.skill?.map((items) => {
                                        return (
                                          <>
                                            {item &&
                                              item.matchSkilll &&
                                              item.matchSkilll.filter(
                                                (item) => item == items.skillId
                                              ).length > 0 && (
                                                <li className="d-inline-block">
                                                  <div
                                                    className={
                                                      item &&
                                                        item.matchSkilll &&
                                                        item.matchSkilll.filter(
                                                          (item) =>
                                                            item == items.skillId
                                                        ).length > 0
                                                        ? "checkbox-round checkbox-check checkbox-round-md"
                                                        : "checkbox-round checkbox-round-md"
                                                    }
                                                  >
                                                    <label className="custom-checkbox">
                                                      {items?.title}
                                                      <Controls.Input
                                                        type="checkbox"
                                                        checked={
                                                          item &&
                                                            item.matchSkilll &&
                                                            item.matchSkilll.filter(
                                                              (item) =>
                                                                item ==
                                                                items.skillId
                                                            )
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </div>
                                                </li>
                                              )}
                                          </>
                                        );
                                      })}

                                      {/* <li className="d-inline-block ">
                                      <div className="checkbox-round checkbox-round-md">
                                        <label className="custom-checkbox">
                                          {" "}
                                          APIs
                                          <Controls.Input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </li> */}
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-auto mb-3 mb-md-0">
                                  <div class="fw-medium font-18-24-05 mb-1">
                                    Status
                                  </div>
                                  <div class="font-16-24-05 mb-2"> {interviewstatus(
                                    item?.status
                                  )}</div>
                                </div>
                                <div className="col-12 col-lg-4">
                                  <div className="row text-nowrap">
                                    <div className="col-md-6 mb-2 pe-md-0">
                                      <select
                                        className="select-with-blue-btn h-33 w-100"
                                        value={statusValue}
                                        onChange={(e) => {
                                          handleOptionClick(e);
                                          setFresherId(item?.fresherId);
                                          setJobId(row?.jobPostId);
                                          setInterviewIdEmployer(item?.fresherId);
                                        }}
                                      >
                                        <option value="">Select Option</option>
                                        {item?.status == 3 || item?.status == 4 ? null : (
                                          <option value="Interview Invite">
                                            Interview Invite
                                          </option>
                                        )}
                                        {item?.status == 3 ? null : (
                                          <option value="Reject">Reject</option>
                                        )}
                                        {item?.status !== 2 ? null : (
                                          <option value="Hire">Hire</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                      <Controls.Button
                                        className="btn btn-link text-decoration-none w-100 h-32 fw-medium"
                                        text="Candidate Details"
                                        onClick={() => {
                                          setCandidateInformation(true);
                                          getCandidateList(item?.fresherId, row?.jobPostId);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }))}


                </div>
              </div>
            </>
          );
        })}

        {InterviewCalenderList && InterviewCalenderList.length > 0 && (<>
          <div className="rounded-card-head pt-3 pt-lg-4 mb-2">
            <div className="row align-items-center">

              <div className="col">
                <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">
                  Interviews Calendar
                </div>
              </div>


            </div>
          </div>


          <div className="row mb-3">
            {InterviewCalenderList?.map((row) => {
              return (
                <>
                  {row && row.id ? (
                    <div className="col-12 col-md-6 col-lg-4 my-3">
                      <div className="rounded-card border-radius-8 h-100">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <div className="interviewer-profile box-42">
                              <img
                                src={row?.profilePhoto}
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col ps-0">
                            <div className="font-16-24-05 fw-semibold">
                              {row?.firstName}
                              {row?.lastName}
                            </div>
                            <div className="font-16-24-05">{row?.jobTitle}</div>
                          </div>
                        </div>
                        <div className="row align-items-center my-3">
                          <div className="col-6">
                            <div className="font-16-24-05 fw-semibold">Date</div>
                            <div className="font-16-24-05">
                              {moment(row.date).format("DD-MM-YYYY ")}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="font-16-24-05 fw-semibold">Time</div>
                            <div className="font-16-24-05">{row?.time}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                setJobDetails(true);
                                getJobList(row?.jobId);
                              }
                              }
                              className="btn btn-primary h-46 fw-medium w-100"
                            >
                              Go to job post
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        </>
        )}

        <div className="rounded-card-head pt-3 mb-16">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">About us</div>
            </div>
          </div>
        </div>

        <div className="rounded-card mb-5">
          <div className="row align-items-center flex-lg-row-reverse">
            <div className="col-lg-6">
              <img src="https://dev-job-portal.s3.ap-south-1.amazonaws.com/about_us/1686294584999.png" className="w-100" />
            </div>
            <div className="col-lg-6">
              <p className="font-18-24-05 lh-24 mb-0 pb-2">At Job Portal, we understand how challenging it can be for employers to find the right talent for their organizations. That's why we've created a platform that connects fresh tech graduates with employers looking to hire top talent.</p>
              <p className="font-18-24-05 lh-24 mb-0 pb-2">Our platform uses a sophisticated algorithm to match job requirements with the most suitable candidates based on their skills and preferences. We provide access to skill assessments and coding challenges reports, which offer a more comprehensive understanding of a candidate's capabilities.</p>
              <p className="font-18-24-05 lh-24 mb-0 pb-2">Employers can browse through detailed candidate profiles to evaluate their skill and capabilities. Our goal is to provide employers with a high-quality pool of candidates, making the hiring process efficient and informed.</p>
              <p className="font-18-24-05 lh-24 mb-0 pb-2">At Job Portal, we are committed to helping employers find the best talent for their organizations, making it easier for them to achieve their business goals.</p>
            </div>
          </div>
        </div>

        <div className="rounded-card-head pt-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">
                Why join Job Portal?
              </div>
            </div>
          </div>
        </div>

        <div className="row join-tech-fresher-wrap mb-3">
          {whyjoinusList?.map((row) => {
            return (
              <>
                <div className="col-6 col-md-6 col-lg-3 my-3">
                  <div className="rounded-card h-100 text-center text-md-start">
                    <div className="join-tech-fresher-img">
                      <img src={row?.image} className="w-100 h-100" />
                    </div>
                    <div className="font-18-24-05 mt-3">
                      {row?.description}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <div className="rounded-card-head pt-3 mb-16">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 pb-0 font-18-24-05">FAQs</div>
            </div>
            <div className="col col-md-auto text-end">
              <a
                href={`${process.env.DOMAIN}/faqs`}
                target="_blank"
                className="pe-2 btn btn-link text-decoration-none text-primary card-head-action"
              >
                More FAQs
              </a>
            </div>
          </div>
        </div>

        <div className="rounded-card p-0 mb-5">
          {faqList?.map((row, index) => {
            return (
              <>
                <Accordion
                  key={index}
                  activeKey={activeAccordion}
                  onSelect={handleAccordionToggle}
                  className="accordion-transparent"
                >
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{row?.question}</Accordion.Header>
                    <Accordion.Body className="font-16-24-05 pt-0 px-4 mx-1">
                      {row?.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            );
          })}
        </div>

        {/* <footer className="footer-copyright font-18-24-05 mb-1 text-center py-3">
          TechFresher 2023 copyright received
        </footer> */}
      </div>

      <Modal
        size="xl"
        show={JobDetails}
        onHide={() => setJobDetails(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton

        ></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 mb-1">Job Details</div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Job Title</div>
              <div className="font-16-24-05 mb-2">
                {jobPostList?.jobTitle}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Number of openings</div>
              <div className="font-16-24-05 mb-2">
                {jobPostList?.openings}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Salary</div>
              <div className="font-16-24-05 mb-2">
                {" "}
                {jobPostList?.minSalary} - {jobPostList?.maxSalary} INR
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Location</div>
              <div className="font-16-24-05 mb-2">
                {jobPostList?.cityName} {jobPostList?.stateName}{" "}
                {jobPostList?.countryName}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Type</div>
              <div className="font-16-24-05 mb-2">
                {" "}
                {jobPostList?.jobTypeId?.title},{" "}
                {jobPostList?.jobPreferId?.title}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="font-18-24-05 mb-1 fw-semibold mb-1">
                Required Skills
              </div>
            </div>
            <div className="col-12 col-lg-12 mb-16">
              <div className="round-ship-style-1">
                <ul className="d-flex flex-wrap">
                  {jonPostSkillList?.map((row) => {
                    return (
                      <>
                        <li>{row?.skillName}</li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="font-18-24-05 mb-1 fw-semibold mb-3">
                Job Description
              </div>
            </div>
            <div className="col-12 col-lg-12">
              <div dangerouslySetInnerHTML={{ __html: jobPostList?.jobDescription }} />
              {/* <div className="font-16-24-05">{jobPostList?.jobDescription}</div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
          <Button
            variant="outline-danger"
            className="h-44 fw-medium mx-4"
            onClick={() => {
              setIgnoreJob(true);
              setDeleteId(jobPostList?.id);
            }}
          >
            Delete
          </Button>
          <Button
            variant="primary"
            className="h-44 fw-medium m-0"
            onClick={() => {
              setPersonalInformation(true);
              setDeleteId(jobPostList?.id);
              setValidationType(2);
              setJobDetails(false);
            }}
          >
            Edit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={FillInterviewDetails}
        onHide={() => {
          setFillInterviewDetails(false)
          resetForm();
        }}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleInterviewSubmit}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-4">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-18-24-05 mb-1 fw-semibold">
                  Fill Interview Details
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-6 mb-3">
                <div className="form-label">
                  The interview will be
                </div>
                <div className="blue-tabs-wrap">
                  <ul className="d-flex font-18-24-05 pb-0 mb-1 text-center">
                    <li
                      onClick={(e) => {
                        setTab(1);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 1 ? "active" : ""}`}
                    >
                      In-Person
                    </li>
                    <li
                      onClick={(e) => {
                        setTab(2);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 2 ? "active" : ""}`}
                    >
                      Online
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl mb-3">
                <div className="form-label">Interview Type</div>
                <select
                  className="form-select form-control"
                  name="interviewTypeId"
                  onChange={handleInputChange}
                  value={values.interviewTypeId}
                >
                  <option value="">None</option>
                  {interviewType?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.interviewTypeId) ? (
                  <p className="text-invalid">{errors.interviewTypeId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Time</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter interview time"
                  name="time"
                  type="time"
                  lable="Enter interview time"
                  onChange={handleInputChange}
                  value={values.time}
                />
                {Boolean(errors.time) ? (
                  <p className="text-invalid">{errors.time}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Date</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter interview date"
                  name="date"
                  type="date"
                  lable="Enter interview date"
                  onChange={handleInputChange}
                  value={values.date}
                  min={new Date().toISOString().split("T")[0]}
                />
                {Boolean(errors.date) ? (
                  <p className="text-invalid">{errors.date}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                {acctiveTab === 2 ? (
                  <div className="form-label">Interview Link</div>
                ) : (
                  <div className="form-label">Interview Location</div>
                )}
                <Controls.Input
                  className="form-control"
                  placeholder={
                    acctiveTab === 2
                      ? "Enter interview link"
                      : "Enter interview location"
                  }
                  name="location"
                  type="text"
                  lable="Enter interview location"
                  onChange={handleInputChange}
                  value={values.location}
                />
                {Boolean(errors.location) ? (
                  <p className="text-invalid">{errors.location}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Send
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="md"
        show={InterviewReject}
        onHide={() => setInterviewReject(false)}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleRejectcandidate}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-4">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-18-24-05 mb-1 fw-semibold mb-2">
                  Do you want to reject this candidate?
                </div>
                <div className="font-16-24-05">
                  If yes, Please provide the feedback
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <textarea
                  className="form-control h-auto"
                  placeholder="Eg. I didn't like this candidate because..."
                  rows="4"
                  name="employerReason"
                  type="text"
                  value={values?.employerReason}
                  onChange={handleInputChange}
                ></textarea>
                {/* <div className="opacity-75">Minimum 100 characters</div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-4"
              type="submit"
            >
              Yes, Reject
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              onClick={() => setInterviewReject(false)}
            >
              No, Keep candidate
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="xl"
        show={CandidateInformation}
        onHide={() => setCandidateInformation(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 mb-1 pb-0">
                Candidate Informations
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5">
              <div className="interviewer-profile_1 mx-auto">
                <img src={candidateList?.fresherId?.profilePhoto} height='100px' />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Name</div>
              <div className="font-16-24-05 mb-2">
                {candidateList?.fresherId?.firstName} {candidateList?.fresherId?.lastName}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Email</div>
              <div className="font-16-24-05 mb-2">
                {candidateList?.fresherId?.email}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Phone Number</div>
              <div className="font-16-24-05 mb-2">
                {candidateList?.fresherId?.countryCode}
                {candidateList?.fresherId?.mobile}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3 mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Resume</div>

              <div className="font-16-24-05 mb-2 d-inline-block">
                <a
                  href="#"
                  className="d-flex btn btn-link"
                  onClick={handleDownload}
                >
                  <div className="card-head-action-icon pe-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_871_17631)">
                        <path
                          d="M8 17L12 21L16 17"
                          stroke="#74777E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 12V21"
                          stroke="#74777E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.8799 18.0901C21.7493 17.4787 22.4013 16.6062 22.7412 15.5992C23.0811 14.5922 23.0913 13.5031 22.7703 12.4899C22.4493 11.4767 21.8138 10.5921 20.956 9.96462C20.0982 9.33709 19.0628 8.99922 17.9999 9.00006H16.7399C16.4392 7.82793 15.8764 6.7393 15.0941 5.81614C14.3117 4.89297 13.3301 4.15932 12.2231 3.67041C11.1162 3.1815 9.91278 2.95008 8.70346 2.99357C7.49414 3.03706 6.31045 3.35433 5.24149 3.92148C4.17253 4.48864 3.24616 5.2909 2.53212 6.26788C1.81808 7.24487 1.33498 8.3711 1.11919 9.5618C0.903403 10.7525 0.960543 11.9767 1.28631 13.1421C1.61208 14.3075 2.19799 15.3838 2.99993 16.2901"
                          stroke="#74777E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_871_17631">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>{" "}
                  <Tooltip
                    title={candidateList?.resume?.fileLocation}
                  ></Tooltip>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">Education</div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">College</div>
              <div className="font-16-24-05 mb-2">
                {candidateList?.fresherId?.college}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Level of education</div>
              <div className="font-16-24-05 mb-2">
                {candidateList?.fresherId?.educationLevel}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Field of study</div>
              <div className="font-16-24-05 mb-2">
                {candidateList?.fresherId?.studyField}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">Start Date</div>
              <div className="font-16-24-05 mb-2">
                {console.log("0-0-0-00-0-0",candidateList?.fresherId?.eduStartDate)}
                {moment(candidateList?.fresherId?.eduStartDate).format("DD-MM-YYYY")}
              </div>
            </div>
            {/* <div className="col-6 col-md-4 col-xl mb-3">
              <div className="fw-medium font-18-24-05 mb-1">End Date</div>
              <div className="font-16-24-05 mb-2">
                {moment(candidateList?.eduEndDate).format("MM-DD-YYYY LT")}
              </div>
            </div> */}
            <div className="col-6 col-md-4 col-xl mb-3">
              <div class="fw-medium font-18-24-05 mb-1">End Date</div>

              {/* <div className="font-16-24-05 mb-2"> */}

              {candidateList?.fresherId?.studyRunning == 0 ? (
                <>

                  <div className="font-16-24-05 mb-2">
                    {moment(candidateList?.fresherId?.eduEndDate).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </>
              ) : (
                <div className="font-16-24-05 mb-2">
                  <div class=" font-16-24-05 mb-1">Still enrolled</div>
                </div>
              )}
              {/* </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">
                Candidate's Skills
              </div>
            </div>
            <div className="mt-0 mb-3">
              <ul>
                {candidateList?.fresherSkill?.map((items) => {
                  return (
                    <>
                      {(
                        // candidateList &&
                        // candidateList.matchSkill &&
                        // candidateList?.matchSkill.filter(
                        //   (item) => Number(item) != items.skillId
                        // ).length > 0 && 
                        <li className="d-inline-block">
                          <div
                            className={
                              candidateList &&
                                candidateList.matchSkill &&
                                candidateList?.matchSkill.filter(
                                  (item) => {
                                    return Number(item) == items.skillId;
                                  }
                                ).length > 0
                                ? "checkbox-round checkbox-check checkbox-round-md"
                                : "checkbox-round checkbox-round-md"
                            }
                          >
                            <label className="custom-checkbox">
                              {items?.fskilltitle}
                              <Controls.Input
                                type="checkbox"
                                checked={
                                  candidateList &&
                                    candidateList.matchSkill &&
                                    candidateList?.matchSkill.filter(
                                      (item) => item == items.skillId
                                    ).length > 0
                                    ? true
                                    : false
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      )}

                    </>
                  );
                })}

                {/* <li className="d-inline-block ">
                                      <div className="checkbox-round checkbox-round-md">
                                        <label className="custom-checkbox">
                                          {" "}
                                          APIs
                                          <Controls.Input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </li> */}
              </ul>

              {/* <ul> */}
              {/* <li className="d-inline-block">
                  <div className="checkbox-round checkbox-check">
                    <label className="custom-checkbox">
                      {" "}
                      Java
                      <Controls.Input type="checkbox" checked />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </li>
                <li className="d-inline-block">
                  <div className="checkbox-round checkbox-check">
                    <label className="custom-checkbox">
                      {" "}
                      C#
                      <Controls.Input type="checkbox" checked />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </li>
                <li className="d-inline-block">
                  <div className="checkbox-round">
                    <label className="custom-checkbox">
                      {" "}
                      APIs
                      <Controls.Input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </li> */}
              {/* </ul> */}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="fw-semibold font-18-24-05 mb-1">Candidate Score</div>
            </div>
            <div className="col-12 px-lg-5">
              <div className="row">
                {candidateList && candidateList?.userexamdata && candidateList?.userexamdata?.map((items) => {
                  return (
                    items.isPass == 1 && (
                      <div className="col-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-3">
                        <div className="level-card rounded-3 text-center p-3">
                          <div className="font-18-24-05 mb-1 fw-medium mb-1">
                            {items?.examType == 1 ? 'Soft Skill Assessment' : items?.examType == 2 ? 'Technical Assessment' : 'Coding Assessment'}
                          </div>
                          <div className="font-18-24-05 mb-1">{items?.skilltitle != "" ? items?.skilltitle : items?.examType == 3 ? 'Coding Challenge' : ''}</div>
                          <div className="d-flex justify-content-center align-items-center py-3">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="#FEC348"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                                stroke="#FEC348"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="ps-1 pt-1 font-16-24-05">{
                              items?.percentage > 90 ? '5/5' : items?.percentage > 74 ? '4.5/5' : items?.percentage > 49 ? '4/5' : items?.percentage > 34 ? '3.5/5' : items?.percentage > 24 ? '3/5' : '0/5'
                            } </span>
                          </div>
                          <div className="font-18-24-05 mb-1 opacity-75 mb-1">
                            {items?.totalTime} minutes
                          </div>
                        </div>
                      </div>
                    )
                  )
                })}
                {/* <div className="col-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-3">
                  <div className="level-card rounded-3 text-center p-3">
                    <div className="font-18-24-05 mb-1 fw-medium mb-1">
                      Soft Skill Assessment
                    </div>
                    <div className="font-18-24-05 mb-1">Soft Skill</div>
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="#FEC348"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                          stroke="#FEC348"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="ps-1 pt-1 font-16-24-05">4/5</span>
                    </div>
                    <div className="font-18-24-05 mb-1 opacity-75 mb-1">
                      32 minutes
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-3">
                  <div className="level-card rounded-3 text-center p-3">
                    <div className="font-18-24-05 mb-1 fw-medium mb-1">
                      Soft Skill Assessment
                    </div>
                    <div className="font-18-24-05 mb-1">Soft Skill</div>
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="#FEC348"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                          stroke="#FEC348"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="ps-1 pt-1 font-16-24-05">4/5</span>
                    </div>
                    <div className="font-18-24-05 mb-1 opacity-75 mb-1">
                      32 minutes
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-3">
                  <div className="level-card rounded-3 text-center p-3">
                    <div className="font-18-24-05 mb-1 fw-medium mb-1">
                      Soft Skill Assessment
                    </div>
                    <div className="font-18-24-05 mb-1">Soft Skill</div>
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="#FEC348"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                          stroke="#FEC348"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="ps-1 pt-1 font-16-24-05">4/5</span>
                    </div>
                    <div className="font-18-24-05 mb-1 opacity-75 mb-1">
                      32 minutes
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={IgnoreJob}
        onHide={() => setIgnoreJob(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 mb-1 fw-medium">
            Do you want to Delete this job?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => {
              handleDeleteJob(deleteId);
            }}
          >
            Yes
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => setIgnoreJob(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={PersonalInformation}
        onHide={() => {
          setPersonalInformation(false)
          setConvertedContent(null);
          setEditorState(() => EditorState.createEmpty());
        }}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="border-0" closeButton onClick={(e) => {
            getJobList(jobIds)
          }}
          ></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">Update Job Post</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Job title</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter job title"
                  name="jobTitle"
                  type="text"
                  lable="Enter job title"
                  onChange={handleInputChange}
                  value={values.jobTitle}
                />
                {Boolean(errors.jobTitle) ? (
                  <p className="text-invalid">{errors.jobTitle}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Job description</div>
                {/* <textarea
                  className="form-control h-auto"
                  placeholder="Enter the description of the job"
                  rows="4"
                  name="jobDescription"
                  onChange={handleInputChange}
                  value={values.jobDescription}
                  maxLength={1000}
                ></textarea> */}
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                // onblur={() => handleSubmit()}
                />

                {Boolean(errors.jobDescription) ? (
                  <p className="text-invalid">{errors.jobDescription}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-label">Number of openings</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Number of openings"
                  name="openings"
                  type="number"
                  lable="Number of openings"
                  onChange={handleInputChange}
                  value={values.openings}
                />
                {Boolean(errors.openings) ? (
                  <p className="text-invalid">{errors.openings}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-label">Job location</div>
              </div>

              <div className="col-12 col-md-4 pe-md-0 mb-2">
                <select
                  className="form-select form-control"
                  name="countryId"
                  onChange={(e) => {
                    handleInputChange(e);
                    handleStateList(e.target.value);
                  }}
                  value={values.countryId}
                >
                  <option value=""> None</option>
                  {countryList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {Boolean(errors.countryId) ? (
                  <p className="text-invalid">{errors.countryId}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-4 mb-2">
                <select
                  className="form-select form-control"
                  name="stateId"
                  onChange={(e) => {
                    handleInputChange(e);
                    handleCityList(e.target.value);
                  }}
                  value={values.stateId}
                >
                  <option value=""> None</option>
                  {stateList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {Boolean(errors.stateId) ? (
                  <p className="text-invalid">{errors.stateId}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-4 ps-md-0 mb-2">
                <select
                  className="form-select form-control"
                  name="cityId"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={values.cityId}
                >
                  <option value=""> None</option>
                  {cityList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {Boolean(errors.cityId) ? (
                  <p className="text-invalid">{errors.cityId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-label">Job type</div>
              </div>
              <div className="col-12 col-md-6 mb-16">
                <select
                  className="form-select form-control"
                  name="jobTypeId"
                  onChange={handleInputChange}
                  value={values.jobTypeId}
                >
                  <option value="">None</option>
                  {jobType.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.jobTypeId) ? (
                  <p className="text-invalid">{errors.jobTypeId}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <select
                  className="form-select form-control"
                  name="jobPreferId"
                  onChange={handleInputChange}
                  value={values.jobPreferId}
                >
                  <option value="">None</option>
                  {jobPrefer.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.jobPrefer) ? (
                  <p className="text-invalid">{errors.jobPrefer}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="col-12 col-md-12 mb-16">
                  <div className="form-label">Skills</div>
                  <Multiselect
                    options={skillData} // Options to display in the dropdown
                    selectedValues={selectSkill} // Preselected value to persist in dropdown
                    onSelect={multiselectSelectSkill} // Function will trigger on select event
                    onRemove={removeSkill}  // Function will trigger on remove event
                    // displayValue={(option) => (option ? option.skillName : "")} // Property name to display in the dropdown options
                    displayValue="title"
                    disable={true}
                  />
                  {errorMessage && (
                    <div className="text-invalid">{errorMessage}</div>
                  )}
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-label">Salary range</div>
              </div>
              <div className="col-12 col-md-3 mb-16">
                <select
                  className="form-select form-control"
                  name="salaryCurrency"
                  onChange={handleInputChange}
                  value={values.salaryCurrency}
                >
                  <option>INR</option>
                  <option>USD</option>
                </select>
                {Boolean(errors.salaryCurrency) ? (
                  <p className="text-invalid">{errors.salaryCurrency}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md mb-16">
                <Controls.Input
                  className="form-control"
                  placeholder="Minimum"
                  name="minSalary"
                  type="number"
                  lable="SkiMinimumlls"
                  onChange={handleInputChange}
                  value={values.minSalary}
                />
                {Boolean(errors.minSalary) ? (
                  <p className="text-invalid">{errors.minSalary}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md mb-16">
                <Controls.Input
                  className="form-control"
                  placeholder="Maximum"
                  name="maxSalary"
                  type="number"
                  lable="Maximum"
                  onChange={handleInputChange}
                  value={values.maxSalary}
                />
                {Boolean(errors.maxSalary) ? (
                  <p className="text-invalid">{errors.maxSalary}</p>
                ) : (
                  ""
                )}
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => {
                getJobList(jobIds)
                setPersonalInformation(false)
                setConvertedContent(null);
                setEditorState(() => EditorState.createEmpty());
              }
              }
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="md"
        show={InterviewHire}
        onHide={() => setInterviewHire(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-18-24-05 mb-1 fw-semibold mb-2">
                Do you want to Hire this candidate?
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
          <Button
            variant="outline-primary"
            className="h-44 fw-medium mx-4"
            onClick={handleHirecandidate}
          >
            Yes
          </Button>
          <Button
            variant="primary"
            className="h-44 fw-medium m-0"
            onClick={() => setInterviewHire(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={CloseJob}
        onHide={() => setCloseJob(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 mb-1 fw-medium">
            Do you want to Close this job?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={handleCloseSubmit}
          >
            Yes
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => {
              setCloseJob(false)
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default EmployerDashboard;
