import axiosInstance from "../../utills/AxiosInstance";

//admin list
export const getFAQlists = async (querystring) => {
    try {
        const response = await axiosInstance.get('/v1/faqAdminList'+querystring)

        return response.data;
    } catch (error) {
        return error;
    }
};


//Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/faqStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add FAQ
export const AddFAQData = async (payload) => {
  try {
    const response = await axiosInstance.post(`/v1/addFaq`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update 
export const UpdateFAQ = async (id,payload) => {
  try {
    const response = await axiosInstance.put(`/v1/editFaq/${id}`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};





// delete 

export const deleteFAQ = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteFaq/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }

};


