import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Controls from "../../components/controls/Controls";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getPageList,
  getPagePermissionList,
  addPermissionRole,
} from "../../state/action/addRoleAction";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";

const AddPermission = () => {
  const { state } = useLocation();
  // getPagesListAPI();

  const roleId = state?.id;
  const navigate = useNavigate();

  const [finalList, setFinalList] = useState([]);
  const [pagesList, setPagesList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [status, setStatus] = useState({ loading: true, error: false });
  const [page, setPage] = useState(1);
  const [view, setView] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleCheckboxes = (index, purpose) => {
    var clone = [...finalList];
    if (purpose === "readP") {
      if (clone[index].readP === 1) {
        clone[index].readP = 2;
      } else {
        clone[index].readP = 1;
      }
    } else if (purpose === "writingP") {
      if (clone[index].writingP === 1) {
        clone[index].writingP = 2;
      } else {
        clone[index].writingP = 1;
      }
    } else if (purpose === "deleteP") {
      if (clone[index].deleteP === 1) {
        clone[index].deleteP = 2;
      } else {
        clone[index].deleteP = 1;
      }
    }
    setFinalList(clone);
  };

  const modify = () => {
    var newClone = [...finalList];
    newClone = newClone.map((list) => {
      delete list.status;
      // delete list.id;
      delete list.pageName;
      return list;
    });
    return newClone;
  };

  const getPagesListAPI = async () => {
    try {
      const queryString = `?limit=10&page=${page}`;
      var response = await getPageList(queryString);
      if (response && response.status) {
        setStatus({ loading: false, error: false });
        setPagesList(response.data);
      } else {
        setStatus({ loading: false, error: false });
        setPagesList([]);
      }
    } catch (error) {
      setStatus({ loading: false, error: false });
      setPagesList([]);
    }
  };

  const initialization = () => {
    getPagePermissionListAPI();
  };
  useEffect(() => {
    initialization();
  }, []);

  const openStatusModal = () => {
    setStatusModal(true);
  };
  const closeStatusModal = () => {
    setStatusModal(false);
  };

  const mergePages_Permissions = () => {
    var clonePages = [...pagesList];
    pagesList.map((pages, outerIndex) => {
      const pageIds = pages.id;

      const index = permissionList.findIndex((list) => list.pageId === pageIds);
      if (index === -1) {
        var element = {
          readP: 2,
          writingP: 2,
          deleteP: 2,
          pageId: pages.id,
          roleId: roleId,
        };
        delete clonePages[outerIndex].id;
      } else {
        var element = permissionList[index];
        delete element.createdAt;
        delete element.updatedAt;
        delete element.slug;
      }
      clonePages[outerIndex] = { ...clonePages[outerIndex], ...element };
    });
    setFinalList(clonePages);
  };

  useEffect(() => {
    if (pagesList.length !== 0) {
      mergePages_Permissions();
    }
  }, [pagesList, permissionList]);
  useEffect(() => {
    if (permissionList.length !== 0) {
      getPagesListAPI();
    }
  }, [permissionList]);

  const getPagePermissionListAPI = async () => {
    try {
      const body = {
        roleId: roleId,
      };
      var response = await getPagePermissionList(body);
      if (response && response.status) {
        setStatus({ loading: false, error: false });
        setPermissionList(response.data);
      } else {
        setStatus({ loading: false, error: false });
        setPermissionList([]);
      }
    } catch (error) {
      setStatus({ loading: false, error: false });
    }
  };

  const onUpdatePermission = async () => {
    setView(true);

    const body = {
      permission: modify(),
    };

    const response = await addPermissionRole(body);
    if (response && response.status) {
      setShow({
        isOpen: true,
        message: "Permission Updated Successfully",
        type: "success",
      });
      setView(false);
      initialization();
      getPagesListAPI();
    } else {
      setShow({
        isOpen: true,
        message: response.message,
        type: "error",
      });
      setView(false);
    }
    closeStatusModal();
  };

  return (
    <div className="container-fluid">

      <div className="row">
        <div className="col-12">
          <div>
            <AutohideExample show={show} setShow={setShow} />


            {!view && (
              <>
                <div className="theme-table-wrap">
                  <Table responsive className="striped-table mb-0">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Page Name</th>
                        <th>Read</th>
                        <th>Write</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {finalList &&
                        finalList.map((row, index) => (
                          <tr
                            //   key={row.id}
                            className="border rounded"
                            style={{ backgroundColor: "white" }}
                          >
                            <td style={{ width: "20%" }} className="table-cell">
                              {index + 1}
                            </td>
                            <td style={{ width: "20%" }} className="table-cell">
                              {row.pageName}
                            </td>
                            <td style={{ width: "20%" }} className="table-cell">
                              <Controls.Input
                                name="readP"
                                type="checkbox"
                                defaultChecked={row && row.readP === 1}
                                onChange={(e) => handleCheckboxes(index, "readP")}
                              />
                            </td>
                            <td style={{ width: "20%" }} className="table-cell">
                              <Controls.Input
                                name="writingP"
                                type="checkbox"
                                defaultChecked={row && row.writingP == 1}
                                onChange={(e) => handleCheckboxes(index, "writingP")}
                              />
                            </td>
                            <td style={{ width: "20%" }} className="table-cell">
                              <Controls.Input
                                name="deleteP "
                                type="checkbox"
                                // checked={row && row.deleteP === 1}
                                defaultChecked={row && row.deleteP === 1}
                                onChange={(e) => handleCheckboxes(index, "deleteP")}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <button
                  onClick={() => {
                    openStatusModal();
                  }}
                  className="btn btn-primary"
                  style={{ float: "right", marginTop: "20px" }}
                >
                  Submit
                </button>
              </>
            )}

            <Loader view={view} />



            <Modal
              size="md"
              show={statusModal}
              onHide={closeStatusModal}
              aria-labelledby=""
              centered
            >
              <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
              <Modal.Body>
                <p className="mb-0 font-18-24-05 fw-medium">
                  Are you sure you want to Update ?
                </p>
              </Modal.Body>
              <Modal.Footer className="border-0 pt-0">
                <Button
                  className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                  onClick={closeStatusModal}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                  onClick={() => {
                    onUpdatePermission();
                    navigate("/admin/role");
                  }}

                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>

  );
};

export default AddPermission;
