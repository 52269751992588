import React, { useEffect, useState } from "react";
import Controls from "../../../components/controls/Controls";
import { useForm, Form } from "../../../utills/useForms";
import { useDispatch, useSelector } from "react-redux";
import {
  getNumberEmployer,
} from "../../../state/action/employerAction";
import {
  addEmployerDataRedux,
} from "../../../state/action/authAction";
import { CountryCode } from "../../../state/action/employerAction";
import { useLocation, useNavigate } from "react-router-dom";

const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  website: "",
  companyName: "",
  noOfEmployees: "",
};

const EmployerPersonalInformation = (props) => {
  const dispatch = useDispatch();

  // const [activeTab, setActiveTab] = useState(1);
  const { activeTab, setActiveTab } = props;
  const [countryCode, setCountryCode] = useState([]);
  const [employerList, setEmployerList] = useState(null);

  const data = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    if (data.length !== 0) {
      setValues({
        ...data,
        firstName: data?.firstName,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
        website: data?.website,
        companyName: data?.companyName,
        noOfEmployees: data?.noOfEmployees,
      });

    }
  }, [data]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };


    if ("firstName" in fieldValues) {
      if (fieldValues.firstName !== "" && fieldValues.firstName !== undefined && fieldValues.firstName != null) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.firstName)) {
          temp.firstName = "Invalid First Name";
        } else {
          temp.firstName = "";
        }
      } else {
        temp.firstName = "This field is required.";
      }
    }

    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName && fieldValues.companyName != null
        ? ""
        : "This field is required.";

    if ("lastName" in fieldValues) {
      if (fieldValues.lastName !== "" && fieldValues.lastName !== undefined && fieldValues.lastName != null) {
        if (!/^[a-zA-Z ]*$/.test(fieldValues.lastName)) {
          temp.lastName = "Invalid Last Name";
        } else {
          temp.lastName = "";
        }
      } else {
        temp.lastName = "This field is required.";
      }
    }

    if ("noOfEmployees" in fieldValues)
      temp.noOfEmployees = fieldValues.noOfEmployees && fieldValues.noOfEmployees != null
        ? ""
        : "This field is required.";

    if ("phoneNumber" in fieldValues) {
      if (
        fieldValues.phoneNumber !== "" &&
        fieldValues.phoneNumber !== undefined && fieldValues.phoneNumber != null
      ) {
        const regex = /^\d{10}$/;
        if (!regex.test(fieldValues.phoneNumber)) {
          temp.phoneNumber = "Invalid Phone Number";
        } else {
          temp.phoneNumber = "";
        }
      } else {
        temp.phoneNumber = "This field is required.";
      }
    }

    // if ("website" in fieldValues) {
    //   if (fieldValues.website !== "" && fieldValues.website !== undefined) {
    //     const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    //     if (!regex.test(fieldValues.website)) {
    //       temp.website = "Invalid website";
    //     } else {
    //       temp.website = "";
    //     }
    //   }
    // }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const getCountryCode = async () => {
    const res = await CountryCode();
    if (res && res.data) {
      setCountryCode(res.data);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const data = await dispatch(addEmployerDataRedux(values));
      setActiveTab(2);
    }
  };

  useEffect(() => {
    getCountryCode();
  }, []);

  const handleInputLength = (event) => {
    const maxLength = 5;
    const input = event.target;
    const value = input.value;

    if (value.length > maxLength) {
      input.value = value.slice(0, maxLength);
    }
  };

  //opening type dropdown

  const getNumberList = async () => {
    const res = await getNumberEmployer();
    if (res) {
      setEmployerList(res?.data);
    } else {
    }
  };

  useEffect(() => {
    getNumberList();
  }, []);

  return (
    <main>
      <div className="">
        <div className="row">
          <div className="col-lg-12 align-self-center">
            <div className="login-box-width">
              <div className="row">
                <div className="col-lg-12 align-self-center">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-16">
                        <div className="form-label">First name</div>
                        <Controls.Input
                          className="form-control"
                          placeholder="First name"
                          name="firstName"
                          type="text"
                          lable="first name"
                          onChange={handleInputChange}
                          maxLength="10"
                          value={values.firstName}
                        />
                        {Boolean(errors.firstName) ? (
                          <p className="text-invalid">{errors.firstName}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-16">
                        <div className="form-label">Last name</div>
                        <Controls.Input
                          className="form-control"
                          placeholder="Last name"
                          name="lastName"
                          type="text"
                          lable="last name"
                          onChange={handleInputChange}
                          maxLength="10"
                          value={values.lastName}
                        />
                        {Boolean(errors.lastName) ? (
                          <p className="text-invalid">{errors.lastName}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-16">
                      <div className="form-label">Phone number</div>
                      <div className="position-relative phone-number-field">
                        <div className="phone-number-select">
                          <select className="form-select">
                            <option selected>+91</option>
                            <option>+91</option>
                            <option>+91</option>
                            <option>+91</option>
                          </select>
                        </div>
                        <Controls.Input
                          className="form-control"
                          placeholder="Enter your phone number"
                          name="phoneNumber"
                          type="text"
                          lable="Phone number"
                          onChange={handleInputChange}
                          error={errors.phoneNumber}
                          helperText={errors.phoneNumber}
                          maxLength="10"
                          value={values.phoneNumber}
                        />

                        {errors.phoneNumber ? (
                          <p className="text-invalid">{errors.phoneNumber}</p>
                        ) : (
                          <p
                            style={{
                              color: "red",
                              marginTop: "4px",
                              marginBottom: "4px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.phoneNumber}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-16">
                      <div className="form-label">Company name</div>
                      <Controls.Input
                        className="form-control"
                        placeholder="Enter your company name"
                        name="companyName"
                        type="text"
                        lable="Company name"
                        onChange={handleInputChange}
                        maxLength="200"
                        value={values.companyName}
                      />
                      {Boolean(errors.companyName) ? (
                        <p className="text-invalid">{errors.companyName}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-16">
                      <div className="form-label">Number of employees</div>

                      <select
                        className="form-select"
                        name="noOfEmployees"
                        onChange={handleInputChange}
                        value={values.noOfEmployees}
                      >
                        <option option="">None</option>
                        {employerList?.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      {Boolean(errors.noOfEmployees) ? (
                        <p className="text-invalid">{errors.noOfEmployees}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-16">
                      <div className="form-label">
                        Company's website{" "}
                        <span className="opacity-75">(optional)</span>
                      </div>
                      <Controls.Input
                        className="form-control"
                        placeholder="Enter company website"
                        name="website"
                        type="url"
                        lable="Company's website (optional)"
                        onChange={handleInputChange}
                        value={values.website}
                      />
                      {Boolean(errors.website) ? (
                        <p className="text-invalid">{errors.website}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-16 mt-16">
                <Controls.Button
                  className="btn btn-primary h-54 w-100"
                  text="Next"
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmployerPersonalInformation;
