import React from "react";

import { useForm } from "../../utills/useForms";
import { useState, useEffect } from "react";
import {
  AddCountryData,
  updateCountryLocation,
} from "../../state/action/locationAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Controls from "../../components/controls/Controls";

import { useNavigate, useLocation } from "react-router";

const initialValues = {
  name: "",
  PhoneCode: "",
};
const AddCountry = (props) => {
  const {
    setIsEdit,
    isEdit,
    handleAddCloseSwitch,
    openAddSwitchModel,
    countryDataList,
  } = props;

  const [value, setValue] = useState("1");

  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [filterData, setFilterData] = useState(true);
  const navigate = useNavigate();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = async (e) => {
    if (validate()) {
      const body = {
        name: values.name,
        PhoneCode: values.PhoneCode,
      };
      if (isEdit) {
        // updateProduct(formData, state.product._id);
        const res = await updateCountryLocation(countryDataList.id, body);
        if (res && res.status === true) {
          navigate("/Country");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await AddCountryData(body);
        if (res && res.status === true) {
          navigate("/Country");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  //details edit
  useEffect(() => {
    if (
      countryDataList.id != "" &&
      countryDataList.id != null &&
      countryDataList.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        name: countryDataList?.name,
        PhoneCode: countryDataList?.PhoneCode,
      });
    } else {
      resetForm();
    }
  }, [countryDataList]);

  return (
    <div>
      <Modal
        size="md"
        show={openAddSwitchModel}
        onHide={handleAddCloseSwitch}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <h3>Add country</h3>
          </div>
          <Controls.Input
            className="form-control font-16-24-05 h-auto py-1"
            placeholder="add country code"
            name="PhoneCode"
            type="text"
            lable="countrycode"
            value={values.PhoneCode}
            onChange={handleInputChange}
          />

          <Controls.Input
            className="form-control font-16-24-05 h-auto py-1"
            placeholder="add country"
            name="name"
            type="text"
            lable="country"
            value={values.name}
            onChange={handleInputChange}
          />
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={handleAddCloseSwitch}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
              handleAddCloseSwitch();
            }}
            variant="primary"
            className="h-44 fw-medium m-0"
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCountry;
