import React, { useState, useEffect } from "react";
import { useForm } from "../../utills/useForms";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Controls from "../../components/controls/Controls";
import { useLocation } from "react-router";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";
import {
  AddAdminData,
  updateAdminData,

} from "../../state/action/addAdminAction";
import { roledropdown } from "../../state/action/addAdminAction";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  roleId: "",
};

const AddAdmin = (props) => {
  const {
    setIsEdit,
    isEdit,
    handleAddCloseSwitch,
    openAddSwitchModel,
    AdminDataList,
    getAdminList,
    page
  } = props;

  const { state } = useLocation();
  const [view, setView] = useState(false);
  const [AdminList, setAdminList] = useState([]);

  const getroleDropDown = async () => {
    const res = await roledropdown();
    if (res) {
      setAdminList(res?.data);
    } else {
    }
  };

  const closeAddSkillPopup = () => {
    handleAddCloseSwitch();
    resetForm();
  }


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "This field is required.";
    }

    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid =
          regex.test(fieldValues.email) && fieldValues.email.endsWith(".com");
        if (!isEmailValid) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("phone" in fieldValues) {
      if (fieldValues.phone !== "" && fieldValues.phone !== undefined) {
        const regex = /^\d{10}$/;
        if (!regex.test(fieldValues.phone)) {
          temp.phone = "Invalid Phone Number";
        } else {
          temp.phone = "";
        }
      } else {
        temp.phone = "This field is required.";
      }
    }

    if ("roleId" in fieldValues) {
      temp.roleId = fieldValues.roleId ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);


  const { enqueueSnackbar } = useSnackbar();

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setIsEdit("");
    getroleDropDown();
    // if (user && user?.id) getroleDropDown(user.id);
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        roleId: values.roleId,
      };

      if (isEdit) {
        // Update admin data if in edit mode
        const res = await updateAdminData(AdminDataList.id, body);
        setView(true);
        if (res && res.status === true) {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
          handleAddCloseSwitch();
          resetForm();
          getAdminList(page, "", "");
          // window.location.reload();
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      } else {
        // Add new admin data if not in edit mode
        const res = await AddAdminData(body);
        if (res && res.status === true) {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
          handleAddCloseSwitch();
          resetForm();
          getAdminList(1, "", "");
          // window.location.reload(); 
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }
    } else {
      setShow({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    }
  };


  useEffect(() => {
    if (
      AdminDataList.id != "" &&
      AdminDataList.id != null &&
      AdminDataList.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        name: AdminDataList?.name,
        email: AdminDataList?.email,
        phone: AdminDataList?.phone,
        roleId: AdminDataList?.roleId,
      });
    } else {
      resetForm();
    }
  }, [AdminDataList]);

  return (
    <div>
      <Loader view={view} />
      {/* {!view && ( */}
      <Modal
        size="md"
        show={openAddSwitchModel}
        onHide={() => closeAddSkillPopup()}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton onClick={() => closeAddSkillPopup()}></Modal.Header>
        <Modal.Body>
          <div>
            <h4>Add Admin</h4>
          </div>
          <div className="mb-3">
            <Controls.Input
              className="form-control font-16-24-05 h-auto py-[10px] px-3"
              placeholder="enter admin name"
              name="name"
              type="text"
              value={values.name}
              onChange={handleInputChange}
              maxLength={50}
            />
            {Boolean(errors.name) ? (
              <p className="text-invalid">{errors.name}</p>
            ) : (
              ""
            )}
          </div>

          <div className="mb-3">
            <Controls.Input
              className="form-control font-16-24-05 h-auto py-[10px] px-3"
              placeholder="enter admin email"
              name="email"
              type="text"
              lable="name"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
            />
            {Boolean(errors.email) ? (
              <p className="text-invalid">{errors.email}</p>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <Controls.Input
              className="form-control font-16-24-05 h-auto py-[10px] px-3"
              placeholder="enter admin phone"
              name="phone"
              type="text"
              lable="name"
              value={values.phone}
              onChange={handleInputChange}
              error={errors.phone}
              style={{ marginTop: "10px" }}
              maxLength={10}
            />
            {Boolean(errors.phone) ? (
              <p className="text-invalid">{errors.phone}</p>
            ) : (
              ""
            )}
          </div>

          <div>
            <select
              className="form-select font-16-24-05 h-auto py-[10px] px-3"
              name="roleId"
              placeholder="enter admin role"
              onChange={handleInputChange}
              value={values.roleId}
            >
              <option value=""> None</option>
              {AdminList?.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          {Boolean(errors.roleId) ? (
            <p className="text-invalid">{errors.roleId}</p>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button

            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => { closeAddSkillPopup(); handleAddCloseSwitch() }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="h-40 fw-medium m-0 px-3 py-2"
            onClick={(e) => {
              handleSubmit(e);

            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* )} */}
      <AutohideExample show={show} setShow={setShow} />
    </div>
  );
};

export default AddAdmin;