import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import { BsFillCheckCircleFill } from "../assets/Icons/icons";
function AutohideExample(props) {
  const { show, setShow } = props;

  return (
    <Row>
      <Col xs={"col"}></Col>
      <Col
        xs={"auto"}
        style={{
          position: "fixed",
          top: 10,
          right: 0,
          zIndex: 9999,
        }}
      >
        <Toast
          onClose={() => setShow(false)}
          show={show?.isOpen || false}
          delay={3000}
          autohide
          
        >
          <Toast.Header className="d-flex align-items-center">
            <div className="me-2">
              {show && show.type == "error" ? (
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    fill="#DC3545"
                    stroke="#DC3545"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 9L9 15"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 9L15 15"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : show && show.type == "success" ? (
                <BsFillCheckCircleFill
                  style={{ color: "green", height: "28", width: "28" }}
                />
              ) : show && show.type == "warning" ? (
                <svg

                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    fill="#FFC107"
                    stroke="#FFC107"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 16V12"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8H12.01"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                ""
              )}

            </div>
            <strong className="me-auto font-14 fw-semibold">
              {show?.message}
            </strong>
          </Toast.Header>
        </Toast>
      </Col>
      {/* <Col xs={6}>
                <Button onClick={() => setShow(true)}>Show Toast</Button>
            </Col> */}
    </Row>
  )
}

export default AutohideExample;
