import axiosInstance from "../../utills/AxiosInstance";

//admin list
export const getinterviewLists = async (queryString) => {
    try {
        const response = await axiosInstance.get('/v1/getInterviewTypeList'+queryString)
        return response.data;
    } catch (error) {
        return error;
    }
};

// Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/statusUpdateInterviewType/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


//add interview
export const AddInterviewData = async (payload) => {
  try {
    const response = await axiosInstance.post(`/v1/addInterviewType`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update interview
export const UpdatInterview = async (id,payload) => {
  try {
    const response = await axiosInstance.put(`/v1/updateInterviewType/${id}`,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

