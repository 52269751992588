import React, { useEffect, useState } from "react";
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import GoogleButtonLogo from "../../assets/images/google-logo.svg";
import SliderImage1 from "../../assets/images/connecting_with_people.svg";
import SliderImage2 from "../../assets/images/man_using_browser.svg";
import SliderImage3 from "../../assets/images/man_climbing_mountain.svg";
import ArrowLeft from "../../assets/images/arrow_left.svg";
import Controls from "../../components/controls/Controls";
import Slider from "react-slick";
import PersonalInformations from "../personalinformation/personalinformation";
import EducationalInformation from "../educationalinformation/educationalinformation";
import PreferencesExpectations from "../preferencesexpectations/preferencesexpectations";
import "./main.scss";
import FinalDetails from "../FresherRegister/FinalDetails/finalDetails";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Tooltip,
} from "@mui/material";
const PersonalInformation = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [activeTab, setActiveTab] = useState(1);
  const [storedata, setStoreData] = useState(null);

  const navigate = useNavigate();
  // const datas = useSelector((state) => state?.auth?.user);

  const handleSubmitBack2 = async (e) => {
    e.preventDefault();
    setActiveTab(2);
  };

  const handleSubmitBack3 = async (e) => {
    e.preventDefault();
    setActiveTab(3);
  };

  const handleSubmitBack1 = async (e) => {
    e.preventDefault();

    setActiveTab(1);
  };
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <main>
      <div className="container">
        <header className="row header-logo-wrap justify-content-between header-row-wrap">
          <div className="col-auto">
            <a href="" className="d-inline-block">
              {/* <img
                src={TechfreshrLogo}
                className="img-fluid header-logo-width"
              /> */}
              <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

            </a>
          </div>

          {activeTab == 2 ? (
            <div className="col-auto align-self-center">
              <Tooltip placement="bottom" title="Back">

                <div className={activeTab === 2 ? 'arrowleft' : 'd-none'}>
                  <a
                    href="javascript:void(0)"
                    className=""
                    onClick={(e) => handleSubmitBack1(e)}
                  >
                    <img src={ArrowLeft} className="img-fluid mx-auto" />
                  </a>
                </div>
              </Tooltip>
            </div>
          ) : null}

          {activeTab === 3 ? (
            <div className="col-auto align-self-center">
              <Tooltip placement="bottom" title="Back">

                <div className={activeTab === 3 ? 'arrowleft' : 'd-none'}>
                  <a
                    href="javascript:void(0)"
                    className=""
                    onClick={(e) => handleSubmitBack2(e)}
                  >
                    <img src={ArrowLeft} className="img-fluid mx-auto" />
                  </a>
                </div>
              </Tooltip>
            </div>
          ) : null}

          {activeTab == 4 ? (
            <div className="col-auto align-self-center">
              <Tooltip placement="bottom" title="Back">

                <div className={activeTab === 4 ? 'arrowleft' : 'd-none'}>
                  <a
                    href="javascript:void(0)"
                    className=""
                    onClick={(e) => handleSubmitBack3(e)}
                  >
                    <img src={ArrowLeft} className="img-fluid mx-auto" />
                  </a>
                </div>
              </Tooltip>
            </div>
          ) : null}
        </header>
      </div>

      <div className="container">
        <div className="row login-height-calc align-items-start">
          <div
            className={`${activeTab == 1 || activeTab == 4 ? '' : activeTab == 3 ? '' : 'height_800'} d-none d-lg-block col-lg-6`}
            style={{
              height: '630px', width: '43%',
              margin: '0 auto'
            }}
          >
            <div className="blur-gradiant">
              <div className="login-left-slider">
                <Slider {...settings}>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage1}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Large Network
                        </div>
                        <div className="slider-content-text">
                          Gain access to a vast network of recruiters <br /> and
                          top employers.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage2}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Best-fit Job
                        </div>
                        <div className="slider-content-text">
                          Find your dream job with our personalized <br />{" "}
                          matching system.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage3}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Achieve your Goals
                        </div>
                        <div className="slider-content-text">
                          Start building your dream career and soar to <br />{" "}
                          new heights with our exciting job <br />{" "}
                          opportunities.
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="login-box-width width_40">
              <div className="signup-top-step-wrap">
                <div className="row">
                  <div className="col">
                    {activeTab == 1 ? (
                      <h1 className="login-top-head mb-2 font-PTSerif-b">
                        Personal information
                      </h1>
                    ) : null}
                    {activeTab == 2 ? (
                      <h1 className="login-top-head mb-2 font-PTSerif-b">
                        Educational information
                      </h1>
                    ) : null}

                    {activeTab == 3 ? (
                      <h1 className="login-top-head mb-2 font-PTSerif-b">
                        Preferences and Expectations
                      </h1>
                    ) : null}

                    {activeTab == 4 ? (
                      <h1 className="login-top-head mb-2 font-PTSerif-b">
                        Final detail
                      </h1>
                    ) : null}
                  </div>
                  <div className="col-auto align-items-center">
                    <div className="step-count-number login-top-head font-PTSerif-b">
                      {activeTab}
                    </div>
                  </div>
                </div>
                <ul className="d-flex mb-32 step-count-dash">
                  <li
                    className="col"
                    style={{
                      backgroundColor: activeTab == 1 ? "#476FB2" : "#E0E0E0",
                    }}
                  ></li>
                  <li
                    className="col"
                    style={{
                      backgroundColor: activeTab == 2 ? "#476FB2" : "#E0E0E0",
                    }}
                  ></li>
                  <li
                    className="col"
                    style={{
                      backgroundColor: activeTab == 3 ? "#476FB2" : "#E0E0E0",
                    }}
                  ></li>
                  <li
                    className="col"
                    style={{
                      backgroundColor: activeTab == 4 ? "#476FB2" : "#E0E0E0",
                    }}
                  ></li>
                </ul>
              </div>

              {activeTab === 1 && (
                <PersonalInformations
                  setActiveTab={setActiveTab}
                  storedata={storedata}
                  handleSubmitBack1={handleSubmitBack1}
                />
              )}
              {activeTab === 2 && (
                <EducationalInformation setActiveTab={setActiveTab} />
              )}
              {activeTab === 3 && (
                <PreferencesExpectations setActiveTab={setActiveTab} />
              )}
              {activeTab === 4 && <FinalDetails setActiveTab={setActiveTab} />}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PersonalInformation;
