import axiosInstance from "../../utills/AxiosInstance";


export const getJobDropDown = async (employerId) => {
    try {
        const response = await axiosInstance.get(`/employee/getJobTitle/${employerId}`);
        return response.data;
    } catch (error) {
        return error;
    }
}

