import React, { useState } from "react";
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import SliderImage1 from "../../assets/images/connecting_with_people.svg";
import SliderImage2 from "../../assets/images/man_using_browser.svg";
import SliderImage3 from "../../assets/images/man_climbing_mountain.svg";
import Controls from "../../components/controls/Controls";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useForm } from "../../utills/useForms";
import { verifyotp } from "../../state/action/fresherforgotpasswordAction";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";
import { useLocation } from "react-router-dom";
import { resendOtp1 } from "../../state/action/fresherforgotpasswordAction";
import { useSelector } from "react-redux";
import ArrowLeft from "../../assets/images/arrow_left.svg";
import { Tooltip } from "react-bootstrap";

const initialValues = { otp: "" };
const OtpSend = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const { state } = useLocation();

    const [loadingButton, setLoadingButton] = useState(false);
    const [show, setShow] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    const navigate = useNavigate();
    const [view, setView] = useState(false);
    const [email, setEmail] = useState("");
    const [counter, setCounter] = useState(10)
    const decrementCounter = () => {
        setCounter((prevCounter) => prevCounter - 1);
    };

    // const { user } = useSelector((state) => state?.auth);
    // const Id = useSelector((store) => store.auth.user?.id);
    const id = useSelector((state) => state);
    const handleResendOTP = async () => {

        if (counter === 0) {

            setCounter(10);

            try {
                const response = await resendOtp1(email);

                if (response.status === true) {
                    setShow({
                        isOpen: true,
                        message: response.message,
                        type: "success",
                    });
                } else {
                    setShow({
                        isOpen: true,
                        message: response.message || "Failed to resend OTP.",
                        type: "error",
                    });
                }
            } catch (error) {
                setShow({
                    isOpen: true,
                    message: "Technical error. Please try again later.",
                    type: "error",
                });
            }
        }
    };


    useEffect(() => {
        if (state && state?.email) {
            setEmail(state?.email);
        } else {
            navigate("/sendotp");
        }
    }, []);

    useEffect(() => {
        if (state) {
            setEmail(state?.email);
        }
        if (counter > 0) {
            const intervalId = setInterval(decrementCounter, 1000);
            return () => clearInterval(intervalId);
        }
    }, [state, counter]);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("otp" in fieldValues) {
            //only number allowed regex
            const reg = /^\d+$/;
            if (fieldValues.otp === "") {
                temp.otp = "OTP is required";
            }
            else if (!reg.test(fieldValues.otp)) {
                temp.otp = "Only numbers are allowed";
            }
            else if (fieldValues.otp.length < 6) {
                temp.otp = "OTP must be 6 digits";
            }
            else {
                temp.otp = "";
            }

        }


        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);

        const handleBackButton = (event) => {
            event.preventDefault();
            window.history.pushState(null, document.title, window.location.href);
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         event.preventDefault();
    //         event.returnValue = ''; // Chrome requires returnValue to be set
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);


    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            let varifyOtp = await verifyotp({
                email: email,
                otp: values.otp,
            })

            if (varifyOtp && varifyOtp.status === true) {
                setShow({
                    isOpen: true,
                    message: varifyOtp?.message || varifyOtp?.message,
                    type: "success",
                });
                setTimeout(() => {
                    navigate("/resetpassword", { state: { email: email } });
                }, 2000);
            } else {
                setLoadingButton(false);
                setShow({
                    isOpen: true,
                    message: "Please enter the valid code",
                    type: "error",
                });
            }
        }
    };





    return (
        <main>
            <Loader view={view} />

            <div className="container">
                <header className="row header-logo-wrap justify-content-between header-row-wrap">
                    <div className="col-auto">
                        <a href="" className="d-inline-block">
                            {/* <img
                                src={TechfreshrLogo}
                                className="img-fluid header-logo-width"
                            /> */}
                            <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

                        </a>
                    </div>
                    {/* <div className="col-auto align-self-center">
                        <div className='arrowleft'>
                            <a
                                href="javascript:history.go(-1)"
                            >
                                <img src={ArrowLeft} className="img-fluid mx-auto" />
                            </a>
                        </div>
                    </div> */}
                </header>
            </div>
            <div className="container">
                <div className="row login-height-calc align-items-center">
                    <div
                        className="col-lg-6 d-none d-lg-block align-self-center" style={{
                            height: '630px', width: '43%',
                            margin: 'auto'
                        }}
                    >
                        <div className="blur-gradiant">
                            <div className="login-left-slider">
                                <Slider {...settings}>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage1}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Large Network
                                                </div>
                                                <div className="slider-content-text">
                                                    Gain access to a vast network of recruiters <br /> and
                                                    top employers.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage2}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Best-fit Job
                                                </div>
                                                <div className="slider-content-text">
                                                    Find your dream job with our personalized <br />{" "}
                                                    matching system.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage3}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Achieve your Goals
                                                </div>
                                                <div className="slider-content-text">
                                                    Start building your dream career and soar to <br />{" "}
                                                    new heights with our exciting job <br />{" "}
                                                    opportunities.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="login-box-width width_40">
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <h1 className="login-top-head mb-32 font-PTSerif-b">
                                    Forgot Password
                                </h1>
                                <div className="row">
                                    <div
                                        className={`col-12 col-md-12 ${errors.email ? "mb-0" : "mb-2"
                                            }`}
                                    >
                                        <div className="form-label">OTP</div>

                                        <Controls.Input
                                            className="form-control"
                                            placeholder="Enter OTP"
                                            name="otp"
                                            type="text"
                                            lable="enter otp"
                                            value={values.otp}
                                            onChange={handleInputChange}
                                            maxLength="6"
                                        />
                                        {Boolean(errors.otp) ? (
                                            <p className="text-invalid">{errors.otp}</p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                                <div className="text-end">
                                    <div className="resend_otp">
                                        <Controls.Button
                                            style={{ color: '#143f87' }}
                                            className="bg-transparent border-0 h-45 w-35 mb-2"
                                            text={`Resend OTP  ${counter === 0 ? '' : counter}`}
                                            type="submit"
                                            onClick={handleResendOTP}
                                        />
                                    </div>
                                </div>




                                <div className="mb-10">
                                    <Controls.Button
                                        className="btn btn-primary h-54 w-100"
                                        text="Submit"
                                        type="submit"
                                    // onClick={() => {
                                    //     navigate("/resetpassword");
                                    // }}
                                    />
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <AutohideExample show={show} setShow={setShow} />
        </main>
    );
};

export default OtpSend;
