import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Async, { useAsync } from "react-select/async";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";
import {
  getFresherLists,
  StatusUpdate,
  deleteFresher,
} from "../../state/action/addFresherAction";
import Form from "react-bootstrap/Form";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/view.svg";

import ActionIcon1 from "../../assets/images/edit.svg";
import { async } from "videojs-record";

// import "./filter.css";

const initialValues = {
  name: "",
};
const Aboutus = () => {
  const [flagName, setflagName] = useState(false);
  const [FresherList, setFresherList] = useState([]);

  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  const [openFilterModel, setOpenFilterModel] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [addskill, setaddskill] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [id, setId] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [skillDataList, setskillDataList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [sortFlag, setSortFlag] = useState(false);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const navigate = useNavigate();

  const [showDrawer, setShowDrawer] = useState(false);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);

  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);

  const handleCloseFilter = () => setOpenFilterModel(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const handleChangePage = (e, value) => {
    if (fieldName == "bannerName") {
      getFresherList(value, fieldName, flagName);
    } else {
      getFresherList(value, "", "");
    }
    setPage(value);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  // list
  useEffect(() => {
    getFresherList("", "");
  }, [refresh, filterData, search, page]);

  const getFresherList = async (page, name = "", flag, limit) => {
    setOpenBreakdrop(true);

    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = limit ? limit : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${flag}`;

    const res = await getFresherLists(queryString);

    if (res.status === true) {
      setFresherList(res.data);

      setTotalRecords(res.total);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPageLimit(recordLimit);
    } else {
      setFresherList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  //status change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "name") {
        getFresherList(fieldName, flagName);
      } else {
        getFresherList("");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handleChangeRow = (event) => {
    if (fieldName == "SkillName") {
      getFresherList(fieldName, flagName, event);
    } else {
      getFresherList("", "", event);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    closeDrawer();
  };

  const handleDelete = async () => {
    const res = await deleteFresher(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getFresherList(fieldName, flagName);
      } else {
        getFresherList("");
      }
      handleCloseDelete();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handleReset = () => {
    resetForm();
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };
  return (
    <main className="body-background">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="rounded-card-head">
              <div className="row align-items-center mb-3">
                <div className="col col-md">
                  <div className="font-PTSerif-b ps-2 font-18-24-05 mb-1">
                    About us
                  </div>
                </div>
                <div className="col-auto col-md-auto">
                  <div className="col col-md-auto text-end">
                    <Controls.Button
                      style={{ padding: "10px 30px", marginLeft: "16px" }}
                      className="btn btn-success h-44"
                      text="filter"
                      onClick={() => {
                        toggleDrawer();
                        // openFilterDrawer();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {showDrawer && (
              <div className="filter-drawer-overlay">
                <div className="filter-drawer-content">
                  <div className="d-flex justify-content-between align-items-center filter-header_div ">
                    <label className="filter-header">Filter</label>
                    <button
                      className="filter-close-button"
                      onClick={toggleDrawer}
                    >
                      x
                    </button>
                  </div>
                  <div className="d-flex flex-column">
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div>
                        <label className="filter-label">Name</label>
                        <Controls.Input
                          className="input_filter"
                          name="name"
                          type="text"
                          value={values.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="filter-label">Email</label>
                        <Controls.Input
                          className="input_filter"
                          name="email"
                          type="text"
                          value={values.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="filter-label">Mobile</label>
                        <Controls.Input
                          className="input_filter"
                          name="phone"
                          type="text"
                          value={values.phone}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="filter-label">Location</label>
                        <Controls.Input
                          className="input_filter"
                          name="countryId"
                          type="text"
                          value={values.countryId}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="position-fixed bottom-0 mb-5 filter-reset-div">
                        <div className="d-flex justify-content-between">
                          <button
                            className="filter-reset-btn"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                          <button className="filter-btn" type="submit">
                            Filter
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            <div className="theme-table-wrap">
              <Table responsive className="striped-table mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Location</th>
                    <th>Job Preffer</th>
                    <th>Eduction</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {FresherList.map((row) => {
                  return (
                    <tbody>
                      <tr
                        className="border rounded"
                        style={{ backgroundColor: "white" }}
                      >
                        <td>{row?.firstName}</td>
                        <td>{row?.email}</td>
                        <td>{row?.mobile}</td>
                        <td>{row?.countryId}</td>
                        <td>{row?.jobPraferId}</td>
                        <td>{row?.firstName}</td>
                        <td>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            checked={row.isActive == 1 ? true : false}
                            onChange={(e) => {
                              handleSwitchModel();
                              setStatus(
                                e.target.checked == true ? "Active" : "InActive"
                              );
                              setSwitchId(row.id);
                              setChecked(e.target.checked);
                            }}
                          />
                        </td>
                        <td>{moment(row.createdAt).format("MM-DD-YYYY LT")}</td>

                        <td>
                          <ul className="d-flex action-col-main">
                            <li className="me-3">
                              {/* <button
                                className="btn btn-sm btn-icon btn-warning me-4"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title="Edit"
                                onClick={() => {
                                  handleAddSwitchModel();
                                  setskillDataList(row);
                                  setIsEdit(true);
                                }}
                              >
                                <span className="btn-inner">
                                  <EditIcon />
                                </span>
                              </button> */}

                              <button
                                className="btn btn-sm btn-icon btn-danger btn-inner me-4"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title="Delete"
                                onClick={() => {
                                  handleDeleteSwitchModel();
                                  setId(row.id);
                                  setDeleteId(row?.id);
                                  handleDeleteModel();
                                }}
                              >
                                <span className="btn-inner">
                                  <DeleteIcon />
                                </span>
                              </button>

                              <button
                                className="btn btn-sm btn-icon btn-success btn-inner"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title="Edit"
                                onClick={() => {
                                  handleAddSwitchModel();
                                  setskillDataList(row);
                                  setIsEdit(true);
                                }}
                              >
                                <span className="btn-inner">
                                  <ViewIcon />
                                </span>
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
              {FresherList && FresherList.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* status change */}
      <Modal
        size="md"
        show={openSwitchModel}
        onHide={() => handleCloseSwitch(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 fw-medium">
            Are you sure you want to status change?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => handleCloseSwitch()}
          >
            No
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            type="submit"
            onClick={() => {
              handleCloseSwitch();

              handleChangeStatus(switchId);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete */}
      <Modal
        size="md"
        show={openDeleteSwitchModel}
        onHide={() => handleDeleteCloseSwitch(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 fw-medium">
            Are you sure you want to delete?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => handleDeleteCloseSwitch()}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            type="submit"
            onClick={() => {
              handleDeleteCloseSwitch();
              handleDelete(deleteId);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <AddSkill
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        handleAddCloseSwitch={handleAddCloseSwitch}
        openAddSwitchModel={openAddSwitchModel}
        skillDataList={skillDataList}
        setOpenFilterModel={setOpenFilterModel}
        handleCloseFilter={handleCloseFilter}
      /> */}
    </main>
  );
};

export default Aboutus;
