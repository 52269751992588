export const languageOptions = [
    {
        id: 63,
        name: "JavaScript (Node.js 12.14.0)",
        label: "JavaScript",
        value: "javascript",
        title: "javascript"
    },
    {
        id: 1,
        name: "Python 3",
        label: "Python 3",
        value: "python",
        title: "python"
    },
    {
        id: 2,
        name: "C++",
        label: "C++",
        value: "cpp",
        title: "c++"
    },
    {
        id: 3,
        name: "C",
        label: "C",
        value: "c",
        title: "c"
    },
    {
        id: 4,
        name: "C#",
        label: "C#",
        value: "csharp",
        title: "c#"
    },
    {
        id: 5,
        name: "Java",
        label: "Java",
        value: "java",
        title: "java"
    }
];
