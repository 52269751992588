import React, { useState } from "react";
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import SliderImage1 from "../../assets/images/hired.svg";
import SliderImage2 from "../../assets/images/office_management.svg";
import SliderImage3 from "../../assets/images/devices.svg";
import Controls from "../../components/controls/Controls";
import Slider from "react-slick";
import ArrowLeft from "../../assets/images/arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useForm } from "../../utills/useForms";
import { loginUserApi } from "../../state/action/authAction";
import AutohideExample from "../../utills/Notification";
import { sendotp } from "../../state/action/employerForgotPasswordAction";
const initialValues = { email: "" };

const EmployerSendOtp = () => {
    const [loadingButton, setLoadingButton] = useState(false);

    const navigate = useNavigate();

    const [show, setShow] = useState({
        isOpen: false,
        message: "",
        type: "",
    });


    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("email" in fieldValues) {
            if (fieldValues.email !== "" && fieldValues.email !== undefined) {
                const emailDomain = fieldValues.email.split('@')[1];

                const allowedDomains = [
                    "gmail.com", "yahoo.com", "outlook.com", "aol.com", "hotmail.com",
                    "icloud.com", "protonmail.com", "mail.com", "zoho.com", "yandex.com",
                    "gmx.com", "tutanota.com", "163.com", "qq.com", "sina.com", "live.com",
                    "aim.com", "rocketmail.com", "inbox.com", "me.com", "msn.com",
                    "fastmail.com", "hushmail.com", "lavabit.com", "opmbx.org", "rediffmail.com",
                    "mail.ru", "bigpond.com", "mailnaitor.com"
                ];
                const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                const isEmailValid = emailRegex.test(fieldValues.email);

                if (!isEmailValid) {
                    temp.email = "Invalid email address. Please enter a valid email.";
                } else if (allowedDomains.includes(emailDomain)) {
                    temp.email = "Please enter a valid work email";
                } else {
                    temp.email = "";
                }
            } else {
                temp.email = "This field is required.";
            }
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            let sendotpRes = await sendotp({
                email: values.email,
                type: 3,
            });

            if (sendotpRes && sendotpRes.status === true) {
                setShow({
                    isOpen: true,
                    message: sendotpRes?.message || sendotpRes?.message,
                    type: "success",
                });
                setTimeout(() => {
                    navigate("/otpverify", { state: { email: values.email } });
                }, 2000);
            } else {
                setShow({
                    isOpen: true,
                    message: sendotpRes?.message || "Technical error please try again later.",
                    type: "error",
                });
            }
        }
    };

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <main>
            <AutohideExample show={show} setShow={setShow} />
            <div className="container">
                <header className="row header-logo-wrap justify-content-between header-row-wrap">
                    <div className="col-auto">
                        <a href="" className="d-inline-block">
                            {/* <img
                                src={TechfreshrLogo}
                                className="img-fluid header-logo-width"
                            /> */}
                            <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

                        </a>
                    </div>
                    <div className="col-auto align-self-center">
                        <div className='arrowleft'>
                            <a
                                href="javascript:history.go(-1)"
                            >
                                <img src={ArrowLeft} className="img-fluid mx-auto" />
                            </a>
                        </div>
                    </div>
                </header>
            </div>

            <div className="container">
                <div className="row login-height-calc align-items-center">
                    <div
                        className="d-none d-lg-block col-lg-6 align-self-center" style={{
                            height: '630px', width: '43%',
                            margin: 'auto'
                        }}
                    >
                        <div className="cyan-gradiant">
                            <div className="login-left-slider">
                                <Slider {...settings}>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage1}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Qualified Engineers
                                                </div>
                                                <div className="slider-content-text">
                                                    Find qualified engineers quickly and efficiently{" "}
                                                    <br /> with Job Portal
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage2}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Management System
                                                </div>
                                                <div className="slider-content-text">
                                                    We offers tracking and managing hiring <br /> process
                                                    from start to finish.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="slider-inner-itam text-center">
                                            <div className="slider-item-image mb-32">
                                                <img
                                                    src={SliderImage3}
                                                    className="img-fluid mx-auto h-slid-width"
                                                />
                                            </div>
                                            <div className="text-white slider-inner-content">
                                                <div className="font-PTSerif-b slider-content-head">
                                                    Reliable platform
                                                </div>
                                                <div className="slider-content-text">
                                                    Securely connect with top talents and simplify your{" "}
                                                    <br /> recruitment process with TechFresher's reliable
                                                    platform.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="login-box-width width_40">
                            <h1 className="login-top-head mb-32 font-PTSerif-b">Forgot Password</h1>
                            <div className="row">
                                <div className="col-12 col-md-12 mb-16">
                                    <div className="form-label">Email</div>
                                    <div className="d-flex flex-column">
                                        <Controls.Input
                                            className="form-control"
                                            placeholder="Enter your work email"
                                            name="email"
                                            type="email"
                                            lable="enter your email"
                                            value={values.email}
                                            onChange={handleInputChange}
                                            maxLength="50"
                                        />
                                        {Boolean(errors.email) ? (
                                            <p className="text-invalid">{errors.email}</p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="mb-32">
                                <Controls.Button
                                    className="btn btn-primary h-54 w-100"
                                    text="Send OTP"
                                    type="submit"
                                    onClick={handleSubmit}
                                />
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EmployerSendOtp;
