import React, { useState } from "react";
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import SliderImage1 from "../../assets/images/hired.svg";
import SliderImage2 from "../../assets/images/office_management.svg";
import SliderImage3 from "../../assets/images/devices.svg";
import Controls from "../../components/controls/Controls";
import Slider from "react-slick";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useForm, Form } from "../../utills/useForms";
import { loginUserApi } from "../../state/action/authAction";
import AutohideExample from "../../utills/Notification";
import { AiOutlineEyeInvisible, AiOutlineEye } from "../../assets/Icons/icons";
import { Helmet } from 'react-helmet'


const initialValues = { email: "", password: "" };

const EmployerLogin = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  const cookies = new Cookies();
  const isAuthenticated = useSelector((store) => {
    return store?.auth?.isAuthenticated;
  });

  const { user } = useSelector((state) => state?.auth);

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (isAuthenticated && user && user.completed == 1) {
    navigate("/");
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailDomain = fieldValues.email.split('@')[1];

        const allowedDomains = [
          "gmail.com", "yahoo.com", "outlook.com", "aol.com", "hotmail.com",
          "icloud.com", "protonmail.com", "mail.com", "zoho.com", "yandex.com",
          "gmx.com", "tutanota.com", "163.com", "qq.com", "sina.com", "live.com",
          "aim.com", "rocketmail.com", "inbox.com", "me.com", "msn.com",
          "fastmail.com", "hushmail.com", "lavabit.com", "opmbx.org", "rediffmail.com",
          "mail.ru", "bigpond.com", "mailnaitor.com"
        ];
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const isEmailValid = emailRegex.test(fieldValues.email);

        if (!isEmailValid) {
          temp.email = "Invalid email address. Please enter a valid email.";
        } else if (allowedDomains.includes(emailDomain.toLowerCase())) {
          temp.email = "Please enter a valid work email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("password" in fieldValues) {
      if (fieldValues.password !== "" && fieldValues.password !== undefined) {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 charecter";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(fieldValues.password)) {
                temp.password = "Password must contain at least 1 special character";
              } else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoadingButton(!loadingButton);
      let LoginResponse = await dispatch(
        loginUserApi(
          {
            email: values.email,
            password: values.password,
          },
          2
        )
      );

      if (LoginResponse && LoginResponse.status === true) {
        setShow({
          isOpen: true,
          message: LoginResponse.message,
          type: "success",
        });
        if (LoginResponse && LoginResponse.data && LoginResponse.data.completed && LoginResponse.data.completed == 1) {
          setTimeout(() => {
            navigate("/employer");
          }, 500);
        } else {
          setTimeout(() => {
            navigate("/employerpersonalinformation");
          }, 500);
        }
      } else {
        if (LoginResponse && LoginResponse.isVerified == 1) {
          navigate("/employerverifyemail", {
            state: {
              email: values.email,
            },
          });
        } else {
          setShow({
            isOpen: true,
            message:
              LoginResponse?.message || "Technical error please try again later.",
            type: "error",
          });
        }
      }
    }
  };



  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <main>
      <AutohideExample show={show} setShow={setShow} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Employer Login | Job Portal - Connect with Skilled Tech Graduates</title>
        <meta name="description" content="Access your Job Portal employer account and unlock a pool of talented tech graduates. Sign in to your employer account and discover skilled candidates ready to contribute to your team's success." />
      </Helmet>
      <div className="container">
        <header className="row header-logo-wrap justify-content-between header-row-wrap">
          <div className="col-auto">
            <a href="" className="d-inline-block">
              {/* <img
                src={TechfreshrLogo}
                className="img-fluid header-logo-width"
              /> */}
              <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

            </a>
          </div>
        </header>
      </div>

      <div className="container">
        <div className="row login-height-calc align-items-center">
          <div
            className="d-none d-lg-block col-lg-6 align-self-center" style={{
              height: '630px', width: '43%',
              margin: 'auto'
            }}
          >
            <div className="cyan-gradiant">
              <div className="login-left-slider">
                <Slider {...settings}>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage1}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Qualified Engineers
                        </div>
                        <div className="slider-content-text">
                          Find qualified engineers quickly and efficiently{" "}
                          <br /> with Job Portal
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage2}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Management System
                        </div>
                        <div className="slider-content-text">
                          We offers tracking and managing hiring <br /> process
                          from start to finish.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage3}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Reliable platform
                        </div>
                        <div className="slider-content-text">
                          Securely connect with top talents and simplify your{" "}
                          <br /> recruitment process with Job Portal reliable
                          platform.
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="login-box-width width_40">
              <h1 className="login-top-head mb-32 font-PTSerif-b">Login</h1>
              <div className="row">
                <div className="col-12 col-md-12 mb-16">
                  <div className="form-label">Email</div>
                  <div className="d-flex flex-column">
                    <Controls.Input
                      className="form-control"
                      placeholder="Enter your work email"
                      name="email"
                      type="email"
                      lable="enter your email"
                      value={values.email}
                      onChange={handleInputChange}
                      maxLength="50"
                    />
                    {Boolean(errors.email) ? (
                      <p className="text-invalid">{errors.email}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 mb-16">
                  <div className="form-label">Password</div>
                  <div className="d-flex flex-column input-relative">
                    <Controls.Input
                      className="form-control "
                      placeholder="Enter your password"
                      name="password"
                      lable="enter your password"
                      value={values.password}
                      onChange={handleInputChange}
                      type={passwordVisible ? "text" : "password"}
                      maxLength="12"
                    />
                    <div
                      className="position-absolute password_eye"
                      variant="link"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? (
                        <AiOutlineEyeInvisible
                          style={{ width: "80%", height: "30px" }}
                        />
                      ) : (
                        <AiOutlineEye
                          style={{ width: "80%", height: "30px" }}
                        />
                      )}
                    </div>
                    {Boolean(errors.password) ? (
                      <p className="text-invalid">{errors.password}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-32 text-end">
                <a
                  href="Javascript:void(0)"
                  onClick={() => {
                    navigate("/send-otp");
                  }}
                  className="font-16 btn btn-link text-decoration-none"
                >
                  Forgot Password?
                </a>
              </div>
              <div className="mb-32">
                <Controls.Button
                  className="btn btn-primary h-54 w-100"
                  text="Login"
                  type="submit"
                  onClick={handleSubmit}
                />
              </div>

              <div className="mb-32 text-center text-secondary">OR</div>
              <div className="">
                <button
                  type="button"
                  className="btn btn-outline-primary h-54 w-100 mb-16"
                  onClick={() => {
                    navigate("/employersignup");
                  }}
                >
                  Don't have account? Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmployerLogin;
