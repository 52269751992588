import React, { useState, useEffect } from "react";
import Profile from "../../assets/images/interviewer_profile_2.png";
import {
  AiFillEye,
  AiOutlineDelete,
  FaBackward,
  HiLocationMarker,
  HiPhone,
  MdEmail,
  AiOutlineDownload,
} from "../../assets/Icons/icons";
import Table from "react-bootstrap/esm/Table";
import moment from "moment";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import profilePhoto from "../../assets/images/interviewer_profile_2.png";
import { useForm } from "../../utills/useForms";
import { getFresherDetails } from "../../state/action/addFresherAction";
import { Tooltip } from "react-bootstrap";

const initialValues = {
  title: "",
};

const FresherPage = () => {
  const navigate = useNavigate();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = FresherList?.fresherResume?.fileLocation;
    link.download = "filename.pdf";
    link.click();
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [filterData, setFilterData] = useState(true);
  const [FresherList, setFresherList] = useState([]);
  const [testData, setTestData] = useState([]);

  const [search, setSearch] = useState("");

  const [refresh, setRefresh] = useState(false);

  const [page, setPage] = useState(1);

  const [id, setId] = useState([]);

  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  useEffect(() => {
    getFresherList("", "");
  }, [refresh, filterData, search, page]);
  const { state } = useLocation();


  const getFresherList = async (page, name = "", flag, limit) => {
    setOpenBreakdrop(true);
    let string = "";

    const res = await getFresherDetails(state?.id);
    if (res.status === true) {
      setFresherList(res.data);
      setTestData(res.data?.testData);
    } else {
      setFresherList([]);
    }
    // setFilter(false);
  };
  //interview status
  const jobchange = (jobchange) => {

    const statusColors = {
      1: {
        key: "yes",
        backgroundColor: "white",
        color: "balck",
      },
      2: {
        key: "exploring",
        backgroundColor: "white",
        color: "balck",
      },
      3: {
        key: "no",
        backgroundColor: "white",
        color: "balck",
      },
    };

    const status = statusColors[jobchange];

    return (
      <div
        style={{
          height: "30px",
          fontSize: "15px",
          backgroundColor: status ? status.backgroundColor : "",
          color: status ? status.color : "",
        }}
      >
        {status ? status.key : ""}
      </div>
    );
  };

  const englishproficiency = (englishproficiency) => {

    const englevel = {
      1: {
        key: "good",
        backgroundColor: "white",
        color: "balck",
      },
      2: {
        key: "Average",
        backgroundColor: "white",
        color: "balck",
      },
      3: {
        key: "Excellent",
        backgroundColor: "white",
        color: "balck",
      },
    };

    const status1 = englevel[englishproficiency];
    return (
      <div
        style={{
          height: "30px",
          fontSize: "15px",
          backgroundColor: status1 ? status1.backgroundColor : "",
          color: status1 ? status1.color : "",
        }}
      >
        {status1 ? status1.key : ""}
      </div>
    );
  };
  return (
    <div className="container-fluid pb-3">
      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          <div className="d-flex align-items-center gap-2">
            <span>
              <FaBackward />
            </span>
            <span>Back</span>
          </div>
        </button>
        {/* <button className="btn btn-danger">
          {" "}
          <div className="d-flex align-items-center gap-2">
            <span>
              <AiOutlineDelete />
            </span>
            <span>Delete</span>
          </div>
        </button> */}
      </div>
      <div className="row ProfileCard">
        <div
          className='ProfileCard col-md-12'
        >
          <div className="card flex-fill">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title fw-bold">{FresherList.firstName}{" "}{FresherList.lastName}</h5>
              <div className="profile d-flex align-items-center flex-column gap-3">
                <img src={FresherList.profilePhoto} className="img-fluid" width="60px" height="60px" />
                <span
                  className="mt-3"
                >
                  <HiPhone /> :{FresherList.mobile || "-"}
                </span>
                <span className="email">
                  <MdEmail /> :
                  {FresherList.email || "-"}
                </span>
                <span className="email">
                  <HiLocationMarker /> :{FresherList.cityId?.name},
                  {FresherList.stateId?.name},{FresherList.countryId?.name}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='ProfileCard col-md-12'>
          <div className='ProfileCard gap-0 mt-3'>
            <div className="card flex-fill mb-3">
              <div className="card-body">
                <h5
                  className="card-title fw-bold pb-2"
                  style={{
                    borderBottom: "1px solid #E1E8F4",
                    marginBottom: "12px",
                  }}
                >
                  Education Details
                </h5>

                <div className="row">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">College Name:</h6>
                        </div>
                        <div className="col-md-6">
                          <p> {FresherList.college || "-"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Level of Education:</h6>
                        </div>
                        <div className="col-md-6">
                          <p>{FresherList?.educationLevelId?.title || "-"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Field of Study:</h6>
                        </div>
                        <div className="col-md-6">
                          <p>{FresherList?.educationLevelId?.title || "-"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex align-items-middle row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Skills:</h6>
                        </div>
                        <div className="d-flex flex-column col-md-6">

                          {FresherList?.fresherSkill?.map((row) => {
                            return (
                              <div className="">
                                {row.skillName || "-"}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Start Date:</h6>
                        </div>
                        <div className="col-md-6">
                          <p>
                            {" "}
                            {FresherList && FresherList.eduStartDate != null && (
                              moment(FresherList.eduStartDate).format(
                                "MM-DD-YYYY LT"
                              ))}{" "}
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">End Date:</h6>
                        </div>
                        <div className="col-md-6">
                      
                          {FresherList?.studyRunning == 0 || FresherList?.studyRunning == null ? (

                            <div class="font-16-24-05 mb-2">
                              {FresherList?.eduEndDate == null ? "-" : moment(FresherList?.eduEndDate).format("MM-DD-YYYY")}

                            </div>
                          ) : null}

                          {
                            FresherList?.studyRunning == 1 && (

                              <div class="font-16-24-05 mb-2">
                                Still enrolled
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card flex-fill">
              <div className="card-body">
                <h5
                  className="card-title fw-bold pb-2"
                  style={{
                    borderBottom: "1px solid #E1E8F4",
                    marginBottom: "12px",
                  }}
                >
                  Preferance and Expectations
                </h5>

                <div className="row">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">English Proficiency:</h6>
                        </div>
                        <div className="col-md-6">
                          <p>{englishproficiency(FresherList?.engLevel) || "-"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Looking for Job Change:</h6>
                        </div>

                        <div className="col-md-6">
                          <p> {jobchange(FresherList?.lookingJobs) || "-"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Job Preferance</h6>
                        </div>
                        <div className="col-md-6">
                          {FresherList?.jobPraferId?.title == null && FresherList?.jobTypeId?.title == null ? "-" : <p>{FresherList?.jobPraferId?.title},{FresherList?.jobTypeId?.title} </p>}

                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Preferred 1st Location:</h6>
                        </div>
                        {FresherList?.jobLocation?.slice(0, 1)?.map((row) => {
                          return (
                            <div className="col-md-6">
                              <p>
                                {row.cityName},{row.stateName},
                                {row.countryName}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Preferred 2nd Location:</h6>
                        </div>
                        {FresherList?.jobLocation
                          ?.slice(1, 2)
                          ?.map((row) => {
                            return (
                              <div className="col-md-6">
                                <p>
                                  {" "}
                                  {row.cityName},{row.stateName},
                                  {row.countryName}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="fw-bold">Preferred 3r Location:</h6>
                        </div>
                        {FresherList?.jobLocation
                          ?.slice(2, 3)
                          ?.map((row) => {
                            return (
                              <div className="col-md-6">
                                <p>
                                  {" "}
                                  {row.cityName},{row.stateName},
                                  {row.countryName}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-table-wrap" style={{ marginTop: "10px", overflow: 'scroll' }}>
        <Table className="striped-table mb-0">
          <thead>
            <tr>
              <th>Resume</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* {FresherList?.map((row) => {
              return ( */}
            <tr
              // key={row.id}
              className="border rounded"
              style={{ backgroundColor: "white" }}
            >
              <td style={{ width: "33.3%" }}>Resume</td>
              <td style={{ width: "33.3%" }}>
                {FresherList && FresherList.createdAt != null ? (
                  moment(FresherList.createdAt).format("MM-DD-YYYY LT")) : "-"}
              </td>
              <td>
                <div className="col-6 col-md-4 col-xl-3 mb-4">
                  <div className="fw-medium font-18-24-05"></div>
                  <div className="font-16-24-05 mb-2 d-inline-block">
                    <a
                      href="#"
                      className="d-flex btn btn-link"
                      onClick={handleDownload}
                    >
                      <div className="card-head-action-icon pe-2">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_871_17631)">
                            <path
                              d="M8 17L12 21L16 17"
                              stroke="#74777E"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 12V21"
                              stroke="#74777E"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.8799 18.0901C21.7493 17.4787 22.4013 16.6062 22.7412 15.5992C23.0811 14.5922 23.0913 13.5031 22.7703 12.4899C22.4493 11.4767 21.8138 10.5921 20.956 9.96462C20.0982 9.33709 19.0628 8.99922 17.9999 9.00006H16.7399C16.4392 7.82793 15.8764 6.7393 15.0941 5.81614C14.3117 4.89297 13.3301 4.15932 12.2231 3.67041C11.1162 3.1815 9.91278 2.95008 8.70346 2.99357C7.49414 3.03706 6.31045 3.35433 5.24149 3.92148C4.17253 4.48864 3.24616 5.2909 2.53212 6.26788C1.81808 7.24487 1.33498 8.3711 1.11919 9.5618C0.903403 10.7525 0.960543 11.9767 1.28631 13.1421C1.61208 14.3075 2.19799 15.3838 2.99993 16.2901"
                              stroke="#74777E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_871_17631">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>{" "}
                      <Tooltip
                        title={FresherList?.fresherResume?.fileLocation}
                      ></Tooltip>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="theme-table-wrap" style={{ marginTop: "10px", overflow: 'scroll' }}>
        <Table className="striped-table mb-0">
          <thead>
            <tr>
              <th>Skill</th>
              <th>Total Questions</th>
              <th>Questions Attempted</th>
              <th>Correct Answers</th>
              <th>Incorrect Answers</th>
              <th>Time</th>
              <th>Rating</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>

            {testData && testData?.map((row) => {
              return (
                <>
                  <tr
                    className="border rounded"
                    style={{ backgroundColor: "white" }}
                  >
                    <td >{row?.skillId == null ? "Coding Assessment" : row?.skillName}</td>
                    <td >{row?.totalQuestion}</td>
                    <td >{row?.totalAttempt}</td>
                    <td >{row?.totalCorrect}</td>
                    <td >{row?.totalWrong}</td>
                    <td >{(row?.totalTime) + " " + "m"}</td>
                    {/* <td >{row?.percentage}</td> */}
                    <td>{row?.percentage > 90 ? '5/5' : row?.percentage > 74 ? '4.5/5' : row?.percentage > 49 ? '4/5' : row?.percentage > 32 ? '3.5/5' : row?.percentage > 24 ? '3/5' : '0/5'
                    }</td>
                    <td >{row?.isPass == 1 ? "Pass" : "Fail"}</td>
                  </tr>

                </>
              )

            })}

          </tbody>
        </Table>
        {testData && testData.length === 0 ? (
          <div
            className="noDataFound"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "150px",
              alignItems: "center",
              fontSize: "20px",
            }}
          >
            {" "}
            No Data Found
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FresherPage;
