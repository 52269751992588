import axiosInstance from "../../utills/AxiosInstance";
import { DELETE_EMPLOYER_DATA, FRESHER_FAIL } from "../action-types/settingTypes";

export const deleteEmployerApi = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance.delete(`/employee/deleteEmployer/${id}`);
        if (res.data && res.status == 200) {
            localStorage.removeItem("token");
            dispatch({
                type: DELETE_EMPLOYER_DATA,
                payload: res.data && res.data.data,
            });
        } else {
            dispatch({
                type: FRESHER_FAIL,
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: FRESHER_FAIL,
        });
        return err;
    }
}

export const changeEmployerEmail = async (payload, id) => {
    try {
        const response = await axiosInstance.put(`/employee/changeEmployerEmail/${id}`, payload);
        return response.data
    } catch (error) {
        return error;
    }
};

export const employerEmailVerified = async (payload, id) => {
    try {
        const response = await axiosInstance.post(`/employee/changeEmailVerification/${id}`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const changeEmployerPassword = async (payload, id) => {
    try {
        const response = await axiosInstance.put(`/employee/employerPasswordChange/${id}`, payload);
        return response.data
    } catch (error) {
        return error;
    }
};