import { React } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import MenuHamburgerIcon from "../../assets/images/menu-hamburger-icon.svg";
import MenuCloseIcon from "../../assets/images/menu_close_icon.svg";
import ProfileImage from "../../assets/images/profile_image.png";
import ProfileChevronDown from "../../assets/images/chevron_down.svg";
import MenuBarIcon from "../../assets/images/menu_bar_icon.svg";
import MenuMobileIcon from "../../assets/images/chevron_right.svg";
import { Redirect, useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../state/action/authAction";
import { useEffect } from "react";
import { useUserNotificationSubscribeHook } from "../../notification";
import { getNotificationLists, notificationRead, markAllRead } from "../../state/action/pushNotificationAction";
import moment from "moment";



const Header = (props) => {
  const { time, setTime, RESET_INTERVAL_S } = props;


  const [show, setShow] = useState(false);
  const [playerId, setPlayerId] = useState("");
  const [navMenu, setNavMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const pathName = useLocation();


  const navigate = useNavigate();
  const { registerAndSubscribe } = useUserNotificationSubscribeHook();

  const data = useSelector((state) => state?.auth);
  const token = useSelector((store) => store?.auth?.token);
  const dispatch = useDispatch();

  const Timer = ({ time }) => {
    const timeRemain = RESET_INTERVAL_S - (time % RESET_INTERVAL_S);

    return (
      <>
        <div
          style={{
            marginBottom: "auto",
            marginTop: "auto",
          }}
        >
          {" "}
          {formatTime(time)}
        </div>
      </>
    );
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  let timerId = null;
  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);

    if (time === 0) {
      clearInterval(timerId);
      // handelSubmit();
      // Clear the interval
      // history.push('/new-route'); // Navigate to a new route
    }

    return () => clearInterval(timerId);
  }, [time]);

  const getNotificationData = async () => {
    const res = await getNotificationLists(data?.user?.id, 2);
    if (res && res.data && res.data.length > 0) {
      setNotificationList(res.data);
    } else {
      setNotificationList([]);
    }
  }

  useEffect(() => {
    getNotificationData();
  }, []);

  useEffect(() => {
    if (data && data?.user?.id) {

      const payload = {
        userId: data?.user?.id,
        type: 2,

      }
      registerAndSubscribe(payload);
    }
  }, []);

  useEffect(() => {
    if (navMenu == true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [navMenu]);

  return (
    <main onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }} onPaste={(e) => { e.preventDefault(); }} onCut={(e) => { e.preventDefault(); }}>
      <div className="container-fluid">
        <header
          className="row header-logo-wrap justify-content-between align-items-center header-row-wrap"
          style={{ backgroundColor: "white" }}
        >
          <div className="col-auto col-md-4 d-none d-md-block">
            <a
              href="javascript:void(0)"
              className="d-inline-block"
              onClick={() => navigate("/")}
            >
              {/* <img
                src={TechfreshrLogo}
                className="img-fluid header-logo-width"
              /> */}
              <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

            </a>
          </div>
          <div className="col-auto">
            <div className="fs-2 text-primary">
              {/* 00:23:54 */}
              <Timer time={time} />

            </div>
          </div>
        </header>
      </div>
    </main>
  );
};

export default Header;
