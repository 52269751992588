import React, { useState, useEffect } from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import Main from "./main";
import Footer from "../Layout/Footer";
import './admin.css'
const Layout = (props) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    setIsActive(current => !current);
  };
  return (
    <>
      <div className="theme">
        <Sidebar {...props} handleClick={handleClick} isActive={isActive} setIsActive={setIsActive}/>
        <div className="layout__content" >
          {/* <Header {...props} /> */}
          <div className={`${isActive ? 'layout__content-main-toggle' : ''} layout__content-main`}>
            <Main {...props} handleClick={handleClick} isActive={isActive} />
            <div className="d-flex justify-content-center">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
