import axiosInstance from "../../utills/AxiosInstance";
import { DELETE_FRESHER_DATA, FRESHER_FAIL } from "../action-types/settingTypes";

export const deleteFresherApi = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance.put(`/fresher/deleteFresher/${id}`);
        if (res.data && res.status == 200) {
            localStorage.removeItem("token");
            dispatch({
                type: DELETE_FRESHER_DATA,
                payload: res.data && res.data.data,
            });
        } else {
            dispatch({
                type: FRESHER_FAIL,
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: FRESHER_FAIL,
        });
        return err;
    }
}

export const removeData = () => async (dispatch) => {
    try {
        localStorage.removeItem("token");
        dispatch({
            type: DELETE_FRESHER_DATA,
        });

    } catch (err) {
        dispatch({
            type: FRESHER_FAIL,
        });
        return err;
    }
}

export const changeFresherEmail = async (payload, id) => {
    try {
        const response = await axiosInstance.put(`/fresher/changeFresherEmail/${id}`, payload);
        return response.data
    } catch (error) {
        return error;
    }
};

export const fresherEmailVerified = async (payload, id) => {
    try {
        const response = await axiosInstance.post(`/fresher/changeEmailVerification/${id}`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const changeFresherPassword = async (payload, id) => {
    try {
        const response = await axiosInstance.put(`/fresher/fresherPasswordChange/${id}`, payload);
        return response.data
    } catch (error) {
        return error;
    }
};

export const resendOtp = async (id) => {
    try {
        const response = await axiosInstance.put(`/fresher/fresherResendOtp/${id}`)
        return response.data;
    } catch (error) {
        return error;
    }
}
