import React, { useState } from "react";
import Controls from "../../components/controls/Controls";
import Slider from "react-slick";
// import "./login.scss";
import "./adminlogin.css";
import Cookies from "universal-cookie";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useForm, Form } from "../../utills/useForms";
import { loginAdminApi } from "../../state/action/authAction";
import { useSnackbar } from "notistack";
import AutohideExample from "../../utills/Notification";
import { AiOutlineEyeInvisible, AiOutlineEye } from "../../assets/Icons/icons";

const initialValues = { email: "", password: "" };
const AdminLogin = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handlePasswordToggle = () => {
    setShowPassword((prevState) => !prevState);
  };
  const cookies = new Cookies();
  const isAuthenticated = useSelector((store) => store?.auth?.isAuthenticated);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.auth?.user?.email);

  // if (isAuthenticated) {
  //   navigate("/");
  // }


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isEmailValid =
          regex.test(fieldValues.email) && fieldValues.email.endsWith("");
        if (!isEmailValid) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("password" in fieldValues) {
      if (fieldValues.password !== "" && fieldValues.password !== undefined) {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 charecter";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let LoginResponse = await dispatch(
        loginAdminApi(
          {
            email: values.email,
            password: values.password,
          },
          1
        )
      );

      if (LoginResponse && LoginResponse.status === true) {
        setLoadingButton(!loadingButton);
        setShow({
          isOpen: true,
          message: LoginResponse?.message,
          type: "success",
        });
        setTimeout(() => {
          navigate("/admin/admins");
        }, 2000);
      } else {
        setLoadingButton(false);
        setShow({
          isOpen: true,
          message: LoginResponse?.response?.data?.message || LoginResponse.message,
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    const userEmail = cookies.get("email");
    const userPassword = cookies.get("password");
    if (userEmail !== "" && userPassword !== "") {
      setValues({
        ...values,
        email: userEmail,
        password: userPassword,
      });
    }
  }, []);

  return (
    <main>
      <div className="admin_page">
        <div className="container_admin">
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className="form_login"
          >
            <h1 className="login-top-head mb-32 font-PTSerif-b login">Login</h1>

            <div className="mb-3">
              <div className="col-12 input col-md-12">
                <div className="form-label label " align="left">Email</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter your email"
                  name="email"
                  type="email"
                  lable="enter your email"
                  value={values.email}
                  onChange={handleInputChange}
                />
                {Boolean(errors.email) ? (
                  <p className="text-invalid">{errors.email}</p>
                ) : (
                  ""
                )}
              </div>
            </div>


            <div className="mb-3">
              <div className="row">
                <div className="col-12 col-md-12 mb-16">
                  <div className="form-label" align="left">Password</div>
                  <div className="d-flex flex-column input-relative">
                    <Controls.Input
                      className="form-control"
                      placeholder="Enter your password"
                      name="password"
                      lable="enter your password"
                      value={values.password}
                      onChange={handleInputChange}
                      type={passwordVisible ? "text" : "password"}
                      maxLength="12"
                    />
                    <div
                      className="position-absolute password_eye"
                      variant="link"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? (
                        <AiOutlineEyeInvisible
                          style={{ width: "80%", height: "30px" }}
                        />
                      ) : (
                        <AiOutlineEye
                          style={{ width: "80%", height: "30px" }}
                        />
                      )}
                    </div>
                    {Boolean(errors.password) ? (
                      <p className="text-invalid">{errors.password}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>



            <div className="mb-32">
              <Controls.Button
                className="btn btn-primary h-44 w-100"
                text="Login"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
      <AutohideExample show={show} setShow={setShow} />
    </main>
  );
};

export default AdminLogin;
