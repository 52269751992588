import axiosInstance from "../../utills/AxiosInstance";

//jobtype list
export const JobType = async () => {
  try {
    const response = await axiosInstance.get("/v1/jobTypeList");
    return response.data;
  } catch (error) {
    return error;
  }
};
export const JobTypes = async () => {
  try {
    const response = await axiosInstance.get("/v1/jobTypeDropdown");
    return response.data;
  } catch (error) {
    return error;
  }
};

//job prefer list
export const JobPreferList = async () => {
  try {
    const response = await axiosInstance.get("/v1/jobPreferList");
    return response.data;
  } catch (error) {
    return error;
  }
};
export const JobPreferLists = async () => {
  try {
    const response = await axiosInstance.get("/v1/jobPreferDropdown");
    return response.data;
  } catch (error) {
    return error;
  }
};
// addpostnewjobs
export const addNewJobs = async (payload) => {
  try {
    const response = await axiosInstance.post(`/employee/addJobpost`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//country code dropdown
export const getCountryLists = async () => {
  try {
    const response = await axiosInstance.get("/v1/countryDropdown");
    return response.data;
  } catch (error) {
    return error;
  }
};
//state dropdown
export const getStateLists = async (id) => {
  try {
    const response = await axiosInstance.get(`/v1/stateDropdown/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
//city dropdown
export const getCityLists = async (id) => {
  try {
    const response = await axiosInstance.get(`/v1/cityDropdown/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//job listing

export const getJobLists = async (id, queryString) => {
  try {
    const response = await axiosInstance.get(
      `/employee/getEmployerJobPostList/${id}` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//update the job

export const getJobUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/employee/updateJobPost/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
