import React from "react";
import { Routes, Route } from "react-router-dom";
import MCQAssessment from "../../pages/MCQAssessment";
import State from "../../pages/Location/States";
import AddState from "../../pages/Location/AddState";
import City from "../../pages/Location/Citys";
import Country from "../../pages/Location/Country";
import AddCity from "../../pages/Location/AddCity";
import AddCountry from "../../pages/Location/AddCountry";
import Role from "../../pages/Role";
import Assesment from "../../pages/Assesment";
import Employeer from "../../pages/Employeer";
import Freshers from "../../pages/Freshers";
import Admin from "../../pages/Admin";
import JobLists from "../../pages/Jobs";
import { useDispatch, useSelector } from "react-redux";
import AdminLogin from "../../pages/AdminLogin/index";
import Grid from "../../pages/Grid/index";
import TalentPool from "../../pages/TalentPool";
import Jobpost from "../../pages/jobpost/index";
import CodingAssessment from "../../pages/codingAssessment/index";
import FAQ from "../../pages/FAQ/index";
import Aboutus from "../../pages/Aboutus/index";
import InterviewType from "../../pages/interviewtype/index";
import JobType from "../../pages/jobtype/index";

import FresherPage from "../../pages/Freshers/FresherPage";
import EmployerDetail from "../../pages/Employeer/employerDetailPage";
import AddPermission from "../../pages/Role/AddPermission";
import AssessmentPage from "../../pages/Assesment/AssessmentPage";
import QuestionDetail from "../../pages/Assesment/QuestionDetails";
import JobpostDetails from "../../pages/jobpost/jobpostdetails";
import Setting from "../../pages/fresherSetting/Setting";
import EmployerSetting from "../../pages/employerSetting/EmployerSetting";

const Main = ({ handleClick, isActive }) => {
  const user = useSelector((state) => state?.auth?.admin);


  const pages = [
    { name: "Admin", path: "/admin/admins", element: <Admin /> },
    { name: "role", path: "/admin/role", element: <Role /> },
    { name: "jobLists", path: "/admin/jobLists", element: <JobLists /> },
    { name: "assesment", path: "/admin/assesment", element: <Assesment /> },
    { name: "setting", path: "/admin/setting", element: <Setting /> },
    { name: "employer-setting", path: "/admin/setting", element: <EmployerSetting /> },
    { name: "freshers", path: "/admin/fresherDetail", element: <FresherPage /> },
    { name: "freshers", path: "/admin/freshers", element: <Freshers /> },
    { name: "jobpost", path: "/admin/jobpostdetails", element: <JobpostDetails /> },

    {
      name: "assesment",
      path: "/admin/addAssesment",
      element: <AssessmentPage />,
    },
    {
      name: "assesment",
      path: "/admin/assessment-details",
      element: <QuestionDetail />,
    },
    { name: "employeer", path: "/admin/employeer", element: <Employeer /> },
    {
      name: "employeer",
      path: "/admin/employeerDetail",
      element: <EmployerDetail />,
    },
    {
      name: "codingAssessment",
      path: "/admin/codingassessment",
      element: <CodingAssessment />,
    },
    { name: "skill", path: "/admin/skills", element: <Grid /> },
    { name: "telentpoll", path: "/admin/TalentPool", element: <TalentPool /> },

    { name: "faq", path: "/admin/FAQ", element: <FAQ /> },
    {
      name: "interviewtype",
      path: "/admin/interviewtype",
      element: <InterviewType />,
    },
    { name: "jobtype", path: "/admin/jobtype", element: <JobType /> },
    {
      name: "role",
      path: "/admin/addPermission",
      element: <AddPermission />,
    },
    {
      name: "mcqassessment",
      path: "/admin/mcqassessment",
      element: <MCQAssessment />,
    },
    { name: "city", path: "/admin/city", element: <City /> },
    { name: "state", path: "/admin/state", element: <State /> },
    { name: "country", path: "/admin/country", element: <Country /> },
    { name: "addcity", path: "/admin/addcity", element: <AddCity /> },
    { name: "addstate", path: "/admin/addstate", element: <AddState /> },
    { name: "addcountry", path: "/admin/addcountry", element: <AddCountry /> },
    { name: "adminlogin", path: "/admin/admins", element: <AdminLogin /> },
    { name: "jobpost", path: "/admin/jobpost", element: <Jobpost /> },
    { name: "aboutus", path: "/admin/aboutus", element: <Aboutus /> },
    { name: "Location", path: "/admin/Location", element: <Country /> }
  ];

  return (
    <>
      <Routes>
        {pages.map((list, index) => {
          const { name, path, element } = list;
          const permissions =
            user &&
            user.permission &&
            user.permission.find((permission) => {
              return permission.pageSlug == name;
            });
          if (user?.role != 3) {
            if (permissions && permissions.readP == 1) {
              return <Route key={path} path={path} element={element} />;
            } else {
              return (
                <Route key={index} path={path} element={<h1>Not Found</h1>} />
              );
            }
          }
          if (user?.role == 3) {
            return <Route key={path} path={path} element={element} />;
          }
        })}
      </Routes>
    </>
  );
};

export default Main;
