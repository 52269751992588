import React, { useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getAssessmentFresherList } from "../../state/action/fresherAction";
import { useSelector } from "react-redux";
import { useForm } from "../../utills/useForms";
import { SkillList } from "../../state/action/skillAction";
import { useNavigate } from "react-router-dom";
import AutohideExample from "../../utills/Notification";
import { BsCalendarEvent } from "react-icons/bs";
import JobPrefrenceEdit from "../../components/Modal/JobPrefrenceEdit";
import { Helmet } from 'react-helmet';


const initialValues = {
  title: "",
};

const Assessments = () => {
  const [SoftSkillAssessment, setSoftSkillAssessment] = useState(false);
  const [Permissions, setPermissions] = useState(false);
  const [CodingChallenge, setCodingChallenge] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [isSoftSkill, setisSoftSkill] = useState(false);
  const [JobPreference, setJobPreference] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [profileComplete, setProfileComplate] = useState("");
  const [search, setSearch] = useState("");
  const [skillData, setSkillData] = useState([]);
  const [hasCameraPermission, setHasCameraPermission] = useState(true);
  const [skillIds, setSkillIds] = useState(0);
  const [studyRunning, setStudyRunning] = useState(false); // Set initial state to false
  const [testType, setTestType] = useState(""); // Set initial state to false
  const [softSkillData, setSoftSkillData] = useState([]);
  const [skillNames, setSkillNames] = useState("");
  const [softSkillDescription, setSoftSkillDescription] = useState("");
  const [codingData, setCodingData] = useState([]);
  const [cameraPermission, setCameraPermission] = useState(false);


  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const navigate = useNavigate();
  const Id = useSelector((state) => state?.auth?.user?.id);

  const calculateDays = (date) => {
    const start = new Date();
    const end = new Date(date);
    const timeDiff = Math.abs(end - start);
    const daysCount = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysCount;
  };


  //skilllist
  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      setSkillData(res.data);
    }
  };

  useEffect(() => {
    getSkillList();
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid =
          regex.test(fieldValues.email) && fieldValues.email.endsWith(".com");
        if (!isEmailValid) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("noofEmployee" in fieldValues)
      temp.noofEmployee = fieldValues.noofEmployee
        ? ""
        : "This field is required.";

    if ("mobile" in fieldValues) {
      if (fieldValues.mobile !== "" && fieldValues.mobile !== undefined) {
        const regex = /^\d{10}$/;
        if (!regex.test(fieldValues.mobile)) {
          temp.mobile = "Invalid Phone Number";
        } else {
          temp.mobile = "";
        }
      } else {
        temp.mobile = "This field is required.";
      }
    }

    if ("firstName" in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const getPermission = async () => {

    try {
      // Request permission to use the camera
      await navigator.mediaDevices.getUserMedia({ video: true });
      if (testType == 1) {
        navigate("/mcqassessment", {
          state: {
            isSoftSkill: isSoftSkill,
            skillId: skillIds,
            skillName: skillNames

          }
        }
        );
      }
      if (testType == 2) {
        navigate("/codingassessment", {
          state: {
            isCoding: true,
          }
        });
      }

    } catch (error) {
      setShow({
        isOpen: true,
        message: "Please allow camera permission",
        type: "error",
      });
    }
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  //getlist
  const getAssesmentList = async () => {
    let string = "";

    let queryString = `?search=${search}${string}&skillId=${values.title}`;

    const res = await getAssessmentFresherList(Id, queryString);
    if (res && res.status === true) {
      setItemList(res?.data);
      setProfileComplate(res?.data?.profilePercentage);
      setSoftSkillData(res?.data?.softSkill);
      setCodingData(res?.data?.coding);

    }
  };

  useEffect(() => {
    getAssesmentList(Id);
    localStorage.removeItem('keyPressCount');

  }, [Id, values.title, refresh, search]);

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  return (

    <main className="body-background" >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tech Assessments | Assess Your Skills for Freshers | Job Portal"</title>
        <meta name="description" content="Take skill assessments on Job Portal to showcase your expertise and increase your chances of landing your dream job. Join us today!" />
      </Helmet>

      {/* {cameraPermission && (
      <CameraComponent1 setHasCameraPermission={setHasCameraPermission} cameraPermission={cameraPermission} setCameraPermission={setCameraPermission} /> )} */}

      <div className="bg-cyan">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard-welcome-text py-5">
                <h3 className="mb-2 font-PTSerif-b">Assessments</h3>
                <p className="mb-0 font-16-24-05">
                  Give assessment for skills you are interested in, including essential evaluations and we'll match you with the best-fit job opportunities involving those skill.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-3 py-lg-5 justify-content-center">
          <div className="col-md-12 col-xl-8">
            <div className="wizard-form">
              <div className="wizard-form">
                <ul className="d-flex progressbar text-center px-0">
                  <li
                    className={
                      itemList?.isPassSofSkill === 1
                        ? "d-inline-block col position-relative text-center progressbar-list active"
                        : "d-inline-block col position-relative text-center progressbar-list"
                    }
                  >
                    Soft Skill <br /> Assessment
                  </li>
                  <li
                    className={
                      itemList?.isAssessMentPass === 1
                        ? "d-inline-block col position-relative text-center progressbar-list active"
                        : "d-inline-block col position-relative text-center progressbar-list"
                    }
                  >
                    Technical <br /> Assessment
                  </li>
                  <li
                    className={
                      itemList?.isCodingPass === 1
                        ? "d-inline-block col position-relative text-center progressbar-list active"
                        : "d-inline-block col position-relative text-center progressbar-list"
                    }
                  >
                    Coding <br /> Challenge
                  </li>
                  {/* <li className="d-inline-block col position-relative text-center progressbar-list">
                    Coding <br /> Challenge
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-card-head pt-3 pt-lg-5">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05">
                Essential assessments to pass
              </div>
            </div>
          </div>
        </div>

        <div className="row flex-nowrap flex-lg-wrap level-card-scroll mb-4">
          <div className="col-10 col-md-5 col-lg-3 my-3">
            {/* <div className="level-card level-card-primary"> */}
            <div className={`level-card ${profileComplete > 99 && softSkillData?.isBlockd != 1 ? "level-card-primary" : "level-card-locked"} `}>

              <div className="font-18-24-05 fw-medium level-card-head">
                {softSkillData && softSkillData.title && softSkillData.title || "Soft Skill"}
              </div>
              <div className="font-16-24-05 level-card-body">
                {
                  softSkillData && softSkillData.description && softSkillData.description || "Soft Skill"
                }
              </div>
              {profileComplete > 99 ? (
                <div className="level-card-footer">
                  <a
                    href="javascript:void(0)"
                    className="font-18-24-05 fw-medium"
                    onClick={() => {

                      if (softSkillData.isPass == 1) {
                        setShow({
                          isOpen: true,
                          message: "You have already passed this assessment",
                          type: "warning",
                        });

                      }
                      else if (softSkillData.isBlockd == 1) {
                        setShow({
                          isOpen: true,
                          message: `Your Assessment is blocked for ${calculateDays(softSkillData.blockedDate)} days`,
                          type: "error",
                        });
                      }
                      else if (profileComplete > 99) {
                        if (width <= 991) {
                          navigate('/assessments-mobile')
                        } else {
                          setSoftSkillAssessment(true);
                          setSkillNames(softSkillData && softSkillData.title && softSkillData.title || "Soft Skill");
                          setSoftSkillDescription(softSkillData && softSkillData.bdescription && softSkillData.bdescription || "Soft Skill");
                          setisSoftSkill(false);
                          setTestType(1);
                          setSkillIds(1);
                        }

                      } else {
                        if (softSkillData.isPass == 1) {
                          setShow({
                            isOpen: true,
                            message: "You have already passed this assessment",
                            type: "warning",
                          });

                        }
                        else if (softSkillData.isBlockd == 1) {
                          setShow({
                            isOpen: true,
                            message: `Your Assessment is blocked for ${calculateDays(softSkillData.blockedDate)} days`,
                            type: "error",
                          });
                        }
                        else if (profileComplete > 99) {
                          if (width <= 991) {
                            navigate('/assessments-mobile')
                          } else {
                            setSoftSkillAssessment(true);
                            setSkillNames(softSkillData && softSkillData.title && softSkillData.title || "Soft Skill");
                            setSoftSkillDescription(softSkillData && softSkillData.bdescription && softSkillData.bdescription || "Soft Skill");
                            setisSoftSkill(false);
                            setTestType(1);
                            setSkillIds(1);
                          }
                        } else {
                          setShow({
                            isOpen: true,
                            message: "Your profile is not completed yet please complete your profile 100% to unlock this assessment",
                            type: "error",
                          });
                        }
                      }
                    }}

                  >
                    <div className="mx-0 row justify-content-between">

                      <div className="col-auto">{softSkillData.isPass == 1 ? "Pass" : softSkillData.isPass == 2 ? "Didn't Pass" : "Start"}</div>
                      {softSkillData.isPass == 0 && softSkillData.isBlockd != 1 && (
                        <div className="col-auto">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 12H19"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5L19 12L12 19"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>)}
                      {
                        softSkillData.isBlockd == 1 && (
                          <>
                            {/* <div className="col-auto">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div> */}
                            <div className="col-auto d-flex align-items-center" style={{ gap: '6px' }}>
                              <BsCalendarEvent className="col-auto" />
                              <div>

                                {calculateDays(softSkillData.blockedDate)}&nbsp;days
                              </div>
                            </div>
                          </>
                        )

                      }
                    </div>
                  </a>
                </div>) : (<div className="level-card-footer">
                  <a href="JavaScript:void(0)" className="font-18-24-05 fw-medium ">
                    <div className="mx-0 row justify-content-between" onClick={() => {
                      setShow({
                        isOpen: true,
                        message: "Complete your profile to 100% to unlock this assessment",
                        type: "error",
                      });
                    }}>
                      <div className="col-auto">Locked</div>
                      <div className="col-auto">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                            stroke="#E4E4E4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                            stroke="#E4E4E4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>)}

            </div>
          </div>
        </div>

        <div className="rounded-card-head pt-3 pt-lg-5">
          <div className="row align-items-center mb-2">
            <div className="col-12 col-md">
              <div className="font-PTSerif-b ps-2 pb-0 font-18-24-05">
                Technical assessments based on your skills
              </div>
            </div>
            <div className="col-12 col-md-auto ">
              <a
                href="JavaScript:void(0)"
                className="d-flex pe-2 btn btn-link text-decoration-none card-head-action justify-content-end"
                onClick={(e) => {
                  setJobPreference(true);
                }}
              >
                <div className="card-head-action-icon pe-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>{" "}
                Edit My Tech stack
              </a>
            </div>
            <div className="col-12 col-md-12 pt-2">
              {softSkillData?.isPass != 1 ? (
                <div className="font-16-24-05 ps-2">
                  <svg
                    className="me-2"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.29 3.85996L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7238C2.83871 20.9009 3.18082 20.9961 3.53002 21H20.47C20.8192 20.9961 21.1613 20.9009 21.4623 20.7238C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.85996C13.5318 3.56607 13.2807 3.32308 12.9812 3.15444C12.6817 2.98581 12.3438 2.89722 12 2.89722C11.6563 2.89722 11.3184 2.98581 11.0188 3.15444C10.7193 3.32308 10.4683 3.56607 10.29 3.85996Z"
                      stroke="#982951"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 9V13"
                      stroke="#982951"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 17H12.01"
                      stroke="#982951"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="text-danger">
                    Pass the essential assessments to unlock
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row flex-nowrap flex-lg-wrap level-card-scroll mb-4">
          {itemList?.assesment?.map((row) => {
            return (
              <>
                <div className="col-10 col-md-5 col-lg-3 my-3">
                  <div className={`level-card ${softSkillData?.isPass == 1 ? "level-card-primary" : "level-card-locked"} `}>
                    {/* level-card-locked */}
                    <div className="font-18-24-05 fw-medium level-card-head">
                      {row?.skillname}
                    </div>
                    <div className="font-16-24-05 level-card-body">
                      {row?.description}
                    </div>
                    {softSkillData?.isPass != 1 ? (
                      <div className="level-card-footer">
                        <a href="javascript:void(0)" className="font-18-24-05 fw-medium ">
                          <div className="mx-0 row justify-content-between" onClick={() => {
                            setShow({
                              isOpen: true,
                              message: "Pass the soft skill test to unlock the technical assessments.",
                              type: "error",
                            });
                          }}>
                            <div className="col-auto">Locked</div>
                            <div className="col-auto">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>

                          </div>
                        </a>
                      </div>
                    ) : (
                      <div className="level-card-footer">
                        <a
                          href="javascript:void(0)"
                          className="font-18-24-05 fw-medium"
                          onClick={() => {
                            if (row.isPass == 1) {
                              setShow({
                                isOpen: true,
                                message: "You have already passed this assessment",
                                type: "warning",
                              });

                            } else if (row.isBlockd == 1) {
                              setShow({
                                isOpen: true,
                                message: `Your Assessment is blocked for ${calculateDays(row.blockedDate)} days`,
                                type: "error",
                              });
                            }
                            else if (profileComplete > 99) {
                              if (width <= 991) {
                                navigate('/assessments-mobile')
                              } else {
                                setSkillIds(row?.skillId);
                                setSoftSkillAssessment(true)
                                setisSoftSkill(false);
                                setSkillNames(row?.skillname);
                                setSoftSkillDescription(row?.bdescription);
                                setTestType(1);
                              }
                            } else {
                              setShow({
                                isOpen: true,
                                message: "Your profile is not completed yet please complete your profile 100% to unlock this assessment",
                                type: "error",
                              });
                            }
                          }}
                        >
                          <div className="mx-0 row justify-content-between">{
                            softSkillData.isPass == 1 &&
                            <div className="col-auto">{row.isPass == 1 ? "Pass" : row.isPass == 2 ? "Didn't Pass" : "Start"}</div>
                          }
                            {row.isPass == 0 && row.isBlockd != 1 ? (
                              <>
                                {/* <a href="JavaScript:void(0)" className="font-18-24-05 fw-medium ">
                                  <div className="mx-0 row justify-content-between">
                                    <div className="col-auto">Locked</div>
                                    <div className="col-auto">
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                          stroke="#E4E4E4"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                          stroke="#E4E4E4"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  </a> */}
                                <div className="col-auto">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 12H19"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12 5L19 12L12 19"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                              </>
                            ) : null}
                            {
                              row.isBlockd == 1 && (
                                <>
                                  {/* <div className="col-auto">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                        stroke="#E4E4E4"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                        stroke="#E4E4E4"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div> */}
                                  <div className="col-auto d-flex align-items-center" style={{ gap: '6px' }}>
                                    <BsCalendarEvent className="col-auto" />
                                    <div>
                                      {calculateDays(row.blockedDate)}&nbsp;days
                                    </div>
                                  </div>
                                </>
                              )
                            }


                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <div className="rounded-card-head pt-3 pt-lg-5">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05 pb-2">
                Coding assessments
              </div>
            </div>
            <div className="col-12 col-md-12">
              {itemList?.isAssessMentPass !== 1 ? (
                <div className="font-16-24-05 ps-2">
                  <svg
                    className="me-2"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.29 3.85996L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7238C2.83871 20.9009 3.18082 20.9961 3.53002 21H20.47C20.8192 20.9961 21.1613 20.9009 21.4623 20.7238C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.85996C13.5318 3.56607 13.2807 3.32308 12.9812 3.15444C12.6817 2.98581 12.3438 2.89722 12 2.89722C11.6563 2.89722 11.3184 2.98581 11.0188 3.15444C10.7193 3.32308 10.4683 3.56607 10.29 3.85996Z"
                      stroke="#982951"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 9V13"
                      stroke="#982951"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 17H12.01"
                      stroke="#982951"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="text-danger">
                    Pass at least one technical assessments to unlock
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row flex-nowrap flex-lg-wrap level-card-scroll mb-4">
          <div className="col-10 col-md-5 col-lg-3 my-3">
            {/* <div className="level-card level-card-primary"> */}
            <div className={`level-card ${itemList?.isAssessMentPass == 1 ? "level-card-primary" : "level-card-locked"} `}>

              <div className="font-18-24-05 fw-medium level-card-head">
                Coding Challenge
              </div>
              <div className="font-16-24-05 level-card-body">
                Coding Challenge evaluates coding proficiency, logic, and algorithm understanding.
              </div>
              {itemList?.isAssessMentPass !== 1 ? (
                <div className="level-card-footer">
                  <a href="javascript:void(0)" className="font-18-24-05 fw-medium ">
                    <div className="mx-0 row justify-content-between" onClick={() => {
                      setShow({
                        isOpen: true,
                        message: "Pass at least one technical skill assessment to unlock the coding challenge.",
                        type: "error",
                      });
                    }}>
                      <div className="col-auto">Locked</div>
                      <div className="col-auto">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                            stroke="#E4E4E4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                            stroke="#E4E4E4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>

                    </div>
                  </a>
                </div>
              ) : (
                <div className="level-card-footer">
                  <a
                    href="javascript:void(0)"
                    className="font-18-24-05 fw-medium"
                    onClick={() => {

                      if (codingData.isPass == 1) {
                        setShow({
                          isOpen: true,
                          message: "You have already passed this assessment",
                          type: "warning",
                        });
                      }
                      else if (codingData.isBlockd == 1) {
                        setShow({
                          isOpen: true,
                          message: `Your Assessment is blocked ${calculateDays(codingData.blockedDate)} days`,
                          type: "error",
                        });
                      }
                      else if (profileComplete > 99) {
                        if (width <= 991) {
                          navigate('/assessments-mobile')
                        } else {
                          setCodingChallenge(true)
                          setTestType(2);
                        }
                      } else {
                        setShow({
                          isOpen: true,
                          message: "Your profile is not completed yet please complete your profile 100% to unlock this assessment",
                          type: "error",
                        });
                      }
                    }}
                  >
                    <div className="mx-0 row justify-content-between">
                      <div className="col-auto">{codingData.isPass == 1 ? "Pass" : codingData.isPass == 2 ? "Didn't Pass" : "Start"}</div>
                      {codingData.isPass == 0 && codingData.isBlockd != 1 && (
                        <div className="col-auto">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 12H19"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5L19 12L12 19"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>)}
                      {
                        codingData.isBlockd == 1 && (
                          <>
                            {/* <div className="col-auto">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div> */}
                            <div className="col-auto d-flex align-items-center" style={{ gap: '6px' }}>
                              <BsCalendarEvent className="col-auto" />
                              <div>
                                {calculateDays(codingData.blockedDate)}&nbsp;days
                              </div>
                            </div>
                          </>
                        )

                      }

                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="rounded-card-head pt-3 pt-lg-5">
          <div className="row align-items-center">
            <div className="col col-lg-9">
              <div className="font-PTSerif-b ps-2 font-18-24-05 mb-2">
                All assessments
              </div>
              <div className="ps-2 pb-3 font-16-24-05">
                Give assessment for skills you are interested in, and we'll
                match you with the best-fit job opportunities involving this
                skill. Don't miss out on this chance to kick start your career!
              </div>
            </div>
          </div>
          <div className="row ps-2 align-items-center">
            <div className="col">
              <div className="row align-items-center mb-2">
                <div className="col col-md-auto">
                  <Controls.Input
                    className="form-control font-16-24-05 h-44 py-1"
                    placeholder="Search"
                    name="text"
                    type="text"
                    lable="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="row align-items-center mb-2">
                {/* <div className="col-auto col-md-auto">
                  <div class="form-label">Filter</div>
                </div>
                <div className="col col-md">
                  <select
                    className="form-select h-44"
                    name="title"
                    onChange={handleInputChange}
                    value={values.title}
                  >
                    <option value="">All</option>
                    {skillData?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row flex-nowrap flex-lg-wrap level-card-scroll mb-4">
          {itemList?.skill?.map((row) => {
            return (
              <>
                <div className="col-10 col-md-5 col-lg-3 my-3">
                  <div className={`level-card ${itemList?.isAssessMentPass == 1 ? "level-card-primary" : "level-card-locked"} `}>

                    <div className="font-18-24-05 fw-medium level-card-head">
                      {row?.title}
                    </div>
                    <div className="font-16-24-05 level-card-body">
                      {row?.description}
                    </div>
                    {softSkillData?.isPass != 1 ? (
                      <div className="level-card-footer">
                        <a href="javascript:void(0)" className="font-18-24-05 fw-medium ">
                          <div className="mx-0 row justify-content-between" onClick={() => {
                            setShow({
                              isOpen: true,
                              message: "Pass the soft skill test to unlock the technical assessments.",
                              type: "error",
                            });
                          }}>
                            <div className="col-auto">Locked</div>
                            <div className="col-auto">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                  stroke="#E4E4E4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>

                          </div>
                        </a>
                      </div>
                    ) : (
                      // <div className="level-card-footer">
                      //   <a
                      //     href="javascript:void(0)"
                      //     className="font-18-24-05 fw-medium"
                      //     onClick={() => {
                      //       setSoftSkillAssessment(true)
                      //       setSkillNames(row?.title);
                      //       setSoftSkillDescription(row?.bdescription);
                      //       setSkillIds(row?.id);
                      //       setisSoftSkill(false);
                      //     }}
                      //   >
                      //     <div className="mx-0 row justify-content-between">
                      //       <div className="col-auto">Start</div>
                      //       <div className="col-auto">
                      //         <svg
                      //           width="18"
                      //           height="18"
                      //           viewBox="0 0 24 24"
                      //           fill="none"
                      //           xmlns="http://www.w3.org/2000/svg"
                      //         >
                      //           <path
                      //             d="M5 12H19"
                      //             stroke="white"
                      //             stroke-width="2"
                      //             stroke-linecap="round"
                      //             stroke-linejoin="round"
                      //           />
                      //           <path
                      //             d="M12 5L19 12L12 19"
                      //             stroke="white"
                      //             stroke-width="2"
                      //             stroke-linecap="round"
                      //             stroke-linejoin="round"
                      //           />
                      //         </svg>
                      //       </div>
                      //     </div>
                      //   </a>
                      // </div>
                      <div className="level-card-footer">
                        <a
                          href="javascript:void(0)"
                          className="font-18-24-05 fw-medium"
                          onClick={() => {
                            if (row.isPass == 1) {
                              setShow({
                                isOpen: true,
                                message: "You have already passed this assessment",
                                type: "warning",
                              });

                            } else if (row.isBlockd == 1) {
                              setShow({
                                isOpen: true,
                                message: `Your Assessment is blocked for ${calculateDays(row.blockedDate)} days`,
                                type: "error",
                              });
                            }
                            else if (profileComplete > 99) {
                              if (width <= 991) {
                                navigate('/assessments-mobile')
                              } else {
                                setSkillIds(row?.id);
                                setSoftSkillAssessment(true)
                                setisSoftSkill(false);
                                setSkillNames(row?.title);
                                setSoftSkillDescription(row?.bdescription);
                                setTestType(1);
                              }
                            } else {
                              setShow({
                                isOpen: true,
                                message: "Your profile is not completed yet please complete your profile 100% to unlock this assessment",
                                type: "error",
                              });
                            }
                          }}
                        >
                          <div className="mx-0 row justify-content-between">{
                            softSkillData.isPass == 1 &&
                            <div className="col-auto">{row.isPass == 1 ? "Pass" : row.isPass == 2 ? "Didn't Pass" : "Start"}</div>
                          }
                            {row.isPass == 0 && row.isBlockd != 1 ? (
                              <>
                                {/* <a href="JavaScript:void(0)" className="font-18-24-05 fw-medium ">
                                  <div className="mx-0 row justify-content-between">
                                    <div className="col-auto">Locked</div>
                                    <div className="col-auto">
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                          stroke="#E4E4E4"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                          stroke="#E4E4E4"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  </a> */}
                                <div className="col-auto">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 12H19"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12 5L19 12L12 19"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                              </>
                            ) : null}
                            {
                              row.isBlockd == 1 && (
                                <>
                                  {/* <div className="col-auto">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                                        stroke="#E4E4E4"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                                        stroke="#E4E4E4"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div> */}
                                  <div className="col-auto d-flex align-items-center" style={{ gap: '6px' }}>
                                    <BsCalendarEvent className="col-auto" />
                                    <div>
                                      {calculateDays(row.blockedDate)}&nbsp;days
                                    </div>
                                  </div>
                                </>
                              )
                            }


                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>

      <Modal
        size="xl"
        show={SoftSkillAssessment}
        onHide={() => setSoftSkillAssessment(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 fw-semibold">
                {isSoftSkill ? "Soft Skill Assessment" : `${skillNames} Assessment`}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="font-16-24-05 mb-2">
                {softSkillDescription}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="fw-medium font-18-24-05">Instructions</div>
            </div>
          </div>
          <div className="row flex-nowrap flex-lg-wrap level-card-scroll">
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 7V12H17M21.0036 4.57115L17.9395 2M6.06418 2L3 4.57115M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Time</div>
                <div className="font-16-24-05 mb-lg-2">
                  You will have 20 minutes to complete 30 assessment questions
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8506 11.5442C17.0475 10.6829 16.0641 10.0096 14.9707 9.57227M20.7759 8.81625C19.5712 7.52437 18.0961 6.51439 16.4561 5.8584C14.816 5.20241 13.0514 4.91635 11.2881 5.02111M8.34277 14.5905C8.95571 13.9332 9.73448 13.4532 10.5971 13.2012C11.4598 12.9491 12.3745 12.9335 13.2449 13.1574M6.14941 11.5438C7.09778 10.5268 8.29486 9.77461 9.62259 9.36133M3.22363 8.81604C4.1215 7.85319 5.17169 7.04466 6.33211 6.42285M4.41406 4L18.5562 18.1421M12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">
                  Internet Connection
                </div>
                <div className="font-16-24-05 mb-lg-2">
                  Ensure that you have a stable internet connection before
                  starting
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H20C21.1046 17 22 16.1046 22 15V5C22 3.89543 21.1046 3 20 3Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 21H16"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 17V21"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Screen</div>
                <div className="font-16-24-05 mb-lg-2">
                  Do not switch tabs or browsers during assessment.
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23 7L16 12L23 17V7Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Record</div>
                <div className="font-16-24-05 mb-lg-2">
                  Your video will be recorded during the assessment
                  for evaluation purposes.
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 5L6 9H2V15H6L11 19V5Z"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M23 9L17 15"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 9L23 15"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Quiet Room</div>
                <div className="font-16-24-05 mb-lg-2">
                  Find a distraction-free and quiet room for the assessment.
                  Avoid noisy environments.
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="fw-medium font-18-24-05">Notes</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ul className="font-16-24-05 list-style-dots">
                <li className="mb-2">
                  By clicking the "Continue" button, you agree to our{" "}
                  <span className="fw-semibold">terms and services.</span>
                </li>
                <li className="mb-2">
                  If you do not pass the assessment, you will have the
                  opportunity to retake it after 15 days.
                </li>
                <li className="mb-2">
                  <span className="text-danger">Warning:</span> Cheating during
                  the assessment will result in a ban from our platform.
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
          <Button
            variant="primary"
            className="d-flex justify-content-center h-44 fw-medium m-0"
            onClick={() => {
              setPermissions(true);
              setSoftSkillAssessment(false);
            }}
          >
            Continue
            <div className="card-head-action-icon ps-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12H19"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 5L19 12L12 19"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={Permissions}
        onHide={() => setPermissions(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-32">
              <div className="fw-medium font-18-24-05 mb-2">Permissions</div>
              <div className="font-16-24-05">
                We need to record your video during the assessment for evaluation purposes, Do you agree to record your video?
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="checkbox-round-md">
                <label className="custom-checkbox">
                  {" "}
                  Yes, I agree
                  <Controls.Input
                    type="checkbox"
                    onChange={() => {
                      setStudyRunning(!studyRunning)
                    }}
                    checked={studyRunning}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-lg-4 pt-0 border-top-0 pb-lg-4">
          <Button
            variant="primary"
            className={
              studyRunning
                ? "d-flex justify-content-center align-items-center h-44 fw-medium m-0"
                : "d-flex justify-content-center align-items-center h-44 fw-medium m-0 disabled"
            }
            onClick={() => {
              getPermission();
            }}
          >
            <div className="card-head-action-icon pe-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 3L19 12L5 21V3Z"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            Start
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={CodingChallenge}
        onHide={() => setCodingChallenge(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="row">
            <div className="col-12 col-lg mb-16">
              <div className="font-PTSerif-b font-18-24-05 fw-semibold">
                Coding Challenge
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="font-16-24-05 mb-2">
                This Coding Challenge is designed to assess your coding proficiency, logical reasoning, and grasp of algorithms. These challenges aim to mimic real-world scenarios you might encounter in a technical role. Successfully completing these tasks not only showcases your technical skills but also your ability to think critically and adapt. This assessment is a common step for employers to identify promising candidates for technical positions, so give it your best effort!
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="fw-medium font-18-24-05">Instructions</div>
            </div>
          </div>
          <div className="row flex-nowrap flex-lg-wrap level-card-scroll">
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 7V12H17M21.0036 4.57115L17.9395 2M6.06418 2L3 4.57115M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Time</div>
                <div className="font-16-24-05 mb-lg-2">
                  You will have 45 minutes to complete 3 coding questions
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8506 11.5442C17.0475 10.6829 16.0641 10.0096 14.9707 9.57227M20.7759 8.81625C19.5712 7.52437 18.0961 6.51439 16.4561 5.8584C14.816 5.20241 13.0514 4.91635 11.2881 5.02111M8.34277 14.5905C8.95571 13.9332 9.73448 13.4532 10.5971 13.2012C11.4598 12.9491 12.3745 12.9335 13.2449 13.1574M6.14941 11.5438C7.09778 10.5268 8.29486 9.77461 9.62259 9.36133M3.22363 8.81604C4.1215 7.85319 5.17169 7.04466 6.33211 6.42285M4.41406 4L18.5562 18.1421M12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">
                  Internet Connection
                </div>
                <div className="font-16-24-05 mb-lg-2">
                  Ensure that you have a stable internet connection before
                  starting
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H20C21.1046 17 22 16.1046 22 15V5C22 3.89543 21.1046 3 20 3Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 21H16"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 17V21"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Screen</div>
                <div className="font-16-24-05 mb-lg-2">
                  Do not switch tabs or browsers during assessment.
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23 7L16 12L23 17V7Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
                        stroke="#205FC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Record</div>
                <div className="font-16-24-05 mb-lg-2">
                  Your video will be recorded during the assessment
                  for evaluation purposes.
                </div>
              </div>
            </div>
            <div className="col-10 col-md-7 col-lg-4 col-xl my-3">
              <div className="level-card h-100 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2 round-blue-style-2 w-36">
                  <div className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 5L6 9H2V15H6L11 19V5Z"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M23 9L17 15"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 9L23 15"
                        stroke="#205fc8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="fw-medium font-18-24-05 mb-2">Quiet Room</div>
                <div className="font-16-24-05 mb-lg-2">
                  Find a distraction-free and quiet room for the assessment.
                  Avoid noisy environments.
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="fw-medium font-18-24-05">Notes</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ul className="font-16-24-05 list-style-dots">
                <li className="mb-2">
                  By clicking the "Continue" button, you agree to our{" "}
                  <span className="fw-semibold">terms and services.</span>
                </li>
                <li className="mb-2">
                  If you do not pass the assessment, you will have the
                  opportunity to retake it after 15 days.
                </li>
                <li className="mb-2">
                  <span className="text-danger">Warning:</span> Cheating during
                  the assessment will result in a ban from our platform.
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
          <Button
            variant="primary"
            className="d-flex justify-content-center h-44 fw-medium m-0"
            onClick={() => {
              setPermissions(true);
              setCodingChallenge(false);
            }}
          >
            Continue
            <div className="card-head-action-icon ps-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12H19"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 5L19 12L12 19"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
      <AutohideExample show={show} setShow={setShow} />
      <JobPrefrenceEdit
        setJobPreference={setJobPreference}
        JobPreference={JobPreference}
        refresh={refresh}
        setRefresh={setRefresh}
      />

    </main >
  )
}

export default Assessments;
