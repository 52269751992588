import axiosInstance from "../../utills/AxiosInstance";

//admin list
export const getjobpost = async (querystring) => {
  try {
    const response = await axiosInstance.get('/v1/admins' + querystring)

    return response.data;
  } catch (error) {
    return error;
  }
};

//Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/employee/statusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete role

export const deletejobpost = async (id) => {
  try {
    const response = await axiosInstance.delete(`/employee/deleteJobpost/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const approveJob = async (id) => {
  try {
    const response = await axiosInstance.put(`/employee/adminApproveJobPost/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const rejectJob = async (id) => {
  try {
    const response = await axiosInstance.put(`/employee/adminJobReject/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};





//job post Details

export const getJobPostDetails = async (id) => {
  try {
    const response = await axiosInstance.get(`/employee/getJobpostById/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const assignFresherbyAdmin = async (id, value) => {
  try {
    const response = await axiosInstance.post(`/v1/addjobPostFresher/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const rejectJobbyAdmin = async (fresherId, jobId) => {
  try {
    const response = await axiosInstance.put(`/employee/rejectCandidate/${jobId}/${fresherId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


//reject the candiate
export const getRejectCandidateJob = async (jobId, fresherId, values) => {
  try {
    const response = await axiosInstance.put(
      `/employee/rejectCandidate/${jobId}/${fresherId}`,
      values
    );
    return response.data;
  } catch (error) {
    return error;
  }
};


//candidate module list
export const getCandidateLists = async (id, queryString) => {
  try {
    const response = await axiosInstance.get(
      `/employee/getCandidateList/${id}` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};



//reject candid by admin
export const rejectCandidateByAdmin = async (jobId, fresherId) => {
  try {
    const response = await axiosInstance.put(

      `/employee/adminRejectCandidate/${jobId}/${fresherId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};



export const assignjob = async () => {
  try {
    const response = await axiosInstance.get('/v1/getadmins')

    return response.data;
  } catch (error) {
    return error;
  }
};



export const adminassignjob = async (id,payload) => {
  try {
    const response = await axiosInstance.post(`/v1/assignJob/${id} `,payload)

    return response.data;
  } catch (error) {
    return error;
  }
};