import auth from "./authReducer";
import Employer from "./employerReducer";
import settingReducer from "./settingReducer";
import { combineReducers } from "redux";
import fresherReducer from "./fresherReducer";

const reducers = combineReducers({
    auth,
    Employer,
    fresherReducer,
});

export default reducers;
