import { getFresherPerferenceLists } from "./state/action/pushNotificationAction";

export const useUserNotificationSubscribeHook = () => {
    // const { addUserSubscriptionAPI } = useAddUserSubscriptionAPI();

    async function regSw() {
        if ('serviceWorker' in navigator) {
            const url = `${process.env.DOMAIN}/sw.js`;
            const reg =
                await navigator.serviceWorker.register(url, { scope: '/' });
            return reg;
        }
        throw Error('serviceWorker not supported');
    }

    async function subscribe(serviceWorkerReg, notificationData) {
        try {
            let subscription = await serviceWorkerReg.pushManager.getSubscription();
            if (subscription === null) {
                subscription = await serviceWorkerReg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: "BMZ1ONQ7-BGI3FiqrV27FcbpotuQNsHIwsA3lcG4MesA0CAclWi4Cnd0-PupRuPJ6jbCgThGYwyTyadtSNAiIlw",
                });
                const payload = {
                    subscription: JSON.stringify(subscription),
                    userId: notificationData.userId,
                    type: notificationData.type,
                }

                const { data } = await getFresherPerferenceLists(payload);
            }
        } catch (error) {
            console.log('Error subscribing to push notifications:', error);
        }

    }

    async function registerAndSubscribe(data) {
        try {
            const serviceWorkerReg = await regSw();
            await subscribe(serviceWorkerReg, data);
        } catch (error) {
            return error;
        }
    }

    return { regSw, subscribe, registerAndSubscribe };
};
