import axiosInstance from "../../utills/AxiosInstance";


export const getFresherPerferenceLists = async (values) => {
    try {
        const response = await axiosInstance.post(
            `/fresher/addSubscription`, values
        );
        return response.data;
    } catch (error) {
        return error;
    }
}


export const getNotificationLists = async (id, type) => {
    try {
        const response = await axiosInstance.get(
            `/v1/getNotification/${id}/${type}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
}

export const notificationRead = async (id) => {
    try {
        const response = await axiosInstance.put(
            `/v1/updateNotification/${id}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
}


export const markAllRead = async (id, type) => {
    try {
        const response = await axiosInstance.post(
            `/v1/readAllNotification/${id}/${type}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getNotificationListsAdmin = async (type) => {
    try {
        const response = await axiosInstance.get(
            `/v1/getNotification/${type}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
}

export const markAllReadAdmin = async (type) => {
    try {
        const response = await axiosInstance.post(
            `/v1/readAllNotification/${type}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
}