import axiosInstance from "../../utills/AxiosInstance";

//admin list
export const getAdminLists = async (querystring) => {
  try {
    const response = await axiosInstance.get('/v1/adminList' + querystring)

    return response.data;
  } catch (error) {
    return error;
  }
};

// Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/adminStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const AllowForJobApprove = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/allowjobassign/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete 

export const deleteAdmin = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteAdmin/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
}

//add Admin
export const AddAdminData = async (payload) => {
  try {
    const response = await axiosInstance.post(`/v1/addAdmin`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};


//update Admin
export const updateAdminData = async (id, payload) => {
  try {
    const response = await axiosInstance.put(`/v1/updateAdmin/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const roledropdown = async () => {
  try {
    const res = await axiosInstance.get(`/v1/roleDropdown`);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};


//update Admin
export const ResetAdmin = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/adminPasswordChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};