import React, { useState, useEffect } from "react";
import Profile from "../../assets/images/interviewer_profile_2.png";
import {
  AiFillEye,
  AiOutlineDelete,
  FaBackward,
  HiLocationMarker,
  HiPhone,
  MdEmail,
} from "../../assets/Icons/icons";
import Table from "react-bootstrap/esm/Table";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router";
import { useForm } from "../../utills/useForms";
import {
  getEmployerDetails,
  deleteEmployer,
} from "../../state/action/addEmployerAction";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ReactComponent as ViewIcon } from "../../assets/images/view.svg";
import { IsWrite, IsDelete } from "../../utills/handlePermission";

const initialValues = {
  title: "",
};

const EmployerDetail = () => {
  const navigate = useNavigate();

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [filterData, setFilterData] = useState(true);
  const [EmployerList, setEmployerList] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);

  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);

  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [interviewdataList, setinterviewadataList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [sortFlag, setSortFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [view, setView] = useState(false);
  const [jobskilldata, setjobskilldata] = useState([]);
  const [jobPost, setjobpost] = useState([]);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [id, setId] = useState([]);

  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const editPermissions = IsWrite("employerDetailsPage");

  useEffect(() => {
    getEmployerLists("", "");
  }, [refresh, filterData, search, page]);
  const { state } = useLocation();


  const handlePageChange = (page) => {
    setCurrentPage(page);
    getEmployerLists(page, "", "", pageLimit); // Call the API with the new page number and page limit
  };

  const getEmployerLists = async (page, name = "", flag, perPageSize, limit) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = perPageSize ? perPageSize : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&sortby=${name}&sortFlag=${flag}`;

    const res = await getEmployerDetails(state?.id, queryString); // Pass the queryString to the API function
    setView(true);
    if (res.status === true) {
      setEmployerList(res.data);
      setTotalRecords(res.total);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPageLimit(recordLimit);
      setView(false);
    } else {
      setEmployerList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      setView(false);
    }
    setFilter(false);
  };

  const handleDelete = async () => {
    const res = await deleteEmployer(state?.id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getEmployerLists(fieldName, flagName);
      } else {
        getEmployerLists("");
      }
      handleCloseDelete();
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setView(false);
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
      setView(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          <div className="d-flex align-items-center gap-2">
            <span>
              <FaBackward />
            </span>
            <span>Back</span>
          </div>
        </button>
        {/* <button className="btn btn-danger">
          {" "}
          <div
            className="d-flex align-items-center gap-2"
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteSwitchModel();
              setId(id);
              setDeleteId(id);
              handleDeleteModel();
            }}
          >
            <span>
              <AiOutlineDelete />
            </span>
            <span>Delete</span>
          </div>
        </button> */}
      </div>
      <div className="row ProfileCard">
        <div
          className={
            "ProfileCard" ? "col-md-12" : "col-md-4 d-flex flex-column "
          }
        >
          <div className="card flex-fill">
            <div className="card-body d-flex flex-column align-items-center">
              <h5 className="card-title">{EmployerList.firstName}{" "} {EmployerList.lastName}</h5>
              <div className="profile d-flex align-items-center flex-column gap-3">
                {/* <img src={Profile} alt="Profile" className="profile-image" /> */}
                <span className="mobile mt-3 md-1">
                  <HiPhone />{" "}: {" "}{EmployerList.mobile || "-"}
                </span>
                <span className="email ">
                  <MdEmail />&nbsp; :{" "}{EmployerList.email || "-"}
                </span>

                <span className="email">
                  Company Name {" "}: {" "}{EmployerList.companyName || "-"}
                </span>
                <span className="email">
                  Company's Website {" "}:{" "} {EmployerList.website || "-"}
                </span>
                <span className="email">
                  No.of Employees {" "}:{" "}{EmployerList.noofOpeningTitle || "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-table-wrap" style={{ marginTop: "10px" }}>
        <Table responsive className="striped-table mb-0">
          <thead>
            <tr>
              <th>Job Title</th>
              <th>No.of Openings</th>
              <th>Job Location</th>
              <th>Job Type</th>
              {/* <th>Job description</th> */}
              <th>Skills</th>
              <th>Salary</th>
              <th>Action</th>
            </tr>
          </thead>
          {EmployerList &&
            EmployerList?.jobPost?.map((row) => {
              return (
                <tbody>
                  <tr
                    key={row?.id}
                    className="border rounded"
                    style={{ backgroundColor: "white" }}
                  >
                    <td style={{ width: "14.5%" }}>{row?.jobTitle || "-"}</td>

                    <td style={{ width: "14.5%" }}>{row?.openings || "-"}</td>
                    <td style={{ width: "14.5%" }}>
                      {row?.cityName},{" "}
                      {row?.stateName},{" "}
                      {row?.countryName}
                    </td>
                    <td style={{ width: "14.5%" }}>{row?.jobTypeName || "-"}</td>
                    {/* <td
                      style={{
                        width: "14.5%",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      
                      }}
                    >
                      {row?.jobDescription || "-" }
                    </td> */}
                    <td
                      style={{
                        width: "14.5%",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >

                      {row && row?.jobskilldata && row?.jobskilldata?.map((item) => {
                        return <div>{item?.title || "-"}</div>;
                      })}
                    </td>

                    <td>
                      {" "}
                      {row?.maxSalary}- {row?.minSalary}
                    </td>

                    <td style={{ width: "11%" }}>
                      <ul className="d-flex action-col-main">
                        <li className="me-3">
                          {/* {editPermissions && ( */}
                            <button
                              className="btn btn-sm btn-icon btn-success btn-inner"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="View"
                              onClick={() => {
                                navigate(`/admin/jobpostdetails`, {
                                  state: {
                                    id: row.id,
                                  },
                                });
                              }}
                              style={{ padding: "2px 4px 2px 4px" }}
                            >
                              <span className="btn-inner">
                                <ViewIcon />
                              </span>
                            </button>
                          {/* )} */}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              );
            })}
        </Table>
      </div>


      {/* delete */}
      <Modal
        size="md"
        show={openDeleteSwitchModel}
        onHide={() => handleDeleteCloseSwitch(false)}
        aria-labelledby=""
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <p className="mb-0 font-18-24-05 fw-medium">
            Are you sure you want to delete?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            onClick={() => handleDeleteCloseSwitch()}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary h-44 fw-medium m-2"
            type="submit"
            onClick={() => {
              handleDeleteCloseSwitch();
              handleDelete(deleteId);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmployerDetail;
