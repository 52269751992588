import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectWithCountdown = () => {
    const [countdown, setCountdown] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            navigate('/assessments');
            // Redirect logic here
            // window.location.href = 'https://www.example.com'; // Replace with your desired URL
        }
    }, [countdown]);

    return (
        <div className="countdown-container">
            <h1 className="countdown-text"> This Assessment in not enabled for you.
                Redirecting in {countdown} seconds</h1>
            {/* You can add additional UI elements here */}
        </div>
    );
};

export default RedirectWithCountdown;
