import React, { useState } from "react";

export function useForm1(initialFvalues1, validateOnChange = false, validate) {
    const [values1, setvalues1] = useState(initialFvalues1);
    const [errors1, setErrors1] = useState({});

    const handleInputChange1 = (e) => {
        const { name, value } = e.target;
        setvalues1({
            ...values1,
            [name]: value,
        });
        if (validateOnChange) validate({ [name]: value });
    };

    const resetForm1 = () => {
        setvalues1(initialFvalues1);
        setErrors1({});
    };

    return {
        values1,
        setvalues1,
        errors1,
        setErrors1,
        handleInputChange1,
        resetForm1,
    };
}

export function Form(props) {
    const { children, ...other } = props;
    return (
        <form autoComplete="off" {...other}>
            {props.children}
        </form>
    );
}
