import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Async, { useAsync } from "react-select/async";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";
import {
  getTalentpool,
  StatusUpdate,
  deleteFresher,
} from "../../state/action/TalentpoolAction";
import Form from "react-bootstrap/Form";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/view.svg";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";
import { IsWrite, IsDelete } from "../../utills/handlePermission";
import Multiselect from "multiselect-react-dropdown";
import { SkillList } from "../../state/action/skillAction";
import { jobtypelist } from "../../state/action/jobTypeAction";
import { CountryData, StateData, CityData } from "../../state/action/locationAction";
import { getJobTypesLists, getJobPreferLists } from "../../state/action/fresherAction";

import '../../components/pagination/style.scss';
import '../../components/pagination/pagination.scss';
import PaginationOne from '../../components/pagination/PaginationOne';
import { useMemo } from 'react';

let PageSize = 10;





const initialValues = {
  firstName: "",
  email: "",
  mobile: "",
  status: "",
  isActive: "",
};

const Freshers = () => {
  const [flagName, setflagName] = useState(false);
  const [FresherList, setFresherList] = useState([]);
  const [view, setView] = useState(false);
  const deletePermissions = IsDelete("freshers");
  const editPermissions = IsWrite("freshers");
  const [skillData, setSkillData] = useState([]);
  const [jobpostData, setjobpostData] = useState([]);
  const [jobTypeList, setjobTypeList] = useState([]);
  const [countryLists, setCountryList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [jobPreferList, setJobPreferList] = useState([]);

  const [jobpostlist, setjobpostlist] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [stateList, setstateList] = useState([]);

  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });


  const [sortFlag, setSortFlag] = useState(false);
  const [skillsId, setSkillsId] = useState([]);

  const [totalPages, setTotalPages] = useState(10);
  const [totalRecords, setTotalRecords] = useState(3);

  const [openFilterModel, setOpenFilterModel] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [addskill, setaddskill] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [id, setId] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [skillDataList, setskillDataList] = useState([]);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [deleteSkill, setDeleteSkill] = useState([]);
  const [selectSkill, setSelectSkill] = useState([]);

  const navigate = useNavigate();

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);

  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);

  const handleCloseFilter = () => setOpenFilterModel(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const statuslist = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];


  const jobstatus = [
    { id: "1", title: "Interviewed" },
    { id: "0", title: "Selected for Job" },
    { id: "3", title: "Hired" },
    { id: "5", title: "Rejected" },

  ]
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  //skill list
  useEffect(() => {
    getFresherList(page, "", "");
  }, [refresh, filterData, search, page]);

  const getFresherList = async (page, name = "", flag, perPageSize) => {
    setOpenBreakdrop(true);
    setView(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = perPageSize ? perPageSize : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&sortby=${name}&sortFlag=${flag}&fresherSkill=${skillsId}`;



    const res = await getTalentpool(queryString);
    setView(true);
    if (res.status === true) {
      setFresherList(res.data);
      setTotalRecords(res.total);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPageLimit(recordLimit);
      setView(false);
    } else {
      setFresherList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      setView(false);
    }
    setFilter(false);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getFresherList(page, "", "", pageLimit); // Call the API with the new page number and page limit
    setPage(page);
  };

  const handlePageLimitChange = (e) => {
    const perPageSize = parseInt(e.target.value);
    setPageLimit(perPageSize || 10);
    setCurrentPage(1); // Reset the current page to 1 when page limit is changed
    getFresherList(1, "", "", perPageSize); // Call the API with the new page limit and reset the page number to 1
  };

  const [currentPage, setCurrentPage] = useState(1);

  const TalentPoolData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return FresherList;
  }, [currentPage, FresherList]);


  //skilllist
  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      setSkillData(res.data);
    }
  };


  //countrylist
  const CountryListData = async () => {
    const res = await CountryData();

    if (res.status === true) {
      setCountryList(res.data);

    }
  };

  //statelist
  const StateListData = async (ids) => {

    const res = await StateData(ids);

    if (res.status === true) {
      setstateList(res.data);
    }
  };

  //citylist
  const CityListData = async (ids) => {
    const res = await CityData(ids);

    if (res.status === true) {
      setCityList(res.data);
    }
  };



  // const getjobpostList = async () => {
  //   const res = await getjobpost();

  //   if (res.status === true) {
  //     setjobpostlist(res.data);

  //   } 
  // };

  const getJobTypeData = async (e) => {
    const res = await getJobTypesLists();
    if (res && res.data) {
      setJobList(res.data);
    }
  };

  const getJobPreferData = async (e) => {
    const res = await getJobPreferLists();
    if (res && res.data) {
      setJobPreferList(res.data);
    }
  };


  const multiselectSelectSkill = (data, e) => {
    setSkillsId([...skillsId, e.id]);
    setSelectSkill([...selectSkill, {
      id: e.id,
      //fresherId: fresherId?.user?.id,
      title: e.title,
    }]);
    setSkillData(skillData?.filter((item) => { return !data.some((item2) => { return item.id === item2.id }) }));
  };


  const removeSkill = async (e, item) => {
    setSkillData([...skillData, item]);
    const removeSkill = selectSkill.filter(
      (items) => items.id != item.id
    );
    setSelectSkill(removeSkill);
    if (item && item.id) {
      setDeleteSkill([...deleteSkill, item?.id]);
    }
  };
  // list
  useEffect(() => {
    getTalentpool(page, "", "");
    getSkillList();

    CountryListData();

    StateListData();
    CityListData();
    getJobTypeData();
    getJobPreferData();


  }, [filterData, search, page]);
  //status change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "name") {
        getFresherList(fieldName, flagName);
      } else {
        getFresherList(currentPage, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    // setSelectSkill([]);

    closeDrawer();
  };

  const handleDelete = async () => {
    const res = await deleteFresher(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getFresherList(fieldName, flagName);
      } else {
        getFresherList(page, "", "");
      }
      handleCloseDelete();
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setView(false);
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
      setView(false);
    }
  };

  const [showDrawer, setShowDrawer] = useState(false);

  const handleReset = (e) => {
    resetForm();
    setSkillsId([]);
    setSelectSkill([]);
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
    // resetForm();
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <div>
      <Loader view={view} />
      {/* {!view && ( */}
      <main className="body-background">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="rounded-card-head">
                <div className="row align-items-center mb-3">
                  <div className="col-md-3">
                    <div className="font-PTSerif-b ps-2 font-18-24-05">
                      Talent Pool
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="col col-md-auto text-end d-flex justify-content-end flex-wrap">
                      <Controls.Input
                        className="border-0 rounded p-2 mt-2"
                        placeholder="search"
                        name="text"
                        type="text"
                        onChange={(e) => {
                          setTimeout(() => {
                            setSearch(e.target.value);
                          }, 800);
                        }}
                      />
                      <Controls.Button
                        style={{ padding: "7px 30px", marginLeft: "16px" }}
                        className="btn btn-success h-40 mt-2"
                        text="filter"
                        onClick={() => {
                          toggleDrawer();
                          // openFilterDrawer();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {showDrawer && (
                <>
                  <div className="filter-drawer-overlay" onClick={toggleDrawer}>  </div>
                  <div className="filter-drawer-content" style={{ overflow: "auto" }}>
                    <div className="d-flex justify-content-between align-items-center filter-header_div ">
                      <label className="filter-header">Filter</label>
                      <button
                        className="filter-close-button"
                        onClick={toggleDrawer}
                      >
                        x
                      </button>
                    </div>
                    <div className="d-flex flex-column h-100">
                      <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">
                        <div>
                          <div>
                            <label className="filter-label">Name</label>
                            <Controls.Input
                              className="input_filter"
                              name="firstName"
                              type="text"
                              value={values?.firstName}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <label className="filter-label">Email</label>
                            <Controls.Input
                              className="input_filter"
                              name="email"
                              type="text"
                              value={values?.email}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <label className="filter-label">Mobile</label>
                            <Controls.Input
                              className="input_filter"
                              name="mobile"
                              type="text"
                              value={values?.mobile}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <label className="filter-label">status</label>
                            <select
                              className="form-select h-auto px-3 py-2 "
                              name="isActive"
                              onChange={handleInputChange}
                              value={values.isActive}
                            //options={statuslist}
                            >
<option value="">None</option> 
                              {statuslist?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <label className="filter-label">Skills</label>
                            <Multiselect
                              options={skillData} // Options to display in the dropdown
                              selectedValues={selectSkill} // Preselected value to persist in dropdown
                              onSelect={multiselectSelectSkill} // Function will trigger on select event
                              onRemove={removeSkill}                    // displayValue={(option) => (option ? option.skillName : "")} // Property name to display in the dropdown options
                              displayValue="title"
                            />
                          </div>
                          <div>
                            <label className="filter-label">job Type</label>
                            <Form.Select
                              className="form-select h-auto px-3 py-2 "

                              name="jobTypeId"
                              onChange={handleInputChange}
                              value={values?.jobTypeId}
                            >
                              <option value="">Select job type</option>

                              <option value="">none</option>
                              {jobList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <div>
                            <label className="filter-label">job Post</label>
                            <Form.Select
                              className="form-select h-auto px-3 py-2 "
                              name="jobPraferId"
                              value={values.jobPraferId}
                              onChange={handleInputChange}
                            >

                              <option value="">none</option>
                              {jobPreferList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </Form.Select>
                          </div>

                          <div>
                            <label className="filter-label">Job status</label>
                            <select
                              className="form-select"
                              name="status"
                              onChange={handleInputChange}
                              value={values.status}
                            >
                             <option value="">None</option> 
                              {jobstatus?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>



                          <div>
                            <label className="filter-label">Country</label>
                            <Form.Select
                              className="form-select h-auto px-3 py-2 "
                              name="countryId"
                              value={values.countryId}
                              onChange={(e) => {
                                handleInputChange(e);
                                StateListData(e.target.value)
                              }}
                              onClick={CountryListData}
                            >
                              <option value="">Select country</option>

                              {countryLists?.map((item, index) => (

                                <option key={index} value={item.id}>

                                  {item.name}

                                </option>
                              ))}
                            </Form.Select>
                          </div>


                          <div>
                            <label className="filter-label">state</label>
                            <Form.Select
                              className="form-select h-auto px-3 py-2 "
                              name="stateId"
                              value={values.stateId}
                              onChange={(e) => {
                                handleInputChange(e);
                                CityListData(e.target.value)
                              }}
                              onClick={StateListData}
                            >
                              <option value="">Select state</option>

                              {stateList?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>


                          <div>
                            <label className="filter-label">city</label>
                            <Form.Select
                              className="form-select h-auto px-3 py-2 "
                              name="cityId"
                              value={values.cityId}
                              onChange={handleInputChange}
                              onClick={CityListData}
                            >
                              <option value="">Select city</option>

                              {CityList?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>





                        <div className="mt-3 filter-reset-div">
                          <div className="d-flex justify-content-between">
                            <button
                              className="filter-reset-btn"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                            <button
                              className="filter-btn"
                              type="submit"
                            // onClick={closeDrawer}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>

              )}

              <div className="theme-table-wrap">
                <Table responsive className="striped-table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>

                      <th>Job Preference Title</th>

                      <th>Status</th>
                      <th>Date</th>
                      {editPermissions || deletePermissions ? (
                        <th>Action</th>
                      ) : null}

                    </tr>
                  </thead>
                  <tbody>
                    {TalentPoolData.map((row) => (
                      <tr
                        key={row.id}
                        className="border rounded"
                        style={{ backgroundColor: "white" }}
                  
                      >
                        <td style={{ width: "12.5%" }}>{row?.firstName}{" "}{row?.lastName}</td>
                        <td style={{ width: "12.5%" }}>{row?.email}</td>
                        <td style={{ width: "12.5%" }}>{row?.mobile}</td>
                        <td style={{ width: "12.5%" }}>
                          {row?.jobprefertitle}
                        </td>
                        <td style={{ width: "12.5%" }}>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={row.isActive == 1 ? true : false}
                            onChange={(e) => {
                              if (!editPermissions) {
                                setShow({
                                  isOpen: true,
                                  message: "You don't have permission to edit",
                                  type: "error",
                                });
                                return;
                              } else {
                                handleSwitchModel();
                                setStatus(
                                  e.target.checked == true
                                    ? "Active"
                                    : "InActive"
                                );
                                setSwitchId(row.id);
                                setChecked(e.target.checked);
                              }
                            }}
                            name="checkedB"
                            color="primary"
                            sx={{ marginLeft: "40px", px: 0 }}
                          />
                        </td>

                        <td style={{ width: "12.5%" }}>
                          {/* {moment(row.createdAt).format("MM-DD-YYYY LT")|| "-"} */}

                          {
                            // row?.fresherId?.eduEndDate
                            row.createdAt ? moment(row.createdAt).format(
                              "MM-DD-YYYY LT"
                            ) : 'still enrolled'
                          }
                        </td>
                        {deletePermissions || editPermissions ? (
                          <td style={{ width: "12.5%" }}>
                            <ul className="d-flex action-col-main">
                              <li className="me-3">

                                {deletePermissions && (
                                  <button
                                    className="btn btn-sm btn-icon btn-danger btn-inner me-4"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteSwitchModel();
                                      setId(row.id);
                                      setDeleteId(row?.id);
                                      handleDeleteModel();
                                    }}
                                  >
                                    <span className="btn-inner">
                                      <DeleteIcon />
                                    </span>
                                  </button>
                                )}

                                {editPermissions && (
                                  <button
                                    className="btn btn-sm btn-icon btn-success btn-inner"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View"

                                    onClick={() => {

                                      navigate(`/admin/fresherDetail`, {
                                        state: {
                                          id: row.id,
                                        },
                                      });
                                    }}
                                    style={{ padding: "2px 4px 2px 4px" }}
                                  >
                                    <span className="btn-inner">
                                      <ViewIcon />
                                    </span>
                                  </button>
                                )}
                              </li>
                            </ul>
                          </td>
                        ) : null}

                      </tr>
                    ))}
                  </tbody>
                </Table>
                {TalentPoolData && TalentPoolData.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    No Data Found
                  </div>
                ) : null}
              </div>
              <PaginationOne
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={PageSize}
                onPageChange={page => [setCurrentPage(page), getFresherList(page, "", "", pageLimit)]} />
              <div className="pt-2">
                {" "}
                Showing{" "}
                {currentPage * pageLimit > totalRecords
                  ? totalRecords
                  : currentPage * pageLimit}{" "}
                of {totalRecords} Results
              </div>

            </div>
          </div>
        </div>

        {/* status change */}
        <Modal
          size="md"
          show={openSwitchModel}
          onHide={() => handleCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to status change?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleCloseSwitch()}
            >
              No
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleCloseSwitch();

                handleChangeStatus(switchId);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete */}
        <Modal
          size="md"
          show={openDeleteSwitchModel}
          onHide={() => handleDeleteCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to delete?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleDeleteCloseSwitch()}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleDeleteCloseSwitch();
                handleDelete(deleteId);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <AutohideExample show={show} setShow={setShow} />
      </main>
      {/* )} */}
    </div>
  );
};

export default Freshers;
