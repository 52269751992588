import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Async, { useAsync } from "react-select/async";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";
import AddState from "./AddState";
import { Loader } from "../../utills/Loader";
import AutohideExample from "../../utills/Notification";
import { useSnackbar } from "notistack";
import Form from "react-bootstrap/Form";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { Redirect, useNavigate, Link, useLocation } from "react-router-dom";

import {
  getStateLocationList,
  StateStatusUpdate,
  deleteState,
} from "../../state/action/locationAction";
import { useSelector } from "react-redux";


import '../../components/pagination/style.scss';
import '../../components/pagination/pagination.scss';
import PaginationOne from '../../components/pagination/PaginationOne';
import { useMemo } from 'react';
import { IsWrite, IsDelete } from "../../utills/handlePermission";


let PageSize = 10;


const initialValues = {};

const StateList = () => {
  const [stateList, setstateList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  // const [totalpages, setTotalPages] = useState([]);
  const [page, setPage] = useState(1);
  const [switchId, setSwitchId] = useState(null);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const { state } = useLocation();
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);

  const editPermissions = IsWrite("location");


  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);

  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [flagName, setFlagName] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");

  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [sortFlag, setSortFlag] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const handleAddModel = () => setOpenAddModel(true);
  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [status, setStatus] = useState();
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [stateDataList, setstateDataList] = useState([]);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);
  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);
  const [view, setView] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [totalpages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);



  const StateListsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return stateList;
  }, [currentPage, stateList]);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);



  const StateListData = async (page, name = "", limit, perPageSize, flag) => {
    setOpenBreakdrop(true);
    setView(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = perPageSize ? perPageSize : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&sortby=${name}&sortFlag=${flag}`;


    const countryId = localStorage.getItem("country-id");
    const res = await getStateLocationList(queryString, state?.id || countryId);

    if (res.status === true) {
      setstateList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / recordLimit));
      setSortFlag(!sortFlag);
      StateListsData();
      setOpenBreakdrop(false);
      setPageLimit(recordLimit);
      setView(false);

      setOpenBreakdrop(false);
    } else {
      setstateList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      setView(false);
    }
    setFilter(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    StateListData(page, "", "");
  }, [search, filterData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    StateListData(page, "", "", pageLimit); // Call the API with the new page number and page limit
    setPage(page);
  };

  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const body = {
      status: status,
    };
    
    const res = await StateStatusUpdate(switchId, body);

    if (res && res.status === true) {
      if (fieldName == "name") {
        StateListData(fieldName, flagName);
      } else {
        StateListData(currentPage, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };
  const [showDrawer, setShowDrawer] = useState(false);

  const handleReset = (e) => {
    resetForm();
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    closeDrawer();
  };

  const statuslist = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];
  const handleDelete = async () => {
    const res = await deleteState(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        StateListData(fieldName, flagName);
      } else {
        StateListData("");
      }
      handleCloseDelete();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };
  const stateNavigateHandler = (stateid) => {
    localStorage.setItem("City-id", stateid);
    navigate(`/admin/City`, {
      state: {
        id: stateid,
      },

    })
  }



  return (
    <div>
      <Loader view={view} />
      <main className="body-background">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="rounded-card-head">
                <div className="row align-items-end mb-3">
                  <div className="col-md-3">
                    <div className="font-PTSerif-b ps-2 font-18-24-05">State</div>
                    <Link to="/admin/Country">
                      <button className="btn btn-light h-40 ">
                        Back
                      </button>
                    </Link>

                  </div>
                  <div className="col-md-9 pt-2">
                    <div className="col col-md-auto text-end d-flex justify-content-end flex-wrap">
                      {/* <Controls.Button
                      className="btn btn-primary h-44"
                      text="Add"
                      onClick={() => {
                        setIsEdit(false);
                        handleAddSwitchModel();
                      }}
                    /> */}
                      <Controls.Input
                        className="border-0 rounded p-2 mt-2"
                        placeholder="search"
                        name="text"
                        type="text"
                        onChange={(e) => {
                          setTimeout(() => {
                            setSearch(e.target.value);
                          }, 800);
                        }}
                      />
                      <Controls.Button
                        style={{ padding: "7px 30px", marginLeft: "16px" }}
                        className="btn btn-success h-40 mt-2"
                        text="filter"
                        onClick={() => {
                          toggleDrawer();
                          // openFilterDrawer();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {showDrawer && (
                <>
                  <div className="filter-drawer-overlay" onClick={toggleDrawer}>
                  </div>
                  <div className="filter-drawer-content">
                    <div className="d-flex justify-content-between align-items-center filter-header_div ">
                      <label className="filter-header">Filter</label>
                      <button
                        className="filter-close-button"
                        onClick={toggleDrawer}
                      >
                        x
                      </button>
                    </div>
                    <div className="d-flex flex-column h-100">
                      <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">



                        <div>
                          <label className="filter-label">status</label>
                          <select
                            className="form-select h-auto px-3 py-2 "
                            name="status"
                            onChange={handleInputChange}
                            value={values.status}
                          //options={statuslist}
                          >
                            <option>status</option>
                            {statuslist?.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </div>


                        <div className="position-relative bottom-0 mb-5 filter-reset-div">
                          <div className="d-flex justify-content-between">
                            <button
                              className="filter-reset-btn"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                            <button
                              className="filter-btn"
                              type="submit"
                            // onClick={closeDrawer}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>

              )}
              <div className="theme-table-wrap">
                <Table responsive className="striped-table mb-0">
                  <thead>
                    <tr>

                      <th>state</th>
                      <th>status</th>


                    </tr>
                  </thead>
                  {StateListsData.map((row) => {
                    return (
                      <tbody>

                        <tr
                          key={row.id}
                          className="border rounded"
                          style={{ backgroundColor: "white" }}
                          onClick={() => stateNavigateHandler(row.id)}

                        >

                          <td>{row?.name}</td>
                          <td>
                            <Form.Check
                              type="switch"
                              onClick={(e) => e.stopPropagation()}
                              id="custom-switch"
                              checked={row.status == 1 ? true : false}
                              onChange={(e) => {
                                if (!editPermissions) {
                                  setShow({
                                    isOpen: true,
                                    message: "You don't have permission",
                                    type: "error",
                                  })
                                  return;
                                } else {
                                  handleSwitchModel();
                                  setStatus(
                                    e.target.checked == true ? 1 : 0
                                  );
                                  setSwitchId(row.id);
                                  setChecked(e.target.checked);
                                }
                              }}
                            />
                          </td>

                          {/* <td>
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              checked={row.isActive == 1 ? true : false}
                              onChange={(e) => {
                                if (!editPermissions) {
                                  setShow({
                                    isOpen: true,
                                    message: "You don't have permission",
                                    type: "error",
                                  })
                                  return;
                                } else {
                                  handleSwitchModel();
                                  setStatus(
                                    e.target.checked == true
                                      ? "Active"
                                      : "InActive"
                                  );
                                  setSwitchId(row.id);
                                  setChecked(e.target.checked);
                                }
                              }}
                            />
                          </td> */}

                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
                {StateListsData && StateListsData.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </div>
              <PaginationOne
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={PageSize}
                onPageChange={page => [setCurrentPage(page), StateListData(page, "", "", pageLimit)]} />
              <div className="pt-2">

                {" "}
                Showing{" "}
                {currentPage * pageLimit > totalRecords
                  ? totalRecords
                  : currentPage * pageLimit}{" "}
                of {totalRecords} Results
              </div>


            </div>
          </div>
        </div>

        {/* status change */}
        <Modal
          size="md"
          show={openSwitchModel}
          onHide={() => handleCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to status change?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleCloseSwitch()}
            >
              No
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleCloseSwitch();

                handleChangeStatus(switchId);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>




        <AddState
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          handleAddCloseSwitch={handleAddCloseSwitch}
          openAddSwitchModel={openAddSwitchModel}
          stateDataList={stateDataList}
        // setOpenFilterModel={setOpenFilterModel}
        // handleCloseFilter={handleCloseFilter}
        />
        <AutohideExample show={show} setShow={setShow} />
      </main>
    </div>


  );
};

export default StateList;