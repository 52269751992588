import React, { useState } from "react";

export function useForm2(initialFValues, validateOnChange = false, validate) {
    const [values2, setValues2] = useState(initialFValues);
    const [errors2, setErrors2] = useState({});

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setValues2({
            ...values2,
            [name]: value,
        });
        if (validateOnChange) validate({ [name]: value });
    };

    const resetForm2 = () => {
        setValues2(initialFValues);
        setErrors2({});
    };

    return {
        values2,
        setValues2,
        errors2,
        setErrors2,
        handleInputChange2,
        resetForm2,
    };
}

export function Form(props) {
    const { children, ...other } = props;
    return (
        <form autoComplete="off" {...other}>
            {props.children}
        </form>
    );
}
