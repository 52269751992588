import axios from "axios";
import { store } from "../state/store";
axios.defaults.withCredentials = false;
const axiosInstance = axios.create({
  // baseURL: `${process.env.REACT_APP_API_URL}/api`,
  baseURL: `https://apijobportal.mobiginie.com/api`,
  // baseURL: `http://localhost:4010/api`,


  // baseURL: "https://www.techfresher.com/apis",
  crossDomain: false,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();

  const token = state?.auth?.token;

  config.params = config?.params || {};

  config.headers["Authorization"] = token;
  return config;
});

export default axiosInstance;
