import axios from "axios";
import axiosInstance from "../../utills/AxiosInstance";


export const sendotp = async (payload) => {
    try {
        const response = await axiosInstance.post('/fresher/forgotPasswordMailSend', payload)
        return response.data;
    } catch (error) {
        return error;
    }
}


export const verifyotp = async (payload) => {
    try {
        const response = await axiosInstance.put('/employee/emailVerification', payload)
        return response.data;
    } catch (error) {
        return error;
    }
}


export const changepassword = async (payload) => {
    try {
        const response = await axiosInstance.put('/employee/employerForgotPassword', payload)
        return response.data;
    } catch (error) {
        return error;
    }
}



export const resendOtp = async (payload, id) => {
    try {
        const response = await axiosInstance.post(`/employee/employeResendOtp/${id}`, payload)
        return response.data;
    } catch (error) {
        return error;
    }
}

export const resendOtp1 = async (payload, email) => {
    try {
        const response = await axiosInstance.post(`/employee/employeResendForgotOtp/${email}`, payload)
        return response.data;
    } catch (error) {
        return error;
    }
}

export const registerResendOtp = async (email) => {
    try {
        const response = await axiosInstance.post(`/employee/employeRegisterResendOtp/${email}`)
        return response.data;
    } catch (error) {
        return error;
    }
}
