export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const ADMIN_LOADED = "ADMIN_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const VERIFICATION_FAIL = "VERIFICATION_FAIL";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const LOGOUT = "LOGOUT";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
//export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAIL = "VERUFY_FAIL";
export const PERSONALREGISTER_SUCCESS = "REGISTER_SUCCESS";
export const PERSONALREGISTER_FAIL = "REGISTER_FAIL";
