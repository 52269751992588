import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";
import {
  getAssesmentList,
  StatusUpdate,
  deleteAssesment,
  getAssesmentDetails

} from "../../state/action/addAssesment.js";
import Form from "react-bootstrap/Form";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";
import { SkillList } from "../../state/action/skillAction";
import { IsWrite, IsDelete } from "../../utills/handlePermission";
import AddAssesment from "./addAssesment";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { async } from "videojs-record";
import { Redirect, Link, useLocation } from "react-router-dom";
import '../../components/pagination/style.scss';
import '../../components/pagination/pagination.scss';
import PaginationOne from '../../components/pagination/PaginationOne';
import { useMemo } from 'react';
import { ReactComponent as ViewIcon } from "../../assets/images/view.svg";
import { FaBackward } from "react-icons/fa";


const initalvalues = {
  skillTitle: "",
  question: "",
};

const statuslist = [
  { id: "1", title: "Active" },
  { id: "0", title: "Inactive" },
];



const Assesment = () => {
  const [flagName, setflagName] = useState(false);
  const [AssesmentListData, setAssesmentListData] = useState([]);
  const [assesmentDataList, setassesmentDataList] = useState([]);
  const [assessmentDetails, setassessmentDetails] = useState([]);
  const [skillIds, setskillIds] = useState("");
  const [view, setView] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const { state } = useLocation();

  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [skillNames, setSkillNames] = useState("");

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  let PageSize = 10;
  const AssessmentDataPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return AssesmentListData;
  }, [currentPage, AssesmentListData]);

  const [sortFlag, setSortFlag] = useState(false);
  const [openFilterModel, setOpenFilterModel] = useState(false);
  const [page, setPage] = useState(1);

  const [refresh, setRefresh] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);
  //const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [totalpages, setTotalPages] = useState(10);

  const deletePermissions = IsDelete("assesment");
  const editPermissions = IsWrite("assesment");

  const { enqueueSnackbar } = useSnackbar();
  const [addskill, setaddskill] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [id, setId] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [skillDataList, setskillDataList] = useState([]);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);

  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);

  const handleFilterModel = () => setOpenFilterModel(true);
  const handleCloseFilter = () => setOpenFilterModel(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initalvalues);

  //skilllist
  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      setSkillData(res.data);
    }
  };

  useEffect(() => {
    if (state && state.id) {
      setskillIds(state.id);
      setSkillNames(state.skillaName);
    }
  }, [state]);

  //Assessment list
  useEffect(() => {
    if (skillIds != "") {
      AssesmentList(currentPage, "", "");
    }
    getSkillList();
  }, [page, refresh, filterData, search, skillIds]);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const AssesmentList = async (page, name = "", flag, perPageSize, limit) => {
    setView(true);
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = perPageSize ? perPageSize : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&sortby=${name}&sortFlag=${flag}&skillId=${skillIds}`;



    const res = await getAssesmentList(queryString);
    setView(true);
    setOpenBreakdrop(true);
    setTotalRecords(res.totalQue);

    if (res.status === true) {
      setAssesmentListData(res.data);
      setTotalPages(Math.ceil(res.totalQue / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPageLimit(recordLimit);
      setView(false);
    } else {
      setAssesmentListData([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      setView(false);

    }
    setFilter(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPage(page);
    AssesmentList(page, "", "", pageLimit); // Call the API with the new page number and page limit
  };





  const handlePageLimitChange = (e) => {
    const limit = parseInt(e.target.value);
    setPageLimit(limit || 10);
    setCurrentPage(1); // Reset the current page to 1 when page limit is changed
    AssesmentList(1, "", "", limit); // Call the API with the new page limit and reset the page number to 1
  };

  const handleReset = (e) => {
    resetForm();
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
    // resetForm();
  };

  const handleChangePage = (e, value) => {
    if (fieldName == "name") {
      AssesmentList(value, fieldName, flagName);
    } else {
      AssesmentList(value, "", "");
    }
    setPage(value);
  };
  //status change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "name") {
        AssesmentList(fieldName, flagName);
      } else {
        AssesmentList(currentPage, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    closeDrawer();
  };

  const handleDelete = async () => {
    const res = await deleteAssesment(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        AssesmentList(fieldName, flagName);
      } else {
        AssesmentList(page, "", "");
      }
      handleCloseDelete();
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setView(false);
    } else {
      setOpenBreakdrop(!openBackdrop);

      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
      setView(false);
    }
  };

  return (
    <div>
      <Loader view={view} />
      <main className="body-background">
        <div className="container-fluid">
          <div className="d-flex justify-content-between mb-3">
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <div className="d-flex align-items-center gap-2">
                <span>
                  <FaBackward />
                </span>
                <span>Back</span>
              </div>
            </button>
          </div>
          <div className="row">
            <div className="col-12 my-4">
              <div className="rounded-card-head">
                <div className="row align-items-center mb-3">
                  <div className="col-md-3">
                    <div className="font-PTSerif-b ps-2 font-18-24-05">
                      {/* Assesment List */}
                      {skillNames}
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="col col-md-auto text-end d-flex justify-content-end flex-wrap">
                      <Controls.Input
                        className="border-0 rounded p-2 mt-2"
                        placeholder="search"
                        name="text"
                        type="text"
                        onChange={(e) => {
                          setTimeout(() => {
                            setSearch(e.target.value);
                          }, 800);
                        }}
                      />
                      <div className="mt-2">
                        {editPermissions && (
                          <Controls.Button
                            style={{ padding: "7px 30px", marginLeft: "16px" }}
                            className="btn btn-primary h-40"
                            text="Add Assessment"
                            onClick={() => {
                              if (skillIds != "") {
                                navigate("/admin/addAssesment", {
                                  state: {
                                    id: skillIds,
                                    skillName: skillNames,
                                    isEdit: false,
                                  }
                                });
                              } else {
                                navigate("/admin/skills");
                              }
                            }}
                          />
                        )}
                        <Controls.Button
                          style={{ padding: "7px 30px", marginLeft: "16px" }}
                          className="btn btn-success h-40"
                          text="filter"
                          onClick={() => {
                            toggleDrawer();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showDrawer && (
                <>
                  <div className="filter-drawer-overlay" onClick={toggleDrawer}>
                  </div>
                  <div className="filter-drawer-content">
                    <div className="d-flex justify-content-between align-items-center filter-header_div ">
                      <label className="filter-header">Filter</label>
                      <button
                        className="filter-close-button"
                        onClick={toggleDrawer}
                      >
                        x
                      </button>
                    </div>
                    <div className="d-flex flex-column h-100">
                      <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">
                        <div>
                          <div>
                            <label className="filter-label">question</label>
                            <Controls.Input
                              className="input_filter"
                              name="question"
                              type="text"
                              value={values?.question}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div>
                            <label className="filter-label">status</label>
                            <select
                              className="form-select h-auto px-3 py-2 "
                              name="isActive"
                              onChange={handleInputChange}
                              value={values.isActive}
                            //options={statuslist}
                            >
                              <option value="">None</option>
                              {statuslist?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="position-relative bottom-0 mb-5 filter-reset-div">
                          <div className="d-flex justify-content-between">
                            <button
                              className="filter-reset-btn"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                            <button
                              className="filter-btn"
                              type="submit"
                            // onClick={closeDrawer}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                </>
              )}

              <div className="theme-table-wrap">
                <Table responsive className="striped-table mb-0">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Skill</th>
                      {/* <th>Question Type</th> */}
                      <th>Question</th>
                      <th>Report A Problem</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Status</th>
                      {editPermissions || deletePermissions ? (
                        <th>Action</th>
                      ) : null}
                    </tr>
                  </thead>
                  {AssessmentDataPage?.map((row, index) => {
                    return (
                      <tbody>
                        <tr
                          className="border rounded"
                          style={{ backgroundColor: "white" }}
                        >
                          <td>{index + 1 + (currentPage - 1) * pageLimit}</td>
                          < td > {row.skillTitle || "-"}</td>
                          <td>{row.question}</td>
                          <td>{row.reportCount}</td>
                          <td>{row.typeOfQuestion == 1 ? "Basic" : row.typeOfQuestion == 2 ? "Medium" : "Hard"}</td>
                          <td>
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </td>

                          <td>
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              checked={row.isActive == 1 ? true : false}
                              onChange={(e) => {
                                if (!editPermissions) {
                                  setShow({
                                    isOpen: true,
                                    message: "You don't have permission to edit",
                                    type: "error",
                                  });
                                  return;
                                } else {
                                  handleSwitchModel();
                                  setStatus(
                                    e.target.checked == true
                                      ? "Active"
                                      : "InActive"
                                  );
                                  setSwitchId(row.id);
                                  setChecked(e.target.checked);
                                }
                              }}
                            />
                          </td>
                          {editPermissions || deletePermissions ? (

                            <td>
                              {editPermissions || deletePermissions ? (
                                <ul className="d-flex action-col-main">
                                  <li className="me-3">
                                    {editPermissions && (
                                      <button
                                        className="btn btn-sm btn-icon btn-warning me-4"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        data-original-title="Edit"
                                        onClick={async (e) => {
                                          //handleAddSwitchModel();
                                          e.preventDefault();

                                          const data = await getAssesmentDetails(row?.id);
                                          if (data) {
                                            navigate(`/admin/addAssesment`, {
                                              state: {
                                                id: skillIds,
                                                skillName: skillNames,
                                                isEdit: true,
                                                data: data && data.data,
                                              }
                                            }

                                            );
                                          }

                                          setIsEdit(true);
                                        }}
                                      >
                                        <span className="btn-inner">
                                          <EditIcon />
                                        </span>
                                      </button>
                                    )}
                                    {deletePermissions && (
                                      <button
                                        className="btn btn-sm btn-icon btn-danger btn-inner me-4"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        data-original-title="Delete"
                                        onClick={() => {
                                          handleDeleteSwitchModel();
                                          setId(row.id);
                                          setDeleteId(row?.id);
                                          handleDeleteModel();
                                        }}
                                      >
                                        <span className="btn-inner">
                                          <DeleteIcon />
                                        </span>
                                      </button>
                                    )}
                                    {editPermissions && (
                                      <button
                                        className="btn btn-sm btn-icon btn-success btn-inner"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        data-original-title="View"
                                        onClick={() => {
                                          navigate(`/admin/assessment-details`, {
                                            state: {
                                              id: row.id,
                                            },
                                          });
                                        }}
                                        style={{ padding: "2px 4px 2px 4px" }}
                                      >
                                        <span className="btn-inner">
                                          <ViewIcon />
                                        </span>
                                      </button>
                                    )}
                                  </li>
                                </ul>
                              ) : null}
                            </td>


                          ) : null}

                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
                {AssessmentDataPage && AssessmentDataPage.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </div>
              <PaginationOne
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={PageSize}
                onPageChange={page => [setCurrentPage(page), AssesmentList(page, "", "", pageLimit)]} />
              <div className="pt-2">
                {" "}
                Showing{" "}
                {currentPage * pageLimit > totalRecords
                  ? totalRecords
                  : currentPage * pageLimit}{" "}
                of {totalRecords} Results
              </div>


            </div>
          </div>
        </div>

        {/* status change */}
        <Modal
          size="md"
          show={openSwitchModel}
          onHide={() => handleCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to status change?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleCloseSwitch()}
            >
              No
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleCloseSwitch();

                handleChangeStatus(switchId);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete */}
        <Modal
          size="md"
          show={openDeleteSwitchModel}
          onHide={() => handleDeleteCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to delete?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleDeleteCloseSwitch()}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleDeleteCloseSwitch();
                handleDelete(deleteId);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AddAssesment
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          handleAddCloseSwitch={handleAddCloseSwitch}
          openAddSwitchModel={openAddSwitchModel}
          setOpenFilterModel={setOpenFilterModel}
          handleCloseFilter={handleCloseFilter}
          assesmentDataList={assesmentDataList}
          refreshScreen={refreshScreen}
          AssesmentListData={AssesmentListData}
          AssesmentList={AssesmentList}
          getAssesmentDetails={getAssesmentDetails}
          page={page}
        />
        <AutohideExample show={show} setShow={setShow} />
      </main>
    </div>
  );
};

export default Assesment;

