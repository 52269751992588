import axios from "axios";
import axiosInstance from "../../utills/AxiosInstance";
import {
    UPDATETESTRESULT,
    STARTUPDATING,
    CLEARTESTARRAY,
} from "../action-types/assessment";
import { store } from "../store";


export const getQuestionList = async (payload) => {
    try {
        const response = await axiosInstance.post("/fresher/getSoftSkillQuestion", payload);

        return response.data;
    } catch (error) {
        return error;
    }
}

export const AddTest = (payload) => async (dispatch) => {
    const { test } = store.getState();
    let reduxData = test?.test;
    let newindex = [...reduxData];


    let index = newindex && newindex?.findIndex((item) => item.questionId == payload.questionId);
    if (index > -1) {
        newindex[index] = payload;

    } else {
        newindex = [...newindex, payload];
    }

    dispatch({
        type: STARTUPDATING,
    });
    dispatch({
        type: UPDATETESTRESULT,
        payload: newindex,
    });
};

export const testSubmit = async (payload) => {
    try {
        // payload.testId = id;

        const response = await axiosInstance.post(`/fresher/submitTest`, payload);
        return response.data;
    } catch (e) {

        return e.response.data;
    }
};

export const clearTestArray = () => async (dispatch) => {
    dispatch({
        type: CLEARTESTARRAY,
    });
};


export const getQuestionDetails = async (id) => {
    try {
        const response = await axiosInstance.get(`/v1/questionById/${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
}