
const initialState = {
  employerData: [],
  loading: true,
  isAuthenticated: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  // switch (type) {
  //   case EMPTY_ALL_DATE:
  //     return initialState;


  //   return {
  //     ...state,
  //     isAuthenticated: true,
  //     loading: false,
  //     user: payload,
  //     employerData: payload,
  //   };
  //   case ADD_EMPLOYER_DATA:
  //     return {
  //       // ...state,
  //       isAuthenticated: true,
  //       loading: false,
  //       admin: payload,
  //       user: payload,
  //       employerData: payload,
  //     };
  //   case EMPLOYER_FAIL:
  //     return {
  //       ...state,
  //       loading: false,
  //       isAuthenticated: false,
  //       employerData: null,
  //     };
  //   default:
  //     return state;
  // }
}

