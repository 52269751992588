import React, { useCallback, useEffect, useRef, useState } from "react";
import Controls from "../../components/controls/Controls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  FresherJobUpdate,
  FresherPersonalUpdater,
  UpdateResume,
  getBasicInfoLists,
  getCityLists,
  getCountryLists,
  getJobPreferLists,
  getJobTypesLists,
  FresherEducationUpdate,
  getStateLists,
} from "../../state/action/fresherAction";
import { useSelector } from "react-redux";
import moment from "moment";
import { Form, useForm } from "../../utills/useForms";
import { SkillList } from "../../state/action/skillAction";
import { getEducationLists, countryCodeDropdown } from "../../state/action/fresherAction";
import AutohideExample from "../../utills/Notification";
import Webcam from "react-webcam";
import Multiselect from "multiselect-react-dropdown";
import ProfileImage from "../../assets/images/user_icon.png";
import { FiUser } from "react-icons/fi";
import { Helmet } from 'react-helmet'

const initialValues = {
  firstName: "",
  lastName: "",
  mobile: "",
  countryId: "",
  stateId: "",
  cityId: "",
  email: "",
  engLevel: "",
  eduStartDate: null,
  eduEndDate: null,
  jobPraferId: "",
  jobTypeId: "",
  prefferLocations: "",
  country1: "",
  country2: "",
  country3: "",
  state1: "",
  state2: "",
  state3: "",
  city1: "",
  city2: "",
  city3: "",
  fresherSkill: "",
  fileLocation: "",
  studyRunning: false,
  countryCode: 91
};

const Profile = () => {
  const [PersonalInformation, setPersonalInformation] = useState(false);
  const [UploadResume, setUploadResume] = useState(false);
  const [EducationalInformation, setEducationalInformation] = useState(false);
  const [UploadImage, setUploadImage] = useState(false);
  const [JobPreference, setJobPreference] = useState(false);
  const [resumeFiles, setResumeFiles] = useState(null);
  const [jobLocationList, setJobLocationList] = useState([]);
  const [countryList1, setCountryList1] = useState([]);
  const [cityList1, setCityList1] = useState([]);
  const [stateList1, setStateList1] = useState([]);
  const [countryList0, setCountryList0] = useState([]);
  const [cityList0, setCityList0] = useState([]);
  const [stateList0, setStateList0] = useState([]);
  const [countryList2, setCountryList2] = useState([]);
  const [cityList2, setCityList2] = useState([]);
  const [stateList2, setStateList2] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [jobPreferList, setJobPreferList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [selectSkill, setSelectSkill] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [profileList, setProfileList] = useState();
  const [nameChange, setNameChange] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [deleteSkill, setDeleteSkill] = useState([]);
  const [file, setFile] = useState([]);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [acctiveTab, setAcctiveTab] = useState(1);
  const [img, setImg] = useState(null);
  const [saveimg, setSaveImg] = useState(null);
  const Id = useSelector((state) => state?.auth?.user?.id);

  const url = resumeFiles;

  const getLocationValidation = () => {
    //if select country 1 then state 1 and city 1 is required
    if (
      values.country1 == "" &&
      values.country2 == "" &&
      values.country3 == ""
    ) {
      setShow({
        isOpen: true,
        message: "Please select atleast one location",
        type: "error",
      });
      return false;
    }

    if (values.country1 != "") {
      if (values.state1 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      } else if (values.city1 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }

    if (values.country2 != "") {
      if (values.state2 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      }
      if (values.city2 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }

    if (values.country3 != "") {
      if (values.state3 == "") {
        setShow({
          isOpen: true,
          message: "Please select state",
          type: "error",
        });
        return false;
      }
      if (values.city3 == "") {
        setShow({
          isOpen: true,
          message: "Please select city",
          type: "error",
        });
        return false;
      }
    }
    return true;
  };

  const jobPreferValidation = () => {
    if (values.jobPraferId == "" || values.jobTypeId == "") {
      setShow({
        isOpen: true,
        message: "Please select job prefer",
        type: "error",
      });
      return false;
    }else{
      return true;
    }
  }

  const getCountryCodeData = async () => {
    const res = await countryCodeDropdown();
    if (res && res.data) {
      setCountryCodeList(res.data);

    } else {
      setCountryCodeList([]);
    }
  };

  useEffect(() => {
    getCountryCodeData();

  }, []);

  //camera
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);

  const handleSubmitSaveImage = (e) => {
    e.preventDefault();
    setSaveImg(img);

    setUploadImage(false);
    setNameChange(true);
  };

  //country Data
  const handleCountryLocation = (value) => {
    setValues({
      ...values,
      countryId: value,
      stateId: "",
      cityId: "",
    });
    handleStateList(value);
  };

  const handleStateLocation = (value) => {
    setValues({
      ...values,
      stateId: value,
      cityId: "",
    });
    handleCityList(value);
  };

  const getContryData = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList(res.data);
      // setCityList([]);
      // setStateList([]);
    } else {
      setCountryList([]);
      // setCityList([]);
      // setStateList([]);
    }
  };
  //city Data

  const handleCityList = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList(res.data);
    } else {
      setCityList([]);
    }
  };

  //state Data
  const handleStateList = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };


  const handleEditPersonalInfo = () => {
    setValues({
      ...values,
      countryId: values?.countryId,
      stateId: values?.stateId,
      cityId: values?.cityId,
    });
    setPersonalInformation(true);
  };

  const handleStudyRunningChange = () => {
    setValues({
      ...values,
      studyRunning: !values?.studyRunning,
    });
  };

  const handleImageItem = async (e) => {
    const selected = e.target.files[0];
    if (selected && selected.size > 2000000) {
      setShow({
        isOpen: true,
        message: "File size is too large (Max 2MB)",
        type: "error",
      });
      return;
      //set state empty
    }
    else {
    const ALLOWED_TYPES = ["application/pdf"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setValues({ ...values, fileLocation: selected });
      setResumeFiles(selected?.name)
    } else {
    }
  }
  };

  const resetImage = () => {
    setResumeFiles("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };
  async function deleteImage1(index) {
    setResumeFiles("");
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["fileLocation"];
      return newValues;
    });
  }

  //skilllist

  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      const skills = res.data.map((item) => {
        return { skillId: item.id, skillName: item.title };
      });
      setSkillData(skills.filter((item) => { return !selectSkill.some((item2) => { return item.skillId === item2.skillId }) }));

    }
  };

  //remove the skill
  const removeSkill = async (e, item) => {
    setSkillData([...skillData, item]);
    const removeSkill = selectSkill.filter(
      (items) => items.skillId != item.skillId
    );
    setSelectSkill(removeSkill);
    if (item && item.id) {
      setDeleteSkill([...deleteSkill, item?.id]);
    }
  };

  //country Data
  const handleCountryLocation0 = (e) => {
    setValues({
      ...values,
      country1: e.target.value,
      state1: "",
      city1: "",
    });
    handleStateList0(e.target.value);
    setCityList0([]);
  };

  const handleStateLocation0 = (e) => {
    setValues({
      ...values,
      state1: e.target.value,
      city1: "",
    });
    handleCityList0(e.target.value);
  };

  const getContryData0 = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList0(res.data);
      setCityList0([]);
      setStateList0([]);
    } else {
      setCountryList0([]);
      setCityList0([]);
      setStateList0([]);
    }
  };
  //city Data

  const handleCityList0 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList0(res.data);
    } else {
      setCityList0([]);
    }
  };

  //state Data
  const handleStateList0 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList0(res.data);
    } else {
      setStateList0([]);
    }
  };

  //country 1Data
  const handleCountryLocation1 = (e) => {
    setValues({
      ...values,
      country2: e.target.value,
      state2: "",
      city2: "",
    });
    handleStateList1(e.target.value);
    setCityList1([]);
  };

  const handleStateLocation1 = (e) => {
    setValues({
      ...values,
      state2: e.target.value,
      city2: "",
    });
    handleCityList1(e.target.value);
  };

  const getContryData1 = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList1(res.data);
      setCityList1([]);
      setStateList1([]);
    } else {
      setCountryList1([]);
      setCityList1([]);
      setStateList1([]);
    }
  };
  //city1 Data

  const handleCityList1 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList1(res.data);
    } else {
      setCityList1([]);
    }
  };

  //state1 Data
  const handleStateList1 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList1(res.data);
    } else {
      setStateList1([]);
    }
  };

  //country 1Data
  const handleCountryLocation2 = (e) => {
    setValues({
      ...values,
      country3: e.target.value,
      state3: "",
      city3: "",
    });
    handleStateList2(e.target.value);
    setCityList2([]);
  };

  const handleStateLocation2 = (e) => {
    setValues({
      ...values,
      state3: e.target.value,
      city3: "",
    });
    handleCityList2(e.target.value);
  };

  const getContryData2 = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList2(res.data);
      setCityList2([]);
      setStateList2([]);
    } else {
      setCountryList2([]);
      setCityList2([]);
      setStateList2([]);
    }
  };
  //city1 Data

  const handleCityList2 = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList2(res.data);
    } else {
      setCityList2([]);
    }
  };

  //state1 Data
  const handleStateList2 = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList2(res.data);
    } else {
      setStateList2([]);
    }
  };

  const getPrefferLocation = (e, i) => {
    setJobLocationList((prevState) => {
      if (!Array.isArray(prevState)) {
        // Handle the case where prevState is not an array
        return [];
      }
      const newState = [...prevState];
      if (i >= newState.length) {
        // Handle the case where i is out of bounds
        newState.push({
          [e.target.name]: e.target.value,
        });
      } else {
        newState[i] = {
          ...newState[i],
          [e.target.name]: e.target.value,
        };
      }
      return newState;
    });
  };

  useEffect(() => {
    getContryData0();
    getContryData1();
    getContryData2();
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid =
          regex.test(fieldValues.email) && fieldValues.email.endsWith("");
        if (!isEmailValid) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("mobile" in fieldValues) {
      if (fieldValues.mobile !== "" && fieldValues.mobile !== undefined) {
        const regex = /^\d{10}$/;
        if (!regex.test(fieldValues.mobile)) {
          temp.mobile = "Invalid Phone Number";
        } else {
          temp.mobile = "";
        }
      } else {
        temp.mobile = "This field is required.";
      }
    }



    if ("firstName" in fieldValues) {
      temp.firstName = fieldValues.firstName ? "" : "This field is required.";
    }

    if ("lastName" in fieldValues) {
      temp.lastName = fieldValues.lastName ? "" : "This field is required.";
    }

    if ("stateId" in fieldValues) {
      temp.stateId = fieldValues.stateId ? "" : "This field is required.";
    }

    if ("cityId" in fieldValues) {
      temp.cityId = fieldValues.cityId ? "" : "This field is required.";
    }

    if ("countryId" in fieldValues) {
      temp.countryId = fieldValues.countryId ? "" : "This field is required.";
    }


    // if ("jobPraferId" in fieldValues) {
    //   temp.jobPraferId = fieldValues.jobPraferId
    //     ? ""
    //     : "This field is required.";
    // }

    // if ("jobPraferId" in fieldValues) {
    //   temp.jobPraferId = fieldValues.jobPraferId
    //     ? ""
    //     : "This field is required.";
    // }

    // if ("jobTypeId" in fieldValues) {
    //   temp.jobTypeId = fieldValues.jobTypeId ? "" : "This field is required.";
    // }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleJobPrefernce = () => {
    setJobPreference(true);
    getSkillList();
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  //getlist
  const getProfileList = async () => {
    const res = await getBasicInfoLists(Id);

    if (res && res.status === true) {
      getContryData();
      handleCityList(res?.data?.stateId?.id);
      handleStateList(res?.data?.countryId?.id);
      setProfileList(res?.data);
      setJobLocationList([
        {
          id: res?.data?.jobLocation[0]?.id,
          fresherId: Id,
          country1: res?.data?.jobLocation[0]?.countryId,
          state1: res?.data?.jobLocation[0]?.stateId,
          city1: res?.data?.jobLocation[0]?.cityId,
          cityName: res?.data?.jobLocation[0]?.cityName,
          stateName: res?.data?.jobLocation[0]?.stateName,
          countryName: res?.data?.jobLocation[0]?.countryName,
        },
        {
          id: res?.data?.jobLocation[1]?.id,
          fresherId: Id,
          country2: res?.data?.jobLocation[1]?.countryId,
          state2: res?.data?.jobLocation[1]?.stateId,
          city2: res?.data?.jobLocation[1]?.cityId,
          cityName: res?.data?.jobLocation[1]?.cityName,
          stateName: res?.data?.jobLocation[1]?.stateName,
          countryName: res?.data?.jobLocation[1]?.countryName,
        },
        {
          id: res?.data?.jobLocation[2]?.id,
          fresherId: Id,
          country3: res?.data?.jobLocation[2]?.countryId,
          state3: res?.data?.jobLocation[2]?.stateId,
          city3: res?.data?.jobLocation[2]?.cityId,
          cityName: res?.data?.jobLocation[2]?.cityName,
          stateName: res?.data?.jobLocation[2]?.stateName,
          countryName: res?.data?.jobLocation[2]?.countryName,
        },
      ]);
      setSkillList(res?.data?.fresherSkill);
      const formattedEndDate = new Date(
        res.data?.eduEndDate === "null" ? "null" : res?.data?.eduEndDate
      ).toLocaleDateString("en-CA");

      const formattedStartDate = new Date(
        res?.data?.eduStartDate
      ).toLocaleDateString("en-CA");

      setValues({
        ...values,
        firstName: res?.data?.firstName,
        profilePhoto: res?.data?.profilePhoto,
        lastName: res?.data?.lastName,
        email: res?.data?.email,
        cityId: res?.data?.cityId?.id,
        stateId: res?.data?.stateId?.id,
        countryId: res?.data?.countryId?.id,
        mobile: res?.data?.mobile,
        educationLevelId: res?.data.educationLevelId?.id,
        studyField: res?.data?.studyField,
        college: res?.data?.college,
        engLevel: res?.data?.engLevel,
        eduEndDate: formattedEndDate,
        eduStartDate: formattedStartDate,
        jobPraferId: res?.data?.jobPraferId?.id,
        jobTypeId: res?.data?.jobTypeId?.id,
        country1: res?.data?.jobLocation[0]?.countryId,
        state1: res?.data?.jobLocation[0]?.stateId,
        city1: res?.data?.jobLocation[0]?.cityId,
        country2: res?.data?.jobLocation[1]?.countryId,
        state2: res?.data?.jobLocation[1]?.stateId,
        city2: res?.data?.jobLocation[1]?.cityId,
        country3: res?.data?.jobLocation[2]?.countryId,
        state3: res?.data?.jobLocation[2]?.stateId,
        city3: res?.data?.jobLocation[2]?.cityId,
        fresherSkill: res?.data?.fresherSkill,
        studyRunning: res?.data?.studyRunning,
        fileLocation: res?.data?.fileLocation,
        countryCode: res?.data?.countryCode
      });
      // setStudyRunning(res?.data?.studyRunning);
      setAcctiveTab(res?.data?.engLevel);
      setUpFile(res?.data?.profilePhoto);

      handleStateList0(res?.data?.jobLocation[0]?.countryId);
      handleCityList0(res?.data?.jobLocation[0]?.stateId);
      handleStateList1(res?.data?.jobLocation[1]?.countryId);
      handleCityList1(res?.data?.jobLocation[1]?.stateId);
      handleStateList2(res?.data?.jobLocation[2]?.countryId);
      handleCityList2(res?.data?.jobLocation[2]?.stateId);
      setSelectSkill(res?.data?.fresherSkill);
      setResumeFiles(res.data?.fresherResume?.fileLocation?.split("/")?.pop());
    } else {
    }
  };

  useEffect(() => {
    getProfileList(Id);
  }, [Id]);


  const setTab = (tab) => {
    setAcctiveTab(tab);
  };

  //personal information
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {

      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        cityId: values?.cityId,
        stateId: values?.stateId,
        countryId: values?.countryId,
        mobile: values?.mobile,
        countryCode: values?.countryCode,
      };

      if (saveimg) {
        payload.profilePhoto = saveimg;
      }




      const res = await FresherPersonalUpdater(profileList?.id, payload);

      if (res && res.status === true) {
        setPersonalInformation(false);
        getProfileList(Id);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
        if (nameChange || saveimg) {
          localStorage.setItem("name", values?.firstName);
          window.location.reload();
        }
        setSaveImg("");
        setImg(null);
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }

    }
  };

  //englevel status

  const getStatus = (status) => {
    const mapStatus = {
      1: "Basic",
      2: "Average",
      3: "Great",
    };
    return mapStatus[status];
  };

  const multiselectSelectSkill = (data, e) => {
    setSelectSkill([...selectSkill, {
      skillId: e.skillId,
      skillName: e.skillName,
      fresherId: Id,
    }]);

    (skillData.filter((item) => { return !data.some((item2) => { return item.skillId === item2.skillId }) }));
  };


  const validatess = (fieldValues = values) => {

    let temp = { ...errors };

    if ("eduStartDate" in fieldValues) {

      if (fieldValues.eduStartDate !== "" && fieldValues.eduStartDate !== undefined || fieldValues.eduStartDate != null) {

        if ((fieldValues.eduEndDate == " " || fieldValues.eduEndDate == undefined) && (fieldValues.studyRunning == false || fieldValues.studyRunning == undefined || fieldValues.studyRunning == null)) {
          temp.eduEndDate = "This field is required.";
        }
        else {
          temp.eduEndDate = "";
        }
      }
      else {
        temp.eduEndDate = "";
      }

    }


    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");



  }

  const levelOfEducationValidation = () => {
    if (values?.educationLevelId == "" || values?.educationLevelId == undefined) {
      setShow({
        isOpen: true,
        message: "Please select level of education",
        type: "error",
      });
      return false;
    }
    else {
      return true;
    }
  }
  //education
  const handleSubmitEducation = async (e) => {
    e.preventDefault();
    if (validatess() && levelOfEducationValidation()) {
      const payload = {
        educationLevelId: values?.educationLevelId,
        studyField: values?.studyField,
        college: values?.college,
        engLevel: acctiveTab,
        eduEndDate: values?.eduEndDate,
        eduStartDate: values?.eduStartDate,
        studyRunning: values?.studyRunning == true ? 1 : 0,
      };

      const res = await FresherEducationUpdate(profileList?.id, payload);

      if (res && res.status === true) {
        setEducationalInformation(false);
        getProfileList(Id);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  //JobType Data
  const getJobTypeData = async (e) => {
    const res = await getJobTypesLists();
    if (res && res.data) {
      setJobList(res.data);
    }
  };

  useEffect(() => {
    getJobTypeData();
  }, []);

  //JobType Prefer Data
  const getJobPreferData = async (e) => {
    const res = await getJobPreferLists();
    if (res && res.data) {
      setJobPreferList(res.data);
    }
  };

  useEffect(() => {
    getJobPreferData();
  }, []);

  //education Data
  const getEducationData = async (e) => {
    const res = await getEducationLists();
    if (res && res.data) {
      setEducationList(res.data);
    }
  };

  useEffect(() => {
    getEducationData();
  }, []);
  let properties = {};

  //job
  const handleSubmitJob = async (e) => {
    e.preventDefault();

    if (getLocationValidation()&&jobPreferValidation()) {
      const payload = {
        location: jobLocationList,
        jobPraferId: values.jobPraferId,
        jobTypeId: values?.jobTypeId,
        skillId: selectSkill,
        deleteSkill: deleteSkill,
      };
      const res = await FresherJobUpdate(payload);

      if (res && res.status === true) {
        setJobPreference(false);
        getProfileList(Id);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
        setDeleteSkill([]);
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };
  //update the resume

  const handleSubmitResume = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    if (values?.fileLocation || resumeFiles) {
      formData.append("fileLocation", values?.fileLocation);
      const res = await UpdateResume(
        profileList?.fresherResume?.id,
        Id,
        formData
      );
      if (res && res.status === true) {
        setUploadResume(false);
        getProfileList(Id);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
    else {
      setShow({
        isOpen: true,
        message: "Please upload your resume",
        type: "error",
      });
    };
  }

  const handleDownload = () => {
    const fileURL = profileList?.fresherResume?.fileLocation;
  
    if (fileURL) {
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "savan.pdf"; // Set the download attribute to "savan.pdf"
      link.click();
    }
  };

  async function handleImageItems(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setSaveImg(base64String);
      setValues({ ...values, profileImage: selected });
    } else {
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  const formattedToday = `${day}/${month}/${year}`;

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [upfile, setUpFile] = useState();
  function handleChange(e) {
    if (e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "image/png" && e.target.files[0].type !== "image/jpg") {
      setShow({
        isOpen: true,
        message: "Only JPG, JPEG, PNG files are allowed",
        type: "error",
      });
      return;
    }
   else if (e.target.files[0].size > 2000000) {
      setShow({
        isOpen: true,
        message: "File size is too large (Max 2MB)",
        type: "error",
      });
      return;
    }
    else {
    setUpFile(URL.createObjectURL(e.target.files[0]));
    getBase64(e.target.files[0]).then((data) => {
      setSaveImg(data);
    }
    );
  }
  }

  return (
    <main className="body-background">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Job Portal Profile | Build Your Tech Career Profile</title>
        <meta name="description" content="Create your Job Portal profile to showcase your skills, qualifications, and experience to potential employers. Stand out in the competitive job market!" />
      </Helmet>
      <div className="bg-cyan">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard-welcome-text py-5">
                <h3 className="mb-2 font-PTSerif-b">Profile</h3>
                <p className="mb-0 font-16-24-05">
                  You can edit and update all your information from here
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="rounded-card-head pt-3 pt-lg-5 mb-2">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05">
                Personal information
              </div>
            </div>
            <div className="col-auto">
              <a
                href="javascript:void(0)"
                className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                onClick={() => handleEditPersonalInfo()}
              >
                <div className="card-head-action-icon pe-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>{" "}
                Edit
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-card mb-3 mb-md-4">
          <div className="row">
            <div className="col-12 my-2">

              <div className="row justify_between align-items-center">
                <div className="col-12 col-md-12 col-lg-2 my-3 my-lg-0 d-flex d-sm-inline justify-content-center">
                  <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0 ">
                    <div className="rounded-circle mx-auto" style={{ width: "7rem", height: "7rem" }}>
                      {profileList?.profilePhoto == null ? ( <div className="profile_upload"><FiUser /></div>) : (<img src={profileList?.profilePhoto} alt="profile" className="rounded-circle mx-auto" style={{ width: "7rem", height: "7rem", objectFit: "cover" }} />)}

                    </div>

                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-2 my-2 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">Name</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.firstName} {profileList?.lastName}
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-2 my-2 my-lg-0 profile-name">
                  <div class="fw-medium font-18-24-05 mb-1">Email</div>
                  <div class="font-16-24-05 mb-2">{profileList?.email?.slice(0, 26)}{profileList?.email?.length > 26 ? '...' : ''}</div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 my-2 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">Address</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.cityId?.name}, {profileList?.stateId?.name},{" "}
                    {profileList?.countryId?.name}
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-2 my-2 my-lg-0 profile-name">
                  <div class="fw-medium font-18-24-05 mb-1">Phone Number</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.countryCode ? `+${profileList?.countryCode} -` : ''} {profileList?.mobile}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-card-head pt-3 mb-2">
          <div className="row align-items-center">
            <div className="col d-flex align-items-center">
              <div className="font-PTSerif-b ps-2 font-18-24-05 ">Resume</div>
            </div>
            <div className="col-auto">
              <a
                href="javascript:void(0)"
                className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                onClick={() => setUploadResume(true)}
              >
                <div className="card-head-action-icon pe-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>{" "}
                Edit
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-card mb-3 mb-md-4">
          <div className="row">
            <div className="col-12 my-2">
              <div className="row justify-content-between resume-link">
                <div className="col my-1 my-md-0 d-flex align-items-center">
                  <div class="font-16-24-05">Resume</div>
                </div>

                <div className="col my-1 my-md-0">
                  {!resumeFiles ? '' :
                    <div class="font-16-24-05 opacity-75">
                      <a
                        href={profileList?.fresherResume?.fileLocation}
                        download 
                        className="d-flex btn btn-link justify-content-end"
                        // onClick={handleDownload}
                      >
                        <div className="card-head-action-icon pe-2">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_871_17631)">
                              <path
                                d="M8 17L12 21L16 17"
                                stroke="#74777E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 12V21"
                                stroke="#74777E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.8799 18.0901C21.7493 17.4787 22.4013 16.6062 22.7412 15.5992C23.0811 14.5922 23.0913 13.5031 22.7703 12.4899C22.4493 11.4767 21.8138 10.5921 20.956 9.96462C20.0982 9.33709 19.0628 8.99922 17.9999 9.00006H16.7399C16.4392 7.82793 15.8764 6.7393 15.0941 5.81614C14.3117 4.89297 13.3301 4.15932 12.2231 3.67041C11.1162 3.1815 9.91278 2.95008 8.70346 2.99357C7.49414 3.03706 6.31045 3.35433 5.24149 3.92148C4.17253 4.48864 3.24616 5.2909 2.53212 6.26788C1.81808 7.24487 1.33498 8.3711 1.11919 9.5618C0.903403 10.7525 0.960543 11.9767 1.28631 13.1421C1.61208 14.3075 2.19799 15.3838 2.99993 16.2901"
                                stroke="#74777E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_871_17631">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg> &nbsp;
                          Resume
                          {/* {profileList?.fresherResume?.fileLocation} */}
                        </div>{" "}
                      </a>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-card-head pt-3 mb-2">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05">
                Educational information
              </div>
            </div>
            <div className="col-auto">
              <a
                href="javascript:void(0)"
                className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                onClick={() => setEducationalInformation(true)}
              >
                <div className="card-head-action-icon pe-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>{" "}
                Edit
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-card mb-3 mb-md-4">
          <div className="row">
            <div className="col-12 my-2">
              <div className="row justify-content-between">
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">College</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.college || "-"}
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">Level of education</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.educationLevelId?.title || "-"}
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">Field of study</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.studyField || "-"}
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">English proficiency</div>
                  <div class="font-16-24-05 mb-2">
                    {getStatus(profileList?.engLevel)}
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">Start Date</div>
                  {profileList?.eduStartDate == null ? (
                    <div>-</div>
                  ) : (
                    <div className="font-16-24-05 mb-2">
                      {/* {profileList?.eduStartDate} */}
                      {moment(profileList?.eduStartDate || "-").format(
                        "MM-DD-YYYY"
                      )}
                    </div>
                  )}
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">End Date</div>
                  {profileList?.studyRunning == 0 || profileList?.studyRunning == null ? (

                    <div class="font-16-24-05 mb-2">
                      {profileList?.eduEndDate == null ? "-" : moment(profileList?.eduEndDate).format("MM-DD-YYYY")}
                      
                    </div>
                  ) : null}

                  {
                    profileList?.studyRunning == 1 && (

                      <div class="font-16-24-05 mb-2">
                        Still enrolled
                      </div>
                    )}

                  {
                    profileList?.studyRunning == null || profileList?.eduEndDate == null && (
                      <div>-</div>
                    )

                  }
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="rounded-card-head pt-3 mb-2">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05">
                Job preference
              </div>
            </div>
            <div className="col-auto">
              <a
                href="javascript:void(0)"
                className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                onClick={() => handleJobPrefernce()}
              >
                <div className="card-head-action-icon pe-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>{" "}
                Edit
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-card mb-3 mb-md-4">
          <div className="row">
            <div className="col-12 my-2">
              <div className="row justify-content-between">
                <div className="col-12 col-lg-4 col-xl-4 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">
                    Preferred job location
                  </div>
                  {jobLocationList?.map((row) => {
                    return (
                      <div class="font-16-24-05 mb-2">
                        {row?.cityName || "-"}
                        {row?.stateName && (
                          <>
                            {row.cityName && ", "}
                            {row.stateName}
                          </>
                        )}
                        {row?.countryName && (
                          <>
                            {(row.cityName || row.stateName) && ", "}
                            {row.countryName}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="col-12 col-lg-4 col-xl-4 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">
                    Type of jobs you prefer
                  </div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.jobTypeId?.title || "-"},{" "}
                    {profileList?.jobPraferId?.title || "-"}{" "}
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-xl-4 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05 mb-1">Skills</div>
                  <div className="round-suggestion-wrap">
                    <ul className="d-flex flex-wrap">
                      {skillList?.map((row) => {
                        return <li>{row?.skillName || row?.title}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      
      </div>
      <Modal
        size="lg"
        show={PersonalInformation}
        onHide={() => {
          getProfileList(Id);
          setPersonalInformation(false)
        }}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">
                  Personal information
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="font-18-24-05 text-center mb-2">
                  Profile picture
                </p>
                <div className="interviewer-profile_1 overflow-visible box-100 mx-auto">
                  {/* {saveimg ? (
                    <img
                      src={img}
                      style={{ maxWidth: "100%", height: "101px" }}
                    />
                  ) : (
                    <img
                      src={values.profilePhoto}
                      style={{ maxWidth: "100%", height: "101px" }}
                    />
                  )} */}
                  {!upfile ? (
                    <>
                      <div className="profile_upload"><FiUser /></div>
                    </>
                  ) : (
                    <>
                      <img src={upfile} width={100} height={100} style={{ borderRadius: '50%' }} />
                    </>
                  )}
                </div>
                <div className="text-center py-3">
                  {/* <Button
                    variant="outline-primary"
                    className="h-44 fw-medium"
                    onClick={() => setUploadImage(true)}
                  >
                    {saveimg || values.profilePhoto ? "Update profile picture" : "Upload profile picture"}
                  </Button> */}
                  <div className="position-relative">
                    <input type="file" onChange={handleChange} className="position-absolute" style={{ opacity: '0', width: '100%', height: '100%' }} accept="image/png, image/jpeg, image/jpg" />
                    <button className="btn btn-outline-primary btn-lg">Upload profile picture</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">
                  First name<span className="text-danger-light">*</span>
                </div>
                <Controls.Input
                  className="form-control"
                  placeholder="First name"
                  name="firstName"
                  type="text"
                  lable="First name"
                  value={values.firstName}
                  onChange={(e) => {
                    setNameChange(true);
                    handleInputChange(e);
                  }}
                />
                {Boolean(errors.firstName) ? (
                  <p className="text-invalid">{errors.firstName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">
                  Last name<span className="text-danger-light">*</span>
                </div>
                <Controls.Input
                  className="form-control"
                  placeholder="Last name"
                  name="lastName"
                  type="text"
                  lable="Last name"
                  value={values.lastName}
                  onChange={(e) => {
                    setNameChange(true);
                    handleInputChange(e);
                  }}
                />
                {Boolean(errors.lastName) ? (
                  <p className="text-invalid">{errors.lastName}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">
                  Email<span className="text-danger-light">*</span>
                </div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter your email"
                  name="email"
                  type="email"
                  lable="Enter your email"
                  value={values.email}
                  onChange={handleInputChange}
                  disabled={values.email}
                />
                {Boolean(errors.email) ? (
                  <p className="text-invalid">{errors.email}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Country of residence</div>

                <select
                  className="form-select"
                  name="countryId"
                  onChange={(e) => {
                    handleCountryLocation(e.target.value);
                  }}
                  value={values.countryId}
                >
                  <option value="">Select the country</option>
                  {countryList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {Boolean(errors.countryId) ? (
                  <p className="text-invalid">{errors.countryId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">State of residence</div>
                <select
                  className="form-select"
                  name="stateId"
                  onChange={(e) => {
                    handleStateLocation(e.target.value);
                  }}
                  value={values.stateId}
                >
                  <option value="">Select the State</option>
                  {stateList?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                  {Boolean(errors.stateId) ? (
                    <p className="text-invalid">{errors.stateId}</p>
                  ) : (
                    ""
                  )}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">City of residence</div>
                <select
                  className="form-select"
                  name="cityId"
                  onChange={handleInputChange}
                  value={values?.cityId}
                >
                  <option value="">Select the city</option>
                  {cityList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {Boolean(errors.cityId) ? (
                  <p className="text-invalid">{errors.cityId}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Phone number</div>
                <div className="position-relative phone-number-field">
                  <div className="phone-number-select">
                    <select className="form-select"
                      name="countryCode"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      id="countryCode"
                      value={values.countryCode}

                    >
                      <option value="">Select the Country Code</option>

                      {
                        countryCodeList && countryCodeList.map((item, index) => (
                          <option key={index} value={item.phonecode}>
                            {`+${item.phonecode}`}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                  <Controls.Input
                    className="form-control"
                    placeholder="Enter your phone number"
                    name="mobile"
                    type="text"
                    lable="Phone number"
                    value={values.mobile}
                    onChange={handleInputChange}
                    maxLength="10"
                  />
                </div>
                {Boolean(errors.mobile) ? (
                  <p className="text-invalid">{errors.mobile}</p>
                ) : (
                  ""
                )}
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => {
                setPersonalInformation(false);
                getProfileList(Id);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        size="lg"
        show={UploadResume}
        onHide={() => setUploadResume(false)}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmitResume}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">Resume</div>
                <div className="font-16-24-05">
                  Upload your resume here. Don't be concerned if your resume
                  isn't perfect, you can always update it.
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="final-detail-file-upload p-4">
                  <label htmlFor="image-upload">
                    <input
                      id="image-upload"
                      type="file"
                      onChange={handleImageItem}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                      accept="application/pdf"
                      // accept="image/*"
                      multiple={false}
                    />
                    {!resumeFiles && (
                      <svg
                        width="40"
                        height="40"
                        className="mb-3"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                          stroke="#143F87"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17 8L12 3L7 8"
                          stroke="#143F87"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 3V15"
                          stroke="#143F87"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                    <br />{" "}
                    {!resumeFiles && (
                      <span className="font-18-24-05 fw-medium">
                        Upload your resume
                      </span>
                    )}
                  </label>
                  {resumeFiles && (
                    <div
                      className="d flex py-4"
                      style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        fill="currentColor"
                        class="bi bi-file-pdf"
                        viewBox="0 0 16 16"
                        width="27px"
                      >
                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                        <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                      </svg>
                      <p className="mb-0">{resumeFiles}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => {
                getProfileList(Id);
                setUploadResume(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        size="lg"
        show={EducationalInformation}
        onHide={() => setEducationalInformation(false)}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmitEducation}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">
                  Educational information
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Level of education</div>
                <select
                  className="form-select"
                  name="educationLevelId"
                  onChange={handleInputChange}
                  value={values?.educationLevelId}
                >
                  <option value="">none</option>
                  {educationList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.educationLevelId) ? (
                  <p className="text-invalid">{errors.educationLevelId}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Enter your field of study</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Field of study"
                  name="studyField"
                  type="text"
                  lable="Field of study"
                  value={values.studyField}
                  onChange={handleInputChange}
                />
                {Boolean(errors.studyField) ? (
                  <p className="text-invalid">{errors.studyField}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">College</div>
                <Controls.Input
                  className="form-control"
                  name="college"
                  type="text"
                  lable="college"
                  value={values?.college}
                  onChange={handleInputChange}
                />
                {Boolean(errors.college) ? (
                  <p className="text-invalid">{errors.college}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">English proficiency</div>
                <div className="blue-tabs-wrap">
                  <ul className="d-flex font-18-24-05 text-center pb-0">
                    <li
                      onClick={(e) => {
                        setTab(1);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 1 ? "active" : ""}`}
                      value={values.engLevel}
                    >
                      Basic
                    </li>
                    <li
                      onClick={(e) => {
                        setTab(2);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 2 ? "active" : ""}`}
                      value={values.engLevel}
                    >
                      Average
                    </li>
                    <li
                      onClick={(e) => {
                        setTab(3);
                        handleInputChange(e);
                      }}
                      className={`w-100 ${acctiveTab === 3 ? "active" : ""}`}
                      value={values.engLevel}
                    >
                      Great
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">Start date</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Start date"
                  name="eduStartDate"
                  type="date"
                  lable="Start date"
                  value={values.eduStartDate}
                  onChange={handleInputChange}
                />
                {Boolean(errors.eduStartDate) ? (
                  <p className="text-invalid">{errors.eduStartDate}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-md-6 mb-16">
                <div className="form-label">End date</div>

                <Controls.Input
                  id="eduEndDate"
                  className="form-control"
                  placeholder="End date"
                  name="eduEndDate"
                  type="date"
                  lable="End date"
                  value={
                    values?.studyRunning ? null : values?.eduEndDate
                  }
                  onChange={handleInputChange}
                  disabled={values?.studyRunning}
                  min={values?.eduStartDate}
                />
                {Boolean(errors.eduEndDate) ? (
                  <p className="text-invalid">{errors.eduEndDate}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 pt-2">
                <div className="checkbox-round-md">
                  <label className="custom-checkbox">
                    {" "}
                    Still enrolled here?
                    <Controls.Input
                      type="checkbox"
                      name="studyRunning"
                      onChange={handleStudyRunningChange}
                      checked={values.studyRunning}

                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => setEducationalInformation(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        size="lg"
        show={JobPreference}
        onHide={() => {
          getProfileList(Id);
          setJobPreference(false)
        }}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmitJob}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">Job preference</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12"></div>
              <div className="col-12">
                <div className="row">
                  {width <= 767 ?
                    <>
                      <div className="col">
                        Preferred Location 1
                      </div>
                      <hr />
                    </>
                    : ''}
                  <div className="col-12 col-md-4 pe-md-0 mb-2">
                    <div className="form-label">Country</div>

                    <select
                      className="form-select"
                      name="country1"
                      onChange={(e) => {
                        getPrefferLocation(e, 0);
                        handleCountryLocation0(e);
                        // handleInputChange(e);
                      }}
                      value={values.country1}
                    >
                      <option value="">country</option>
                      {countryList0.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 mb-2">
                    <div className="form-label">State</div>

                    <select
                      className="form-select"
                      name="state1"
                      onChange={(e) => {
                        getPrefferLocation(e, 0);
                        handleStateLocation0(e);
                        // handleInputChange(e);
                      }}
                      value={values.state1}
                    >
                      <option value="">state</option>
                      {stateList0.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 ps-md-0 mb-2">
                    <div className="form-label">City</div>

                    <select
                      className="form-select"
                      name="city1"
                      onChange={(e) => {
                        getPrefferLocation(e, 0);
                        handleInputChange(e);
                      }}
                      value={values.city1}
                    >
                      <option value="">city</option>
                      {cityList0.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  {width <= 767 ?
                    <>
                      <div className="col pt-3">
                        Preferred Location 2
                      </div>
                      <hr />
                    </>
                    : ''}
                  <div className="col-12 col-md-4 pe-md-0 mb-2">
                    {width <= 767 ?
                      <div className="form-label">Country</div>
                      : ''}

                    <select
                      className="form-select"
                      name="country2"
                      onChange={(e) => {
                        getPrefferLocation(e, 1);
                        handleCountryLocation1(e);
                      }}
                      value={values.country2}
                    >
                      <option value="">country</option>
                      {countryList1.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 mb-2">
                    {width <= 767 ?
                      <div className="form-label">State</div>
                      : ''}
                    <select
                      className="form-select"
                      name="state2"
                      onChange={(e) => {
                        getPrefferLocation(e, 1);
                        handleStateLocation1(e);
                      }}
                      value={values.state2}
                    >
                      <option value="">state</option>
                      {stateList1.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 ps-md-0 mb-2">
                    {width <= 767 ?
                      <div className="form-label">City</div>
                      : ''}
                    <select
                      className="form-select"
                      name="city2"
                      onChange={(e) => {
                        getPrefferLocation(e, 1);
                        handleInputChange(e);
                      }}
                      value={values.city2}
                    >
                      <option value="">city</option>
                      {cityList1.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  {width <= 767 ?
                    <>
                      <div className="col pt-3">
                        Preferred Location 3
                      </div>
                      <hr />
                    </>
                    : ''}
                  <div className="col-12 col-md-4 pe-md-0 mb-2">
                    {width <= 767 ?
                      <div className="form-label">Country</div>
                      : ''}
                    <select
                      className="form-select"
                      name="country3"
                      onChange={(e) => {
                        getPrefferLocation(e, 2);
                        handleCountryLocation2(e);
                      }}
                      value={values.country3}
                    >
                      <option value="">Country</option>
                      {countryList2.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 mb-2">
                    {width <= 767 ?
                      <div className="form-label">State</div>
                      : ''}
                    <select
                      className="form-select"
                      name="state3"
                      onChange={(e) => {
                        getPrefferLocation(e, 2);
                        handleStateLocation2(e);
                      }}
                      value={values.state3}
                    >
                      <option value="">state</option>
                      {stateList2.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 ps-md-0 mb-2">
                    {width <= 767 ?
                      <div className="form-label">City</div>
                      : ''}
                    <select
                      className="form-select"
                      name="city3"
                      onChange={(e) => {
                        getPrefferLocation(e, 2);
                        handleInputChange(e);
                      }}
                      value={values.city3}
                    >
                      <option value="">city</option>
                      {cityList2.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {width <= 767 ?
                  <hr />
                  : ''}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-label">
                  What type of jobs do you prefer?
                </div>
              </div>
              <div className="col-12 col-md-6 mb-16">
                <select
                  className="form-select"
                  name="jobTypeId"
                  onChange={handleInputChange}
                  value={values?.jobTypeId}
                >
                  <option value="">none</option>
                  {jobList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 mb-16">
                <select
                  className="form-select"
                  name="jobPraferId"
                  onChange={handleInputChange}
                  value={values?.jobPraferId}
                >
                  <option value="">none</option>
                  {jobPreferList?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="col-12 col-md-12 mb-16">
                  <div className="form-label">Skills</div>

                  <Multiselect
                    options={skillData} // Options to display in the dropdown
                    selectedValues={selectSkill} // Preselected value to persist in dropdown
                    onSelect={multiselectSelectSkill} // Function will trigger on select event
                    onRemove={removeSkill} // Function will trigger on remove event
                    // displayValue={(option) => (option ? option.skillName : "")} // Property name to display in the dropdown options
                    displayValue="skillName"
                    placeholder={selectSkill && selectSkill.length > 0 ? "" : "Please select the skills"}

                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => {
                setJobPreference(false);
                setDeleteSkill([]);
                getProfileList(Id);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        size="lg"
        show={UploadImage}
        onHide={() => setUploadImage(false)}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmitSaveImage}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">Upload Photo</div>
                <div className="font-16-24-05">Upload your Photo here.</div>
              </div>
            </div>
            <div className="row">
              <div className="col align-self-center mb-32">
                {img === null ? (
                  <>
                    <Webcam
                      audio={false}
                      mirrored={true}
                      height={400}
                      width={400}
                      ref={webcamRef}
                      screenshotFormat="image/*"
                    />
                    <button
                      variant="primary"
                      className="h-44 fw-medium m-0 ml-5"
                      onClick={(e) => {
                        e.preventDefault();
                        capture();
                        setUploadImage(true);
                      }}
                    >
                      Capture photo
                    </button>
                  </>
                ) : (
                  <>
                    <img src={img} alt="screenshot" />
                    <button
                      variant="primary"
                      className="h-44 fw-medium m-0 ml-5"
                      onClick={(e) => {
                        e.preventDefault();
                        setImg(null);
                      }}
                    >
                      Retake
                    </button>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => setUploadImage(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <AutohideExample show={show} setShow={setShow} />
    </main>
  );
};

export default Profile;