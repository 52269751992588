import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ADMIN_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  VERIFICATION_FAIL,
  VERIFICATION_SUCCESS,
  // //LOGOUT,
  CHANGE_PASSWORD,
  LOGOUT,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  VERIFY_SUCCESS,
  VERIFY_FAIL,
  ADMIN_LOGOUT,
  PERSONALREGISTER_SUCCESS,
  PERSONALREGISTER_FAIL,
} from "../action-types/authActionTypes";
import Cookies from "universal-cookie";
import handleAuthToken from "../../utills/handleAuthToken";
import axiosInstance from "../../utills/AxiosInstance";
import {
  ADD_FRESHER_DATA,
  FRESHER_FAIL,
} from "../action-types/fresherActionType";
import { ADD_EMPLOYER_DATA, ADD_EMPLOYER_DATAS, EMPLOYER_FAIL, EMPTY_ALL_DATE } from "../action-types/employerTypes";



const cookies = new Cookies();

// let BASE_URL = `${process.env.REACT_APP_API_URL}/api`;
let BASE_URL = "https://apijobportal.mobiginie.com/api";
// let BASE_URL = `http://localhost:4010/api`;


//fresherLogin api
export const loginUserApi = (values, type) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let res = [];
    if (type === 1) {
      res = await axios.post(
        `${BASE_URL}/fresher/fresherLogin`,
        values,
        config
      );
    }
    if (type === 2) {
      res = await axios.post(
        `${BASE_URL}/employee/employerLogin`,
        values,
        config
      );
    }

    if (res.data && res.data.status === true) {
      cookies.set("token", res.data.data.token, { path: "/admin" });
      cookies.set("email", values.email, { path: "/admin" });
      cookies.set("password", values.password, { path: "/admin" });

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data && res.data.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
    return res.data;

  } catch (error) {
    return { status: false, message: error?.response?.data?.message };
  }
};
//};
export const logoutApi = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

//admin api
export const loginAdminApi = (values, type) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`${BASE_URL}/v1/adminLogin`, values, config);
    if (res.data && res.data.status === true) {
      cookies.set("token", res.data.data.token, { path: "/" });
      cookies.set("email", values.email, { path: "/" });
      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        payload: res.data && res.data.data,
      });
    } else {
      dispatch({
        type: ADMIN_LOGIN_FAIL,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: ADMIN_LOGIN_FAIL,
    });
    return err;
  }
};

// //loaduser
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    axiosInstance.defaults.headers.common[
      "authorization"
    ] = `${localStorage.token}`;
  }
  try {
    const res = await axiosInstance.post(`/v1/tokenDecoder`);
    if (res.data && res.data.status) {
      dispatch({
        type: ADMIN_LOADED,
        payload: JSON.parse(JSON.stringify(res.data.data)),
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//fresher registerapi
export const SignupUserApi = (values) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axiosInstance.post(
      `/fresher/addFresherEmail`,
      values,
      config
    );
    if (res.data && res.status == 200) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data && res.data.data,
      });
      return res;
    } else {
      dispatch({
        type: REGISTER_FAIL,
      });
      return res;
    }
    //return res.data;
  } catch (err) {
    dispatch({
      type: REGISTER_FAIL,
    });
    return err;
  }
};

//verify email

export const EmailverifyApi = (values, type) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${BASE_URL}/fresher/fresherVerification`,
      values,
      config
    );

    if (res && res.data.status == true) {
      cookies.set("token", res?.data?.fresher?.token, {
        path: "/personalinformation",
      });
      cookies.set("email", values.email, { path: "/personalinformation" });

      dispatch({
        type: VERIFY_SUCCESS,
        payload: res.data && res?.data?.fresher,
      });
    } else {
      dispatch({
        type: VERIFY_FAIL,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: VERIFY_FAIL,
    });
    return err;
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const adminLogout = () => (dispatch) => {
  dispatch({ type: ADMIN_LOGOUT });
};

export const addRegisterRedux = (data) => async (dispatch) => {
  dispatch({
    type: "REGISTER_SUCCESS",
    payload: data,
  });
};

//employeeverify email

export const EmployeeEmailverifyApi = (values, type) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${BASE_URL}/employee/employeeVerification`,

      values,
      config
    );

    if (res && res.data.status == true) {
      cookies.set("token", res?.data?.employer?.token, {
        path: "/employerpersonalinformation",
      });
      cookies.set("email", values.email, {
        path: "/employerpersonalinformation",
      });

      dispatch({
        type: VERIFY_SUCCESS,
        payload: res.data && res?.data?.employer,
      });
    } else {
      dispatch({
        type: VERIFY_FAIL,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: VERIFY_FAIL,
    });
    return err;
  }
};

//google auth

export const googleLoginFresher = (values) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let res = await axiosInstance.post(
      `${BASE_URL}/fresher/fresherLoginWithGoogle`,
      values,
      config
    );


    if (res.data && res.data.status === true) {
      cookies.set("token", res.data.data.token, { path: "/" });
      cookies.set("email", values.email, { path: "/" });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data && res.data.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return err;
  }
};


export const registerFresherApi = (id, values) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(
      `/fresher/registerFresher/${id}`,
      values
    );
    if (res.data && res.status == 200) {
      dispatch({
        type: ADD_FRESHER_DATA,
        payload: res.data && res.data.data,
      });
    } else {
      dispatch({
        type: FRESHER_FAIL,
      });
    }
    //return res.data;
  } catch (err) {
    dispatch({
      type: FRESHER_FAIL,
    });
    return err;
  }
};

export const addFresherDataRedux = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_FRESHER_DATA",
    payload: JSON.parse(JSON.stringify(data)),
  });
};

//registerfresher register
export const FresherRegisterApi = (id, values) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(
      `/fresher/registerFresher/${id}`,
      values
    );

    if (response.data && response.status == 200) {
      dispatch({
        type: ADD_FRESHER_DATA,
        payload: response.data && response.data.data,
      });
    } else {
      dispatch({
        type: FRESHER_FAIL,
      });
    }
    return response.data;
  } catch (err) {
    dispatch({
      type: FRESHER_FAIL,
    });
    return err;
  }
};

//employer register
export const EmployerRegisterApi = (id, values) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(
      `/employee/registerEmployer/${id}`,
      values
    );
    if (response.data && response.data && response.data.status == true) {

      dispatch({
        type: ADD_EMPLOYER_DATAS,
        payload: response.data && response.data.data,
      });
    } else {
      dispatch({
        type: EMPLOYER_FAIL,
      });
    }
    return response.data;
  }
  catch (err) {
    dispatch({
      type: EMPLOYER_FAIL,
    });
    return err;
  }
};

export const addEmployerDataRedux = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_EMPLOYER_DATA",
    payload: data,
  });
};


export const changPassword = async (payload, id) => {
  try {

    const response = await axiosInstance.put(`/v1/changePassword/${id}`, payload);
    return response.data;
  } catch (e) {
    return e.response?.data;
  }
};
