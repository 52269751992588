import React, { useState } from "react";
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import GoogleButtonLogo from "../../assets/images/google-logo.svg";
import SliderImage1 from "../../assets/images/connecting_with_people.svg";
import SliderImage2 from "../../assets/images/man_using_browser.svg";
import SliderImage3 from "../../assets/images/man_climbing_mountain.svg";
import Controls from "../../components/controls/Controls";
import Slider from "react-slick";
import "./signup.scss";
import Cookies from "universal-cookie";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useForm } from "../../utills/useForms";
import { SignupUserApi } from "../../state/action/authAction";
import AutohideExample from "../../utills/Notification";
import { AiOutlineEyeInvisible, AiOutlineEye } from "../../assets/Icons/icons";
import { LoginSocialGoogle } from "reactjs-social-login";
import { Loader } from "../../utills/Loader";
import {
  googleLoginFresher,
} from "../../state/action/authAction";
import { Helmet } from 'react-helmet'
const initialValues = { email: "", password: "", confirmpassword: "" };

const Signup = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [view, setView] = useState(false);



  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmpasswordVisible);
  };

  const cookies = new Cookies();
  const isAuthenticated = useSelector((store) => store?.auth?.isAuthenticated);

  const dispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in)$/;
        const isEmailValid =
          emailRegex.test(fieldValues.email) && fieldValues.email.endsWith("");
        if (!isEmailValid) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("password" in fieldValues) {
      if (fieldValues.password !== "" && fieldValues.password !== undefined) {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 charecter";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(fieldValues.password)) {
                temp.password = "Password must contain at least 1 special character";
              }
              else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    if ("confirmpassword" in fieldValues) {
      if (
        fieldValues.confirmpassword !== "" &&
        fieldValues.confirmpassword !== undefined
      ) {
        temp.confirmpassword = "";
      } else {
        temp.confirmpassword = "This is required field";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      email: values.email,
      password: values.password,
    };

    if (validate()) {
      if (values.confirmpassword === values.password) {
        const res = await dispatch(SignupUserApi(body));

        if (res && res?.data && res?.data?.status == true) {
          // setLoadingButton(!loadingButton);
          setShow({
            isOpen: true,
            message: res?.data?.message,
            type: "success",
          });

          setTimeout(() => {
            navigate("/verifyemail", {
              state: {
                email: values.email,
              },
            });
          }, 2000);
        } else {
          setShow({
            isOpen: true,
            message:
              res?.response?.data?.errors?.details[0]?.message ||
              res?.response?.data?.message || res?.data?.message,
            type: "error",
          });
        }
      } else {
        setLoadingButton(false);
        setShow({
          isOpen: true,
          message: "Password and confirm password is mismatched",
          type: "error",
        });
      }
    }
  };
  const loginWithGoogels = async (data) => {
    if (data?.email) {
      setView(true);
      let LoginRes = await dispatch(
        googleLoginFresher({
          email: data.email,
          googleToken: data.access_token,
        })
      );

      if (LoginRes && LoginRes.status === true) {
        setShow({
          isOpen: true,
          message: LoginRes?.message,
          type: "success",
        });
        setView(false);

        navigate("/");
      } else {
        setLoadingButton(false);
        setShow({
          isOpen: true,
          message:
            LoginRes?.message || "Technical error please try again later.",
          type: "error",
        });
        setView(false);
      }
    }
  };

  useEffect(() => {
    const userEmail = cookies.get("email");
    const userPassword = cookies.get("password");
    const userConfirmPassword = cookies.get("confirmpassword");
    if (userEmail !== "" && userPassword !== "" && userConfirmPassword !== "") {
      setValues({
        ...values,
        email: userEmail,
        password: userPassword,
        confirmpassword: userConfirmPassword,
      });
    }
  }, []);

  return (
    <main>
      <Loader view={view} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fresher Signup | Job Portal - Join the Tech Talent Community</title>
        <meta name="description" content="Create your Job Portal account and join a vibrant community of tech talents. Sign up as a fresher and gain access to a wide range of job opportunities tailored to your skills and preferences." />
      </Helmet>

      <div className="container">
        <header className="row header-logo-wrap justify-content-between header-row-wrap">
          <div className="col-auto">
            <a href="" className="d-inline-block">
              {/* <img
                src={TechfreshrLogo}
                className="img-fluid header-logo-width"
              /> */}
              <h1 className="font-PTSerif-b">Job Portal</h1>
            </a>
          </div>
        </header>
      </div>

      <div className="container">
        <div className="row login-height-calc align-items-center">
          <div
            className="d-none d-lg-block col-lg-6 align-self-center" style={{
              height: '630px', width: '43%',
              margin: 'auto'
            }}
          >
            <div className="blur-gradiant">
              <div className="login-left-slider">
                <Slider {...settings}>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage1}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Large Network
                        </div>
                        <div className="slider-content-text">
                          Gain access to a vast network of recruiters <br /> and
                          top employers.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage2}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Best-fit Job
                        </div>
                        <div className="slider-content-text">
                          Find your dream job with our personalized <br />{" "}
                          matching system.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider-inner-itam text-center">
                      <div className="slider-item-image mb-32">
                        <img
                          src={SliderImage3}
                          className="img-fluid mx-auto h-slid-width"
                        />
                      </div>
                      <div className="text-white slider-inner-content">
                        <div className="font-PTSerif-b slider-content-head">
                          Achieve your Goals
                        </div>
                        <div className="slider-content-text">
                          Start building your dream career and soar to <br />{" "}
                          new heights with our exciting job <br />{" "}
                          opportunities.
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="login-box-width width_40">
              <form onSubmit={handleSubmit} autoComplete="off">
                <h1 className="login-top-head mb-32 font-PTSerif-b">Sign up</h1>

                <div className="row">
                  <div className="col-12 col-md-12 mb-16">
                    <div className="form-label">Email</div>
                    <Controls.Input
                      className="form-control"
                      placeholder="Enter your email"
                      name="email"
                      type="email"
                      lable="enter your email"
                      value={values.email}
                      onChange={handleInputChange}
                      maxLength="50"
                    />
                    {Boolean(errors.email) ? (
                      <p className="text-invalid">{errors.email}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 mb-16">
                    <div className="form-label">Password</div>
                    <div className="d-flex flex-column input-relative">
                      <Controls.Input
                        className="form-control "
                        placeholder="Enter your password"
                        name="password"
                        lable="enter your password"
                        value={values.password}
                        onChange={handleInputChange}
                        type={passwordVisible ? "text" : "password"}
                        maxLength="12"
                      />
                      <div
                        className="position-absolute password_eye"
                        variant="link"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <AiOutlineEyeInvisible
                            style={{ width: "80%", height: "30px" }}
                          />
                        ) : (
                          <AiOutlineEye
                            style={{ width: "80%", height: "30px" }}
                          />
                        )}
                      </div>
                      {Boolean(errors.password) ? (
                        <p className="text-invalid">{errors.password}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 mb-16">
                    <div className="form-label">Confirm Password</div>
                    <div className="d-flex flex-column input-relative">
                      <Controls.Input
                        className="form-control"
                        placeholder="Confirm password"
                        name="confirmpassword"
                        lable="confirm password"
                        value={values.confirmpassword}
                        onChange={handleInputChange}
                        type={confirmpasswordVisible ? "text" : "password"}
                        maxLength="12"

                      // error={errors.confirmpassword}
                      // helperText={errors.confirmpassword}
                      />
                      <div
                        className="position-absolute password_eye"
                        variant="link"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {confirmpasswordVisible ? (
                          <AiOutlineEyeInvisible
                            style={{ width: "80%", height: "30px" }}
                          />
                        ) : (
                          <AiOutlineEye
                            style={{ width: "80%", height: "30px" }}
                          />
                        )}
                      </div>
                    </div>
                    {Boolean(errors.confirmpassword) ? (
                      <p className="text-invalid">{errors.confirmpassword}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-10 mt-16">
                  <Controls.Button
                    className="btn btn-primary h-54 w-100"
                    text="Create new account"
                    type="submit"
                  />
                </div>

                {/* <div className="mb-10 text-center text-secondary">OR</div> */}
                <div className="">
                  {/* <LoginSocialGoogle
                    client_id={
                      "652871862866-7ksm0mehu5sh5trclq2aqsksn3qd28l2.apps.googleusercontent.com"
                    }
                    scope="openid profile email"
                    discoveryScopes="claims_supported"
                    access_type="offline"
                    onResolve={(provider, credential) => {
                      loginWithGoogels(provider.data);
                    }}
                    onReject={(provider, error) => { }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-info h-54 w-100 mb-16 text-decoration-none"
                    >
                      <img src={GoogleButtonLogo} className="img-fluid  me-2" />{" "}
                      Continue with Google
                    </button>
                  </LoginSocialGoogle> */}


                  <Controls.Button
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="btn btn-outline-primary h-54 w-100 mb-16"
                    text="Already have an account? Login"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <AutohideExample show={show} setShow={setShow} />
    </main>
  );
};

export default Signup;
