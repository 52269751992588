import React from "react";
import CityList from "../Location/CitysList";

function city() {
  return (
    <div>
      <CityList />
    </div>
  );
}

export default city;
