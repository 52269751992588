import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ADMIN_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  VERIFY_SUCCESS,
  PERSONALREGISTER_SUCCESS,
  //LOGOUT
} from "../action-types/authActionTypes";
import { ADD_FRESHER_DATA } from "../action-types/fresherActionType";
import { ADD_EMPLOYER_DATAS, ADD_EMPLOYER_DATA, EMPLOYER_FAIL, EMPTY_ALL_DATE } from "../action-types/employerTypes";
import { DELETE_EMPLOYER_DATA, DELETE_FRESHER_DATA } from "../action-types/settingTypes"

const initialState = {
  token: localStorage.getItem("token") || "",
  isExpired: false,
  isAuthenticated: null,
  loading: true,
  user: null || {},
  employer: null || {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        admin: payload,
        employer: payload,
        user: payload,
        token: payload.token,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: payload,
        // token: payload.token,
        isAuthenticated: true,
        loading: false,
        admin: payload,
        employer: payload,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        user: payload,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
      };

    case PERSONALREGISTER_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        user: payload,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
      };

    case VERIFY_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        user: payload,
        admin: payload,
        employer: payload,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
      };

    case ADMIN_LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        admin: payload,
        user: payload,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
      };

    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case ADMIN_LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    case ADMIN_LOGOUT:
      localStorage.removeItem("token");
      return {
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    case ADD_FRESHER_DATA:
      return {
        ...state,
        user: payload,
        token: payload?.token,
        admin: state.admin,
        isAuthenticated: true,
        employer: payload,
        loading: false,
      };


    case ADD_EMPLOYER_DATAS:
      localStorage.setItem("token", payload.token);
      return {
        user: payload,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        admin: payload,
        employer: payload,
      };

    case ADD_EMPLOYER_DATA:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        admin: payload,
        user: payload,
        employerData: payload,
      };

    case EMPLOYER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        employerData: null,
      };
    case DELETE_FRESHER_DATA:
      return {
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        employer: null,
        admin: null
      };
    case DELETE_EMPLOYER_DATA:
      localStorage.removeItem("token")
      return {
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        employer: null,
        admin: null
      };

    default:
      return state;
  }
}