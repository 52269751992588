import React, { useEffect, useState } from "react";
import Controls from "../../../components/controls/Controls";
import {
  JobType,
  JobPreferList,
  getNumberEmployer,
  addEmployerDataRedux,
} from "../../../state/action/employerAction";
import { SkillList } from "../../../state/action/skillAction";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Form } from "../../../utills/useForms";
import { EmployerRegisterApi } from "../../../state/action/authAction";
import { useLocation, useNavigate } from "react-router-dom";
import AutohideExample from "../../../utills/Notification";
import {
  getCityLists,
  getCountryLists,
  getStateLists,
} from "../../../state/action/fresherAction";
import Multiselect from "multiselect-react-dropdown";
import { JobPreferLists, JobTypes } from "../../../state/action/addjobAction";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';

const initialValues = {
  jobTitle: "",
  jobType: "",
  jobPrefer: "",
  jobDescription: "",
  maxSalary: "",
  minSalary: "",
  noOfOpenings: "",
  salaryCurrency: "",
  countryId: "",
  stateId: "",
  cityId: "",
  skillId: "",
};

const EmployerJobDetails = (props) => {
  const [skillData, setSkillData] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [jobPrefer, setJobPrefer] = useState([]);
  const [selectSkill, setSelectSkill] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { activeTab, setActiveTab } = props;

  const navigate = useNavigate();
  const { state } = useLocation();
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const dispatch = useDispatch();

  const getHEndelData = async (e) => {
    const res = await JobTypes();
    if (res && res.data) {
      setJobType(res.data);
    }
  };

  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [error, setError] = useState("");
  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);

  const handleEditorChange = (state) => {
    if (state && state != null) {
      setEditorState(state);
      convertContentToHTML();
    }
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }

  const multiselectSelectSkill = (data, e) => {
    setSelectSkill(data);
    setSkillData(skillData.filter((items) => items.id !== e.id));
  };

  const multiselectRemoveSkill = (data) => {
  };


  //country Data
  const handleCountryLocation = (e) => {
    setValues({
      ...values,
      countryId: e.target.value,
      stateId: "",
      cityId: "",
    });
    handleStateList(e.target.value);
    setCityList([]);
  };

  const handleStateLocation = (e) => {
    setValues({
      ...values,
      stateId: e.target.value,
      cityId: "",
    });
    handleCityList(e.target.value);
  };

  const getContryData = async () => {
    const res = await getCountryLists();
    if (res && res.data) {
      setCountryList(res.data);
      setCityList([]);
      setStateList([]);
    } else {
      setCountryList([]);
      setCityList([]);
      setStateList([]);
    }
  };

  useEffect(() => {
    getContryData();
  }, []);
  //city Data

  const handleCityList = async (id) => {
    const res = await getCityLists(id);
    if (res.status === true) {
      setCityList(res.data);
    } else {
      setCityList([]);
    }
  };

  //state Data
  const handleStateList = async (id) => {
    const res = await getStateLists(id);
    if (res.status === true) {
      setStateList(res.data);
      setCityList([]);
    } else {
      setStateList([]);
      setCityList([]);
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("jobTitle" in fieldValues)
      temp.jobTitle = fieldValues.jobTitle && fieldValues.jobTitle != null
        ? "" : "This field is required.";

    if ("noOfOpenings" in fieldValues) {
      //only number
      const re = /^[0-9\b]+$/;
      if (fieldValues.noOfOpenings != "" && fieldValues.noOfOpenings != null) {
        if (fieldValues.noOfOpenings) {
          if (re.test(fieldValues.noOfOpenings)) {
            temp.noOfOpenings = "";
          } else {
            temp.noOfOpenings = "Only number allowed";
          }
        }
      }
      else {
        temp.noOfOpenings = "This field is required.";
      }


    }


    if ("jobType" in fieldValues)
      temp.jobType = fieldValues.jobType && fieldValues.jobType != null
        ? "" : "This field is required.";
    if ("jobPrefer" in fieldValues)
      temp.jobPrefer = fieldValues.jobPrefer && fieldValues.jobPrefer != null
        ? "" : "This field is required.";
   
    if ("minSalary" in fieldValues) {
      if (fieldValues.minSalary) {
        const minSalary = parseFloat(fieldValues.minSalary);
        const maxValue = parseFloat(values.maxSalary);
        if (minSalary < 0) {
          temp.minSalary = "Minimum salary cannot be negative.";
        }
        else if (minSalary > maxValue) {

          temp.minSalary = `Minimum salary should be less than or equal to ${maxValue}.`;
        } else if (
          fieldValues.maxSalary &&
          minSalary > parseFloat(fieldValues.maxSalary)
        ) {
          temp.minSalary =
            "Minimum salary should be less than or equal to the maximum salary.";
        } else if (minSalary < maxValue) {
          temp.maxSalary = "";
          temp.minSalary = "";
        }
        else {
          temp.minSalary = "";
        }
      } else {
        temp.minSalary = "This field is required.";
      }
    }
    if ("maxSalary" in fieldValues) {
      if (fieldValues.maxSalary) {
        const minSalary = parseFloat(values.minSalary);
        const maxSalary = parseFloat(fieldValues.maxSalary);
        if (maxSalary < 0) {
          temp.maxSalary = "Maximum salary cannot be negative.";
        }
        else if (maxSalary < parseFloat(values.minSalary)) {
          temp.maxSalary =
            "Maximum salary should be greater than or equal to the minimum salary.";
        }
        else if (minSalary > maxValue) {

          temp.maxSalary = "";
          temp.minSalary = "";

        } else {
          temp.maxSalary = "";
        }
      } else {
        temp.maxSalary = "This field is required.";
      }
    }

    if ("countryId" in fieldValues)
      temp.countryId = fieldValues.countryId && fieldValues.countryId != null
        ? "" : "This field is required.";

    if ("stateId" in fieldValues)
      temp.stateId = fieldValues.stateId && fieldValues.stateId != null
        ? "" : "This field is required.";
    if ("cityId" in fieldValues)
      temp.cityId = fieldValues.cityId && fieldValues.cityId != null
        ? "" : "This field is required.";

    if ("salaryCurrency" in fieldValues)
      temp.salaryCurrency = fieldValues.salaryCurrency && fieldValues.salaryCurrency != null
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const minValue = values.minSalary;
  const maxValue = values.maxSalary;

  const getHPreferData = async (e) => {
    const res = await JobPreferLists();
    if (res && res.data) {
      setJobPrefer(res.data);
    }
  };

  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      setSkillData(res.data);
    }
  };

  useEffect(() => {
    getSkillList();
  }, []);


  const removeSkills = (e, item) => {
    setSkillData([...skillData, item]);
    const removeSkill = selectSkill.filter((items) => items.id !== item.id);
    setSelectSkill(removeSkill);
  };

  useEffect(() => {
    getHEndelData();
    getHPreferData();
  }, [skillData]);

  const dataEmail = useSelector((state) => state?.auth?.user);

  const data = useSelector((state) => state?.auth?.user);
  //converted content validation
  const convertedContentValidation = () => {
    if (convertedContent === null || convertedContent === "" || convertedContent === "<p></p>") {
      setShow({
        isOpen: true,
        message: "Please enter job description.",
        type: "error",
      });

      return false;
    } else {
      return true;
    }
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    if (validate() && convertedContentValidation()) {

      if (selectSkill.length === 0) {
        setErrorMessage("Please select at least one skill.");
      } else {
        const payload = {
          firstName: data.firstName,
          lastName: data.lastName,
          mobile: data.phoneNumber,
          website: data.website == null ? "" : data.website,
          email: dataEmail?.email,
          companyName: data.companyName,
          noofEmployee: data.noOfEmployees,
          jobTitle: values.jobTitle,
          openings: values.noOfOpenings,
          jobTypeId: values.jobType,
          jobPreferId: values.jobPrefer,
          jobDescription:convertedContent,
          salCurrency: values.salaryCurrency,
          minSalary: values.minSalary,
          maxSalary: values.maxSalary,
          skillId: selectSkill.map((item) => item.id),
          countryId: values.countryId,
          stateId: values.stateId,
          cityId: values.cityId,
          countryCode: "91",
        };

        const res = await dispatch(EmployerRegisterApi(dataEmail?.id, payload))

        if (res && res.status) {
          setLoadingButton(!loadingButton);
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setTimeout(() => {
            navigate("/employer");
          }, 2000);
        } else {
          setShow({
            isOpen: true,
            message: res?.message || "Technical error please try again later.",
            type: "error",
          });
          setLoadingButton(false);
        }
      }
    }
  };

  const handleInputLength = (event) => {
    const maxLength = 5;
    const input = event.target;
    const value = input.value;

    if (value.length > maxLength) {
      input.value = value.slice(0, maxLength);
    }
  };

  useEffect(() => {
    values.countryId = values.countryId || 101; // Set default value to 102 if countryId is not already set
    handleStateList(values.countryId);
  }, [values.countryId]);

  useEffect(() => {
    if (values.stateId) {
      handleCityList(values.stateId);
    }
  }, [values.stateId]);

  return (
    <main>
      <AutohideExample show={show} setShow={setShow} />
      <div className="">
        <div className="row">
          <div className="col-lg-12 align-self-center">
            <div className="login-box-width">
              <div className="row">
                <div className="col-lg-12 align-self-center">
                  <div className="row">
                    <div className="col-12 col-md-12 mb-16">
                      <div className="form-label">Job title</div>
                      <Controls.Input
                        id="jobTitle"
                        className="form-control"
                        placeholder="Job title"
                        name="jobTitle"
                        type="text"
                        lable="Job title"
                        onChange={handleInputChange}
                        value={values.jobTitle}
                      />
                      {Boolean(errors.jobTitle) ? (
                        <p className="text-invalid">{errors.jobTitle}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="form-label">Number of openings</div>
                      <Controls.Input
                        className="form-control"
                        placeholder="Number of openings"
                        name="noOfOpenings"
                        type="text"
                        lable="Number of openings"
                        onChange={handleInputChange}
                        onInput={handleInputLength}
                        value={values.noOfOpenings}
                      />
                      {Boolean(errors.noOfOpenings) ? (
                        <p className="text-invalid">{errors.noOfOpenings}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div style={{ marginTop: "15px" }}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-label">Job location</div>
                      </div>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-4 pe-md-0 mb-2">
                            <select
                              className="form-select form-control"
                              name="countryId"
                              onChange={(e) => {
                                handleInputChange(e);
                                handleCountryLocation(e);
                              }}
                              id="countryId"
                              value={values.countryId || 101}
                            >
                              <option value="">country</option>
                              {countryList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {Boolean(errors.countryId) ? (
                              <p className="text-invalid">{errors.countryId}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-12 col-md-4 mb-2">
                            <select
                              className="form-select form-control"
                              name="stateId"
                              onChange={(e) => {
                                handleInputChange(e);
                                handleStateLocation(e);
                              }}
                              value={values.stateId}
                            >
                              <option value=""> State</option>
                              {stateList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {Boolean(errors.stateId) ? (
                              <p className="text-invalid">{errors.stateId}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-12 col-md-4 ps-md-0 mb-2">
                            <select
                              className="form-select form-control"
                              name="cityId"
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                              value={values.cityId}
                            >
                              <option value=""> city</option>
                              {cityList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {Boolean(errors.cityId) ? (
                              <p className="text-invalid">{errors.cityId}</p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: "5px" }}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-label">Job type</div>
                      </div>
                      <div className="col-12 col-md-6 mb-16">
                        <select
                          className="form-select form-control"
                          name="jobType"
                          onChange={handleInputChange}
                          value={values.jobType}
                        >
                          <option value="">none</option>
                          {jobType.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                        {Boolean(errors.jobType) ? (
                          <p className="text-invalid">{errors.jobType}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12 col-md-6 mb-16">
                        <select
                          className="form-select form-control"
                          name="jobPrefer"
                          onChange={handleInputChange}
                          value={values.jobPrefer}
                        >
                          <option value="">none</option>
                          {jobPrefer.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                        {Boolean(errors.jobPrefer) ? (
                          <p className="text-invalid">{errors.jobPrefer}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 mb-16">
                      <div className="form-label">Job description</div>
                      {/* <textarea
                        className="form-control h-auto"
                        placeholder="Job description"
                        rows="3"
                        onChange={handleInputChange}
                        name="jobDescription"
                        maxLength="1000"
                        value={values.jobDescription}
                      ></textarea> */}

                      <Editor
                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      // onblur={() => handleSubmit()}
                      />
                    </div>
                    {Boolean(errors.jobDescription) ? (
                      <p className="text-invalid">{errors.jobDescription}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 mb-16">
                      <div className="col-12 col-md-12 mb-16">
                        <div className="form-label">
                          Skills<span className="mandatory-field">*</span>
                        </div>
                        <Multiselect
                          options={skillData} // Options to display in the dropdown
                          selectedValues={selectSkill} // Preselected value to persist in dropdown
                          onSelect={multiselectSelectSkill}
                          onRemove={
                            removeSkills
                          }
                          // onRemove={ } // Function will trigger on remove event
                          // displayValue={(option) => (option ? option.skillName : "")} // Property name to display in the dropdown options
                          displayValue="title"
                        />
                        {errorMessage && (
                          <div className="text-invalid">{errorMessage}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-label">Salary range</div>
                    </div>
                    <div className="col-12 col-md-3 mb-16">
                      <select
                        className="form-select form-control"
                        name="salaryCurrency"
                        onChange={handleInputChange}
                        value={values.salaryCurrency}
                      >
                        <option value="">None</option>
                        <option>INR</option>
                        <option>USD</option>
                      </select>
                      {Boolean(errors.salaryCurrency) ? (
                        <p className="text-invalid">{errors.salaryCurrency}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-12 col-md mb-16">
                      <Controls.Input
                        className="form-control"
                        placeholder="Minimum"
                        name="minSalary"
                        type="number"
                        lable="SkiMinimumlls"
                        onChange={handleInputChange}
                        value={values.minSalary}
                      />
                      {Boolean(errors.minSalary) ? (
                        <p className="text-invalid">{errors.minSalary}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-12 col-md mb-16">
                      <Controls.Input
                        className="form-control"
                        placeholder="Maximum"
                        name="maxSalary"
                        type="number"
                        lable="Maximum"
                        onChange={handleInputChange}
                        value={values.maxSalary}
                      />
                      {Boolean(errors.maxSalary) ? (
                        <p className="text-invalid">{errors.maxSalary}</p>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                </div>
              </div>
              <div className="mb-16 mt-16">
                <Controls.Button
                  className="btn btn-primary h-54 w-100"
                  text="Submit"
                  onClick={(e) => {
                    HandleSubmit(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmployerJobDetails;
