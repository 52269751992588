import React, { useState } from "react";
import SideBarLogoIcon from "../../assets/images/tech_fresher_fav_logo.svg";
import SideBarLogoFull from "../../assets/images/techfresher-logo.svg";
import SideBarIcon1 from "../../assets/images/briefcase.svg";
import SideBarIcon2 from "../../assets/images/user.svg";
import SideBarIcon3 from "../../assets/images/zap.svg";
import SideBarCollaseExpandIcon from "../../assets/images/menu_bar_icon.svg";

import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { BsPrinter } from "react-icons/bs";
import { HiAcademicCap } from "react-icons/hi";
import { BsFillPeopleFill } from "react-icons/bs";
import { FcBusiness } from "react-icons/fc";
import { TbAlignBoxLeftMiddleFilled } from "react-icons/tb";
import { BiMaleFemale } from "react-icons/bi";
import { AiFillBook, } from "react-icons/ai";
import { AiFillCalendar } from "react-icons/ai";
import { CiLinkedin } from "react-icons/ci";
import { GiSkills, GiDiploma } from "react-icons/gi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TiDeviceLaptop } from "react-icons/ti";
import { SiAdobefonts } from "react-icons/si";
import AutohideExample from "../../utills/Notification";

import { CiLocationOn } from "react-icons/ci"
// import  {FaLocationDot} from "../../assets/Icons/icons";
import { BsFillKeyFill, FiLogOut, AiFillEyeInvisible, AiFillEye, FaLocationDot } from '../../assets/Icons/icons'


import { changPassword, logoutApi } from "../../state/action/authAction";
import Dropdown from "react-bootstrap/Dropdown";
import { getNotificationLists, notificationRead, markAllRead, getNotificationListsAdmin, markAllReadAdmin } from "../../state/action/pushNotificationAction";
import Menu from "@mui/material/Menu";
import Controls from "../../components/controls/Controls";
import { Form, InputGroup } from 'react-bootstrap';

import { loadUser } from "../../state/action/authAction";
import { useForm } from "../../utills/useForms";
import { useSnackbar } from "notistack";
import headerimage from "../../assets/images/headerProfile.png";

import { ColorModeContext, tokens } from "../../theme";
import { useContext } from "react";
// import { useProSidebar } from "react-pro-sidebar";



import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { adminLogout } from "../../state/action/authAction";
import { IsRead } from "../../utills/handlePermission";

import {
  useTheme,
  Box,
  IconButton,
  Typography,
  Avatar,
  Tooltip,
  Grid,

  InputAdornment,
} from "@mui/material";
import { useEffect } from "react";

const initialValues = { newPassword: "", password: "", confirmPassword: "", oldPassword: "" };


const Sidebar1 = ({ isActive, handleClick, setIsActive }) => {
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const [location, setLocation] = useState(false);
  const [anchorElNoti, setAnchorElNoti] = useState(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const openNoti = Boolean(anchorElNoti);
  const [errorMsg, seterrorMsg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const Id = useSelector((state) => state?.auth?.user?.id);
  const open = Boolean(anchorEl);
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);
  const [ShowOk, setShowOk] = useState(false);

  const [shows, setShows] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  // const { toggleSidebar, broken, rtl } = useProSidebar();

  const userAdmin = useSelector((store) => store?.auth?.user);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [notificationList, setNotificationList] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const data = useSelector((state) => state?.auth);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setShowModal(true);
    setAnchorEl(null)
  };

  const handleClickUser = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    resetForm();
  };
  useEffect(() => {
    getNotificationData()
  }, [])

  const getNotificationData = async () => {
    const res = await getNotificationListsAdmin(1);
    if (res && res.data && res.data.length > 0) {
      setNotificationList(res.data);
    } else {
      setNotificationList([]);
    }
  }

  const ReadNotification = async (id) => {
    const res = await notificationRead(id);
    if (res && res.status) {
      getNotificationData();
    } else {
    }
  }

  const ReadAllNotification = async (type) => {
    const res = await markAllReadAdmin(type);
    if (res && res.status) {
      getNotificationData();
    }
    else {
    }
  }


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("oldPassword" in fieldValues) {
      if (fieldValues.oldPassword !== "" && fieldValues.oldPassword !== undefined) {
        if (fieldValues.oldPassword.length < 8) {
          temp.oldPassword = "Password  must be 8 charecter";
        } else {
          if (!/[0-9]/.test(fieldValues.oldPassword)) {
            temp.oldPassword = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.oldPassword)) {
              temp.oldPassword = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.oldPassword)) {
                temp.oldPassword = "Password  must be 1 Uppercase letter";
              } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(fieldValues.oldPassword)) {
                temp.oldPassword = "Password must contain at least 1 special character";
              } else {
                // setPass(fieldValues.password);
                temp.oldPassword = "";
              }
            }
          }
        }
      } else {
        temp.oldPassword = "This field is required.";
      }
    }

    if ("newPassword" in fieldValues) {
      if (fieldValues.newPassword !== "" && fieldValues.newPassword !== undefined) {
        if (fieldValues.newPassword.length < 8) {
          temp.newPassword = "Password  must be 8 charecter";
        } else {
          if (!/[0-9]/.test(fieldValues.newPassword)) {
            temp.newPassword = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.newPassword)) {
              temp.newPassword = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.newPassword)) {
                temp.newPassword = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.newPassword = "";
              }
            }
          }
        }
      } else {
        temp.newPassword = "This field is required.";
      }
    }


    if ("confirmPassword" in fieldValues) {
      if (fieldValues.confirmPassword !== "" && fieldValues.confirmPassword !== undefined) {
        if (fieldValues.confirmPassword == values.newPassword) {
          temp.confirmPassword = "";

        } else {
          temp.confirmPassword = "Confirm password not same as new password";
        }
      } else {
        temp.confirmPassword = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };



  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  //Confirm password
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const userId = useSelector((state) => state?.user?.id);
  const handleSubmit = async (e) => {

    e.preventDefault();

    const body = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    if (validate()) {
      const res = await changPassword(body, Id);
      if (res && res.status === true) {
        setShows({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
        setShowOk(true);
        handleCloseDelete(false)

      } else {
        setShows({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }

    }
  };
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <main className="sidebar-open">
      {
        isActive || <div className="sidebar_overlay" onClick={(e) => setIsActive(true)}></div>
      }
      <div className="sidebar-wrap">
        <div className={`${isActive ? 'sidebar-menu-wrap-toggle' : ''} sidebar-menu-wrap`} style={{ overflow: "auto" }}>
          <div className="sidebar-header d-flex align-items-center justify-content-between">
            <div className="sidebar-logos">
              <div className="sidebar-logo-small">
                {/* <img src={SideBarLogoIcon} className="img-fluid" /> */}
                <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

              </div>
              <div className="sidebar-logo-large">
                {/* <img src={SideBarLogoFull} className="img-fluid" /> */}
                <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

              </div>
            </div>
            {width <= 991 ? <div className="me-2" onClick={(e) => setIsActive(true)}><IoIosCloseCircleOutline className="h3 mb-0 cursor_pointer" /></div> : ''}
          </div>
          <div className="sidebar-body">
            {IsRead("Admin") && (
              <div className={`${routeLocation.pathname === '/admin/admins' ? 'activeSidebar' : ''} side-menu-list`} onClick={() => navigate("/admin/admins")}>
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                        stroke="#143f87"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                        stroke="#143f87"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div className="side-menu-name">Admin</div>
                </a>
              </div>
            )}
            {IsRead("role") && (

              <div className={`${routeLocation.pathname === '/admin/role' || routeLocation.pathname === '/admin/addPermission' ? 'activeSidebar' : ''} side-menu-list`} onClick={() => navigate("/admin/role")}>
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <BsFillPeopleFill />
                  </div>
                  <div className="side-menu-name">Roles</div>
                </a>
              </div>)}

            {IsRead("skill") && (

              <div className={`${routeLocation.pathname === '/admin/skills' ? 'activeSidebar' : ''} side-menu-list`} onClick={() => navigate("/admin/skills")}>
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <GiSkills />
                  </div>
                  <div className="side-menu-name">skills</div>
                </a>
              </div>
            )}

            {/* {IsRead("assesment") && (

              <div
                className={`${routeLocation.pathname === '/admin/assesment' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={() => navigate("/admin/assesment")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <TbAlignBoxLeftMiddleFilled />
                  </div>
                  <div className="side-menu-name">Assessment</div>
                </a>
              </div>)} */}
            {IsRead("codingAssessment") && (

              <div
                className={`${routeLocation.pathname === '/admin/codingAssessment' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={(e) => navigate("/admin/codingAssessment")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <TiDeviceLaptop />
                  </div>
                  <div className="side-menu-name"> Coding Assessment</div>
                </a>
              </div>
            )}
            {IsRead("freshers") && (

              <div
                className={`${routeLocation.pathname === '/admin/freshers' || routeLocation.pathname === '/admin/fresherDetail' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={() => navigate("/admin/freshers")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <HiAcademicCap />
                  </div>
                  <div className="side-menu-name">freshers</div>
                </a>
              </div>
            )}
            {IsRead("employeer") && (

              <div
                className={`${routeLocation.pathname === '/admin/employeer' || routeLocation.pathname === '/admin/employeerDetail' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={() => navigate("/admin/employeer")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <BiMaleFemale />
                  </div>
                  <div className="side-menu-name">Employer</div>
                </a>
              </div>
            )}
            {IsRead("telentpoll") && (

              <div
                className={`${routeLocation.pathname === '/admin/TalentPool' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={() => navigate("/admin/TalentPool")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <GiDiploma />
                  </div>
                  <div className="side-menu-name">Talent Pool</div>
                </a>
              </div>
            )}

            {IsRead("jobpost") && (

              <div
                className={`${routeLocation.pathname === '/admin/jobpost' || routeLocation.pathname === '/admin/jobpostdetails' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={() => navigate("/admin/jobpost")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <AiFillBook />
                  </div>
                  <div className="side-menu-name">job post</div>
                </a>
              </div>
            )}



            {IsRead("interviewtype") && (

              <div
                className={`${routeLocation.pathname === '/admin/interviewtype' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={() => navigate("/admin/interviewtype")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <CiLinkedin />
                  </div>
                  <div className="side-menu-name">interview Type</div>
                </a>
              </div>
            )}
            {IsRead("jobtype") && (

              <div
                className={`${routeLocation.pathname === '/admin/jobtype' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={() => navigate("/admin/jobtype")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <AiFillCalendar />
                  </div>
                  <div className="side-menu-name">job type</div>
                </a>
              </div>
            )}
            {IsRead("jobLists") && (

              <div
                className={`${routeLocation.pathname === '/admin/jobLists' ? 'activeSidebar' : ''} side-menu-list`}
                onClick={() => navigate("/admin/jobLists")}
              >
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <FcBusiness />
                  </div>
                  <div className="side-menu-name">job Preferences  </div>
                </a>
              </div>)
            }
            {IsRead("faq") && (

              <div className={`${routeLocation.pathname === '/admin/FAQ' ? 'activeSidebar' : ''} side-menu-list`} onClick={() => navigate("/admin/FAQ")}>
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <SiAdobefonts />
                  </div>
                  <div className="side-menu-name">FAQ's</div>
                </a>
              </div>

            )}

            {IsRead("location") && (
              <div className={`${routeLocation.pathname === '/admin/Location' || routeLocation.pathname === '/admin/state' || routeLocation.pathname === '/admin/City' ? 'activeSidebar' : ''} side-menu-list`} onClick={() => navigate("/admin/Location")}>
                <a href="javascript:void(0)" className="side-menu-item" onClick={width <= 991 ? (e) => setIsActive(true) : null}>
                  <div className="side-menu-icon">
                    <CiLocationOn />
                  </div>
                  <div className="side-menu-name">Location</div>
                </a>
              </div>

            )}

          </div>
        </div>
        <div className={`${isActive ? 'content-wrap-toggle' : ''} content-wrap`}>
          <div className="sub-header">
            <div className="container-fluid">
              <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                  <div className="sidebar-collase-expand">
                    <img src={SideBarCollaseExpandIcon} className="img-fluid" onClick={handleClick} />
                  </div>
                </div>

                <div className="col-auto align-self-center">

                  <div className="header-right-wrap">
                    <ul className="d-flex">
                      <li className="d-none d-md-inline-block position-relative">
                        {
                          notificationList && notificationList.filter((item) => item?.isRead == 0).length > 0 &&
                          <div className="position-absolute bg-danger notification__icon_admin"></div>
                        }
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            className="rounded-pill caret-none p-10"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 24 24"
                              fill="none"
                              onClick={() => {
                                getNotificationData();
                              }
                              }
                            >
                              <path
                                d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="notification-dropdown dropdown-shadow py-0 mt-2">
                            <div className="d-flex justify-content-between notification-head">
                              <h2 className="font-18-24-05">Notifications</h2>
                              {notificationList && notificationList.filter((item) => item?.isRead == 0).length > 0 && <h3 className="" onClick={() => {
                                ReadAllNotification(1);
                              }}
                              >Mark all as read</h3>}
                            </div>
                            <div className="notification-body">
                              {notificationList && notificationList.length > 0 ? notificationList.map((item, index) => {
                                return (<Dropdown.Item href="JavaScript:void(0)" className={item?.isRead == 0 ? "active" : ""} onClick={() => {
                                  // navigate("/assessments");
                                  ReadNotification(item?.id);
                                }} >
                                  <div className="row">
                                    <div className="col-auto">
                                      <div className="d-flex align-items-center justify-content-center round-blue-style-1 w-48">
                                        <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            onClick={() => {
                                            }
                                            }
                                          >
                                            <path
                                              d="M2.75008 17.4166V5.22498C2.75003 5.03681 2.80788 4.85318 2.91579 4.69903C3.02369 4.54487 3.17643 4.42766 3.35325 4.36331L12.2183 1.14031C12.2876 1.11511 12.3619 1.10697 12.4349 1.1166C12.508 1.12622 12.5777 1.15332 12.638 1.1956C12.6984 1.23788 12.7477 1.29409 12.7817 1.35946C12.8157 1.42484 12.8334 1.49745 12.8334 1.57114V6.11139L18.6231 8.04098C18.8057 8.1018 18.9645 8.21855 19.0771 8.37469C19.1896 8.53083 19.2501 8.71842 19.2501 8.91089V17.4166H21.0834V19.25H0.916748V17.4166H2.75008ZM4.58342 17.4166H11.0001V3.53373L4.58342 5.86756V17.4166ZM17.4167 17.4166V9.57181L12.8334 8.04373V17.4166H17.4167Z"
                                              fill="#205FC8"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col px-0">
                                      <div className="notification-content">
                                        <h5 className="font-18-24-05 mb-1 text-primary-emphasis">
                                          {item?.title}
                                        </h5>
                                        <p className="font-16-24-05 mb-2">
                                          {item?.description}
                                        </p>
                                        <h6 className="mb-0">{moment(item?.createdAt).format("MMMM Do YYYY, h:mm A")}</h6>
                                      </div>
                                    </div>
                                    {item?.isRead == 0 && <div className="col-auto">
                                      <div className="notification-unread"></div>
                                    </div>}
                                  </div>
                                </Dropdown.Item>)

                              }
                              ) : <div className="text-center">No Notification Found</div>}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li>
                        <div
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClickUser}
                        >
                          <Box
                            display="flex"
                            sx={{ marginLeft: "10px", alignItems: "center" }}
                          >
                            <Box textAlign="right" sx={{ marginRight: "10px" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  //color: "#000",
                                  marginBottom: "0px",
                                  fontWeight: "600",
                                  textTransform: "capitalize",
                                }}
                              >
                                {/* {userAdmin?.name} */}
                              </Typography>
                              <Typography
                                color={colors.grey[600]}
                                fontWeight="500"
                                sx={{
                                  fontSize: "12px",
                                  marginBottom: "0px",
                                  textTransform: "capitalize",
                                }}
                              >
                                Admin
                              </Typography>
                            </Box>
                            <Box>
                              <img loading="lazy"
                                className="avater-image"
                                alt="profile user"
                                width="40px"
                                height="40px"
                                src={headerimage}
                                style={{ cursor: "pointer", borderRadius: "50%" }}
                              />
                            </Box>
                          </Box>
                        </div>
                        <Menu
                          // id="basic-menu" 
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: "#fff",
                            },
                            marginLeft: "5px",
                          }}
                        >
                          <MenuItem
                            sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
                            onClick={() => { setAnchorEl(null) }
                            }
                          >
                            <div onClick={handleDeleteModel}>
                              <BsFillKeyFill /> &nbsp; Change Password
                            </div>


                          </MenuItem>
                          <MenuItem
                            sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}

                            onClick={handleShowModal}
                          >
                            <div>
                              <FiLogOut /> &nbsp; Logout
                            </div>
                          </MenuItem>
                        </Menu>

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-12">{/* <h1><Freshers/></h1> */}</div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to log out?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              onClick={() => setShowModal(false)}
            >
              No
            </Button>
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              onClick={() => {
                dispatch(adminLogout());
                navigate("/admin");
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={openDeleteModel} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={handleInputChange}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {!showPassword ? <AiFillEye className="icon_css" /> : <AiFillEyeInvisible className="icon_css" />}

                  </Button>
                </InputGroup>
                {Boolean(errors.oldPassword) ? (
                  <p className="text-invalid">{errors.oldPassword}</p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleInputChange}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                  >
                    {!showNewPassword ? <AiFillEye className="icon_css" /> : <AiFillEyeInvisible className="icon_css" />}
                  </Button>
                </InputGroup>
                {Boolean(errors.newPassword) ? (
                  <p className="text-invalid">{errors.newPassword}</p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleInputChange}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {!showConfirmPassword ? <AiFillEye className="icon_css" /> : <AiFillEyeInvisible className="icon_css" />}

                  </Button>
                </InputGroup>
                {Boolean(errors.confirmPassword) ? (
                  <p className="text-invalid">{errors.confirmPassword}</p>
                ) : (
                  ""
                )}
                {passwordError && <div>{passwordError}</div>}
                <p className="text-invalid"> {errorMsg && <h5> {errorMsg}</h5>}</p>
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button variant="secondary" className="me-2" onClick={() => [handleCloseDelete(), resetForm()]}>
                  Cancel
                </Button>
                <Button type="submit"
                  onClick={(e) =>
                    handleSubmit(e)}>Save</Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>


        <Modal
          show={ShowOk} onHide={() => setShowOk(false)} centered
          aria-labelledby=""
        >
          <Modal.Header className="border-0 pb-0" closeButton>
            <Modal.Title>Your password has been successfuly changed, please login.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className="text-end">
                <div className="resend_otp">
                  <Controls.Button
                    className="btn btn-light h-45 w-35 mt-2"
                    type="button"
                  />
                </div>
              </div>
            </div>


          </Modal.Body>
          <Modal.Footer
            style={{
              flexDirection: "row",
              columnGap: "10px",
              width: "100%",
              margin: "0 0 10px 0 !important",
              border: "none",
            }}
          >
            <Button
              className="btn btn-primary h-44 fw-medium m-2"
              style={{
                width: "100px",
                padding: "0 13px",
              }}
              onClick={(e) =>
                navigate("/admin")
              }
            >
              ok
            </Button>
          </Modal.Footer>
        </Modal>
        <AutohideExample show={shows} setShow={setShows} />
      </div>
    </main>
  );
};
export default Sidebar1;