import axios from "axios";
import { useSelector } from "react-redux";

export const IsRead = (pageName) => {
  const user = useSelector((state) => state?.auth?.admin);

  const elementIndex =
    user && user?.permission?.findIndex((item) => item.pageSlug == pageName);
  if (user?.role != 3) {
    if (elementIndex == -1) {
      return false;
    } else {
      if (
        user &&
        user.permission &&
        user.permission[elementIndex].readP == 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  else {
    return true;
  }
};

// const elementIndex = user && user.permissions.findIndex((item) => item.pageSlug == pageName);
// if (elementIndex == -1) {
//     return false;
// }
// else {
//     if (user && user.permissions && user.permissions[elementIndex].readP == 1) {
//         return true;
//     }
//     else {
//         return false;
//     }
// }
// }

export const IsWrite = (pageName) => {

  const user = useSelector((state) => state?.auth?.admin);

  const elementIndex =
    user && user?.permission?.findIndex((item) => item.pageSlug == pageName);
  if (user?.role != 3) {
    if (elementIndex == -1) {
      return false;
    } else {
      if (
        user &&
        user.permission &&
        user.permission[elementIndex].writingP == 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  else {
    return true;
  }
};

export const IsDelete = (pageName) => {
  const user = useSelector((state) => state?.auth?.admin);

  const elementIndex =
    user && user?.permission?.findIndex((item) => item.pageSlug == pageName);
  if (user?.role != 3) {
    if (elementIndex == -1) {
      return false;
    } else {
      if (
        user &&
        user.permission &&
        user.permission[elementIndex].deleteP == 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  else {
    return true;
  }
};
