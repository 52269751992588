



import axiosInstance from "../../utills/AxiosInstance";

//role list
export const RoleListApi = async (queryString) => {
  try {
    const response = await axiosInstance.get('/v1/roleList' + queryString)

    return response.data;
  } catch (error) {
    return error;
  }
};

//role Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosInstance.put(`/v1/roleStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add role
export const AddRoleData = async (payload) => {
  try {
    const response = await axiosInstance.post(`/v1/addRole`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update role
export const updateRole = async (id, payload) => {
  try {
    const response = await axiosInstance.put(`/v1/updateRole/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};



// delete role

export const deleteRole = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteRole/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }

};

export const getPagePermissionList = async (body) => {
  try {
    const res = await axiosInstance.post(`/v1/pagePermissionList`, body);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const getPageList = async () => {
  try {
    const res = await axiosInstance.get(`/v1/pageList`);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const addPermissionRole = async (body) => {
  try {
    const res = await axiosInstance.post(`/v1/addPermission`, body);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};




