
import axiosInstance from "../../utills/AxiosInstance";



//get state list

// export const getStateList = async () => {
//   try {
//     const response = await axiosInstance.get(`/v1/addState`);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };




//country List


export const getCountryLocationList = async (queryString) => {
  try {
    const response = await axiosInstance.get(`/v1/listCountry` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};


// State List
export const getStateLocationList = async (queryString,id) => {
  try {
    const response = await axiosInstance.get(`/v1/listState/${id}`+queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};


//city List


export const getCityLocationList = async (queryString,id) => {
  try {
    const response = await axiosInstance.get(`/v1/listCity/${id}`+queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};


//status update  for country
export const StatusUpdate = async (id,value) => {
  try {
    const response = await axiosInstance.put(`/v1/countryStatusChange/${id}`,value);
    return response.data;
  } catch (error) {
    return error;
  }
};




//status update  for state
export const StateStatusUpdate = async (id,value) => {
  try {
    const response = await axiosInstance.put(`/v1/stateStatusChange/${id}`,value);
    return response.data;
  } catch (error) {
    return error;
  }
};

//status update for city
export const CityStatusUpdate = async (id,value) => {
  try {
    const response = await axiosInstance.put(`/v1/cityStatusChange/${id}`,value);
    return response.data;
  } catch (error) {
    return error;


  }
};  

//add country

export const AddCountryData = async (body) => {
  try {
    const response = await axiosInstance.post(`/v1/addCountrys`,body);
    return response.data;
  } catch (error) {
    return error;


  }
};

//upadate country

export const updateCountryLocation = async (id,payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axiosInstance.put(`/v1/updateCountry/${id}`,payload,config);
    return response.data;
  } catch (error) {
    return error;


  }
};

//delete state

export const deleteState = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteState/${id}`,id);
    return response.data;
  } catch (error) {
    return error;


  }
};


//add state

export const AddStateData = async (body) => {
  try {
    const response = await axiosInstance.post(`/v1/addState`,body);
    return response.data;
  } catch (error) {
    return error;


  }
};


//upadate state

export const updateStateLocation = async (id,payload) => {
   const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axiosInstance.put(`/v1/updateState/${id}`,payload,config);
    return response.data;
  } catch (error) {
    return error;


  }
};


//delete country

export const deleteCountry = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteCountry/${id}`,id);
    return response.data;
  } catch (error) {
    return error;


  }
};


//add city

export const AddCityData = async (body) => {
  try {
    const response = await axiosInstance.post(`/v1/addCity`,body);
    return response.data;
  } catch (error) {
    return error;


  }
};


//upadate city

export const updateCityLocation = async (id,payload) => {
  try {
    const response = await axiosInstance.delete(`/v1/updateCity/${id}`,payload);
    return response.data;
  } catch (error) {
    return error;


  }
};

//delete city

export const deleteCity = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteCity/${id}`,id);
    return response.data;
  } catch (error) {
    return error;


  }
};


export const CountryData = async () => {
  try {
      const response = await axiosInstance.get(`/v1/countryDropdown`);
      

      return response.data;
  } catch (error) {
      return error;
  }
};


export const StateData = async (id) => {
  try {
      const response = await axiosInstance.get(`/v1/stateDropdown/${id}`);
      

      return response.data;
  } catch (error) {
      return error;
  }
};

export const CityData = async (id) => {
  try {
      const response = await axiosInstance.get(`/v1/cityDropdown/${id}`);
      

      return response.data;
  } catch (error) {
      return error;
  }
};