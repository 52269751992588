import React, { useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import "./educationalinformation.scss";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../utills/useForms";
import {
  getEducationLists,
} from "../../state/action/fresherAction";
import {
  addFresherDataRedux
} from "../../state/action/authAction"
import { SkillList } from "../../state/action/skillAction";
import Multiselect from "multiselect-react-dropdown";

const initialValues = {
  college: "",
  educationLevelId: "",
  studyField: "",
  eduStartDate: "",
  eduEndDate: "",
  skillId: "",
  studyRunning: 0,
};

const EducationalInformation = (props) => {

  const dispatch = useDispatch();
  const { activeTab, setActiveTab } = props;
  const [educationList, setEducationList] = useState([]);
  const [selectSkill, setSelectSkill] = useState([]);

  const [enrolled, setEnrolled] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  const [skillData, setSkillData] = useState([]);

  const skillsData = selectSkill?.map((x) => x.id);

  const multiselectSelectSkill = (data, e) => {
    setSelectSkill(data);
    setSkillData(skillData.filter((x) => x.id !== e.id));
  };


  //country Data
  const getEducationData = async (e) => {
    const res = await getEducationLists();
    if (res && res.data) {
      setEducationList(res.data);
    }
  };

  useEffect(() => {
    getEducationData();
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("college" in fieldValues)
      temp.college = fieldValues.college && fieldValues.college != null
        ? "" : "This field is required.";

    if ("educationLevelId" in fieldValues)
      temp.educationLevelId = fieldValues.educationLevelId && fieldValues.educationLevelId != null
        ? ""
        : "This field is required.";

    if ("studyField" in fieldValues)
      temp.studyField = fieldValues.studyField && fieldValues.studyField != null
        ? "" : "This field is required.";

    if ("eduStartDate" in fieldValues)
      temp.eduStartDate = fieldValues.eduStartDate && fieldValues.eduStartDate != null
        ? ""
        : "This field is required.";

    if (fieldValues.studyRunning == 0) {
      if ("eduEndDate" in fieldValues)
        temp.eduEndDate = fieldValues.eduEndDate && fieldValues.eduEndDate != null
          ? ""
          : "This field is required.";
    } else {
      temp.eduEndDate = "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const removeSkill = async (e, item) => {
    setSkillData([...skillData, item]);
    const removeSkill = selectSkill.filter((x) => x.id !== item.id);
    setSelectSkill(removeSkill);
  };


  const studyRunnings = () => {
    setEnrolled(!enrolled);
    if (!enrolled) {
      setValues({ ...values, studyRunning: 1 });
    }
    else {
      setValues({ ...values, studyRunning: 0 });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const datas = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    if (datas) {
      setValues({
        ...values,
        ...datas,
      });
      setSelectSkill(datas?.skill);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (!selectSkill || selectSkill.length == 0) {
        setErrorMessage("Please select at least one skill.");

      } else {
        const body = {
          ...datas,
          college: values.college,
          educationLevelId: values.educationLevelId,
          studyField: values.studyField,
          eduStartDate: values.eduStartDate,
          eduEndDate: values.studyRunning === 1 ? "" : values.eduEndDate,
          skillId: JSON.stringify(skillsData),
          studyRunning: values.studyRunning,
          skill: selectSkill,
        };
        const data = await dispatch(addFresherDataRedux(body));
        setActiveTab(3);
        setErrorMessage("");
      }
    }
  };

  //skilllist
  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      setSkillData(res.data);
    }
  };

  useEffect(() => {
    getSkillList();
  }, []);

  return (
    <main>
      <div className="row">
        <div className="col-lg-12 align-self-center">
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div className="form-label">College</div>
              <Controls.Input
                className="form-control"
                placeholder="Enter your college name"
                name="college"
                type="text"
                lable="College"
                onChange={handleInputChange}
                maxLength="40"
                value={values.college}
              />
              {errors.college ? (
                <p className="text-invalid">{errors.college}</p>
              ) : (
                <p
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.college}
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div className="form-label">Level of education</div>
              <select
                className="form-select"
                name="educationLevelId"
                onChange={handleInputChange}
                value={values.educationLevelId}
              >
                <option value="">None</option> 
                {educationList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              {errors.educationLevelId ? (
                <p className="text-invalid">{errors.educationLevelId}</p>
              ) : (
                <p
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.educationLevelId}
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div className="form-label">Field of study</div>
              <Controls.Input
                className="form-control"
                placeholder="Enter your field of study"
                name="studyField"
                type="text"
                lable="Field of study"
                onChange={handleInputChange}
                maxLength="70"
                value={values.studyField}
              />

              {errors.studyField ? (
                <p className="text-invalid">{errors.studyField}</p>
              ) : (
                <p
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.studyField}
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-16">
              <div className="form-label">Start date</div>
              <Controls.Input
                className="form-control"
                placeholder="Start date"
                name="eduStartDate"
                type="date"
                lable="Start date"
                onChange={(e) => {
                  setValues({
                    ...values,
                    eduStartDate: e.target.value,
                    eduEndDate: "",
                  });
                }}
                value={values.eduStartDate}
              />
              {errors.eduStartDate ? (
                <p className="text-invalid">{errors.eduStartDate}</p>
              ) : (
                <p
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.eduStartDate}
                </p>
              )}
            </div>
            <div className="col-12 col-md-6 mb-16">
              {values.studyRunning !== 1 && (
                <>
                  <div className="form-label">End date</div>
                  <Controls.Input
                    className="form-control"
                    placeholder="End date"
                    name="eduEndDate"
                    type="date"
                    lable="End date"
                    onChange={handleInputChange}
                    value={values.eduEndDate}
                    min={values.eduStartDate}
                  />
                  {errors.eduEndDate ? (
                    <p className="text-invalid">{errors.eduEndDate}</p>
                  ) : (
                    <p
                      style={{
                        color: "red",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.eduEndDate}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-3">
              <label className="custom-checkbox">
                {" "}
                Still enrolled here?
                <Controls.Input
                  type="checkbox"
                  name="studyRunning"
                  onChange={() => studyRunnings()}
                  checked={values?.studyRunning === 1 ? 1 : 0}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16">
              <div>
                <div className="col-12 col-md-12 mb-16">
                  <div className="form-label">Skills</div>

                  <Multiselect
                    options={skillData} // Options to display in the dropdown
                    selectedValues={selectSkill} // Preselected value to persist in dropdown
                    onSelect={multiselectSelectSkill} // Function will trigger on select event
                    onRemove={removeSkill} // Function will trigger on remove event
                    displayValue="title"
                    placeholder={selectSkill && selectSkill.length > 0 ? "" : "Please select the skills"}
                  />
                  {errorMessage && (
                    <div className="text-invalid">{errorMessage}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-16 mt-16">
            <Controls.Button
              className="btn btn-primary h-54 w-100"
              text="Next"
              onClick={(e) => handleSubmit(e)}
            />
          </div>
          <div className="row">
            <div className="col-12 col-md-12 mb-16 text-center">
              <p
                href=""
                className="w-100 h-54 font-16 btn btn-link text-decoration-none"
                onClick={() => setActiveTab(3)}
              >
                Skip this Step
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EducationalInformation;
