import Input from "./Input";
import Button from "./Button";
import SelectBox from "./SelectBox";
// import RadioGroup from "./RadioGroup";
import Switch from "./Switch";
// import Checkbox from "./Checkbox";
// import Date from "./Date";
const Controls = {
  Input,
  Button,
  SelectBox,
  // RadioGroup,
  Switch,
  // Checkbox,
  // Date
};

export default Controls;
