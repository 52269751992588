import React from "react";
import { useForm } from "../../utills/useForms";
import { useState, useEffect } from "react";
import {
  AddInterviewData,
  UpdatInterview,
  getinterviewLists,
} from "../../state/action/interviewaction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Controls from "../../components/controls/Controls";
import AutohideExample from "../../utills/Notification";

const initialValues = {
  title: "",
};

const Addinterview = (props) => {
  const {
    setIsEdit,
    isEdit,
    handleAddCloseSwitch,
    openAddSwitchModel,
    interviewdataList,
    updateData
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const closeAddSkillPopup = () => {
    handleAddCloseSwitch();
    resetForm();
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setIsEdit(false);
  }, []);

  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (validate()) {
      const body = {
        title: values.title,
      };

      // Edit
      if (isEdit) {
        const res = await UpdatInterview(interviewdataList?.id, body);
        if (res && res.status === true) {
          //refreshScreen();
          setShow({
            isOpen: true,
            message: res?.message,
            type: "Success",
          });
          updateData("1");
          handleAddCloseSwitch();
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
        }
      }

      // Add
      if (!isEdit) {
        const res = await AddInterviewData(body);
        if (res && res.status === true) {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          updateData("1");
          handleAddCloseSwitch();
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
        }
      }

      // handleAddCloseSwitch();
      resetForm();
    } else {
      setShow({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    }
    resetForm();
  };

  useEffect(() => {
    if (
      interviewdataList &&
      interviewdataList.id !== "" &&
      interviewdataList.id !== null &&
      interviewdataList.id !== undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        title: interviewdataList.title,
      });
    } else {
      resetForm();
    }
  }, [interviewdataList]);

  return (
    <div>
      <Modal
        size="md"
        show={openAddSwitchModel}
        onHide={closeAddSkillPopup}
        aria-labelledby=""
        centered
      >
        <form autoComplete="off">
          <Modal.Header className="border-0 pb-0" closeButton onClick={closeAddSkillPopup}></Modal.Header>
          <Modal.Body>
            <h4>Add interview</h4>
            <Controls.Input
              className="form-control font-16-24-05 h-auto py-[10px] px-3"
              placeholder="Add interview"
              name="title"
              type="text"

              value={values.title}
              onChange={handleInputChange}
            />
            {Boolean(errors.title) ? (
              <p className="text-invalid">{errors.title}</p>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button

              className="btn btn-primary h-44 fw-medium m-2"
              onClick={() => { closeAddSkillPopup(); handleAddCloseSwitch() }}

            >
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} variant="primary" className="h-auto px-3 py-2 fw-medium m-0">
              {isEdit ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <AutohideExample show={show} setShow={setShow} />
    </div>
  );
};

export default Addinterview;
