import React, { useEffect } from "react";
import { useForm } from "../../utills/useForms";
import { useState } from "react";
import {
  AddSkillData,
  UpdateSkill,
  getSkillLists,
} from "../../state/action/addskillAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Controls from "../../components/controls/Controls";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";

const initialValues = {
  title: "",
  description: "",
  bdescription: "",
  passingScore: "",
};

const AddSkill = (props) => {
  const {
    setIsEdit,
    isEdit,
    handleAddCloseSwitch,
    openAddSwitchModel,
    skillDataList,
    getSkillList,
    page,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues) {
      temp.title = !fieldValues.title ? "This field is required." : "";
    }

    if ("description" in fieldValues) {
      temp.description = !fieldValues.description
        ? "This field is required."
        : "";
    }
    if ("bdescription" in fieldValues) {
      temp.bdescription = !fieldValues.bdescription
        ? "This field is required."
        : "";
    }
    if ("passingScore" in fieldValues) {
      if (fieldValues.passingScore > 0 && fieldValues.passingScore < 101) {
        temp.passingScore = "";
      }
      else {
        temp.passingScore = "Please enter valid score";
      }

    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  const [view, setView] = useState(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setIsEdit(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const body = {
        title: values.title,
        description: values.description,
        bdescription: values.bdescription,
        percentage: values.passingScore,
      };

      if (isEdit) {
        const res = await UpdateSkill(skillDataList.id, body);
        setView(true);
        if (res && res.status === true) {

          setShow({
            isOpen: true,
            message: res?.message,
            type: "Success",
          });
          setView(false);
          handleAddCloseSwitch();
          resetForm();
          getSkillList(page, "", "");

        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      } else {
        const res = await AddSkillData(body);
        if (res && res.status === true) {

          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });

          setView(false);
          handleAddCloseSwitch();
          resetForm();
          getSkillList(1, "", "");
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }

    } else {
      setShow({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (skillDataList && skillDataList.id) {

      setValues({
        // ...values,
        title: skillDataList?.title,
        description: skillDataList?.description,
        passingScore: skillDataList?.percentage,
        bdescription: skillDataList?.bdescription,
      });

      setIsEdit(true);

    } else {

      setIsEdit(false);
      resetForm();
    }
  }, [skillDataList]);

  const closeAddSkillPopup = () => {
    handleAddCloseSwitch();
    resetForm();
  }



  return (
    <div>
      <Loader view={view} />
      <Modal
        size="md"
        show={openAddSwitchModel}
        onHide={closeAddSkillPopup}
        aria-labelledby=""
        centered
      >
        <form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Header className="border-0 pb-0" closeButton onClick={() => closeAddSkillPopup()}></Modal.Header>
          <Modal.Body>
            <h4>{isEdit ? "Edit Skill" : "Add Skill"}</h4>
            <div className="mb-3">
              <Controls.Input
                className="form-control font-16-24-05 h-auto py-[10px] px-3"
                placeholder="Add skill"
                name="title"
                type="text"
                value={values.title}
                onChange={handleInputChange}
              />
              {errors.title && (
                <p className="text-invalid">{errors.title}</p>
              )}
            </div>
            <div className="mb-3">
              <Controls.Input
                className="form-control font-16-24-05 h-auto py-[10px] px-3"
                placeholder="Add Passing Score"
                name="passingScore"
                type="number"
                value={values.passingScore}
                onChange={(e) => {
                  if (e.target.value > 101) {

                  } else {

                    handleInputChange(e);
                  }
                }}
              />
              {errors.passingScore && (
                <p className="text-invalid">{errors.passingScore}</p>
              )}
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-12 col-md-12 mb-16">
                  <textarea
                    className="form-control h-auto"
                    placeholder="Skill Description"
                    rows="4"
                    name="description"
                    maxLength={100}
                    type="text"
                    value={values?.description}
                    onChange={handleInputChange}
                  ></textarea>
                  {errors.description && (
                    <p className="text-invalid">{errors.description}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-12 col-md-12 mb-16">
                  <textarea
                    className="form-control h-auto"
                    placeholder="Assessment Description"
                    rows="4"
                    name="bdescription"
                    maxLength={500}
                    type="text"
                    value={values?.bdescription}
                    onChange={handleInputChange}
                  ></textarea>
                  {errors.bdescription && (
                    <p className="text-invalid">{errors.bdescription}</p>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"

              onClick={() => closeAddSkillPopup()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="h-auto px-3 py-2 fw-medium m-0"
              onClick={handleSubmit}
            >
              {isEdit ? "Update" : "save"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <AutohideExample show={show} setShow={setShow} />
    </div>
  );
};

export default AddSkill;
