import React, { useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InterViewerProfile2 from "../../assets/images/interviewer_profile_2.png";
import CloseIcon from "../../assets/images/close_circle.svg";
import { Helmet } from 'react-helmet'
import {
  FresherEducationUpdate,
  FresherPersonalUpdater,
  getBasicInfoLists,
  getCityLists,
  getCountryLists,
  getJobPreferLists,
  getJobTypeLists,
  getJobTypesLists,
  getStateLists,
} from "../../state/action/fresherAction";
import { useSelector } from "react-redux";
import moment from "moment";
import { Form, useForm } from "../../utills/useForms";
import { SkillList } from "../../state/action/skillAction";
import {
  employerCompanyUpdate,
  employerProfileDetails,
  employerProfileUpdate,
  getNumberEmployer,
} from "../../state/action/employerAction";
import AutohideExample from "../../utills/Notification";

const initialValues = {
  firstName: "",
  lastName: "",
  mobile: "",
  noofEmployee: "",
  companyName: "",
  website: "",
};

const EmployerProfile = () => {
  const [PersonalInformation, setPersonalInformation] = useState(false);
  const [CompanyInformation, setCompanyInformation] = useState(false);
  const [change, setChange] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileList, setProfileList] = useState({});
  const [countryList, setCountryList] = useState([]);

  const [selectSkill, setSelectSkill] = useState([]);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [skillData, setSkillData] = useState([]);
  const skillsData = selectSkill.map((x) => x.id);
  const data = useSelector((state) => state?.auth);

  const [employerList, setEmployerList] = useState(null);

  const Id = useSelector((state) => state?.auth?.user?.id);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in)$/;
        const isEmailValid = emailRegex.test(fieldValues.email);
        if (!isEmailValid) {
          temp.email = "Invalid email address. Please enter a valid email.";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("noofEmployee" in fieldValues)
      temp.noofEmployee = fieldValues.noofEmployee
        ? ""
        : "This field is required.";

    if ("mobile" in fieldValues) {
      if (fieldValues.mobile !== "" && fieldValues.mobile !== undefined) {
        const regex = /^\d{10}$/;
        if (!regex.test(fieldValues.mobile)) {
          temp.mobile = "Invalid Phone Number";
        } else {
          temp.mobile = "";
        }
      } else {
        temp.mobile = "This field is required.";
      }
    }

    if ("firstName" in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "This field is required.";

    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "This field is required.";

    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName
        ? ""
        : "This field is required.";

    if ("website" in fieldValues) {
      if (fieldValues.website != "" && fieldValues.website != undefined) {
        const regex = /^(ftp|http|https):\/\/[^ "]+$/;
        if (!regex.test(fieldValues.website)) {
          temp.website = "Invalid website";
        } else {
          temp.website = "";
        }
      } else if (fieldValues.website == "") {
        temp.website = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };



  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  //getlist
  const getProfileList = async () => {
    const res = await employerProfileDetails(Id);
    if (res && res.status === true) {
      setProfileList(res?.data);

      setValues({
        ...values,
        firstName: res?.data?.firstName,
        lastName: res?.data?.lastName,
        mobile: res?.data?.mobile,
        companyName: res?.data?.companyName,
        noofEmployee: res?.data?.noofEmployee,
        countryId: res?.data?.countryCode,
        website: res?.data?.website,
        email: res?.data?.email,
      });
    } else {
    }
  };

  useEffect(() => {
    getProfileList(Id);
  }, [Id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if( values.countryId == ""){
        setShow({
          isOpen: true,
          message: "Please select country code",
          type: "error",
        });
        return false;
      } else {
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        mobile: values.mobile,
        email: values.email,
        countryCode: values.countryId,
      };

      const res = await employerProfileUpdate(Id, payload);

      if (res && res.status === true) {
        setPersonalInformation(false);
        getProfileList(Id);
        // data();
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
        if (change) {
          window.location.reload();
          setChange(false);
        }
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
    }
  };

  const handleInputLength = (event) => {
    const maxLength = 5;
    const input = event.target;
    const value = input.value;

    if (value.length > maxLength) {
      input.value = value.slice(0, maxLength);
    }
  };

  //opening type dropdown

  const getNumberList = async () => {
    const res = await getNumberEmployer();
    if (res) {
      setEmployerList(res?.data);
    } else {
    }
  };

  useEffect(() => {
    getNumberList();
  }, []);

  //company

  const handleCompanySubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        companyName: values.companyName,
        noofEmployee: values.noofEmployee,
        website: values.website,
      };

      const res = await employerCompanyUpdate(Id, payload);

      if (res && res.status === true) {
        setCompanyInformation(false);
        getProfileList(Id);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  return (
    <main className="body-background">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Job Portal Employer Profile | Showcase Your Company and Hiring Opportunities</title>
        <meta name="description" content="Create and optimize your Job Portal employer profile to showcase your company's brand, culture, and job opportunities. Attract top tech talent and build a strong employer brand." />
      </Helmet>
      <div className="bg-cyan">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard-welcome-text py-5">
                <h3 className="mb-2 font-PTSerif-b">Profile</h3>
                <p className="mb-0 font-16-24-05">
                  You can edit and update all your information from here
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="rounded-card-head pt-3 pt-lg-5 mb-2">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05">
                Personal information
              </div>
            </div>
            <div className="col-auto">
              <a
                href="javascript:void(0)"
                className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                onClick={() => setPersonalInformation(true)}
              >
                <div className="card-head-action-icon pe-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>{" "}
                Edit
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-card mb-3 mb-md-4">
          <div className="row">
            <div className="col-12 my-2">
              <div className="row justify-content-between align-items-center">
                <div className="col-12 col-md-4 col-lg-3 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05"> Name</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.firstName} {profileList?.lastName}
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05">Email</div>
                  <div class="font-16-24-05 mb-2">{profileList?.email?.slice(0, 26)}{profileList?.email?.length > 26 ? '...' : ''}</div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05">Phone Number</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.countryCode ? `+${profileList?.countryCode} -` : ''} {profileList?.mobile}
                  </div>
                  {/* <div class="font-16-24-05 mb-2">{profileList?.mobile}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="rounded-card-head pt-3 pt-lg-5 mb-2">
          <div className="row align-items-center">
            <div className="col">
              <div className="font-PTSerif-b ps-2 font-18-24-05">
                Company information
              </div>
            </div>
            <div className="col-auto">
              <a
                href="javascript:void(0)"
                className="d-flex pe-2 btn btn-link text-decoration-none card-head-action"
                onClick={() => setCompanyInformation(true)}
              >
                <div className="card-head-action-icon pe-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>{" "}
                Edit
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-card mb-3 mb-md-4">
          <div className="row">
            <div className="col-12 my-2">
              <div className="row justify-content-between">
                <div className="col-6 col-md-4 col-lg-3 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05">Company Name</div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.companyName}
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05">
                    Current number of employees
                  </div>
                  <div class="font-16-24-05 mb-2">
                    {profileList?.noofOpeningTitle}
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 my-3 my-lg-0">
                  <div class="fw-medium font-18-24-05">Website</div>
                  {profileList && profileList?.website != "" ? (
                  <a href={profileList?.website} class="font-16-24-05 mb-2" target="self">
                    {profileList?.website?.length > 30 ? profileList?.website?.slice(0, 30) + '...' : profileList?.website?.slice(0, 30) || "-"}
                    {/* {profileList?.website?.slice(0, 30) || "-"} */}
                  </a> ) : (
                    <div class="font-16-24-05 mb-2">-</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <footer className="footer-copyright font-18-24-05 text-center py-3">
          TechFresher 2023 copyright received
        </footer> */}
      </div>
      <Modal
        size="lg"
        show={PersonalInformation}
        onHide={() => setPersonalInformation(false)}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">
                  Personal information
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-16">
                  <div className="form-label">First name</div>
                  <Controls.Input
                    className="form-control"
                    placeholder="First name"
                    name="firstName"
                    type="text"
                    lable="first name"
                    onChange={(e) => {
                      handleInputChange(e);
                      setChange(true);
                    }}
                    value={values.firstName}
                  />
                  {Boolean(errors.firstName) ? (
                    <p className="text-invalid">{errors.firstName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-16">
                  <div className="form-label">Last name</div>
                  <Controls.Input
                    className="form-control"
                    placeholder="Last name"
                    name="lastName"
                    type="text"
                    lable="last name"
                    onChange={(e) => {
                      handleInputChange(e);
                      setChange(true);
                    }}
                    value={values.lastName}
                  />
                  {Boolean(errors.lastName) ? (
                    <p className="text-invalid">{errors.lastName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="mb-16">
                  <div className="form-label">Email</div>
                  <Controls.Input
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    type="text"
                    lable="first name"
                    onChange={handleInputChange}
                    value={values.email}
                    maxLength="50"
                    disabled={values.email}
                  />
                  {Boolean(errors.email) ? (
                    <p className="text-invalid">{errors.email}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Phone number</div>
                <div className="position-relative phone-number-field">
                  <div className="phone-number-select">
                    <select
                      className="form-select"
                      name="countryId"
                      onChange={handleInputChange}
                      value={values.countryId}
                    >
                    <option value="">None</option> 
                      <option key="91" value="91">
                        + 91
                      </option>
                    </select>
                  </div>
                  <Controls.Input
                    className="form-control"
                    placeholder="Enter your phone number"
                    name="mobile"
                    type="text"
                    lable="Phone number"
                    onChange={handleInputChange}
                    error={errors.mobile}
                    helperText={errors.mobile}
                    maxLength="10"
                    value={values.mobile}
                  />
                  {Boolean(errors.mobile) ? (
                    <p className="text-invalid">{errors.mobile}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => setPersonalInformation(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        size="lg"
        show={CompanyInformation}
        onHide={() => setCompanyInformation(false)}
        aria-labelledby=""
        centered
      >
        <Form onSubmit={handleCompanySubmit}>
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Body className="px-lg-5">
            <div className="row">
              <div className="col-12 col-lg mb-16">
                <div className="font-22-28 fw-medium mb-2">
                  Company information
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Company name</div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter your company name"
                  name="companyName"
                  type="text"
                  lable="Company name"
                  onChange={handleInputChange}
                  value={values?.companyName}
                  maxLength="200"
                />
                {Boolean(errors.companyName) ? (
                  <p className="text-invalid">{errors.companyName}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">Number of employees</div>
                <select
                  className="form-select"
                  name="noofEmployee"
                  onChange={handleInputChange}
                  value={values.noofEmployee}
                >
                  <option value="">None</option> 
                  {employerList?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {Boolean(errors.noofEmployee) ? (
                  <p className="text-invalid">{errors.noofEmployee}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-16">
                <div className="form-label">
                  Company's website{" "}
                  <span className="opacity-75">(optional)</span>
                </div>
                <Controls.Input
                  className="form-control"
                  placeholder="Enter company website"
                  name="website"
                  type="url"
                  lable="Company's website (optional)"
                  onChange={handleInputChange}
                  value={values?.website}
                />
                {Boolean(errors.website) ? (
                  <p className="text-invalid">{errors.website}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-3 px-lg-5 pb-lg-4 pt-0 border-top-0">
            <Button
              variant="outline-primary"
              className="h-44 fw-medium mx-md-4"
              onClick={() => setCompanyInformation(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="h-44 fw-medium m-0"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <AutohideExample show={show} setShow={setShow} />
    </main>
  );
};

export default EmployerProfile;
