import React, { useEffect, useMemo, useState } from "react";
import CodeEditorWindow from "./Editor";
import { languageOptions } from "./languageOptions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defineTheme } from "./defineTheme";
import useKeyPress from "./useKeyPress";
import OutputWindow from "./OutputWindow";
import LanguagesDropdown from "./LanguageDropdown";
import Button from 'react-bootstrap/Button';
import Header from "./Header";
import { getQuestions, runCode, AddTest, clearTestArray } from "../../state/action/codingAssessmentAction";
import { Loader } from "../../utills/Loader";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../utills/useForms";
import { runProgram } from "../../state/action/codingAssessmentAction";
import { addIssue, submitTest } from "../../state/action/codingAssessmentAction";
import AutohideExample from "../../utills/Notification";
import { useNavigate } from "react-router-dom";
import { store } from "../../state/store";
import { useLocation } from "react-router-dom";
import CameraComponent from "../../components/CameraPermission/CameraPermission";
import { useSelector } from "react-redux";
import { logout } from "../../state/action/authAction";
import { StatusUpdate } from "../../state/action/TalentpoolAction";



const javascriptDefault = ``;
const RESET_INTERVAL_S = 2700; // 20 minutes in seconds

const Landing = () => {
    const [code, setCode] = useState(javascriptDefault);
    const [endPoint, setEndPoint] = useState("");
    const [outputDetails, setOutputDetails] = useState(null);
    const [theme, setTheme] = useState("cobalt");
    const [language, setLanguage] = useState();
    const [questionList, setQuestionList] = useState([]);
    const [position, setPosition] = useState(0);
    const [questionData, setQuestionData] = useState({});
    const [testCase, setTestCase] = useState([]);
    const [view, setView] = useState(false);
    const dispatch = useDispatch();
    const [InterestedForJobEmployer, setInterestedForJobEmployer] = useState();
    const [reportText, setReportText] = useState("");
    const [reportError, setReportError] = useState("");
    const [runCodes, setRunCode] = useState(null);
    const [hasCameraPermission, setHasCameraPermission] = useState(true);
    const [cameraPermission, setCameraPermission] = useState(true);
    const [icon, setIcon] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [InterestedForJobEmployers, setInterestedForJobEmployers] = useState(false);
    const [isFullscreen, setIsFullscreen] = React.useState(false);


    const [time, setTime] = useState(RESET_INTERVAL_S);
    const [confirmNavigation, setConfirmNavigation] = useState(false);

    const toggleFullScreen = (elem) => {
        if (
            (document.fullScreenElement !== undefined && document.fullScreenElement === null) ||
            (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
            (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
            (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)
        ) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullScreen) {
                elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            setIsFullscreen(true);
            document.body.classList.add('no-scrollbars');
            const myDiv = document.getElementById("root");
            if (myDiv) {
                myDiv.classList.add("no-scrollbars");
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
            document.body.classList.remove('no-scrollbars');
            const myDiv = document.getElementById("root");
            if (myDiv) {
                myDiv.classList.add("no-scrollbars");
            }
        }
    };
    document.addEventListener('fullscreenchange', (event) => {
        if (document.fullscreenElement) {

            // We’re going fullscreen
        } else {
            // We’re exiting fullscreen
        }
    });
    function onFullscreenChange() {
        // setInterestedForJobEmployers(!InterestedForJobEmployers);
        setIsFullscreen(Boolean(document.fullscreenElement));

        // When fullscreen is closed, reopen it and simulate a click event at the center of the screen
        if (document.fullscreenElement === null && isFullscreen) {
            toggleFullScreen(document.body);

            // Simulate a click event at the center of the screen



        }
    }
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = ''; // Required for Chrome
            }
        };

        const handleUnload = () => {
            if (hasUnsavedChanges) {
                // Optionally perform additional cleanup or save changes before leaving
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [hasUnsavedChanges])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [position]);

    

    const okButton = async () => {
        let keyPressCount = localStorage.getItem('keyPressCount');

        // If keyPressCount is null or undefined, set it to 0
        if (keyPressCount === null || keyPressCount === undefined) {
            keyPressCount = 0;
        } else {
            // Convert the retrieved value to a number
            keyPressCount = Number(keyPressCount);
        }

        // Increment keyPressCount by 1
        keyPressCount += 1;
        if (keyPressCount >= 3) {
            localStorage.removeItem('keyPressCount');
            const res = await StatusUpdate(user && user?.id);
            toggleFullScreen(document.body)

            if (res && res.status == true) {
                window.location.reload();
                dispatch(logout());

            }
            //     Navigate("/")
        }

        // Update the value in local storage
        localStorage.setItem('keyPressCount', keyPressCount);

        // Set interestedForJobEmployers to false (assuming it's a state variable)
        setInterestedForJobEmployers(false);
    }

    const handleKeyDown = (e) => {
        const charCode = e.charCode || e.keyCode || e.which;
        const data = localStorage.getItem('keyPressCount');
        if (charCode === 27 || charCode === 192) {
            e.preventDefault(); // Prevent the default behavior of the "Escape" key
            // Handle the "Escape" key press as needed
        }
        if (e.key === "Escape" && isFullscreen) {

            e.preventDefault(); // Prevent default behavior (closing fullscreen)
        }

        if (charCode === 18 || charCode === 9) {
            e.preventDefault(); // Prevent the default behavior of the "Escape" key
            // Handle the "Escape" key press as needed
        }

        if (charCode === 18 && charCode === 9) {
            e.preventDefault(); // Prevent the default behavior of the "Escape" key
            // Handle the "Escape" key press as needed
        }

        if (charCode === 9) {
            e.preventDefault(); // Prevent the default behavior of the "Escape" key
            // Handle the "Escape" key press as needed
        }


        if (e.ctrlKey || e.metaKey || e.key === 'Tab') {
            setInterestedForJobEmployers(true);
            e.preventDefault();
        }

        if (e.ctrlKey || e.metaKey || e.key === 'Alt') {
            e.preventDefault();
            setInterestedForJobEmployers(true);
        }

    };



    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isFullscreen]);

    const { user } = useSelector((state) => state?.auth);



    // Watch for fullscreenchange
    useEffect(() => {

        toggleFullScreen(document.body)
        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);
    useEffect(() => {
        if (isFullscreen == false) {
            toggleFullScreen(document.body)
        }
    }, [isFullscreen]);

    const [show, setShow] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    const enterPress = useKeyPress("Enter");
    const ctrlPress = useKeyPress("Control");
    const { state } = useLocation();
    const Navigate = useNavigate();
    const onSelectChange = (sl) => {
        setLanguage(sl);

        setTestCase(questionList[position]?.answer)
        setRunCode(false)
        switch (sl.value) {
            case "javascript":
                setCode(questionData?.js || "");
                // setCode("javascript")
                setEndPoint(questionData?.endPointJs);
                break;
            case "python":
                setCode(questionData?.python || "");
                // setCode("python")
                setEndPoint(questionData?.endPointPython);
                break;
            case "java":
                setCode(questionData?.java || "");
                // setCode("java")
                setEndPoint(questionData?.endPointJava);
                break;
            case "c":
                setCode(questionData?.c || "");
                // setCode("c")
                setEndPoint(questionData?.endPointC);
                break;
            case "cpp":
                setCode(questionData?.c1 || "");
                // setCode("cpp")
                setEndPoint(questionData?.endPointC1);
                break;
            case "csharp":
                setCode(questionData?.c2 || "");
                setEndPoint(questionData?.endPointC2);
                // setCode("csharp")
                break;
            default:
                setCode("");
                setEndPoint("");
                break;
        }
    };
    const handleSubmits = async (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        //time out then submit test
        if (time == 0) {
            handelSubmit();
        }
    }, [time]);





    // /when page refresh then navigate to / page 
    useEffect(() => {
        window.onbeforeunload = function () {
            Navigate("/");
            return "Are you sure you want to leave?";
        };
        return () => {

            window.onbeforeunload = null;
        };

    }, []);

    const handelReportSubmit = async () => {
        const payload = {
            report: reportText,
            questionId: questionData.id,
        }
        if (!reportError && reportError.length == 0 && reportText.length > 0) {
            const res = await addIssue(payload);
            if (res && res.status == true) {
                setShow({
                    isOpen: true,
                    message: "Reported Successfully",
                    type: "success",
                });
                setInterestedForJobEmployer(false)
                setReportError("");
                setReportText("");
            } else {
                setReportText("");
            }
        } else {
            setReportError("Please enter issue");
        }
    }

    const clickOnNext = async () => {

        setView(true);
        setCode(questionList[position + 1]?.js || "");
        setLanguage(languageOptions[0]);

        const formData = new FormData();
        formData.append("code_file", `${code} \n${endPoint}`);
        formData.append("language", language?.title == "c#" ? "csharp" : language?.title);
        formData.append("no_cases", testCase.length);

        let sk = testCase.map((item, index) => {
            formData.append(`input${index + 1}`, item.testcase.map((item) => {
                return item.inputValues
            }
            ).join('\n'));
            formData.append(`output${index + 1}`, item.output);
            formData.append(`cases${index + 1}_input`, item.testcase.map((item) => {
                return item.inputValues
            }
            ).join('\n'));
        })
        const runCodeRes = await runProgram(formData);

        if (runCodeRes && runCodeRes?.status) {
            setOutputDetails(runCodeRes?.data);
            // showSuccessToast();
            setTestCase(testCase.map((item, i) => {
                return {
                    ...item,
                    codeOutput: runCodeRes?.data[i].your_output,
                    result: runCodeRes?.data[i].result,
                }
            }
            ))
            setIcon(!icon)


        }
        else {
            // showErrorToast();
            setIcon(!icon)

        }

        if (runCodeRes) {
            setQuestionData(questionList[position + 1])
            window.scrollTo(0, 0);
            setPosition(position + 1);
            setRunCode(null);
            setEndPoint(questionList[position + 1]?.endPointJs);
            let testCase = {
                testCase1: runCodeRes?.data[0]?.result == "pass" ? true : false,
                testCase2: runCodeRes?.data[1]?.result == "pass" ? true : false,
                testCase3: runCodeRes?.data[2]?.result == "pass" ? true : false,
                testCase4: runCodeRes?.data[3]?.result == "pass" ? true : false,
                testCase5: runCodeRes?.data[4]?.result == "pass" ? true : false,
            }
            setTestCase(questionList[position + 1]?.answer)
            handleQuestionChanges(questionList[position], testCase);
            setTimeout(() => {
                setView(false);
            }, 2000);
        }


    };
    const handleQuestionChanges = async (data, testData) => {
        let payload = {};
        payload = {
            questionId: data?.id,
            testCase: testData,
            rating: 1
        }


        await dispatch(AddTest(payload));
        // setRunCode(true)
    };

    const condeRunFunction = async () => {
        try {

            setView(true);
            setLanguage(language);
            const formData = new FormData();
            formData.append("code_file", `${code} \n${endPoint}`);
            formData.append("language", language?.title == "c#" ? "csharp" : language?.title);
            formData.append("no_cases", testCase.length);

            let sk = testCase.map((item, index) => {
                formData.append(`input${index + 1}`, item.testcase.map((item) => {
                    return item.inputValues
                }
                ).join('\n'));
                formData.append(`output${index + 1}`, item.output);
                formData.append(`cases${index + 1}_input`, item.testcase.map((item) => {
                    return item.inputValues
                }
                ).join('\n'));
            })
            const runCodeRes = await runProgram(formData);
            if (runCodeRes && runCodeRes?.status) {
                setView(false);

                setOutputDetails(runCodeRes?.data);
                showSuccessToast();
                setTestCase(testCase.map((item, i) => {
                    return {
                        ...item,
                        codeOutput: runCodeRes?.data[i].your_output,
                        result: runCodeRes?.data[i].result,
                    }
                }
                ))

                setIcon(!icon)

            }
            else {
                showErrorToast();
                setView(false);
                setIcon(!icon)


            }
            setRunCode(true)



        } catch (error) {
            setLanguage(language);

        }
        return runCodes;
    }

    const getQuestionList = async () => {
        try {
            const response = await getQuestions();

            if (response && response?.status) {
                setQuestionList(response?.data);
                setQuestionData(response?.data[position])
                setCode(response?.data[position]?.js || "");
                setEndPoint(response?.data[position]?.endPointJs);
                setTestCase(response?.data[position]?.answer)
            }
            else {
                setQuestionList([])
            }
        } catch (error) {
        }

    }

    useEffect(() => {
        getQuestionList();
        setLanguage(languageOptions[0]);
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (!e.returnValue) {
                window.location.href = '/'; // Replace with the desired URL
}
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (enterPress && ctrlPress) {
            handleCompile();
        }
    }, [ctrlPress, enterPress]);
    const onChange = (action, data) => {
        switch (action) {
            case "code": {
                setCode(data);
                break;
            }
            default: {
            }
        }
    };
    const handleCompile = () => {
        // We will come to the implementation later in the code
    };
    useEffect(() => {
        defineTheme("oceanic-next").then((_) =>
            setTheme({ value: "oceanic-next", label: "Oceanic Next" })
        );
    }, []);

    const showSuccessToast = (msg) => {
        toast.success(msg || `Compiled Successfully!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const showErrorToast = (msg) => {
        toast.error(msg || `Something went wrong! Please try again.`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Alt' || e.key === 'Tab') {
                e.preventDefault(); // Prevent the default behavior for Alt and Tab
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []); //

    const handelSubmit = async () => {
        let payload = {};
        setView(true);

        const formData = new FormData();
        formData.append("code_file", `${code} \n${endPoint}`);
        formData.append("language", language?.title == "c#" ? "csharp" : language?.title);
        formData.append("no_cases", testCase.length);

        let sk = testCase.map((item, index) => {
            formData.append(`input${index + 1}`, item.testcase.map((item) => {
                return item.inputValues
            }
            ).join('\n'));
            formData.append(`output${index + 1}`, item.output);
            formData.append(`cases${index + 1}_input`, item.testcase.map((item) => {
                return item.inputValues
            }
            ).join('\n'));
        })
        const runCodeRes = await runProgram(formData);
        if (runCodeRes && runCodeRes?.status) {
            setOutputDetails(runCodeRes?.data);
            setTestCase(testCase.map((item, i) => {
                return {
                    ...item,
                    codeOutput: runCodeRes?.data[i].your_output,
                    result: runCodeRes?.data[i].result,
                }
            }
            ))
            setIcon(!icon)

            setView(false);

        }
        else {
            setView(false);
            setIcon(!icon)


        }
        if (runCodeRes) {
            payload = {
                questionId: questionList && questionList[position] && questionList[position]?.id,
                testCase: {
                    testCase1: runCodeRes && runCodeRes?.data[0]?.result == "pass" ? true : false,
                    testCase2: runCodeRes && runCodeRes?.data[1]?.result == "pass" ? true : false,
                    testCase3: runCodeRes && runCodeRes?.data[2]?.result == "pass" ? true : false,
                    testCase4: runCodeRes && runCodeRes?.data[3]?.result == "pass" ? true : false,
                    testCase5: runCodeRes && runCodeRes?.data[4]?.result == "pass" ? true : false,
                },
                rating: 1
            }
            await dispatch(AddTest(payload));
            setReportText("");

            const { codingAssessment } = store.getState();
            let reduxData = codingAssessment.test;

            const payload1 = {
                question: reduxData,
                totalTime: 2700 - Number(time),
                skillId: 0,
                totalQuestion: questionList.length,
            };

            const res = await submitTest(payload1);

            if (res && res.status === true) {
                dispatch(clearTestArray());
                Navigate("/coddingresult", { state: { result: 1, isCoding: 1 } });
            } else {
            }
        }
    };

    useEffect(() => {
        if (state == null) {
            window.location.href = "/";
        }

    }, [state])



    const [subMenu, setSubMenu] = useState(false);
    const memoizedCodeEditorWindow = useMemo(() => (

        <CodeEditorWindow
            code={code}
            setCode={setCode}
            onChange={onChange}
            language={language?.value || "javascript"}
            theme="vs"
        />

    ), [code]);
    return (
        <>
            <Header time={time} setTime={setTime} RESET_INTERVAL_S={RESET_INTERVAL_S} />
            <Loader view={view} />
            <CameraComponent setHasCameraPermission={setHasCameraPermission} cameraPermission={cameraPermission} setCameraPermission={setCameraPermission} />


            {!view && (
                <div className="container-z border_center scroll_hide" style={{
                    background: '#fff !important',
                    overflow: 'auto !important'
                }} onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }} onPaste={(e) => { e.preventDefault(); }} onCut={(e) => { e.preventDefault(); }}>
                    <div className="row p-4 scroll_hide" style={{
                        background: '#fff !important',
                        overflow: 'auto !important'
                    }}>
                        <div className="col-md-6">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div class="h5 mb-0">Question {position + 1} of {questionList?.length}</div>
                                </div>
                            </div>

                            <div className="col-md-12" >
                                {
                                    questionData && questionData?.description &&
                                    <div dangerouslySetInnerHTML={{ __html: questionData?.description }}></div>
                                }
                            </div>
                            <div className="col-md-12">
                                <div className="p-3 mt-3" style={{ background: '#fff', width: 'fit-content' }}>
                                    <div className="h6">Are you facing any problems?</div>
                                    <Button variant="outline-primary" size="sm" onClick={() => setInterestedForJobEmployer(true)}>
                                        Report Problem
                                    </Button>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <ToastContainer
                                position="top-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <div className="h-4 w-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-1000"></div>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex align-items-center justify-content-end py-2">
                                    <div class="h5 mb-0">
                                        Language : &nbsp;
                                    </div>
                                    <LanguagesDropdown onSelectChange={onSelectChange} language={language} />
                                </div>
                            </div>
                            <div className="flex flex-row space-x-4 items-start">
                                <div className="flex flex-col w-full h-full justify-start items-end">
                                    {memoizedCodeEditorWindow}
                                </div>

                                <div className="right-container flex flex-shrink-0 w-[30%] flex-col position-relative">
                                    <OutputWindow outputDetails={outputDetails}
                                        testCase={testCase}
                                        setTestCase={setTestCase}
                                        condeRunFunction={condeRunFunction}
                                        clickOnNext={clickOnNext}
                                        runCode={runCodes}
                                        position={position}
                                        icon={icon}
                                        setIcon={setIcon}
                                        handelSubmit={handelSubmit}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            )}

            <Modal
                size="md"
                show={InterestedForJobEmployer}
                onHide={() => {
                    setInterestedForJobEmployer(false);
                    setReportText("")
                    setReportError("")
                }}
                aria-labelledby=""
                centered
            >
                <Form onSubmit={handleSubmits}>
                    <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
                    <Modal.Body>
                        <p className="mb-0 font-18-24-05 fw-medium">
                            Are you want to report this question?
                        </p>
                        <p className="mb-0 font-16-24-05">
                            Please tell us what problem you are facing in this question.
                        </p>
                        <div className="mt-3">
                            <Controls.Input
                                className="form-control"
                                name="fresherReason"
                                type="text"
                                value={reportText}
                                onChange={(e) => {
                                    if (e.target.value.length > 0) {
                                        setReportError("")
                                        setReportText(e.target.value)
                                    } else {
                                        setReportText(e.target.value)
                                        setReportError("Please enter issue")
                                    }

                                }}
                                rows="4"
                            />
                        </div>
                        {reportError && (
                            <p className="text-danger font-12-24-05 mt-2">
                                {reportError}
                            </p>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="border-0 pt-0">
                        <Button
                            className="btn btn-primary h-44 fw-medium m-2"
                            type="submit"
                            onClick={() => {
                                handelReportSubmit();

                            }}
                        >
                            Yes, Report
                        </Button>
                        <Button
                            className="btn btn-primary h-44 fw-medium m-2"
                            onClick={() => {
                                setInterestedForJobEmployer(false)
                                setReportText("")
                            }}
                        >
                            No, Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <AutohideExample show={show} setShow={setShow} />
            <Modal
                show={InterestedForJobEmployers}
                onHide={false}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <Form onSubmit={handleSubmits}>

                        <div className="d-flex justify-content-center flex-column align-items-center p-4">
                        <Modal.Title>Warning ({localStorage.getItem('keyPressCount') == null ? 1 : Number(localStorage.getItem('keyPressCount')) +1} / 3) : Tab Switch Detected</Modal.Title>
                            <p className="mt-3">Leaving the assessment tab will result in a ban from our platform. This action has been logged and may be reviewed for potential misconduct.</p>
                            <Button
                                variant="outline-primary"
                                className="fw-medium mt-4 px-4 py-2"
                                onClick={() => {
                                    okButton();
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    );
};
export default Landing;