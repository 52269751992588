import React from "react";

export default function Switches(props) {
    const { name, label, value, onChange, checked } = props;
    return (
        <input
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
            {...props}
        />
    )
}