import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import TechfreshrLogo from "../../assets/images/techfresher-logo.svg";
import { getQuestionList, AddTest, clearTestArray, testSubmit } from "../../state/action/assessmentAction";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../state/store";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../utills/Loader";
import RedirectWithCountdown from './RedirectWithCountdown';
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./redirect.css"
import { Form, useForm } from "../../utills/useForms";
import Controls from "../../components/controls/Controls";
import { BsArrowRightShort } from "react-icons/bs";
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import { reportSubmit } from "../../state/action/reportAction";
import AutohideExample from "../../utills/Notification";
import CameraComponent from "../../components/CameraPermission/CameraPermission";
import { StatusUpdate } from "../../state/action/TalentpoolAction";
import { async } from "videojs-record";
import { logout } from "../../state/action/authAction";



const RESET_INTERVAL_S = 1200; // 20 minutes in seconds

const MCQAssessment = () => {
    const toggleFullScreen = (elem) => {
        if (
            (document.fullScreenElement !== undefined && document.fullScreenElement === null) ||
            (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
            (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
            (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)
        ) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullScreen) {
                elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            setIsFullscreen(true);
            elem.style.backgroundColor = "white";
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };
    document.addEventListener('fullscreenchange', (event) => {
        if (document.fullscreenElement) {

        } else {
            // We’re exiting fullscreen
        }
    });
    const { state } = useLocation();
    const [keyPressCount, setKeyPressCount] = useState(localStorage.getItem('keyPressCount') || 0);
    const [userId, setUserId] = useState(0);
    const [reportError, setReportError] = useState("");


    const Timer = ({ time }) => {
        const timeRemain = RESET_INTERVAL_S - (time % RESET_INTERVAL_S);

        return (
            <>
                <div
                    style={{
                        marginBottom: "auto",
                        marginTop: "auto",
                    }}
                >
                    {" "}
                    {formatTime(time)}
                </div>
            </>
        );
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isFullscreen, setIsFullscreen] = React.useState(false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = ''; // Required for Chrome
            }
        };

        const handleUnload = () => {
            if (hasUnsavedChanges) {
                // Optionally perform additional cleanup or save changes before leaving
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [hasUnsavedChanges])


    const okButton = async () => {
        let keyPressCount = localStorage.getItem('keyPressCount');

        // If keyPressCount is null or undefined, set it to 0
        if (keyPressCount === null || keyPressCount === undefined) {
            keyPressCount = 0;
        } else {
            // Convert the retrieved value to a number
            keyPressCount = Number(keyPressCount);
        }

        // Increment keyPressCount by 1
        keyPressCount += 1;
        if (keyPressCount >= 3) {
            localStorage.removeItem('keyPressCount');
            const res = await StatusUpdate(user && user?.id);
            toggleFullScreen(document.body)

            if (res && res.status == true) {
                window.location.reload();
                dispatch(logout());

            }
        }

        // Update the value in local storage
        setTimeout(() => {
            localStorage.setItem('keyPressCount', keyPressCount);
        }, 300);

        setInterestedForJobEmployers(false);
    }

    const handleKeyDown = (e) => {
        const charCode = e.charCode || e.keyCode || e.which;
        const data = localStorage.getItem('keyPressCount');
        if (charCode === 27 || charCode === 192) {
            e.preventDefault(); // Prevent the default behavior of the "Escape" key
        }
        if (e.key === "Escape" && isFullscreen) {

            e.preventDefault(); // Prevent default behavior (closing fullscreen)
        }

        if (charCode === 18 || charCode === 9) {
            e.preventDefault(); // Prevent the default behavior of the "Escape" key
            // Handle the "Escape" key press as needed
        }

        if (charCode === 18 && charCode === 9) {
            e.preventDefault(); // Prevent the default behavior of the "Escape" key
            // Handle the "Escape" key press as needed
        }

        if (charCode === 9) {
            e.preventDefault(); // Prevent the default behavior of the "Escape" key
            // Handle the "Escape" key press as needed
        }


        if (e.ctrlKey || e.metaKey || e.key === 'Tab') {
            setInterestedForJobEmployers(true);
            e.preventDefault();
        }

        if (e.ctrlKey || e.metaKey || e.key === 'Alt') {
            e.preventDefault();
            setInterestedForJobEmployers(true);
        }

    };



    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isFullscreen]);

    const { user } = useSelector((state) => state?.auth);

    useEffect(() => {
        if (user && user?.id == 1) {
            setUserId(user?.id);
        }
    }, [user]);



    const [isVisible, setIsVisible] = useState(!document.hidden);

    useEffect(() => {
        // Handler to call on visibility change
        const handleVisibilityChange = () => {
            setIsVisible(!document.hidden);
        };

        // Set up the event listener
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);




    const [questionData, setQuestionData] = useState({});
    const [exam, setExam] = useState([]);
    const [time, setTime] = useState(RESET_INTERVAL_S);
    const [activeTab, setActiveTab] = useState(-1);
    const [view, setView] = useState(false);
    const [view1, setView1] = useState(false);
    const [position, setPosition] = useState(0);
    const [countDownShow, setCountDownShow] = useState(false);
    const [skillIds, setSkillIds] = useState("");
    const [InterestedForJobEmployer, setInterestedForJobEmployer] = useState(false);
    const [InterestedForJobEmployers, setInterestedForJobEmployers] = useState(false);

    const [reportText, setReportText] = useState("");
    const [examType, setExamType] = useState("");
    const [skillName, setSkillName] = useState("");
    const [hasCameraPermission, setHasCameraPermission] = useState(true);
    const [cameraPermission, setCameraPermission] = useState(true);

    function onFullscreenChange() {
        // setInterestedForJobEmployers(!InterestedForJobEmployers);
        setIsFullscreen(Boolean(document.fullscreenElement));

        // When fullscreen is closed, reopen it and simulate a click event at the center of the screen
        if (document.fullscreenElement === null && isFullscreen) {
            toggleFullScreen(document.body);

            // Simulate a click event at the center of the screen



        }
    }



    // Watch for fullscreenchange
    useEffect(() => {

        toggleFullScreen(document.body)
        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    const [show, setShow] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    const dispatch = useDispatch();
    const Navigate = useNavigate();

    let timerId = null;
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime((prevTime) => Math.max(prevTime - 1, 0));
        }, 1000);

        if (time === 0) {
            clearInterval(timerId);
            handelSubmit();
            // Clear the interval
            // history.push('/new-route'); // Navigate to a new route
        }

        return () => clearInterval(timerId);
    }, [time]);

    useEffect(() => {
        if (state && state?.skillId) {
            getQuestionListData(state && state?.skillId, state && state?.isSoftSkill)
            setExamType(state && state?.isSoftSkill == true ? 1 : 2);
            setSkillName(state && state?.skillName);
        } else {
            Navigate("/");
        }
        if (state && state?.skillId) {
            setSkillIds(state?.skillId);
        }
    }, []);

    //when not open full screen then open full screen
    useEffect(() => {
        if (isFullscreen == false) {
            toggleFullScreen(document.body)
        }
    }, [isFullscreen]);



    const clickOnNext = () => {
        setView(true);
        let index = exam[position + 1] && exam[position + 1].options && exam[position + 1].options.findIndex((item) => item.isTrues == true);
        setActiveTab(index);
        setQuestionData(exam[position + 1]);
        window.scrollTo(0, 0);
        setPosition(position + 1);
        handleQuestionChanges(1);

        setTimeout(() => {
            setView(false);
        }, 200);
    };

    const clickOnPre = () => {
        setView(true);
        setQuestionData(exam[position - 1]);
        window.scrollTo(0, 0);
        setPosition(position - 1);

        let index = exam[position - 1].options.findIndex((item) => item.isTrues == true);
        setTimeout(() => {
            setView(false);
        }, 200);
        setActiveTab(index);
    };

    const getQuestionListData = async (skillId, isSoftSkill) => {
        setView1(true);
        let payload = {}
        if (isSoftSkill == true) {
            payload = {
                'queType': 1,
                'skillId': skillId,
            }
        }
        else {
            payload = {
                'queType': 2,
                'skillId': skillId,
            }
        }

        const res = await getQuestionList(payload);
        if (res && res.status === true) {

            res.data.map((item, index) => {
                item.options.map((item1, index1) => {
                    item1.isTrues = false;
                })
            })

            setExam(res.data);
            setQuestionData(res.data[position]);
            setView1(false);
        } else {
            setView(false);
            setCountDownShow(true);

        }
    };

    const handleQuestionChanges = async (e) => {
        let payload = {};
        payload = {
            questionId: questionData.id || "",
            optionsId:
                (questionData &&
                    questionData.options &&
                    questionData.options.filter((item) => item.isTrues) &&
                    questionData.options.filter((item) => item.isTrues)[0] &&
                    questionData.options.filter((item) => item.isTrues)[0].id) ||
                "",
        };
        await dispatch(AddTest(payload));
        setReportText("");
    };

    const handelAnswerChanges = (i) => {
        setActiveTab(i);
    };

    const handleRadioButtonChange = (e, id) => {
        const data =
            questionData &&
            questionData.options &&
            questionData.options.map((item) => {
                if (item.id == id) {
                    item.isTrues = true;
                } else {
                    item.isTrues = false;
                }
                return item;
            });
        setQuestionData({ ...questionData, options: data });
    };

    const handelSubmit = async () => {
        setView1(true);
        let payload = {};
        payload = {
            questionId: questionData.id || "",
            optionsId:
                (questionData &&
                    questionData.options &&
                    questionData.options.filter((item) => item.isTrues) &&
                    questionData.options.filter((item) => item.isTrues)[0] &&
                    questionData.options.filter((item) => item.isTrues)[0].id) ||
                "",
        };

        await dispatch(AddTest(payload));
        setReportText("");

        const { test } = store.getState();
        let reduxData = test.test;

        const payload1 = {
            question: reduxData,
            examType: examType,
            totalTime: 1200 - Number(time),
            skillId: skillIds
        };

        const res = await testSubmit(payload1);

        if (res && res.status === true) {
            toggleFullScreen(document.body)
            // window.matchMedia('(display-mode: fullscreen)').addEventListener('change', ({ matches }) => {
            //     if (matches) {
            //         window.isFullScreen = true;
            //     } else {
            //         window.isFullScreen = false;
            //     }
            // });
            dispatch(clearTestArray());
            setView1(false);
            Navigate("/result", { state: { result: 1 } });
        } else {
            setView1(false);
        }
    };
    const handleSubmits = async (e) => {
        e.preventDefault();


    };

    useEffect(() => {

        window.history.pushState(null, document.title, window.location.href);

        const handleBackButton = (event) => {
            event.preventDefault();
            window.history.pushState(null, document.title, window.location.href);
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };


    }, []);

    // useEffect(() => {
    //     navigator.mediaDevices.getUserMedia({ video: true });

    // }, []);

    const handelReportSubmit = async () => {

        const payload = {
            report: reportText,
            questionId: questionData.id,
        }

        const res = await reportSubmit(payload);
        if (res && res.status == true) {
            setShow({
                isOpen: true,
                message: "Reported Successfully",
                type: "success",
            });
            setInterestedForJobEmployer(false);
            setReportText("");
        } else {
            setReportText("");
        }
    }








    return (


        <main className="mcq-height-wrap" onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }} onPaste={(e) => { e.preventDefault(); }} >
            <CameraComponent setHasCameraPermission={setHasCameraPermission} cameraPermission={cameraPermission} setCameraPermission={setCameraPermission} />

            <Loader view={view} />

            {
                view1 ? ("") : (
                    <>
                        <div className="container-fluid" id="scrollToTop">
                            <header className="row header-logo-wrap justify-content-between align-items-center header-row-wrap" style={{ backgroundColor: "white" }} >
                                <div className="col-auto col-md-4">
                                    <a
                                        href="javascript:void(0)"
                                        className="d-inline-block"
                                    >
                                        {/* <img
                                            src={TechfreshrLogo}
                                            className="img-fluid header-logo-width"
                                        /> */}
                                        <h1 style={{ color: "black" }} className="font-PTSerif-b">Job Portal</h1>

                                    </a>
                                </div>
                                <div className="col-auto col-md-4 text-center">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="header-center-wrap">
                                            <div className="font-16-24-05 fw-bold text-primary">
                                                <h3><Timer time={time} /></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="">
                                        <div className="row justify-content-between align-items-center">
                                            <div className="header-center-wrap text-end d-flex justify-content-end" style={{ gap: '10px' }}>
                                                {position < exam.length - 1 ? (
                                                    <>
                                                        {position != 0 ? (
                                                            <button
                                                                className="btn btn-outline-primary fw-medium"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseExample"
                                                                aria-expanded="false"
                                                                aria-controls="collapseExample"
                                                                onClick={() => clickOnPre()}
                                                            >
                                                                <div className="d-flex align-items-center p-1" style={{ gap: '10px' }}>
                                                                    <MdOutlineArrowBack style={{ fontSize: '14px' }} />
                                                                    <div style={{ lineHeight: '14px' }} onClick={() => clickOnNext()}
                                                                    >Previous</div>
                                                                </div>
                                                            </button>) : null}
                                                        <button
                                                            className="btn btn-primary fw-medium"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseExample"
                                                            aria-expanded="false"
                                                            aria-controls="collapseExample"
                                                            onClick={() => clickOnNext()}
                                                        >
                                                            <div className="d-flex align-items-center p-1" style={{ gap: '10px' }}>
                                                                <div style={{ lineHeight: '14px' }} onClick={() => clickOnNext()}
                                                                >Next</div>
                                                                <MdOutlineArrowForward style={{ fontSize: '14px' }} />
                                                            </div>
                                                        </button>

                                                    </>) : (<>
                                                        {position != 0 ? (
                                                            <button
                                                                className="btn btn-outline-primary fw-medium"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseExample"
                                                                aria-expanded="false"
                                                                aria-controls="collapseExample"
                                                                onClick={() => clickOnPre()}
                                                            >
                                                                <div className="d-flex align-items-center p-1" style={{ gap: '10px' }}>
                                                                    <MdOutlineArrowBack style={{ fontSize: '14px' }} />
                                                                    <div style={{ lineHeight: '14px' }} onClick={() => clickOnNext()}
                                                                    >Previous</div>
                                                                </div>
                                                            </button>) : null}

                                                        <button
                                                            className="btn btn-primary fw-medium"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseExample"
                                                            aria-expanded="false"
                                                            aria-controls="collapseExample"
                                                            onClick={() => handelSubmit()}
                                                        >
                                                            <div className="d-flex align-items-center p-1" style={{ gap: '10px' }}>
                                                                <div style={{ lineHeight: '14px' }} onClick={() => clickOnNext()}
                                                                >Submit</div>
                                                                <MdOutlineArrowForward style={{ fontSize: '14px' }} />
                                                            </div>
                                                        </button>
                                                    </>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div >
                        {
                            view ? ("") : (
                                <>

                                    <div className="container-fluid h-100" style={{ background: "aliceblue" }}>
                                        {
                                            questionData && questionData.question && (
                                                <div className="row h-100">
                                                    <div className="col-md-6 px-lg-4 pt-4">
                                                        <div className="row h-100">
                                                            <div className="col-12">
                                                                <div className="row mb-16">
                                                                    <div className="col">
                                                                        <div className="font-18-24-05 fw-semibold">
                                                                            {skillName}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="font-16-24-05 fw-medium text-gray">Question {position + 1} of {exam.length}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="font-18-24-05">{questionData.question}</div>
                                                                    </div>
                                                                </div>
                                                                {questionData && questionData.code && questionData.code != "<p></p>" && (
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="font-18-24-05 card p-4" dangerouslySetInnerHTML={{ __html: questionData?.code }}>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="d-none d-md-block col-12 align-self-end p-lg-3">
                                                                <p className="font-16-24-05 mb-2">Are you facing any problems?</p>
                                                                <Button variant="outline-primary" className="h-44 fw-medium" onClick={() => setInterestedForJobEmployer(true)}
                                                                >Report Problem</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 px-lg-4 pt-4 border-start">
                                                        <div className="row mb-16">
                                                            <div className="col">
                                                                <div className="font-18-24-05 fw-semibold">Select one answer</div>
                                                            </div>
                                                        </div>
                                                        {questionData?.options?.map((item, index) => {
                                                            return (<>
                                                                <div className="row" onClick={() => {
                                                                    handleRadioButtonChange(item, item.id)
                                                                    handelAnswerChanges(index)
                                                                }
                                                                }>
                                                                    <div className="col-12 mb-16">
                                                                        <div className={`answer-option-wrap ${activeTab == index ? "active" : ""}`}>
                                                                            <div className="answer-option-inner">
                                                                                <div className="row">
                                                                                    <div className="col-auto">
                                                                                        <div className="font-PTSerif-b option-count">{index == 0 ? "A" : index == 1 ? "B" : index == 2 ? "C" : index == 3 ? "D" : ""} </div>
                                                                                    </div>
                                                                                    <div className="col px-1 align-self-center">
                                                                                        <div className="font-18-24-05 option-content pb-0 pt-1">
                                                                                            {item.answer}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div >
                                                            </>)
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div >
                                </>
                            )}</>
                )}

            {countDownShow == true && <RedirectWithCountdown />}
            <Modal
                show={InterestedForJobEmployers}
                onHide={false}
                backdrop="static"
                keyboard={false}
                centered
            >
                {/* <Modal.Header >
                </Modal.Header> */}
                <Modal.Body>
                    <Form onSubmit={handleSubmits}>

                        <div className="d-flex justify-content-center flex-column align-items-center p-4">
                            <Modal.Title>Warning ({localStorage.getItem('keyPressCount') == null ? 1 : Number(localStorage.getItem('keyPressCount')) + 1} / 3) : Tab Switch Detected</Modal.Title>
                            <p className="mt-3">Leaving the assessment tab will result in a ban from our platform. This action has been logged and may be reviewed for potential misconduct.</p>
                            <Button
                                variant="outline-primary"
                                className="fw-medium mt-4 px-4 py-2"
                                onClick={() => {
                                    okButton();
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                show={InterestedForJobEmployer}
                onHide={() => {
                    setInterestedForJobEmployer(false);
                    setReportText("")
                    setReportError("")
                }}
                aria-labelledby=""
                centered
            >
                <Form onSubmit={handleSubmits}>
                    <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
                    <Modal.Body>
                        <p className="mb-0 font-18-24-05 fw-medium">
                            Are you want to report this question?
                        </p>
                        <p className="mb-0 font-16-24-05">
                            Please tell us what problem you are facing in this question.
                        </p>
                        <div className="mt-3">
                            <Controls.Input
                                className="form-control"
                                name="fresherReason"
                                type="text"
                                value={reportText}
                                onChange={(e) => {
                                    if (e.target.value.length > 0) {
                                        setReportError("")
                                        setReportText(e.target.value)
                                    } else {
                                        setReportText(e.target.value)
                                        setReportError("Please enter issue")
                                    }

                                }}
                                rows="4"
                            />
                        </div>
                        {reportError && (
                            <p className="text-danger font-12-24-05 mt-2">
                                {reportError}
                            </p>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="border-0 pt-0">
                        <Button
                            className="btn btn-primary h-44 fw-medium m-2"
                            type="submit"
                            onClick={() => {
                                handelReportSubmit();

                            }}
                        >
                            Yes, Report
                        </Button>
                        <Button
                            className="btn btn-primary h-44 fw-medium m-2"
                            onClick={() => {
                                setInterestedForJobEmployer(false)
                                setReportText("")
                            }}
                        >
                            No, Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <AutohideExample show={show} setShow={setShow} />


        </main >
    )
}

export default MCQAssessment