import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/login/login";
import EmployerLogin from "./pages/employerlogin/employerlogin";
import PrivateRoute from "../src/Routes/PrivateRoute";
import Layout from "../src/components/Layout/layout";
import { loadUser } from "./state/action/authAction";
import { useDispatch, useSelector } from "react-redux";
import PersonalInformation from "./pages/FresherRegister/main";
import SendOtp from "./pages/forgotPAssword/SendOtp";
import AdminLogin from "./pages/AdminLogin";
import EmployerLayout from "./components/Layout/empllyerlayout";
import FresherLayout from "./components/Layout/fresherlayout";
import VerifyEmail from "./pages/verifyemail/verifyemail";
import Signup from "./pages/signup/signup";
import EmployerSignup from "./pages/employersignup/employersignup";
import EmployerVerifyEmail from "./pages/employerverifyemail/employerverifyemail";
import EnterOtp from "./pages/forgotPAssword/EnterOtp";
import ResetPassword from "./pages/forgotPAssword/ChangePassword";
import SendOtpEmployer from "./pages/EmployerForgetpassword/SendOtp";
import EnterOtpEmployer from "./pages/EmployerForgetpassword/EnterOtp";
import ResetPasswordEmployer from "./pages/EmployerForgetpassword/ResetPassword";
import { useNavigate } from "react-router-dom";
import Error from "./pages/error/Error";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname.substr(1);
  useEffect(() => {
    if (
      pathname != "login" ||
      pathname != "admin" ||
      pathname != "employerlogin"
    ) {
      dispatch(loadUser());
    }
  }, []);

  const isAuthenticated = useSelector((store) => store?.auth?.isAuthenticated);
  const slug = location.pathname.split("/")[1];
  const { user } = useSelector((state) => state?.auth);


  useEffect(() => {
    if (slug == "employersignup") {
      if (isAuthenticated && user?.completed == 1) {
        navigate("/employer");
      }
    }
    if (slug == "signUp" || slug == "login") {
      if (isAuthenticated) {
        console.log("slug", slug, isAuthenticated, user)

        navigate("/");
      }
    }
  }, [isAuthenticated, slug, user]);

  let routeElement = null;

  if (user && user?.role) {
    routeElement =
      user && user?.role == 1 ? (
        <PrivateRoute path="/login">
          <FresherLayout />
        </PrivateRoute>
      ) : user && user?.role == 2 ? (
        <PrivateRoute path="/employerlogin">
          <EmployerLayout />
        </PrivateRoute>
      ) : (
        <PrivateRoute path="/login">
          <Layout />
        </PrivateRoute>
      );
  }
  // else {

  //   <Loader view={true} />

  // }


  return (
    <>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/employerlogin" element={<EmployerLogin />} />
        <Route exact path="/admin" element={<AdminLogin />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/verifyemail" element={<VerifyEmail />} />
        <Route exact path="/error404" element={<Error />} />
        {/* <Route exact path="/index" element={<Index/>}/>  */}
        <Route exact path="/employersignup" element={<EmployerSignup />} />
        <Route exact path="/personalinformation" element={<PersonalInformation />} />
        <Route exact path="/sendotp" element={<SendOtp />} />
        <Route exact path="/otp-verify" element={<EnterOtp />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route exact path="/send-otp" element={<SendOtpEmployer />} />
        <Route exact path="/otpverify" element={<EnterOtpEmployer />} />
        <Route exact path="/reset-password" element={<ResetPasswordEmployer />} />
        <Route
          exact
          path="/employerverifyemail"
          element={<EmployerVerifyEmail />}
        />
        <Route path="*" element={<PrivateRoute path="/login">
          {routeElement}
        </PrivateRoute>
        } />
      </Routes>
    </>
  );
}

export default App;
