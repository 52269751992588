import Controls from "../../components/controls/Controls";
import Form from "react-bootstrap/Form";
import {
  AddAssesmentData,
  updateAssesment,
} from "../../state/action/addAssesment";
import { SkillList } from "../../state/action/skillAction";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "../../utills/useForms";
import { FaBackward } from 'react-icons/fa';
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../utills/Loader";
import AutohideExample from "../../utills/Notification";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editorcss.css'
import { convertToHTML } from 'draft-convert';
import "./editorcss.css"

const typeOFQuestion = [
  {
    id: 1,
    title: "Basic Questions",
  },
  {
    id: 2,
    title: "Medium Questions",
  },
  {
    id: 3,
    title: "Hard Questions",
  },
];



const initialValues = {
  question: "",
  queType: "",
  skillId: "",
  typeOfQuestion: "",
  options1: "",
  options2: "",
  options3: "",
  options4: "",
};

const AssessmentPage = (props) => {
  const {
    // isEdit,
    getAssesmentDetails,
    page,
  } = props;

  const [skillData, setSkillData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [skillIds, setSkillIds] = useState("");
  const [skillNames, setSkillNames] = useState("")

  const [convertedContent, setConvertedContent] = useState(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const handleanswerchange = (e, i) => {
    setSelectedAnswer(i);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("question" in fieldValues) {
      temp.question = fieldValues.question ? "" : "This field is required.";
    }
    if ("typeOfQuestion" in fieldValues) {
      temp.typeOfQuestion = fieldValues.typeOfQuestion ? "" : "This field is required.";
    }

    if ("options1" in fieldValues) {
      temp.options1 = fieldValues.options1 ? "" : "This field is  required.";
    }
    if ("options2" in fieldValues) {
      temp.options2 = fieldValues.options2 ? "" : "This field is  required.";
    }
    if ("options3" in fieldValues) {
      temp.options3 = fieldValues.options3 ? "" : "This field is  required.";
    }
    if ("options4" in fieldValues) {
      temp.options4 = fieldValues.options4 ? "" : "This field is   required.";
    }


    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const [view, setView] = useState(false);

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });



  //skilllist
  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      setSkillData(res.data);
    }
  };
  useEffect(() => {
    getSkillList();
  }, []);

  useEffect(() => {
    if (state && state.id) {
      setSkillIds(state.id);
      setSkillNames(state.skillName);
    }
  }, [state]);

  useEffect(() => {

    if (state && state.isEdit) {
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(state.data.code)
        )
      ));
      setConvertedContent(state.data.code);
      setValues({
        ...values,
        question: state.data.question,
        code: state.data.code,
        typeOfQuestion: state.data.typeOfQuestion,
        options1: state.data.options[0]?.answer || "",
        options2: state.data.options[1]?.answer || "",

        options3: state.data.options[2]?.answer || "",
        options4: state.data.options[3]?.answer || "",
        options1Id: state.data.options[0]?.id,
        options2Id: state.data.options[1]?.id,
        options3Id: state.data.options[2]?.id,
        options4Id: state.data.options[3]?.id,
      });
      const index = state && state.data && state.data.options.findIndex((opt) => opt.isTrue);
      setSelectedAnswer(index + 1);
      setIsEdit(true);
    }
  }, [state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);

    if (validate()) {

      const isAtLeastOneAnswerSelected =
        values.options1Id || values.options2Id || values.options3Id || values.options4Id;



      const body = {
        question: values.question,
        code: convertedContent == null ? "" : convertedContent,
        typeOfQuestion: values.typeOfQuestion,
        skillId: skillIds,
        queType: skillIds == 1 ? 1 : 2,
        options: [
          {
            answer: values.options1,
            isTrue: selectedAnswer === 1 ? 1 : 0,
            id: values.options1Id,
            queId: state?.data ? state.data.id.toString() : " "
          },
          {
            answer: values.options2,
            isTrue: selectedAnswer === 2 ? 1 : 0,
            id: values.options2Id,
            queId: state?.data ? state.data.id.toString() : " "
          },
          {
            answer: values.options3,
            isTrue: selectedAnswer === 3 ? 1 : 0,
            id: values.options3Id,
            queId: state?.data ? state.data.id.toString() : " "
          },
          {
            answer: values.options4,
            isTrue: selectedAnswer === 4 ? 1 : 0,
            id: values.options4Id,
            queId: state?.data ? state.data.id.toString() : " "
          },
        ],
      };
      const addPayload = {

        question: values.question,
        code: convertedContent == null ? "" : convertedContent,
        typeOfQuestion: values.typeOfQuestion,
        queType: state && state.id == 1 ? 1 : 2,
        skillId: skillIds,
        options: [
          {
            answer: values.options1,
            isTrue: selectedAnswer === 1 ? 1 : 0,

          },
          {
            answer: values.options2,
            isTrue: selectedAnswer === 2 ? 1 : 0,

          },
          {
            answer: values.options3,
            isTrue: selectedAnswer === 3 ? 1 : 0,

          },
          {
            answer: values.options4,
            isTrue: selectedAnswer === 4 ? 1 : 0,

          },
        ],



      }



      //edit
      if (state?.data || isEdit) {
        const res = await updateAssesment(state.data.id, body);
        setView(true);

        if (res && res.status === true) {
          // refreshScreen();
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
          setTimeout(() => {
            navigate("/admin/assesment", {
              state: {
                id: skillIds,
                skillaName: skillNames
              },
            })
          }, 1000);

          getAssesmentDetails(page, "", "");
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }

      if (!isEdit && !state?.data) {

        const res = await AddAssesmentData(addPayload);

        if (res && res.status === true) {

          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
          navigate("/admin/assesment", {
            state: {
              id: skillIds,
              skillaName: skillNames
            }
          });
          getAssesmentDetails(page, "", "")
          resetForm();
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }
    } else {
      setShow({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    }
  };


  return (
    <div className="container-fluid">
      <Loader view={view} />
      {!view && (
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between mb-3">
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <div className="d-flex align-items-center gap-2">
                <span>
                  <FaBackward />
                </span>
                <span>Back</span>
              </div>
            </button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column gap-2 me-4 ms-1">
                <h4 className="mt-2 px-2 mb-0">Type of question</h4>
                <Form.Select
                  name="typeOfQuestion"
                  value={values?.typeOfQuestion}
                  onChange={handleInputChange}
                  className="py-2"
                >
                  <option value="">Select type of question</option>

                  {typeOFQuestion?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </Form.Select>
                {Boolean(errors.typeOfQuestion) ? (
                  <p className="text-invalid">{errors.typeOfQuestion}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-12">
              <h4 className="mt-2 px-2 mb-0">Question</h4>
            </div>
            <div className="col-md-12">

              <div className="d-flex flex-column gap-2 me-4 ms-1">
                <Controls.Input
                  className="form-control"
                  placeholder="Question"
                  name="question"
                  type="text"
                  maxLength={150}
                  value={values?.question}
                  onChange={handleInputChange}
                />
                {Boolean(errors?.question) ? (
                  <p className="text-invalid">{errors.question}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-12">
              <h4 className="mt-2 px-2 mb-0">Code Snippet</h4>
            </div>
            <div className="col-md-12">
              <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="col-md-12">
              <h4 className="mt-2 px-2 mb-0">Options</h4>
            </div>
            <div className="col-md-12">
              <div className="d-flex gap-1 mt-2">
                <Controls.Input
                  className="form-control"
                  placeholder="options"
                  name="options1"
                  type="text"
                  maxLength={150}
                  value={values?.options1}
                  onChange={handleInputChange}
                />
                {Boolean(errors?.options1) ? (
                  <p className="text-invalid">{errors.options1}</p>
                ) : (
                  ""
                )}

                <Controls.Input
                  placeholder="options"
                  name="college"
                  type="radio"
                  value={values?.options1}
                  onChange={(e) => {
                    handleanswerchange(e, 1);
                  }}
                  checked={selectedAnswer === 1}
                />
              </div>
            </div>


            <div className="col-md-12">
              <div className="d-flex gap-1 mt-2">
                <Controls.Input
                  className="form-control"
                  placeholder="options"
                  name="options2"
                  type="text"
                  maxLength={150}
                  value={values?.options2}
                  onChange={handleInputChange}
                />
                {Boolean(errors.options2) ? (
                  <p className="text-invalid">{errors.options2}</p>
                ) : (
                  ""
                )}
                <Controls.Input
                  placeholder="options"
                  name="college"
                  type="radio"
                  value={values?.options2}
                  onChange={(e) => {
                    handleanswerchange(e, 2);
                  }} checked={selectedAnswer === 2}
                />
              </div>
            </div>


            <div className="col-md-12">
              <div className="d-flex gap-1 mt-2">
                <Controls.Input
                  className="form-control"
                  placeholder="options"
                  name="options3"
                  type="text"
                  maxLength={150}
                  value={values?.options3}
                  onChange={handleInputChange}
                />
                {Boolean(errors.options3) ? (
                  <p className="text-invalid">{errors.options3}</p>
                ) : (
                  ""
                )}
                <Controls.Input
                  placeholder="options"
                  name="college"
                  type="radio"
                  value={values?.options3}
                  onChange={(e) => {
                    handleanswerchange(e, 3);
                  }} checked={selectedAnswer === 3}
                />
              </div>
            </div>
            <div className="col-md-12">

              <div className="d-flex gap-1 mt-2">
                <Controls.Input
                  className="form-control"
                  placeholder="options"
                  name="options4"
                  type="text"
                  maxLength={150}
                  value={values?.options4}
                  onChange={handleInputChange}
                />
                {Boolean(errors.options4) ? (
                  <p className="text-invalid">{errors.options4}</p>
                ) : (
                  ""
                )}
                <Controls.Input
                  placeholder="options"
                  name="college"
                  type="radio"
                  value={values?.options4}
                  onChange={(e) => {
                    handleanswerchange(e, 4);
                  }} checked={selectedAnswer === 4}
                />
              </div>
            </div>
            <div className="col-md-12">
              {errors.options && <p className="text-danger">{errors.options}</p>}

              <div className="col-md-12">
              </div>
              <Button
                type="submit"
                className="btn btn-primary h-auto px-3 py-2 fw-medium m-1 mt-4"
                onClick={(e) => {
                  handleSubmit(e);

                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      )}
      <AutohideExample show={show} setShow={setShow} />
    </div>
  );
};

export default AssessmentPage;


