import axios from "axios";
import { ADD_EMPLOYER_DATA, EMPLOYER_FAIL, EMPTY_ALL_DATE } from "../action-types/employerTypes";
import axiosInstance from "../../utills/AxiosInstance";
import { store } from "../store";
import { UPDATETESTRESULTS, UPDATETESTRESULT, STARTUPDATINGS, CLEARTESTARRAYS } from "../action-types/assessment";

export const getQuestions = async () => {
    try {
        const response = await axiosInstance.get("/v1/coddingAssessmentList");
        return response.data;
    } catch (error) {
        return error;
    }
}

export const runCode = async (payload) => {
    try {
        const response = await axios.post("/v1/runCode", payload);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const AddTest = (payload) => async (dispatch) => {
    const { codingAssessment } = store.getState();
    let reduxData = codingAssessment?.test;
    let newindex = [...reduxData];


    let index = newindex && newindex?.findIndex((item) => item.questionId == payload.questionId);
    if (index > -1) {
        newindex[index] = payload;

    } else {
        newindex = [...newindex, payload];
    }

    dispatch({
        type: UPDATETESTRESULTS,
        payload: newindex,
    });
};


export const runProgram = async (payload) => {
    try {
        const response = await axios.post("https://devcode.techfresher.com/apis/run_code/", payload);
        return response.data;
        // return {
        //     "status": true,
        //     "data": [
        //         {
        //             "expected_output": "5",
        //             "your_output": "your output is wrnog",
        //             "result": "fail"
        //         },
        //         {
        //             "expected_output": "10",
        //             "your_output": "10",
        //             "result": "pass"
        //         }, {
        //             "expected_output": "5",
        //             "your_output": "your output is wrnog",
        //             "result": "fail"
        //         },
        //         {
        //             "expected_output": "10",
        //             "your_output": "10",
        //             "result": "pass"
        //         },
        //         {
        //             "expected_output": "10",
        //             "your_output": "10",
        //             "result": "pass"
        //         }
        //     ]
        // }
    } catch (error) {
        return error;
    }
}

export const addIssue = async (payload) => {
    try {
        const response = await axiosInstance.post("/fresher/addCodingIssue", payload);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getIssue = async (queryString) => {
    try {
        const response = await axiosInstance.get(`/fresher/getReport${queryString}`);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const markAsResolved = async (id) => {
    try {
        const response = await axiosInstance.post(`/fresher/issueSolved/${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const clearTestArray = () => async (dispatch) => {
    dispatch({
        type: CLEARTESTARRAYS,
    });
};


export const submitTest = async (payload) => {
    try {
        const response = await axiosInstance.post("/fresher/addUserExam", payload);
        return response.data;
    } catch (error) {
        return error;
    }
}


