import axiosInstance from "../../utills/AxiosInstance";
import {
  LOGIN_FAIL, LOGIN_SUCCESS,
} from "../action-types/authActionTypes";
import Cookies from "universal-cookie";

const cookies = new Cookies();
// let BASE_URL = `${process.env.REACT_APP_API_URL}/api`;
let BASE_URL = "https://apijobportal.mobiginie.com/api";
// let BASE_URL = `http://localhost:4010/api`;


//fresherlist
export const getFresherPerferenceLists = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/fresher/getPreferenceById/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//personalinformation

export const PersonalInformationApi = async () => {
  try {
    const response = await axiosInstance.post("/fresher/registerFresher/4");

    return response.data;
  } catch (error) {
    return error;
  }
};

//registerfresher


//countrylist
export const getCountryLists = async () => {
  try {
    const response = await axiosInstance.get(`/v1/countryDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//citylist
export const getStateLists = async (id) => {
  try {
    const response = await axiosInstance.get(`/v1/stateDropdown/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//citylist
export const getCityLists = async (id) => {
  try {
    const response = await axiosInstance.get(`/v1/cityDropdown/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//educationlevellist
export const getEducationLists = async () => {
  try {
    const response = await axiosInstance.get(`/v1/educationDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//job type dropdown
export const getJobTypeLists = async () => {
  try {
    const response = await axiosInstance.get(`/v1/educationDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//job Prefer dropdown
export const getJobPreferLists = async () => {
  try {
    const response = await axiosInstance.get(`/v1/jobPreferDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//basic infotmation fresher
export const getBasicInfoLists = async (id) => {
  try {
    const response = await axiosInstance.get(`/fresher/getFresherById/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update personalinfornation in fresher
export const FresherPersonalUpdater = async (id, values) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axiosInstance.put(
      `/fresher/updatePersonalInfo/${id}`,
      values,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//update edication

export const FresherEducationUpdate = async (id, values) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axiosInstance.put(
      `/fresher/updateEducationInformation/${id}`,
      values,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//update job

export const FresherJobUpdate = async (values) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axiosInstance.put(
      `/fresher/updatePrefer`,
      values,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//job type dropdown
export const getJobTypesLists = async () => {
  try {
    const response = await axiosInstance.get(`/v1/jobTypeDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//ignore job
export const getIgnoreJob = async (id, ids, values) => {
  try {
    const response = await axiosInstance.put(
      `/employee/rejectJob/${id}/${ids}`,
      values
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//jobdetails seemore

export const getSeeInterviewJob = async (id, ids, values) => {
  try {
    const response = await axiosInstance.get(
      `/employee/getInterviewById/${id}/${ids}`,
      values
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//reject for employer
export const getRejectJob = async (id, ids, values) => {
  try {
    const response = await axiosInstance.put(
      `/employee/rejectCandidate/${id}/${ids}`,
      values
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//update resume
export const UpdateResume = async (id, ids, values) => {
  try {
    const response = await axiosInstance.put(
      `/fresher/updateResume/${id}/${ids}`,
      values
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//assement list in fresher
export const getAssessmentFresherList = async (id, queryString) => {
  try {
    const response = await axiosInstance.get(
      `/fresher/fresherSkillList/${id}` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//fresher Delete skill

export const DeleteSKill = async (id) => {
  try {
    const response = await axiosInstance.delete(`/v1/deleteSkill/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//interested
export const JobIntrested = async (id, ids) => {
  try {
    const response = await axiosInstance.post(
      `/fresher/jobIntrested/${id}/${ids}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};


export const googleLoginFresher = (values) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let res = await axiosInstance.post(
      `${BASE_URL}/fresher/fresherLoginWithGoogle`,
      values,
      config
    );


    if (res.data && res.data.status === true) {
      cookies.set("token", res.data.data.token, { path: "/" });
      cookies.set("email", values.email, { path: "/" });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data && res.data.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return err;
  }
};


export const countryCodeDropdown = async () => {
  try {
    const response = await axiosInstance.get(`/v1/countryCodeDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
}



export const resendOtp = async (id) => {
  try {
    const response = await axiosInstance.put(`/fresher/fresherResendOtp/${id}`)
    return response.data;
  } catch (error) {
    return error;
  }
}
