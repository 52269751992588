import React from "react";

import { useForm } from "../../utills/useForms";
import { useState, useEffect } from "react";
import { AddRoleData, updateRole } from "../../state/action/addRoleAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Controls from "../../components/controls/Controls";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";

import { Form } from "../../utills/useForms";

import { useNavigate, useLocation } from "react-router";

const initialValues = {
  name: "",
};
const AddRole = (props) => {
  const {
    setIsEdit,
    isEdit,
    handleAddCloseSwitch,
    openAddSwitchModel,
    roleDataList,
    refreshScreen,
  } = props;

  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "This field is required.";

      
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };


  const closeAddSkillPopup = () =>{
    handleAddCloseSwitch();
    resetForm();
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [filterData, setFilterData] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState(false);

  const navigate = useNavigate();

  const [RoleList, setRoleList] = useState([]);

  const [id, setId] = useState([]);
  useEffect(() => {
    setIsEdit("");
  }, []);
  const handleSubmit = async (e) => {
    if (validate()) {
      resetForm();
      const body = {
        name: values.name,
        email: values.email,
      };
      //edit
      if (isEdit) {
        const res = await updateRole(roleDataList.id, body);
        setView(true);
        if (res && res.status === true) {
          refreshScreen();
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }

      if (!isEdit) {
        const res = await AddRoleData(body);

        if (res && res.status === true) {
          refreshScreen();
          setShow({
            isOpen: true,
            message: res?.message,  
            type: "success",
          });
          setView(false);
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }
      handleAddCloseSwitch();
      resetForm();
    } else {
      setShow({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    }
  };
  //details edit
  useEffect(() => {
    if (
      roleDataList.id != "" &&
      roleDataList.id != null &&
      roleDataList.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,

        name: roleDataList?.name,
      });
    } else {
      resetForm();
    }
  }, [roleDataList]);

  return (
    <div>
      <Loader view={view} />
      {!view && (
        <Modal
          size="md"
          show={openAddSwitchModel}
          onHide={closeAddSkillPopup}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton onClick={closeAddSkillPopup}></Modal.Header>
          <Modal.Body>
            <div>
              <h3>Add Role</h3>
            </div>
            <Controls.Input
              className="form-control font-16-24-05 h-auto py-[10px] px-3"
              placeholder="add Role"
              name="name"
              type="text"
              lable="name"
              value={values.name}
              onChange={handleInputChange}
              maxLength={50}
            />
            {Boolean(errors.name) ? (
              <p className="text-invalid">{errors.name}</p>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button

              className="btn btn-primary h-44 fw-medium m-2"
              
              onClick={closeAddSkillPopup}

              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={()=>{
                handleAddCloseSwitch();
              resetForm();
              }}

            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="h-auto px-3 py-2 fw-medium m-0"
              onClick={() => {
                handleSubmit();
                //handleAddCloseSwitch();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <AutohideExample show={show} setShow={setShow} />
    </div>
  );
};

export default AddRole;
