import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { useForm } from "../../utills/useForms";
import {
  getjobpost,
  StatusUpdate,
  deletejobpost,
  approveJob,
  rejectJob,
  assignjob,
  adminassignjob,
} from "../../state/action/jobpostAction";
import moment from "moment";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/view.svg";
import { AiOutlineCheck, RxCross2 } from "../../assets/Icons/icons";
import AutohideExample from "../../utills/Notification";
import Badge from "react-bootstrap/Badge";
import { Loader } from "../../utills/Loader";
import { IsWrite, IsDelete } from "../../utills/handlePermission";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { SkillList } from "../../state/action/skillAction";
import Form from "react-bootstrap/Form";
import Multiselect from "multiselect-react-dropdown";
import { CountryData, StateData, CityData } from "../../state/action/locationAction";
import { useDispatch, useSelector } from "react-redux";
// import "./filter.css";
import '../../components/pagination/style.scss';
import '../../components/pagination/pagination.scss';
import PaginationOne from '../../components/pagination/PaginationOne';
import { useMemo } from 'react';

let PageSize = 10;



const initialValues = {};
const Jobpost = () => {
  const [flagName, setflagName] = useState(false);
  const [jobpostlist, setjobpostlist] = useState([]);
  const [fieldName, setFieldName] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteSwitchModel, setOpenDeleteSwitchModel] = useState(false);
  const [jobApproveRejectModel, setJobApproveRejectModel] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openAddSwitchModel, setOpenAddSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [search, setSearch] = useState("");
  const [jobApproveRejectId, setJobApproveRejectId] = useState(null);
  const [isJobApprove, setIsJobApprove] = useState(false);
  const [selectSkill, setSelectSkill] = useState([]);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [skillsId, setSkillsId] = useState([]);

  const [openFilterModel, setOpenFilterModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [id, setId] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [skillDataList, setskillDataList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(3);
  //const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [sortFlag, setSortFlag] = useState(false);
  const [filterData, setFilterData] = useState(false);
  const [filter, setFilter] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const deletePermissions = IsDelete("jobpost");
  const editPermissions = IsWrite("jobpost");
  const [skillData, setSkillData] = useState([]);
  const [deleteSkill, setDeleteSkill] = useState([]);
  const [countryLists, setCountryList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [jobPreferList, setJobPreferList] = useState([]);
  const [JobidData, setJobidData] = useState("");
  const [AdminDataList, setAdminDataList] = useState([]);
  const [totalpages, setTotalPages] = useState(10);
  const [jobId, setJobId] = useState("");
  const [rolesId, setRolesId] = useState("");

  const [CityList, setCityList] = useState([]);
  const [stateList, setstateList] = useState([]);

  const statuslist = [
    { id: "1", title: "approve" },
    { id: "0", title: "pending" },
    { id: "2", title: "  On Going" },
    { id: "3", title: "Completed" },
    { id: "4", title: "Cancel" },
    { id: "5", title: "delete" },

  ];

  const [currentPage, setCurrentPage] = useState(1);


  const jobpostListsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return jobpostlist;
  }, [currentPage, jobpostlist]);

  const Adminid = useSelector((state) => state?.auth);




  // const Adminid = 2
  const payload = {
    jobId: jobId,
    adminId: JobidData
  }

  const AdminJobAssign = async () => {

    const res = await assignjob();

    if (res && res.status == true) {
      setAdminDataList(res.data);
    }
    else {
      setAdminDataList([]);
    }
  };

  const AdminAssign = async () => {

    const res = await adminassignjob(Adminid?.admin?.id, payload);

    if (res && res.status == true) {
      // setAdminDataList(res.data);
    }
    else {
      // setAdminDataList([]);
    }
  };


  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleDeleteSwitchModel = () => setOpenDeleteSwitchModel(true);
  const handleDeleteCloseSwitch = () => setOpenDeleteSwitchModel(false);
  const [refresh, setRefresh] = useState(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const handleJobApproveReject = (data, type) => {
    if (data && data.id) {
      if (type === "reject") {
        setIsJobApprove(true);
      } else if (type === "approve") {
        setIsJobApprove(false);
      } else {
        setIsJobApprove(false);
      }
      setJobApproveRejectModel(true);
      setJobApproveRejectId(data.id);
    }
  };



  const confirmApproveJob = async () => {
    setOpenBreakdrop(true);

    if (jobApproveRejectId) {
      const res = await approveJob(jobApproveRejectId);
      if (res && res.status === true) {
        refreshScreen();
        setJobApproveRejectModel(false);
        setJobApproveRejectId(null);
        if (fieldName == "name") {
          getjobpostList(fieldName, flagName);
        } else {
          getjobpostList("");
        }
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setOpenBreakdrop(!openBackdrop);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  const statusManage = (status) => {

    // status = 1
    const statusColors = {
      1: {
        type: "success",
        text: "Approved",
      },

      0: {
        type: "warning",
        text: "Pending",
      },
      2: {
        type: "info",
        text: "On Going",
      },
      3: {
        type: "primary",
        text: "Completed",
      },
      4: {
        type: "secondary",
        text: "Rejected",
      },
      5: {
        type: "danger",
        text: "Deleted",
      },
      6: {
        text: "Closed",
        type: "dark",
      },
    };
    return (
      <Badge bg={statusColors[status].type} text={status == 4 ? "dark" : ""}>
        {statusColors[status].text}
      </Badge>
    );
  };

  const confirmRejectJob = async () => {
    if (jobApproveRejectId) {
      const res = await rejectJob(jobApproveRejectId);
      setOpenBreakdrop(true);
      if (res && res.status === true) {
        refreshScreen();
        setJobApproveRejectModel(false);
        setJobApproveRejectId(null);
        if (fieldName == "name") {
          getjobpostList(fieldName, flagName);
        } else {
          getjobpostList("");
        }
        setShow({
          isOpen: true,
          message: res?.message,
          type: "success",
        });
      } else {
        setOpenBreakdrop(!openBackdrop);
        setJobApproveRejectModel(false);
        setShow({
          isOpen: true,
          message: res?.message,
          type: "error",
        });
      }
    }
  };

  //skilllist
  const getSkillList = async () => {
    const res = await SkillList();
    if (res && res.data) {
      setSkillData(res.data);
    }
  };

  const multiselectSelectSkill = (data, e) => {

    setSkillsId([...skillsId, e.id]);
    setSelectSkill([...selectSkill, {
      id: e.id,
      //fresherId: fresherId?.user?.id,
      title: e.title,
    }]);
    setSkillData(skillData?.filter((item) => { return !data.some((item2) => { return item.id === item2.id }) }));
  };


  const removeSkill = async (e, item) => {
    setSkillData([...skillData, item]);
    const removeSkill = selectSkill.filter(
      (items) => items.id != item.id
    );
    setSelectSkill(removeSkill);
    if (item && item.id) {
      setDeleteSkill([...deleteSkill, item?.id]);
    }
  };


  //countrylist
  const CountryListData = async () => {
    const res = await CountryData();

    if (res.status === true) {
      setCountryList(res.data);

    }
  };

  //statelist
  const StateListData = async (ids) => {

    const res = await StateData(ids);

    if (res.status === true) {
      setstateList(res.data);
    }
  };

  //citylist
  const CityListData = async (ids) => {
    const res = await CityData(ids);

    if (res.status === true) {
      setCityList(res.data);
    }
  };





  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  // list
  useEffect(() => {
    getjobpostList(page, "", "");
    getSkillList();
    AdminJobAssign();
    // AdminAssign();


  }, [filterData, search, page]);


  const data = useSelector((state) => state?.auth);


  const getjobpostList = async (page, name = "", flag, perPageSize, limit) => {
    setOpenBreakdrop(true);
    setView(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = perPageSize ? perPageSize : pageLimit;
    //selectedValues={selectSkill} // Preselected value to persist in dropdown
    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&sortby=${name}&sortFlag=${flag}&jobSkillId=${skillsId}&admin=${rolesId == "" ? Adminid?.admin?.id : rolesId}`;

    const res = await getjobpost(queryString);
    setView(true);
    if (res.status === true) {
      setjobpostlist(res.data);
      setTotalRecords(res.total);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPageLimit(recordLimit);
      setView(false);
    } else {
      setjobpostlist([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      setView(false);
    }
    setFilter(false);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getjobpostList(page, "", "", pageLimit); // Call the API with the new page number and page limit
    setPage(page);
  };

  const handlePageLimitChange = (e) => {
    const limit = parseInt(e.target.value);
    setPageLimit(limit || 10);
    setCurrentPage(1); // Reset the current page to 1 when page limit is changed
    getjobpostList(1, "", "", limit);
  };

  //status change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getjobpostList(fieldName, flagName);
      } else {
        getjobpostList(currentPage, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    // setSelectSkill([]);
    closeDrawer();

  };

  const handleDelete = async () => {
    const res = await deletejobpost(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getjobpostList(fieldName, flagName);
      } else {
        getjobpostList(page, "", "");
      }
      handleCloseDelete();
      setShow({
        isOpen: true,
        message: res?.message,
        type: "success",
      });
      setView(false);
    } else {
      setOpenBreakdrop(!openBackdrop);
      setShow({
        isOpen: true,
        message: res?.message,
        type: "error",
      });
      setView(false);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    resetForm();
    closeDrawer();
    setSkillsId([]);
    setSelectSkill([]);
    setFilterData(!filterData);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
    // resetForm();
  };

  const handleInputLength = (event) => {
    const maxLength = 5;
    const input = event.target;
    const value = input.value;

    if (value.length > maxLength) {
      input.value = value.slice(0, maxLength);
    }
  };

  return (
    <div>

      <Loader view={view} />
      {/* {!view && ( */}
      <main className="body-background">

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="rounded-card-head">
                <div className="row align-items-center mb-3">
                  <div className="col-md-3">
                    <div className="font-PTSerif-b ps-2 font-18-24-05">
                      Job post
                    </div>

                  </div>
                  <div className="col-md-9">
                    <div className="col col-md-auto text-end d-flex justify-content-end flex-wrap">
                      <Controls.Input
                        className="border-0 rounded p-2 mt-2"
                        placeholder="search"
                        name="text"
                        type="text"
                        onChange={(e) => {
                          setTimeout(() => {
                            setSearch(e.target.value);
                          }, 800);
                        }}
                      />
                      <Controls.Button
                        style={{ padding: "7px 30px", marginLeft: "16px" }}
                        className="btn btn-success h-40 mt-2"
                        text="filter"
                        onClick={() => {
                          toggleDrawer();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {showDrawer && (
                <>
                  <div className="filter-drawer-overlay" onClick={toggleDrawer}>
                  </div>
                  <div className="filter-drawer-content" style={{ overflow: "auto" }}>
                    <div className="d-flex justify-content-between align-items-center filter-header_div ">
                      <label className="filter-header">Filter</label>
                      <button
                        className="filter-close-button"
                        onClick={toggleDrawer}
                      >
                        x
                      </button>
                    </div>
                    <div className="d-flex flex-column h-100">
                      <form onSubmit={handleSubmit} autoComplete="off" className="d-flex h-100 flex-column justify-content-between gap-4">
                        <div>
                          <div>
                            <label className="filter-label">jobTitle</label>
                            <Controls.Input
                              className="input_filter w-100"
                              name="jobTitle"
                              type="text"
                              value={values.jobTitle}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div>
                            <label className="filter-label">job Type</label>
                            <Controls.Input
                              className="input_filter w-100"
                              name="jobtypetitle"
                              type="text"
                              value={values.jobtypetitle}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div>
                            <label className="filter-label">no.of employee</label>
                            <Controls.Input
                              className="input_filter w-100"
                              name="noofEmployee"
                              type="text"
                              value={values.noofEmployee}
                              onChange={handleInputChange}
                            />
                          </div>


                          <div>
                            <label className="filter-label">status</label>
                            <select
                              className="form-select h-auto px-3 py-2 "
                              name="status"
                              onChange={handleInputChange}
                              value={values.status}
                            //options={statuslist}
                            >
                              <option>status</option>
                              {statuslist?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>


                          <div>
                            <label className="filter-label">Skills</label>
                            <Multiselect
                              options={skillData} // Options to display in the dropdown
                              selectedValues={selectSkill} // Preselected value to persist in dropdown
                              onSelect={multiselectSelectSkill} // Function will trigger on select event
                              onRemove={removeSkill}                    // displayValue={(option) => (option ? option.skillName : "")} // Property name to display in the dropdown options
                              displayValue="title"
                            />
                          </div>


                          <div>
                            <label className="filter-label">Country</label>
                            <Form.Select
                              name="countryId"
                              value={values.countryId}
                              onChange={(e) => {
                                handleInputChange(e);
                                StateListData(e.target.value)
                              }}
                              onClick={CountryListData}
                              className="form-select h-auto px-3 py-2 "
                            >
                              <option value="">Select country</option>

                              {countryLists?.map((item, index) => (

                                <option key={index} value={item.id}>

                                  {item.name}

                                </option>
                              ))}
                            </Form.Select>
                          </div>


                          <div>
                            <label className="filter-label">state</label>
                            <Form.Select
                              name="stateId"
                              value={values.stateId}
                              onChange={(e) => {
                                handleInputChange(e);
                                CityListData(e.target.value)
                              }}
                              onClick={StateListData}
                              className="form-select h-auto px-3 py-2 "
                            >
                              <option value="">Select state</option>

                              {stateList?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>


                          <div>
                            <label className="filter-label">city</label>
                            <Form.Select
                              name="cityId"
                              value={values.cityId}
                              onChange={handleInputChange}
                              onClick={CityListData}
                              className="form-select h-auto px-3 py-2 "
                            >
                              <option value="">Select city</option>

                              {CityList?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>


                        {Adminid?.admin?.roleId == 3 ? (

                          <div className="flex-column gap-1">
                            <h5 className="mt-2">Admin</h5>
                            <div className="mb-3">
                              <select
                                className="form-select font-16-24-05 h-auto py-1"
                                name="roleId"
                                placeholder="enter admin role"
                                onChange={(e) => {
                                  handleInputChange(e);
                                  setJobidData(e.target.value);
                                  setRolesId(e.target.value);
                                }}
                                value={rolesId}
                              >
                                <option> Admin</option>
                                {AdminDataList?.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}





                        <div className="mt-3 filter-reset-div mb-5">
                          <div className="d-flex justify-content-between">
                            <button
                              className="filter-reset-btn"
                              type="button"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                            <button
                              className="filter-btn"
                              type="submit"
                              onClick={(e) => {
                                closeDrawer();
                                handleSubmit(e);
                              }}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>

              )}

              <div className="theme-table-wrap">
                <Table responsive className="striped-table mb-0">
                  <thead>
                    <tr>
                      <th>job Title</th>
                      <th>Assigned Admin</th>
                      <th>Employer</th>
                      <th>openings</th>
                      <th>job Type</th>
                      <th>salary</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {jobpostListsData.map((row) => {
                    const isApproved = row.status === 1;
                    const isRejected = row.status === 4;
                    const onGoing = row.status === 2;
                    const completed = row.status === 3;
                    const closed = row.status === 6;


                    return (
                      <tbody>
                        <tr
                          className="border rounded"
                          style={{ backgroundColor: "white" }}
                        >
                          <td>{row?.jobTitle}</td>
                          <td>{row?.adminName}</td>
                          <td>{`${row?.fname} ${row?.lname}`}</td>
                          <td>{row?.openings}</td>
                          <td>{row?.jobtypetitle}</td>

                          <td>
                            {row?.minSalary}-{row?.maxSalary}
                          </td>
                          <td>{statusManage(row?.status)}</td>

                          <td>
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </td>

                          {editPermissions || deletePermissions ? (
                            <td>
                              <ul className="d-flex action-col-main">
                                <li className="me-3">
                                  {editPermissions && (
                                    <button
                                      className="btn btn-sm btn-icon btn-inner btn-success me-4"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Approve Job"
                                      onClick={() =>
                                        handleJobApproveReject(row, "approve")
                                      }
                                      disabled={isApproved || isRejected || onGoing || completed || closed}
                                    >
                                      <span className="btn-inner">
                                        <AiOutlineCheck
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </span>
                                    </button>)}

                                  {editPermissions && (
                                    <button
                                      className="btn btn-sm btn-icon btn-inner btn-dark me-4"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Reject Job"
                                      onClick={() =>
                                        handleJobApproveReject(row, "reject")
                                      }
                                      disabled={isRejected}
                                    >
                                      <span className="btn-inner">
                                        <RxCross2
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </span>
                                    </button>)}


                                  {deletePermissions && (
                                    <button
                                      className="btn btn-sm btn-icon btn-danger btn-inner me-4"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() => {
                                        handleDeleteSwitchModel();
                                        setId(row.id);
                                        setDeleteId(row?.id);
                                        handleDeleteModel();
                                      }}
                                    >
                                      <span className="btn-inner">
                                        <DeleteIcon />
                                      </span>
                                    </button>
                                  )}
                                  {editPermissions && (
                                    <button
                                      className="btn btn-sm btn-icon btn-secondary btn-inner"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="view"
                                      onClick={() => {
                                        navigate(`/admin/jobpostdetails`, {
                                          state: {
                                            id: row.id,
                                          },
                                        });
                                      }}
                                    >
                                      <span className="btn-inner">
                                        <ViewIcon
                                          style={{
                                            width: "19px",
                                            height: "19px",
                                          }}
                                        />
                                      </span>
                                    </button>
                                  )}
                                </li>
                              </ul>
                            </td>
                          ) : null}

                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
                {jobpostListsData && jobpostListsData.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </div>
              <PaginationOne
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={PageSize}
                onPageChange={page => [setCurrentPage(page), getjobpostList(page, "", "", pageLimit)]} />
              <div className="pt-2">
                {" "}
                Showing{" "}
                {currentPage * pageLimit > totalRecords
                  ? totalRecords
                  : currentPage * pageLimit}{" "}
                of {totalRecords} Results
              </div>



            </div>
          </div>
        </div>

        {/* status change */}
        <Modal
          size="md"
          show={openSwitchModel}
          onHide={() => handleCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to status change?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleCloseSwitch()}
            >
              No
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleCloseSwitch();

                handleChangeStatus(switchId);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete */}
        <Modal
          size="md"
          show={openDeleteSwitchModel}
          onHide={() => handleDeleteCloseSwitch(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want to delete?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              onClick={() => handleDeleteCloseSwitch()}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              type="submit"
              onClick={() => {
                handleDeleteCloseSwitch();
                handleDelete(deleteId);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Job approve & reject */}
        <Modal
          size="md"
          show={jobApproveRejectModel}
          onHide={() => setJobApproveRejectModel(false)}
          aria-labelledby=""
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <p className="mb-0 font-18-24-05 fw-medium">
              Are you sure you want {isJobApprove ? "reject" : "approve"} this
              job?
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
              //onClick={() => handleDeleteCloseSwitch()}
              onClick={() => setJobApproveRejectModel()}
            >
              Cancel
            </Button>
            {isJobApprove ? (
              <Button
                className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                type="submit"
                onClick={() => confirmRejectJob()}
              >
                Reject
              </Button>
            ) : (
              <Button
                className="btn btn-primary h-auto px-3 py-2 fw-medium m-2"
                type="submit"
                onClick={() => confirmApproveJob()}
              >
                Approve
              </Button>
            )}
          </Modal.Footer>
        </Modal>


        <AutohideExample show={show} setShow={setShow} />
      </main>
      {/* )} */}
    </div>
  );
};

export default Jobpost;
