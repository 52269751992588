import axiosInstance from "../../utills/AxiosInstance";


export const issueResolved = async (id) => {
    try {
        const response = await axiosInstance.put(`fresher/markAsResolvedReport/${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const reportSubmit = async (data) => {
    try {
        const response = await axiosInstance.post(`fresher/addIssue`, data);
        return response.data;
    } catch (error) {
        return error;
    }
}
