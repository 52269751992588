import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { BsArrowRightShort, BsCheck, BsCheckSquareFill } from 'react-icons/bs';
import { AiFillCloseSquare } from "react-icons/ai";

const OutputWindow = ({icon, setIcon,outputDetails, testCase, setTestCase, condeRunFunction, handelSubmit, clickOnNext, runCode, position }) => {
    const [activeTab, setActiveTab] = useState(0); // State to manage the active tab index
    const [activeTabs, setActiveTabs] = useState(0); // State to manage the active tab index
    const [mainActiveTab, setMainActiveTab] = useState('input'); // State to manage the active tab index

    const handleIconChange = (e) => {
        setIcon(!icon)
    }
    const handleSetActiveTab = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    const handleSetActiveTabs = (tabIndex) => {
        setActiveTabs(tabIndex);
    };
    const handleSetMainActiveTab = (tabIndex) => {
        setMainActiveTab(tabIndex);
    };

    const getOutput = () => {
        let statusId = outputDetails?.status?.id;

        if (statusId === 6) {
            // compilation error
            return (
                <pre className="px-2 py-1 font-normal text-xs text-red-500">
                    {atob(outputDetails?.compile_output)}
                </pre>
            );
        } else if (statusId === 3) {
            return (
                <pre className="px-2 py-1 font-normal text-xs text-green-500">
                    {atob(outputDetails.stdout) !== null
                        ? `${atob(outputDetails.stdout)}`
                        : null}
                </pre>
            );
        } else if (statusId === 5) {
            return (
                <pre className="px-2 py-1 font-normal text-xs text-red-500">
                    {`Time Limit Exceeded`}
                </pre>
            );
        } else {
            return (
                <pre className="px-2 py-1 font-normal text-xs text-red-500">
                    {atob(outputDetails?.stderr)}
                </pre>
            );
        }
    };
    return (
        <>
            <div onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }} onPaste={(e) => { e.preventDefault(); }}>
                <div className="d-flex justify-content-end gap-2" style={{ position: 'absolute', right: '0', top: `${icon ? '0' : '15px'}` }}>
                    <Button style={{ background: '#207BB5' }} size="sm"
                        onClick={() => condeRunFunction()}
                    >
                        Run Code
                    </Button>
                    {/* <Button style={{ background: '#198754' }} size="sm"
                        onClick={() => condeRunFunction()}
                    >
                        Run Test
                    </Button> */}
                    <Button style={{ background: 'var(--bd-blue-bg)' }} size="sm" onClick={() => {
                                if (position == 2) {
                                    handelSubmit();
                                } else {
                                    clickOnNext()
                                }
                            }}>
                        <div className="d-flex align-items-center">
                            <div style={{ lineHeight: '16px' }} 
                            >Submit</div>
                            <BsArrowRightShort style={{ fontSize: '16px' }} />
                        </div>
                    </Button>
                </div>
            </div>
            {icon && (
                <Tabs
                    defaultActiveKey="input"
                    id="uncontrolled-tab-example"
                    className="mt-3"
                    onSelect={handleSetMainActiveTab} // Use the function to set the active tab
                    activeKey={mainActiveTab}

                >
                    <Tab eventKey="input" title="Input" style={{ background: '#fff', padding: '12px' }}>
                        <Tabs
                            defaultActiveKey="case 1"
                            id="uncontrolled-tab-example"
                            className="mt-3"
                            onSelect={handleSetActiveTab} // Use the function to set the active tab
                            activeKey={activeTab}
                        >
                            {testCase && testCase.map((item, index) => {
                                return (
                                    <Tab title={`Case ${index + 1}`} key={index}
                                        eventKey={index}
                                        style={{ background: '#fff', padding: '12px' }}>
                                        {item && item.testcase && item.testcase.map((row, indexs) => {
                                            return (<>
                                                <p className="mb-0 mt-2"><strong className="example"> {row?.inputVariable ? row?.inputVariable : ''}
                                                </strong></p>
                                                <div className="result_input_box" onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }} onPaste={(e) => { e.preventDefault(); }} >
                                                    <div><input
                                                        value={row?.inputValues ? row?.inputValues : ''}
                                                        onChange={(e) => {
                                                            let temp = [...testCase];
                                                            temp[index].testcase[index].inputValues = e.target.value;

                                                            setTestCase(temp);
                                                        }}
                                                        disabled={true}
                                                        name={`input${indexs}`} /></div>
                                                </div >
                                            </>
                                            )
                                        }
                                        )}
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </Tab >
                    <Tab eventKey="results" title="Results" style={{ background: '#fff', padding: '12px' }}>
                        {runCode ? (
                            <Tabs
                                defaultActiveKey="case1"
                                id="uncontrolled-tab-example"
                                className="mt-3"
                                onSelect={handleSetActiveTabs} // Use the function to set the active tab
                                activeKey={activeTabs}
                            >
                                {testCase && testCase.map((item, index) => {
                                    return (
                                        // <Tab title={`Case ${index + 1} <BsCheck/>`} key={index}
                                        // <AiFillCloseSquare />
                                        <Tab title={(
                                            <span>
                                                Case {index + 1} &nbsp; {item && item.result != "fail" ? < BsCheckSquareFill style={{ color: '#198754' }} /> : <AiFillCloseSquare style={{ color: 'red' }} />}
                                            </span>
                                        )} key={index}
                                        onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }} onPaste={(e) => { e.preventDefault(); }}

                                            eventKey={index}
                                            style={{ background: '#fff', padding: '12px' }}>
                                            <p className="mb-0 mt-2"><strong className="example">Input:</strong></p>

                                            {item && item.testcase && item.testcase.map((row, indexs) => {
                                                return (<>
                                                    <div className="result_input_box mt-2" onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }} onPaste={(e) => { e.preventDefault(); }}>
                                                        <div> {row?.inputVariable ? row?.inputVariable : ''}</div>
                                                        <div>{row?.inputValues ? row?.inputValues : ''}</div>
                                                    </div>
                                                </>
                                                )
                                            }
                                            )}
                                            <p className="mb-0 mt-2"><strong className="example">Output:</strong></p>
                                            <div className="result_input_box">
                                                <div>{item.result == "pass" ? item?.codeOutput : ''}  {item.result == "fail" && (
                                                <p style={{
                                                    color: 'red',
                                                    fontSize: '14px'
                                                }}>
                                                    {item.codeOutput}
                                                </p>
                                            )}</div>
                                            </div>
                                           
                                            <p className="mb-0 mt-2"><strong className="example">Expected:</strong></p>
                                            <div className="result_input_box">
                                                <div>{item?.output ? item?.output : ''}</div>
                                            </div>
                                        </Tab>
                                    )
                                })
                                }

                            </Tabs>
                        ) : (
                            < div className="d-flex justify-content-center align-items-center card" style={{ height: '100px' }}>
                                <p>You must run your code first</p>
                            </div >
                        )}
                    </Tab >
                </Tabs >
            )}

            {/* You must run your code first */}
            {/* {testCase && testCase.length != 0 && (

                // < div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                //     <div className="spinner-border text-primary" role="status">
                //         <span className="visually-hidden">Loading...</span>
                //     </div>
                // </div >
                < div className="d-flex justify-content-center align-items-center card" style={{ height: '100px' }}>
                    <p>You must run your code first</p>
                </div >
            )} */}




            < div className="row  pt-3">
                <div className="col-md-3">
                    <Button variant="outline-primary" size="sm" onClick={handleIconChange}>
                        <div className="d-flex align-items-center">
                            <div style={{ lineHeight: '16px' }}>console</div>
                            {
                                icon ?
                                    <MdKeyboardArrowDown style={{ fontSize: '16px' }} />

                                    :
                                    <MdKeyboardArrowUp style={{ fontSize: '16px' }} />
                            }
                        </div>
                    </Button>
                </div>
            </div >
        </>
    );
};

export default OutputWindow;