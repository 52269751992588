import React from "react";
import { useForm } from "../../utills/useForms";
import { useState, useEffect } from "react";
import {
  Addjobprefrencedata,
  updatejobprefrence,
} from "../../state/action/jobprefrenceAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Controls from "../../components/controls/Controls";
import AutohideExample from "../../utills/Notification";
import { Loader } from "../../utills/Loader";

const initialValues = {
  title: "",
};

const Addjobprefer = (props) => {
  const {
    setIsEdit,
    isEdit,
    handleAddCloseSwitch,
    openAddSwitchModel,
    skillDataList,
    refreshScreen,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : "This field is required.";


    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [setOpenAddSwitchModel] = useState(false);
  const handleAddSwitchModel = () => setOpenAddSwitchModel(true);
  //  const handleAddCloseSwitch = () => setOpenAddSwitchModel(false);
  const { enqueueSnackbar } = useSnackbar();

  const [JobList, setJobList] = useState([]);
  const [view, setView] = useState(false);
  const [show, setShow] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const closeAddSkillPopup = () => {
    handleAddCloseSwitch();
    resetForm();
  }

  useEffect(() => {
    setIsEdit("");
  }, []);

  const handleSubmit = async () => {

    // e.preventDefault();
    //resetForm();
    //setFilterData(!filterData);
    if (validate()) {
      const body = {
        title: values.title,
      };

      //edit
      if (isEdit) {
        const res = await updatejobprefrence(skillDataList.id, body);
        setView(true);

        if (res && res.status === true) {
          refreshScreen();
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }

      if (!isEdit) {
        const res = await Addjobprefrencedata(body);
        if (res && res.status === true) {
          refreshScreen();
          setShow({
            isOpen: true,
            message: res?.message,
            type: "success",
          });
          setView(false);
        } else {
          setShow({
            isOpen: true,
            message: res?.message,
            type: "error",
          });
          setView(false);
        }
      }
      handleAddCloseSwitch();
      resetForm();
    } else {
      setShow({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    }
  };

  //details edit
  useEffect(() => {
    if (
      skillDataList.id != "" &&
      skillDataList.id != null &&
      skillDataList.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        title: skillDataList?.title,
      });
    } else {
      resetForm();
    }
  }, [skillDataList]);

  return (
    <div>
      <Loader view={view} />
      {!view && (
        <Modal
          size="md"
          show={openAddSwitchModel}
          onHide={closeAddSkillPopup}
          aria-labelledby=""
          maxLength={50}
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton onClick={closeAddSkillPopup}></Modal.Header>
          <Modal.Body>
            <h3>Add job Preferences </h3>
            <Controls.Input
              className="form-control font-16-24-05 h-auto py-[10px] px-3"
              placeholder="enter job preferences"
              name="title"
              type="text"
              lable="Title"
              value={values.title}
              onChange={handleInputChange}
              error={errors.title}
              maxLength={50}
            />
            {Boolean(errors.title) ? (
              <p className="text-invalid">{errors.title}</p>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button

              className="btn btn-primary h-44 fw-medium m-2"
              onClick={() => { closeAddSkillPopup(); handleAddCloseSwitch() }}

            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
                // handleAddCloseSwitch();
              }}
              variant="primary"
              className="h-auto px-3 py-2 fw-medium m-0"
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <AutohideExample show={show} setShow={setShow} />
    </div>
  );
};

export default Addjobprefer;
